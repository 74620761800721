import React from 'react'
import { Switch, Route } from 'react-router-dom'

export default function AdminRoutes() {
    return (
        <Switch>
            <Route path='/'>

            </Route>
            <Route path='/:id'>

            </Route>
        </Switch>
    )
}
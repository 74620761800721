import { Paper, makeStyles, Typography, Button, Container } from '@material-ui/core'
import React from 'react'
import { AccountBalanceOutlined } from '@material-ui/icons'

const mytheme = makeStyles(theme => ({
    container: {
        padding: theme.spacing(2),
        textAlign: 'center',
    },
    message: {
        fontSize: '28px',
        marginBottom: theme.spacing(2)
    },
    icon: {
        fontSize: '40px'
    }
}))

export default function ActiveBilling({ goto }) {

    const classes = mytheme()

    return <Container maxWidth="sm">
        <Paper className={classes.container}>
            <AccountBalanceOutlined className={classes.icon} />
            <Typography as="h1" className={classes.message}>Please Activate Billing to view Devices</Typography>
            {!!goto && <Button onClick={goto} color="primary" variant="contained">Billing</Button>}
        </Paper>
    </Container>
}
import React from 'react'
import { Grid, Typography, Card, CardContent, Button } from '@material-ui/core'


export default function InviteCard({ invite, roles, remove, resend }) {
    const role = roles.find(r => r.id === invite.roleId)

    return (
        <Grid item xs={12} sm={8} md={6} lg={4} xl={3}>
            <Card elevation={3} >
                <CardContent>
                    <Typography align="center" variant="h6">{invite.email}</Typography>
                    <Typography align="center" variant="body1">{role ? role.name : ''}</Typography>
                    <Grid container justify="center" style={{ marginTop: 5 }}>
                        <Button variant="contained" size="small" color="primary" onClick={() => resend(invite)} style={{ marginRight: 5 }}>Resend</Button>
                        <Button variant="contained" size="small" onClick={() => remove(invite)} style={{ marginLeft: 5 }}>Remove</Button>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    )
}
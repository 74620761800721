const initialState = {
    theme: window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light',
    drawerOptions: {},
    account: { id: '' },
    role: {},
    billing: {},
    user: {},
    notifications: [],
    auth: {},
    location: null,
    viewport: { width: window.innerWidth, height: window.innerHeight },
    error: null,
    views: {
        devices: 'Table',
        users: 'Table'
    },
    onlineOnly: false,
    path: ''
}

export default function appReducer(state = initialState, action) {
    switch (action.type) {
        case 'logout': {
            let base = JSON.parse(JSON.stringify(initialState))
            base.theme = state.theme
            return base
        }
        case 'setAuth': {
            return {
                ...state,
                auth: action.payload
            }
        }
        case 'toggleTheme': {
            return {
                ...state,
                theme: state.theme === 'dark' ? 'light' : 'dark'
            }
        }
        case 'drawerOptions': {
            return {
                ...state,
                drawerOptions: action.payload
            }
        }
        case 'changeAccount': {
            return {
                ...state,
                account: action.payload
            }
        }
        case 'setNotifications': {
            return { ...state, notifications: action.payload }
        }
        case 'addNotifications': {
            let notifications = state.notifications
            notifications.push(action.payload)
            return { ...state, notifications: notifications, }
        }
        case 'setViewport': {
            return { ...state, viewport: action.payload }
        }
        case 'setView': {
            let views = JSON.parse(JSON.stringify(state.views))
            views[action.payload.view] = action.payload.value
            if (views.hasOwnProperty('[object Object]')) delete views['[object Object]']

            return { ...state, views: views }
        }
        case 'setBilling': {
            return { ...state, billing: action.payload }
        }
        case 'setRole': {
            return { ...state, role: action.payload }
        }
        case 'setUser': {
            return { ...state, user: action.payload }
        }
        case 'setLocation': {
            return { ...state, location: action.payload }
        }
        case 'setError': {
            return { ...state, error: action.payload }
        }
        case 'setOnlineOnly': {
            return { ...state, onlineOnly: action.payload }
        }
        case 'setPath':
            return { ...state, path: action.payload }
        default: return state;
    }
}
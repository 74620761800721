import React from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';

const useStyle = makeStyles(theme => ({
    container: {
        marginTop: theme.spacing(2)
    },
    buttons: {
        marginTop: theme.spacing(1),
        display: 'flex',
        justifyContent: 'flex-end'
    },
    button: {
        marginRight: theme.spacing(1)
    }
}))

const CheckoutForm = ({ onSave, onCancel, subscribe = false, cancel = false }) => {
    const classes = useStyle()
    const stripe = useStripe();
    const elements = useElements();
    const theme = useTheme()
    const cardOptions = {
        style: {
            base: {
                color: theme.palette.text.primary
            }
        }
    }

    const handleSubmit = async (event) => {
        // Block native form submission.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);

        // Use your card Element with other Stripe.js APIs
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            console.log('[error]', error);
        } else {
            onSave(paymentMethod)
            console.log('[PaymentMethod]', paymentMethod);
        }
    };

    return (
        <form onSubmit={handleSubmit} className={classes.container}>
            <CardElement options={cardOptions} />
            <div className={classes.buttons}>
                <Button className={classes.button} variant="contained" type="submit" color="primary" disabled={!stripe}>{'Save'}</Button>
                {(!subscribe || cancel) && <Button variant="contained" type="button" onClick={onCancel}>Cancel</Button>}
            </div>

        </form>
    );
};

export default CheckoutForm
import React, { useState, Fragment } from 'react'
import { Card, CardContent, TextField, Typography, useTheme, FormControl, InputLabel, Input, InputAdornment, IconButton, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Search as SearchIcon } from '@material-ui/icons'
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import { lookupPostcode } from 'utils/postcode'
import Leaflet from 'Components/Map/leaflet'

const myStyle = makeStyles(theme => ({
    card: {
        marginBottom: theme.spacing(3)
    }
}))

export default function EventBasicSettings({ event, setEvent }) {
    const [postcode, setPostcode] = useState('')
    const [map, setMap] = useState(null)
    const theme = useTheme()
    const classes = myStyle()

    const checkPostcode = async (e) => {
        e.preventDefault()
        if (!postcode) return
        const result = await lookupPostcode(postcode)
        if (!result) return
        setEvent({ ...event, location: [result.latitude, result.longitude] })
        if (map) {
            map.fitBounds([[result.latitude, result.longitude]])
        }
    }

    const mapClick = (e) => {
        setEvent({ ...event, locationLatitude: e.latlng.lat, locationLongitude: e.latlng.lng })
    }

    const marker = {
        location: [event.locationLatitude, event.locationLongitude],
        draggable: true,
        autoPan: true,
        color: theme.palette.primary.main,
        pushPinOption: {},
        infoboxOption: {},
        onMove: (i, e) => setEvent({ ...event, locationLatitude: e.lat, locationLongitude: e.lng })
    }
    return (
        <Fragment>
            <Card className={classes.card}>
                <CardContent>
                    <Typography variant="h5">Basic Info</Typography>
                    <TextField fullWidth label="Event Name" value={event.name} onChange={e => setEvent({ ...event, name: e.target.value })} />
                    <TextField fullWidth label="Organiser" value={event.organiser} onChange={e => setEvent({ ...event, organiser: e.target.value })} />
                </CardContent>
            </Card>
            <Card className={classes.card}>
                <CardContent>
                    <Typography variant="h5">Location</Typography>
                    <div style={{ height: 300, marginBottom: 5 }}>
                        <Leaflet center={[event.locationLatitude, event.locationLongitude]} setMap={setMap} onClick={mapClick} markers={[marker]} />
                    </div>
                    <form onSubmit={checkPostcode}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="postcode">Post Code</InputLabel>
                            <Input
                                id="postcode"
                                value={postcode}
                                onChange={e => setPostcode(e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton aria-label="lookup postcode" type="submit" >
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </form>
                </CardContent>
            </Card>
            <Card className={classes.card}>
                <CardContent>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <Typography variant="h5">Date Time</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <KeyboardDatePicker minDate={moment()} autoOk required value={event.startDate} fullWidth variant="inline" format="YYYY-MM-DD" margin="normal" label="Start Date" onChange={d => setEvent({ ...event, startDate: d })} />
                            </Grid>
                            <Grid item xs={6}>
                                <KeyboardTimePicker ampm={false} required value={event.startDate} fullWidth variant="inline" label="Start Time" margin="normal" onChange={t => setEvent({ ...event, startDate: t })} />
                            </Grid>
                            <Grid item xs={6}>
                                <KeyboardDatePicker minDate={event.startDate} autoOk required value={event.endDate} fullWidth variant="inline" format="YYYY-MM-DD" margin="normal" label="End Date" onChange={d => setEvent({ ...event, endDate: d })} />
                            </Grid>
                            <Grid item xs={6}>
                                <KeyboardTimePicker ampm={false} required value={event.endDate} fullWidth variant="inline" label="End Time" margin="normal" onChange={t => setEvent({ ...event, endDate: t })} />
                            </Grid>
                        </Grid>
                    </MuiPickersUtilsProvider>
                </CardContent>
            </Card>
        </Fragment >
    )
}
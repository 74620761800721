import React from 'react'
import { TextField, FormControlLabel, Switch, FormControl, InputLabel, Select, MenuItem, makeStyles, Grid, Typography } from '@material-ui/core'

const mytheme = makeStyles(theme => ({
    container: {
        [theme.breakpoints.down("sm")]: {
            height: '150px',
            overflowY: 'auto',
        }
    },
    textField: {
        marginBottom: theme.spacing(3)
    }
}))

export default function Advanced({ settings, setSet, device }) {
    const classes = mytheme()

    const updateGpsWaitTime = e => {
        const val = parseInt(e.currentTarget.value) * 1000
        setSet({
            target: { name: 'gpsWaitForFixTime', value: val }
        })
    }

    const gpsWaitTime = parseInt(settings.gpsWaitForFixTime) / 1000

    return (
        <Grid className={classes.container} container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="caption">IMEI: {device.imei}</Typography>
            </Grid>
            <Grid item xs={6}>
                <TextField fullWidth type="number" label="gpsWaitForFixTime" name="gpsWaitForFixTime" onChange={updateGpsWaitTime} value={gpsWaitTime} />
            </Grid>
            <Grid item xs={6}>
                <TextField fullWidth type="number" label="updateInterval" name="updateInterval" onChange={setSet} value={settings.updateInterval} />
            </Grid>
            <Grid item xs={6}>
                <TextField fullWidth type="number" label="gpsUpdateFrequency" name="gpsUpdateFrequency" onChange={setSet} value={settings.gpsUpdateFrequency} />
            </Grid>
            <Grid item xs={6}>
                <TextField fullWidth type="number" label="gpsSentencesWithFixBeforeSend" name="gpsSentencesWithFixBeforeSend" onChange={setSet} value={settings.gpsSentencesWithFixBeforeSend} />
            </Grid>
            <Grid item xs={6}>
                <TextField fullWidth type="number" label="gpsSatalitesRequired" name="gpsSatalitesRequired" onChange={setSet} value={settings.gpsSatalitesRequired} />
            </Grid>
            <Grid item xs={6}>
                <TextField fullWidth type="number" label="gpsReadCycles" name="gpsReadCycles" onChange={setSet} value={settings.gpsReadCycles} />
            </Grid>
            <Grid item xs={6}>
                <TextField fullWidth type="number" label="hdop" name="hdop" onChange={setSet} value={settings.hdop} />
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <InputLabel id="debug-label">Debug</InputLabel>
                    <Select labelId="debug-label" id="debug" value={settings.debug} name="debug" onChange={setSet}>
                        <MenuItem value={0}>None</MenuItem>
                        <MenuItem value={1}>Errors</MenuItem>
                        <MenuItem value={2}>Warning</MenuItem>
                        <MenuItem value={3}>Information</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <FormControlLabel
                control={<Switch checked={settings.gpsLowPower} onChange={setSet} name="gpsLowPower" color="primary" />}
                label="gpsLowPower"
            />
            <FormControlLabel
                control={<Switch checked={settings.gpsagps} onChange={setSet} name="gpsagps" color="primary" />}
                label="gpsagps"
            />
            <FormControlLabel
                control={<Switch checked={settings.sleepA9g} onChange={setSet} name="sleepA9g" color="primary" />}
                label="sleepA9g"
            />
            <FormControlLabel
                control={<Switch checked={settings.sleepCPU} onChange={setSet} name="sleepCPU" color="primary" />}
                label="sleepCPU"
            />
            <FormControlLabel
                control={<Switch checked={settings.sddel} onChange={setSet} name="sddel" color="primary" />}
                label="sddel"
            />
            <FormControlLabel
                control={<Switch checked={settings.deviceUpdate} onChange={setSet} name="deviceUpdate" color="primary" />}
                label="deviceUpdate"
            />
            <FormControlLabel
                control={<Switch checked={settings.factoryReset} onChange={setSet} name="factoryReset" color="primary" />}
                label="factoryReset"
            />
            <FormControlLabel
                control={<Switch checked={settings.stayAwake} onChange={setSet} name="stayAwake" color="primary" />}
                label="stayAwake"
            />

        </Grid >
    )
}
import { List, ListItem, ListItemText, ListItemSecondaryAction, Checkbox, Grid, Typography } from '@material-ui/core'
import React from 'react'

export default function MapGeofence({ fences = [], enabled = [], toggleFence = () => { }, onFinish = () => { } }) {
    return (
        <div style={{ padding: 20 }}>
            <Grid container spacing={2}>
                <Grid item={12}>
                    <Typography variant="h5">Geofencing</Typography>
                </Grid>
                <Grid item xs={12}>
                    <List component="nav">
                        {fences.map((fence) => <ListItem key={fence.id} dense button onClick={() => toggleFence(fence.id)}>
                            <ListItemText primary={fence.name} />
                            <ListItemSecondaryAction>
                                <Checkbox edge="start" checked={(enabled || []).indexOf(fence.id) !== -1} onClick={() => toggleFence(fence.id)} tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': fence.id }} />
                            </ListItemSecondaryAction>
                        </ListItem>)}
                    </List>
                </Grid>

            </Grid>
        </div>
    )
}
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { AreaChart, Area, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts';
import { useTheme } from '@material-ui/styles';

export default function AdminDistanceBreakdown() {
    const [data, setData] = useState([])
    const theme = useTheme()
    console.log(theme)

    useEffect(() => {
        const lineData = []
        const date = moment().subtract(1, 'month')
        do {
            lineData.push({ name: date.format('MMM DD'), distance: Math.round(Math.random() * (500 - 101) + 100) })
            date.add(1, 'day')
        }
        while (!date.isSame(moment(), 'day'))
        setData(lineData)
    }, [])

    return (
        <ResponsiveContainer>
            <AreaChart data={data}>
                <XAxis dataKey="name" hide={true} />
                <YAxis axisLine={false} tickLine={false} tickFormatter={value => value + 'km'} />
                <Tooltip contentStyle={{ color: theme.palette.common.black, fontFamily: theme.typography.fontFamily }} formatter={value => value + 'km'} />
                <Area type="monotone" dataKey="distance" stroke={theme.palette.primary.main} fillOpacity={0.9} fill={theme.palette.primary.main} />
            </AreaChart>
        </ResponsiveContainer>
    )
}
import { Card, CardContent, Container, createMuiTheme, CssBaseline, Grid, Link, Typography, ThemeProvider } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import LoadingIcon from 'Components/LoadingIcon'
import GenericMap from 'Components/Map'
import { formatDate } from 'utils/format'
import { get } from 'utils/resources'
import { deepPurple, teal } from '@material-ui/core/colors';

const myTheme = createMuiTheme({
    palette: {
        primary: { main: teal[500] },
        secondary: { main: deepPurple[500] }
    }
})

export default function Event() {
    const [event, setEvent] = useState(null)
    const { id } = useParams()

    useEffect(() => {
        get('event/public/' + id, true, true).then(setEvent)
    }, [id])

    if (!event) return <LoadingIcon />

    const marker = {
        location: [event.locationLatitude, event.locationLongitude],
        draggable: true,
        color: teal[500],
        pushPinOption: {},
        infoboxOption: {},
    }

    return (
        <ThemeProvider theme={myTheme}>
            <Container maxWidth="lg" style={{ marginTop: 20 }}>
                <CssBaseline />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={8}>
                        <Card>
                            <CardContent>
                                <Typography variant="h3" align="center">{event.name}</Typography>
                                <div style={{ textAlign: 'center' }}><img src={event.headerUrl} alt="header" /></div>
                                <div dangerouslySetInnerHTML={{ __html: event.description }} />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4}>
                        <Card style={{ marginBottom: 10 }}>
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography variant="h5" align="center">Date/Time</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography>Start Date</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography><strong>{formatDate(event.startDate, null, true)}</strong></Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography>Start Time</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography><strong>{formatDate(event.startDate, 'HH:mm')}</strong></Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography>End Time</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography><strong>{formatDate(event.endDate, 'HH:mm')}</strong></Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        <Card style={{ marginBottom: 10 }}>
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography variant="h5" align="center">Location</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div style={{ height: 200, marginBottom: 10 }}>
                                            <GenericMap fixed hideOptions smallZoom defaultShowMe={false} pushpins={[marker]} defaultLocation={[event.locationLatitude, event.locationLongitude]} />
                                        </div>
                                        <Typography align="center">
                                            <Link href={`https://www.google.com/maps/dir/?api=1&destination=${event.locationLatitude},${event.locationLongitude}`} target="_blank">Directions</Link>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                </Grid>
            </Container>
        </ThemeProvider>
    )
}
import React, { useState, useEffect, useMemo, useRef } from 'react';
import LoadingIcon from 'Components/LoadingIcon';
import { get, put } from 'utils/resources'
import { makeStyles, Container, Grid, Snackbar, Tab, Tabs, Select, MenuItem } from '@material-ui/core';

import { Alert as MuiAlert } from '@material-ui/lab'
import Basic from './device/basic'
import Map from './device/map'
import Sharing from './device/sharing'
import Notifications from './device/notifications'
import queryString from 'query-string'
import { useLocation } from 'react-router';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const mytheme = makeStyles(theme => ({
    container: {
        padding: theme.spacing(4),
        [theme.breakpoints.down("sm")]: {
            padding: 0,
            paddingTop: theme.spacing(1),
            height: '100%'
        }
    },
    actualcontainer: {
        marginTop: theme.spacing(3),
        [theme.breakpoints.down("sm")]: {
            margin: 0,
            height: 'calc(100% - 38px)',
            overflowY: 'auto',
            overflowX: 'hidden'
        }
    },
    hideMob: {
        [theme.breakpoints.down("sm")]: {
            display: 'none',
        }
    },
    showMobile: {
        display: 'none',
        [theme.breakpoints.down("sm")]: {
            display: 'block',
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        }
    },
    forms: {
        padding: theme.spacing(2)
    },
    formControl: {
        marginBottom: theme.spacing(1)
    }
}))

export default function Device({ id, close }) {
    const history = useHistory()
    const [device, setDevice] = useState({})
    const [settings, setSettings] = useState({})
    const [loaded, setLoaded] = useState(false)
    const [alert, setAlert] = useState('')
    const [options, setOptions] = useState({})
    const [selected, setSelected] = useState('basic')
    const { search } = useLocation()
    const user = useSelector(state => state.user)
    const queryParams = useMemo(() => queryString.parse(search), [search])
    const timer = useRef(null)

    useEffect(() => {
        (async () => {
            try {
                let device = await get('device/{accountid}/' + id)
                let settings = await get('devicesettings/{accountid}/' + id)
                if (!device || !device.id) return
                setDevice(device)
                const myOptions = device.registeredDeviceOptions.colour ? device.registeredDeviceOptions : device.registeredDeviceOptions.userRegisteredDeviceOptions.find(o => o.userId === user.id)
                setOptions(myOptions || {})
                setSettings(settings)
                setLoaded(true)
                const query = queryString.parse(window.location.search)
                if (query.page) {
                    setSelected(query.page)
                }
            } catch (e) {
                history.push('/devices')
                console.log(e)
            }
        })()

        timer.current = setTimeout(async () => {
            setDevice(await get('device/{accountid}/' + id))
        }, 10000)

        return () => {
            if (timer.current) clearTimeout(timer.current)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    useEffect(() => {
        if (selected !== queryParams.page) {
            setSelected(queryParams.page || 'basic')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams])

    const save = async function () {
        await put('device/{accountid}/' + id, { name: device.name })
        await put('device/{accountid}/' + id + '/options' + (!device.isOwner ? '/user' : ''), {
            colour: options.colour
        })
        await put('devicesettings/{accountid}/' + id, settings)
        close()
        history.push('/devices')
    }

    const setVal = function (ev) {
        setDevice({ ...device, [ev.target.name]: ev.target.value })
    }

    const setSet = function (e) {
        const isCheck = e.target.type === 'checkbox'
        setSettings({ ...settings, [e.target.name]: isCheck ? e.target.checked : e.target.value })
    }

    const getDevice = async function () {
        const _device = await get('device/{accountid}/' + device.id)
        setDevice(_device)
        const myOptions = _device.registeredDeviceOptions.colour ? _device.registeredDeviceOptions : _device.registeredDeviceOptions.userRegisteredDeviceOptions.find(o => o.userId === user.id)
        setOptions(myOptions || {})
        close()
    }

    const changeView = function (view) {
        if (view === 'history') {
            return history.push('/history?d=' + device.id)
        }
        setSelected(view)
        if (window.history.pushState) {
            var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?page=' + view;
            window.history.pushState({ path: newurl }, '', newurl);
        }
    }

    const classes = mytheme()

    if (!loaded) return <LoadingIcon />

    return (
        <Container maxWidth="lg" className={classes.container}>
            <Tabs value={selected} className={classes.hideMob} centered onChange={(e, v) => changeView(v)}>
                <Tab label="Settings" value={"basic"} />
                <Tab label="Map" value={"map"} />
                <Tab label="Notifications" value={"notifications"} />
                {device.isOwner && <Tab label="Sharing" value={"sharing"} />}
            </Tabs>
            <div className={clsx(classes.showMobile, classes.formControl)} >
                <Select value={selected} fullWidth onChange={e => changeView(e.target.value)}>
                    <MenuItem value="basic" >Settings</MenuItem>
                    <MenuItem value="map" >Map</MenuItem>
                    <MenuItem value="history" >History</MenuItem>
                    <MenuItem value="notifications" >Notifications</MenuItem>
                    {device.isOwner && <MenuItem value="sharing" >Sharing</MenuItem>}
                </Select>
            </div>
            <Grid container justify="center" className={clsx(classes.actualcontainer, selected !== 'map' ? classes.forms : '')}>
                {selected === 'basic' && <Basic device={device} close={close} settings={settings} save={save} setVal={setVal} setSettings={setSettings} setAlert={setAlert} getDevice={getDevice} setSet={setSet} options={options} setOptions={setOptions} />}
                {selected === 'map' && <Map device={device} />}
                {selected === 'sharing' && <Sharing device={device} getDevice={getDevice} setAlert={setAlert} />}
                {selected === 'notifications' && <Notifications device={device} getDevice={getDevice} setAlert={setAlert} />}
            </Grid>

            <Snackbar open={!!alert} autoHideDuration={2000} onClose={() => setAlert('')}>
                <Alert onClose={() => setAlert('')} severity="success">{alert}</Alert>
            </Snackbar>
        </Container>
    )
}
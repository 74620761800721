import React from 'react'
import { Switch, Route } from 'react-router'

import AllRoutes from './routes'
import SingleRoute from './route'

export default function RoutePlannerTemplate() {
    return (
        <Switch>
            <Route exact path="/routeplanner">
                <AllRoutes />
            </Route>
            <Route path="/routeplanner/:id">
                <SingleRoute />
            </Route>
        </Switch>
    )
}
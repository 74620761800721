import React from 'react'
import { TextField, Container, Typography } from '@material-ui/core';

export default function Step3({ device, setDevice }) {
    return (
        <Container maxWidth="sm">
            <Typography variant="h6" align="center">Name your Device</Typography>
            <TextField id="name" fullWidth type="text" label="Name" name="name" onChange={e => setDevice({ ...device, name: e.target.value })} value={device.name} />
        </Container>
    )
}
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Container, Grid, Typography, Hidden } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { ArrowForward, ArrowBack, Add } from '@material-ui/icons'
import clsx from 'clsx'
import { useHistory } from 'react-router'

const myStyle = makeStyles(theme => {
    const borderColour = theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[900]
    return {
        addEvent: {
            position: 'absolute',
            display: 'none',
            textAlign: 'center',
            fontSize: 12,
            cursor: 'pointer',
            bottom: 0,
            width: '100%',
            [theme.breakpoints.down("sm")]: {
                display: 'block'
            },
        },
        day: {
            position: 'relative',
            height: 100,
            borderBottom: `2px solid ${borderColour}`,
            borderRight: `2px solid ${borderColour}`,
            transition: 'background-color 0.2s ease',
            '&:hover': {
                backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[400] : theme.palette.grey[600],
                '& $addEvent': {
                    display: 'block'
                }
            },
            [theme.breakpoints.down("sm")]: {
                border: `1px solid ${borderColour} !important`
            },
        },
        event: {
            textAlign: 'center',
            backgroundColor: theme.palette.primary.main,
            cursor: 'pointer',
            marginBottom: 2
        },
        borTop: {
            borderTop: `2px solid ${borderColour}`
        },
        borLeft: {
            borderLeft: `2px solid ${borderColour}`
        },
        noBottom: {
            borderBottom: 'none'
        },
        first: {
            marginLeft: -1,
            [theme.breakpoints.down("sm")]: {
                marginLeft: 0
            }
        },
        weekend: {
            backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[100] : theme.palette.grey[700]
        },
        today: {
            backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[600]
        },
        moveIcon: {
            cursor: 'pointer',
            transition: 'color 0.3s ease',
            marginLeft: 5,
            marginRight: 5,
            '&:hover': {
                color: theme.palette.primary.main
            }
        }
    }
})

export default function EventsCalendar({ events, addEvent = () => { } }) {
    const [weeks, setWeeks] = useState([])
    const [month, setMonth] = useState(moment())

    const classes = myStyle()
    const history = useHistory()

    useEffect(() => {
        const _year = month.year() + "";
        const _month = (month.month() + 1 + "").padStart(2, "0");
        const _weeks = []
        let week = {}
        _weeks.push(week)
        const day = moment.utc(_year + "/" + _month, "YYYY/MM").startOf("month");
        let weekDay = day.weekday();
        week[weekDay] = {
            entries: [],
            date: moment.utc(day),
            day: moment.utc().format("ddd"),
            dateStr: moment.utc(day).format("D"),
        };
        day.add(1, "day");
        while (day.date() > 1) {
            let weekDay = day.weekday();
            if (weekDay === 0) {
                let newWeek = {};
                _weeks.push(newWeek);
                week = newWeek;
            }
            const dayEvents = events.filter(e => moment(e.startDate).isSame(day, 'day'))
            week[weekDay] = {
                entries: dayEvents,
                date: moment.utc(day),
                day: day.format("ddd"),
                dateStr: moment.utc(day).format("D"),
            };
            day.add(1, "day");
        }
        setWeeks(_weeks)
    }, [month, events])

    const moveForward = () => {
        const date = moment(month).add(1, "month");
        setMonth(date)
    }

    const moveBack = () => {
        const date = moment(month).subtract(1, "month");
        setMonth(date)
    }

    const daysOfWeek = [0, 1, 2, 3, 4, 5, 6]
    return <Container maxWidth="lg" className={classes.container}>
        <Grid container justify="center" alignItems="center" style={{ marginBottom: 10 }}>
            <ArrowBack className={classes.moveIcon} onClick={moveBack} />
            <div style={{ width: 180 }}>
                <Typography variant="h6" align="center">{month.format("MMMM")} - {month.format('YYYY')}</Typography>
            </div>
            <ArrowForward className={classes.moveIcon} onClick={moveForward} />
        </Grid>
        <Grid container >
            <Hidden only={["xs", "sm"]}>
                {daysOfWeek.map(day => <Grid item xs={12} sm={12} md key={day}>
                    <Typography align="center" variant="body1">{moment().day(day).format('ddd')}</Typography>
                </Grid>)}
            </Hidden>
        </Grid>
        {weeks.map((week, weekIndex) => <Grid key={'week-' + weekIndex} container className={classes.weeks}>
            {daysOfWeek.map((day) => {
                const isToday = week[day] && moment().isSame(week[day].date, "day")
                const isWeekend = week[day] && (week[day].date.day() === 0 || week[day].date.day() === 6)
                const first = week[day] && week[day].dateStr === "1" && day
                return <Grid key={'week-' + weekIndex + '-day-' + day} item xs={12} sm={12} md>
                    {Boolean(week[day]) && <div className={clsx(
                        classes.day,
                        weekIndex === 0 ? classes.noBottom : '',
                        day === 0 || (week[day].dateStr === "1") ? classes.borLeft : '',
                        week[day] && (week[day].dateStr < 8 || weekIndex === 1) ? classes.borTop : '',
                        isToday ? classes.today : '',
                        isWeekend ? classes.weekend : '',
                        first ? classes.first : ''
                    )}>
                        <Typography variant="body2" align="center">{week[day].dateStr}</Typography>
                        {week[day].entries.map(event => (
                            <div className={classes.event} key={'event_' + event.id} onClick={() => history.push(`events/${event.id}/edit?page=basic`)}>{event.name}</div>
                        ))}
                        <div className={classes.addEvent} onClick={() => addEvent(week[day].date)}>
                            <Add fontSize="small" />
                        </div>
                    </div>
                    }
                </Grid>
            })}
        </Grid>)
        }
    </Container >
}

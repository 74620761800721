import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux'
import Cards from './cards'
import AddCard from './card'
import { post } from 'utils/resources';
import { formatDate } from 'utils/format';
import { makeStyles } from '@material-ui/styles';
import { Typography, Button, Card, CardContent } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    paper: {
        // padding: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    title: {
        marginBottom: theme.spacing(2)
    },
    subscription: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    }
}))
export default function Subscription({ plans, cards, refresh, changePlan }) {
    const classes = useStyles()
    const billing = useSelector(state => state.billing)
    const [addingCard, setAddingCard] = useState(false)


    const save = async function (method) {
        await post('billing/{accountid}/card', {
            paymentMethod: method.id,
            isDefault: true
        })
        refresh()
        setAddingCard(false)
    }


    const plan = plans.find(p => p.id === billing.subscriptionType)
    const sub = billing.stripeSubscription

    return <Fragment>
        <Card className={classes.paper}>
            <CardContent>
                <Typography variant="h6" className={classes.title}>Current Subscription</Typography>
                <div className={classes.subscription}>
                    <div>
                        {plan.recurring.interval && <Typography>{plan.recurring.interval === 'month' ? 'Monthly' : 'Annually'}</Typography>}
                        <Typography variant="caption">{plan.nickname}</Typography>
                    </div>
                    <Typography style={{ lineHeight: 1.5 }}>£{plan.unitAmount / 100}</Typography>
                    <Typography>{sub.status === 'canceled' ? 'Cancelled' : 'Renews ' + formatDate(sub.currentPeriodEnd, null, true)}</Typography>
                    <Button variant="contained" onClick={changePlan}>Change Plan</Button>
                </div>
            </CardContent>
        </Card>
        <Card className={classes.paper}>
            <CardContent>
                <Typography variant="h6" className={classes.title}>Payment Methods</Typography>
                <Cards cards={cards} skipBorder={true} refresh={refresh} />
                {addingCard && <AddCard onSave={save} onCancel={() => setAddingCard(false)} subscribe={false} />}
                {!addingCard && <div style={{ textAlign: 'right' }}><Button variant="contained" onClick={() => setAddingCard(true)}>Add Card</Button></div>}
            </CardContent>
        </Card>
        <Card className={classes.paper}>
            <CardContent>
                <Typography variant="h6" className={classes.title}>Invoice History</Typography>
            </CardContent>
        </Card>
    </Fragment>
}

// class Subscription extends Component {
//     state = {
//         addCard: false,
//         test: false
//     }

//     save(method) {
//         post('billing/{accountid}/card', {
//             paymentMethod: method.id,
//             isDefault: true
//         }).then(() => {
//             this.props.refresh()
//             this.setState({
//                 addCard: false
//             })
//         })
//     }

//     render() {
//         let plan = this.props.plans.find(p => p.id === this.props.billing.subscriptionType)
//         let sub = this.props.billing.stripeSubscription

//         return (
//             <div className="quickFlex center">
//                 <div className="setupSubscription">
//                     <Header as="h2">Current Subscription</Header>
//                     <div className="quickFlex between">
//                         <div className="text-lg">
//                             <div style={{ marginTop: plan.nickname ? '' : '10px' }}>{plan.recurring.interval === 'month' ? 'Monthly' : 'Annually'}</div>
//                             <div className="text-md">{plan.nickname}</div>
//                         </div>
//                         <div className="text-xl" style={{ lineHeight: 1.5 }}>£{plan.unitAmount / 100}</div>
//                         <div className="text-md mar-t10">{sub.status === 'canceled' ? 'Cancelled' : 'Renews ' + formatDate(sub.currentPeriodEnd, null, true)}</div>
//                         <Button onClick={this.props.changePlan}>Change Plan</Button>
//                     </div>
//                     <Divider></Divider>
//                     <Header as="h2">Payment Methods</Header>
//                     <Cards cards={this.props.cards} skipBorder={true} refresh={this.props.refresh} />

//                     {this.state.addCard && <AddCard stripe={this.props.stripe} elements={this.props.elements} onSave={this.save.bind(this)} onCancel={() => this.setState({ addCard: false })} subscribe={false} />}
//                     <Divider></Divider>

//                     <Header as="h2">Invoice History</Header>
//                 </div>
//             </div>
//         );
//     }
// }

// export default connect(state => { return { billing: state.billing } })(Subscription);
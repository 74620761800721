import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { Container, Typography, TextField, FormControl, InputLabel, Select, MenuItem, Grid, Button } from '@material-ui/core'
import { put } from 'utils/resources'

const myTheme = makeStyles(theme => ({
    container: {
        padding: theme.spacing(2),
    },
    textField: {
        marginBottom: theme.spacing(1)
    },
    button: {
        margin: theme.spacing(1)
    }
}))

export default function EditUser({ close, currentUser, roles }) {
    const [user, setUser] = useState({
        title: currentUser ? currentUser.user.title : '',
        firstName: currentUser ? currentUser.user.firstName : '',
        surname: currentUser ? currentUser.user.surname : '',
        roleId: currentUser ? currentUser.roleId : '',
        receiveSOSSMS: currentUser ? currentUser.receiveSOSSMS || false : false
    })
    const [message, setMessage] = useState('')

    useEffect(() => { },)

    const save = async function () {
        setMessage('')
        await put('user/' + currentUser.userId, user)
        if (user.roleId !== currentUser.roleId || user.receiveSOSSMS !== currentUser.receiveSOSSMS) {
            await put('accountuserrole/{accountid}/' + currentUser.id, {
                userId: currentUser.userId,
                roleId: user.roleId,
                receiveSOSSMS: user.receiveSOSSMS
            })
        }
        close()
    }

    const setVal = function (ev) {
        setUser({ ...user, [ev.target.name]: ev.target.value })
    }

    const classes = myTheme()

    return <Container className={classes.container} maxWidth="xs">
        <Typography variant="h6" align="center">Edit User</Typography>
        <form onSubmit={save}>
            <TextField className={classes.textField} id="title" fullWidth type="text" label="Title" name="title" onChange={setVal} value={user.title} />
            <TextField className={classes.textField} id="firstName" fullWidth type="text" label="First Name" name="firstName" onChange={setVal} value={user.firstName} />
            <TextField className={classes.textField} id="surname" fullWidth type="text" label="Surname" name="surname" onChange={setVal} value={user.surname} />
            <FormControl className={classes.textField} fullWidth>
                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                <Select labelId="demo-simple-select-label" name="roleId" id="demo-simple-select" value={user.roleId} onChange={setVal}>
                    {roles.map(r => <MenuItem value={r.id} key={r.id}>{r.name}</MenuItem>)}
                </Select>
            </FormControl>
            <FormControl className={classes.textField} fullWidth>
                <InputLabel id="demo-simple-select-label">SMS Notifications</InputLabel>
                <Select labelId="demo-simple-select-label" name="receiveSOSSMS" id="demo-simple-select" value={user.receiveSOSSMS} onChange={setVal}>
                    <MenuItem value={true}>Enabled</MenuItem>
                    <MenuItem value={false}>Disabled</MenuItem>
                </Select>
            </FormControl>
            {!!message && <Typography color="error">{message}</Typography>}
            <Grid container alignItems="center" justify="center">
                <Button className={classes.button} variant="contained" type="button" onClick={close}>Cancel</Button>
                <Button className={classes.button} variant="contained" color="primary" onClick={save}>Save</Button>
            </Grid>
        </form>
    </Container>
}
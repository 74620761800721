import React, { useEffect, useState, useMemo } from 'react'
import { useHistory, useParams, useLocation } from 'react-router'
import { Container, Tabs, Tab, Menu, MenuItem, SwipeableDrawer, Typography, Grid, Button } from '@material-ui/core'
import { Save, Public } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import queryString from 'query-string'
import { get, put } from 'utils/resources'
import Alert from 'Components/Alert'
import LoadingIcon from 'Components/LoadingIcon'

import Basic from './basic'
import Details from './details'
import Route from './route'
import Attendees from './attendees'
import Confirm from 'Components/Confirm'

const myStyle = makeStyles(theme => ({
    container: {
        position: 'relative',
        [theme.breakpoints.down("sm")]: {
            position: 'fixed',
            height: 'calc(100% - 68px)',
            overflowY: 'auto'
        }
    },
    buttons: {
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        top: 10,
        right: theme.spacing(3),
        '& > * + *': {
            marginLeft: theme.spacing(1),
        },
        [theme.breakpoints.down("sm")]: {
            position: 'relative',
            width: '100%',
            marginBottom: theme.spacing(1),
            left: 0
        }
    },
    options: {
        padding: theme.spacing(2)
    }
}))

function validate(event) {
    const errors = []
    if (!event.name) errors.push('Name Required')
    if (!event.organiser) errors.push('Organiser Required')
    if (!event.startDate) errors.push('Start Date Required')
    if (!event.endDate) errors.push('End Date Required')
    return errors
}

export default function EditEvent() {
    const [event, setEvent] = useState(null)
    const [routes, setRoutes] = useState([])
    const [view, setView] = useState('basic')
    const [alert, setAlert] = useState('')
    const [error, setError] = useState('')
    const [anchorEl, setAnchorEl] = useState(null);
    const [showOptions, setShowOptions] = useState(false)
    const [dateChanged, setDateChanged] = useState(false)
    const [closeOnSave, setCloseOnSave] = useState(false)
    const [showWarningModal, setShowWarningModal] = useState(false)
    const { search } = useLocation()
    const queryParams = useMemo(() => queryString.parse(search), [search])
    const { id } = useParams()
    const history = useHistory()
    const classes = myStyle()

    useEffect(() => {
        get('event/{accountid}/' + id).then(_event => {
            if (_event && (!_event.locationLongitude || !_event.locationLatitude)) {
                _event.locationLatitude = 53.6231179
                _event.locationLongitude = -2.1639978
            }
            setEvent(_event)
        })
    }, [id])

    useEffect(() => {
        get('route/{accountid}').then(setRoutes)
        const query = queryString.parse(window.location.search)
        if (query.page) {
            setView(query.page)
        }
    }, [])

    useEffect(() => {
        if (view !== queryParams.page) {
            setView(queryParams.page || 'basic')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams])

    const updateEvent = (obj) => {
        if (obj.startDate !== event.startDate) {
            setDateChanged(true)
        }
        setEvent(obj)
    }

    const saveEvent = async (close) => {
        setAnchorEl(null)
        const errors = validate(event)
        if (errors.length) {
            setError(errors.map(e => <Typography align="center">{e}</Typography>))
            return
        }

        setCloseOnSave(Boolean(close))

        if (dateChanged) {
            setShowWarningModal(true)
        } else {
            confirmSave()
        }
    }

    const confirmSave = async () => {
        setShowWarningModal(false)
        setDateChanged(false)
        await put('event/{accountid}/' + id, event)
        if (event.routeId) {
            await put('event/{accountid}/' + id + '/route', { routeId: event.routeId })
        }

        setAlert('Event Saved')
        if (closeOnSave) {
            history.push('/events')
        }
    }

    const preview = async () => {
        setAnchorEl(null)
        await saveEvent()
        if (event.public) {
            window.open('/event/' + id, "_blank")
        } else {
            window.open('/events/' + id + '/view', "_blank")
        }
    }

    const changeView = (val) => {
        setView(val)
        if (window.history.pushState) {
            var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?page=' + val;
            window.history.pushState({ path: newurl }, '', newurl);
        }
    }

    if (!event) return <LoadingIcon />

    return (
        <Container maxWidth="lg" className={classes.container}>
            <div className={classes.buttons}>
                <Button
                    onClick={e => setAnchorEl(e.currentTarget)}
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    endIcon={<Save>Save</Save>}
                >Save
                </Button>
                <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                    <MenuItem onClick={() => saveEvent()}>Save</MenuItem>
                    <MenuItem onClick={() => saveEvent(true)}>Save &amp; Exit</MenuItem>
                </Menu>
                <Button
                    onClick={preview}
                    variant="contained"
                    className={classes.button}
                    endIcon={<Public>Preview</Public>}
                >Preview
                </Button>
            </div>
            <Tabs value={view} centered onChange={(e, v) => changeView(v)} style={{ marginBottom: 10 }}>
                <Tab label="Basic" value={"basic"} />
                <Tab label="Details" value={"details"} />
                <Tab label="Route" value={"route"} />
                <Tab label="Attendees" value={"attendees"} />
            </Tabs>
            {view === 'basic' && <Basic event={event} setEvent={updateEvent} />}
            {view === 'details' && <Details event={event} setEvent={updateEvent} />}
            {view === 'route' && <Route event={event} setEvent={updateEvent} routes={routes} />}
            {view === 'attendees' && <Attendees event={event} setEvent={updateEvent} routes={routes} />}
            <Alert alert={alert} setAlert={setAlert} />
            <Alert alert={error} setAlert={setError} status={"error"} />
            <SwipeableDrawer open={showOptions} onOpen={() => setShowOptions(true)} onClose={() => setShowOptions(false)} disableSwipeToOpen anchor="right">
                <Container maxWidth="sm" className={classes.options}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h5" align="center">Options</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Button fullWidth variant="contained" onClick={() => setShowOptions(false)}>Close</Button>
                        </Grid>
                    </Grid>

                </Container>
            </SwipeableDrawer>
            <Confirm
                header="Start Date/Time Changed"
                subheader="We will inform external devices of the change"
                open={showWarningModal}
                confirmText={"Ok"}
                skipDisagree={true}
                onConfirm={confirmSave}
            />
        </Container>
    )
}
import { Card, CardContent, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setPath } from 'store/actions'

export default function SupportTickets() {
    const dispatch = useDispatch()
    const [tickets, setTickets] = useState([])

    useEffect(() => {
        dispatch(setPath('Admin | Support'))
    }, [])

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={4} md={4} lg={4}>
                <Card>
                    <CardContent>
                        <Typography align="center">New Tickets</Typography>
                        <Typography align="center" variant="h3">10</Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
                <Card>
                    <CardContent>
                        <Typography align="center">Pending Tickets</Typography>
                        <Typography align="center" variant="h3">10</Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
                <Card>
                    <CardContent>
                        <Typography align="center">Closed Tickets Today</Typography>
                        <Typography align="center" variant="h3">10</Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Account</TableCell>
                                    <TableCell>Summary</TableCell>
                                    <TableCell>Raised Date</TableCell>
                                    <TableCell>Last Comment</TableCell>
                                    <TableCell>Assignee</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!tickets.length && <TableRow>
                                    <TableCell colSpan="99" align="center">No Tickets</TableCell>
                                </TableRow>}
                                {tickets.map(ticket => (
                                    <TableRow key={ticket.id}>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}
import { Button, Card, CardActions, CardContent, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import LoadingIcon from 'Components/LoadingIcon'
import GenericMap from 'Components/Map'
import Confirm from 'Components/Confirm'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { getDistanceFromLatLonInKm } from 'utils/format'
import { get } from 'utils/resources'


const myStyle = makeStyles(theme => ({
    container: {
        overflowY: 'auto',
        [theme.breakpoints.down("sm")]: {
            height: 'calc(100% - 47px)',
            position: 'fixed'
        }
    },
    cardHeader: {
        marginBottom: theme.spacing(2)
    },
    subtext: {
        fontSize: 16
    },
    subtext2: {
        fontSize: 24
    }
}))

export default function Frontpage() {
    const classes = myStyle()
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const [devices, setDevices] = useState([])
    const [events, setEvents] = useState([])
    const [activeDevices, setActiveDevices] = useState([])
    const [travelledToday, setTravelledToday] = useState([])
    const [selectedDevices, setSelectedDevices] = useState([])
    const [sosAlert, setSosAlert] = useState({})
    const notifications = useSelector(state => state.notifications)
    const account = useSelector(state => state.account)
    const cardRef = useRef()

    const role = useSelector(state => state.role)

    useEffect(() => {
        if (role && account && account.id) {
            getDevices()
            getEvents()
        }
        // eslint-disable-next-line 
    }, [role, account])

    useEffect(() => {
        if (activeDevices.length) {
            getDeviceLocations()
        } else {
            setTravelledToday([])
        }
        // eslint-disable-next-line
    }, [activeDevices])

    useEffect(() => {
        if (notifications.length && devices.length) {
            const soses = notifications.filter(n => n.notificationSeverity === 3)
            if (soses.length) {
                for (const sos of soses) {
                    const alert = { ...sos }
                    const device = devices.find(d => d.device.imei === sos.imei)
                    if (device) {
                        alert.name = device.name
                        alert.id = device.id
                        setSosAlert(alert)
                    }
                }
            }
        }
    }, [notifications, devices])

    const getDevices = async () => {
        if (role.devices === 0) {
            return
        }
        const results = await get('device/{accountid}')
        if (Array.isArray(results)) {
            setDevices(results)
            setLoading(false)
            const online = results.filter(d => moment(d.device.lastOnline) > moment().startOf('day'))
            setActiveDevices(online)
            setSelectedDevices(online.map(d => d.id))
        }
    }

    const getEvents = async () => {
        if (role.events === 0) {
            return
        }
        const results = await get('event/{accountid}')
        if (Array.isArray(results)) {
            setEvents(results)
        }
    }

    const getDeviceLocations = async () => {
        if (role.devices === 0) {
            return
        }
        const results = await Promise.all(activeDevices.map(d => {
            return get('devicelocation/{accountid}/' + d.id + '/' + moment().startOf('day').format() + '/' + moment().endOf('day').format())
        }))
        setTravelledToday(results.filter(r => Array.isArray(r)))
    }

    const toggleDevice = id => {
        const arr = [...selectedDevices]
        const index = arr.indexOf(id)
        if (index === -1) {
            arr.push(id)
        } else {
            arr.splice(index, 1)
        }
        setSelectedDevices(arr)
    }

    const viewDevice = () => {
        const id = sosAlert.id
        setSosAlert({})
        history.push('/devices/' + id + '?page=notifications')
    }

    const upcomingEvents = events.filter(e => moment(e.startDate) > moment().startOf('day'))

    if (loading) return <LoadingIcon />

    let totalDistance = 0
    travelledToday.forEach(device => {
        let deviceDistance = 0
        let lastLocation
        device.forEach(l => {
            if (!lastLocation) {
                lastLocation = l
                return
            }
            deviceDistance += getDistanceFromLatLonInKm(lastLocation.latitude, lastLocation.longitude, l.latitude, l.longitude)
            lastLocation = l
        })
        totalDistance += deviceDistance
    })

    let height = 0
    if (cardRef.current) {
        height = window.innerHeight - 64 - cardRef.current.offsetHeight - 68
    }

    const sos = notifications.filter(n => n.notificationSeverity === 3)
    const high = notifications.filter(n => n.notificationSeverity === 2)
    const med = notifications.filter(n => n.notificationSeverity === 1)
    const low = notifications.filter(n => n.notificationSeverity === 0)

    const showDevices = devices.length > 1
    let lastOnline = devices.length ? moment(devices[0].device.lastOnline) : ''
    if (lastOnline && lastOnline > moment().startOf('day')) {
        lastOnline = lastOnline.format('HH:mm:ss')
    } else if (lastOnline) {
        lastOnline = lastOnline.format('YYYY-MM-DD')
    } else {
        lastOnline = 'Never'
    }

    return (
        <Grid container spacing={3} className={classes.container}>
            {showDevices && <Grid item xs={12} sm={6} md={3} lg={3}>
                <Card>
                    <CardContent>
                        <Typography variant="body1" align="center" className={classes.cardHeader}>Active Devices Today</Typography>
                        <Typography variant="h3" align="center">{activeDevices.length} <span className={classes.subtext2}>/ {devices.length}</span></Typography>
                    </CardContent>
                    <CardActions style={{ justifyContent: 'center' }}>
                        <Button size="small" color="primary" onClick={() => history.push('/devices')}>View Devices</Button>
                    </CardActions>
                </Card>
            </Grid>}
            {!showDevices && <Grid item xs={12} sm={6} md={3} lg={3} >
                <Card>
                    <CardContent>
                        <Typography variant="body1" align="center" className={classes.cardHeader}>Last Online</Typography>
                        <Typography variant="h3" align="center">{lastOnline}</Typography>
                    </CardContent>
                    <CardActions style={{ justifyContent: 'center' }}>
                        <Button size="small" color="primary" onClick={() => history.push('/devices')}>View Devices</Button>
                    </CardActions>
                </Card>
            </Grid>}
            <Grid item xs={12} sm={6} md={3} lg={3}>
                <Card ref={cardRef}>
                    <CardContent>
                        <Typography variant="body1" align="center" className={classes.cardHeader}>Distance Travelled Today</Typography>
                        <Typography variant="h3" align="center">{totalDistance.toFixed(2)} <span className={classes.subtext}>km</span></Typography>
                    </CardContent>
                    <CardActions style={{ justifyContent: 'center' }}>
                        <Button size="small" color="primary" onClick={() => history.push('/history')}>View History</Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
                <Card>
                    <CardContent>
                        <Typography variant="body1" align="center" className={classes.cardHeader}>Upcoming Events</Typography>
                        <Typography variant="h3" align="center">{upcomingEvents.length}</Typography>
                    </CardContent>
                    <CardActions style={{ justifyContent: 'center' }}>
                        <Button size="small" color="primary" onClick={() => history.push('/events')}>View Events</Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
                <Card>
                    <CardContent>
                        {!Boolean(notifications.length) && <Typography variant="body1" align="center" className={classes.cardHeader}>Notifications</Typography>}
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {Boolean(sos.length) && <div style={{ margin: '0 10px' }}>
                                <Typography variant="body1" align="center" className={classes.cardHeader} >SOS</Typography>
                                <Typography variant="h3" align="center" color={'error'} >{sos.length}</Typography>
                            </div>}
                            {Boolean(high.length) && <div style={{ margin: '0 10px' }}>
                                <Typography variant="body1" align="center" className={classes.cardHeader} >High</Typography>
                                <Typography variant="h3" align="center" color={'secondary'} >{high.length}</Typography>
                            </div>}
                            {Boolean(med.length) && <div style={{ margin: '0 10px' }}>
                                <Typography variant="body1" align="center" className={classes.cardHeader} >Meduim</Typography>
                                <Typography variant="h3" align="center" color={'primary'} >{med.length}</Typography>
                            </div>}
                            {Boolean(low.length) && <div style={{ margin: '0 10px' }}>
                                <Typography variant="body1" align="center" className={classes.cardHeader} >Low</Typography>
                                <Typography variant="h3" align="center">{low.length}</Typography>
                            </div>}
                            {!Boolean(notifications.length) && <Typography variant="h3" align="center" >0</Typography>}
                        </div>

                    </CardContent>
                    <CardActions style={{ justifyContent: 'center' }}>
                        <Button size="small" color="primary" onClick={() => history.push('/notifications')}>View NOtifications</Button>
                    </CardActions>
                </Card>
            </Grid>
            {
                Boolean(height) && <Grid item xs={12}>
                    <div style={{ height: height }}>
                        <GenericMap devices={devices} ids={selectedDevices} toggleDevice={toggleDevice} />
                    </div>
                </Grid>
            }
            <Confirm open={Boolean(sosAlert.id)}
                header={sosAlert.message}
                subheader={sosAlert.name}
                confirmText={'View Device'} cancelText={'Cancel'}
                onConfirm={viewDevice}
                onCancel={() => setSosAlert({})} />
        </Grid >
    )
}

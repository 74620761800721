import React, { useRef, useEffect } from 'react'
import { Button, Grid, TextField, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const myStyle = makeStyles({
    hidden: {
        display: 'none'
    }
})

export default function GeofenceOptions({ geofence, setGeofence = () => { }, cancel = () => { }, close = () => { } }) {
    const nameInput = useRef(null)
    const classes = myStyle()

    useEffect(() => {
        if (nameInput.current) {
            nameInput.current.focus()
        }
    }, [])

    return (
        <div style={{ width: 300, padding: 20 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField inputRef={nameInput} label="Name" value={geofence.name} onChange={e => setGeofence({ ...geofence, name: e.target.value })} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel>Alert On</InputLabel>
                        <Select value={geofence.geofenceType} onChange={e => setGeofence({ ...geofence, geofenceType: parseInt(e.target.value) })}>
                            <MenuItem value={1}>Alert of Enter</MenuItem>
                            <MenuItem value={2}>Alert of Exit</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth label="Radius (meters)" type="number" InputLabelProps={{ shrink: true, }} value={geofence.radius} onChange={e => setGeofence({ ...geofence, radius: parseInt(e.target.value) })} />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel fullWidth
                        label="Geofence Active" control={<Checkbox fullWidth checked={geofence.active} onChange={e => setGeofence({ ...geofence, active: !geofence.active })} />} />

                </Grid>
                <Grid item xs={geofence.id ? 12 : 6}>
                    <Button variant="contained" fullWidth color="primary" onClick={close}>Save</Button>
                </Grid>
                <Grid item xs={6} className={geofence.id ? classes.hidden : ''}>
                    <Button variant="contained" fullWidth onClick={cancel}>Cancel</Button>
                </Grid>
            </Grid>
        </div>
    )
}
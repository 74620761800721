export function routeToMapX(route) {
    return `<?xml version="1.0" encoding="UTF-8"?>
    <gpx version="1.0">
        <name>${route.name}</name>
        <wpt lat="${route.pins[0].lat}" lon="${route.pins[0].lng}">
            <ele>0</ele>
            <name>Start</name>
        </wpt>
        <wpt lat="${route.pins[route.pins.length - 1].lat}" lon="${route.pins[route.pins.length - 1].lng}">
            <ele>${route.pins.length}</ele>
            <name>End</name>
        </wpt>
        <trk>
            <name>${route.name}</name>
            <number>1</number>
            <trkseg>
                ${route.pins.map((pin, index) => `<trkpt lat="${pin.lat}" lon="${pin.lng}"><ele>${index}</ele><time></time></trkpt>`).join('\n')}
            </trkseg>
        </trk>
    </gpx>`;
}

// export async function getRoute(locations, type = 'foot') {
//     const result = await fetch(`https://api.davephillips.io/osrm/route/v1/foot/${locations.map(l => `${l.longitude},${l.latitude}`).join(';')}?steps=true&geometries=geojson`)
//         .then(r => r.json())
//     return result
// }

export async function getRoute(locations, type = 'foot') {
    const result = await fetch(`https://routing.openstreetmap.de/routed-${type}/route/v1/driving/${locations.filter(l => l.longitude && l.latitude).map(l => `${l.longitude},${l.latitude}`).join(';')}?steps=true&geometries=geojson`)
        .then(r => r.json())

    return result
}
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import { setPath } from 'store/actions'

export default function AdminDevice() {
    const { id } = useParams()
    const [device, setDevice] = useState(null)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setPath('Admin | Device 1'))
        setDevice({})
    }, [id])
    return (
        <div></div>
    )
}
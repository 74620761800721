import React, { useEffect, useState } from 'react';
import queryString from 'query-string'
import authUtils from 'utils/auth'
import { get } from 'utils/resources';
import LoadingIcon from 'Components/LoadingIcon';
import { post } from 'utils/http';
import { Grid, Button, makeStyles, Paper, Typography, Avatar, TextField, Container } from '@material-ui/core';
import { useHistory } from 'react-router';

const myStyles = makeStyles(theme => ({
    container: {
        height: '100%',
        backgroundImage: 'url(https://source.unsplash.com/user/meiligps/likes)',
        backgroundRepeat: 'no-repeat',
        backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        padding: theme.spacing(3)
    },
    avatar: {
        marginBottom: theme.spacing(2),
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    button: {
        margin: theme.spacing(1)
    }
}))

export default function Invite() {
    const classes = myStyles()
    const history = useHistory()
    const [invite, setInvite] = useState(null)
    const [query, setQuery] = useState(null)
    const [loginObj, setLoginObj] = useState({ email: '', password: '', confirmPassword: '', acceptTerms: true })
    const [working, setWorking] = useState(false)
    const [showingLogin, setShowingLogin] = useState(false)
    const [showingRegister, setShowingRegister] = useState(false)
    const [error, setError] = useState('')
    const [invalid, setInvalid] = useState(false)

    useEffect(() => {
        const query = queryString.parse(window.location.search)
        setQuery(query)
        if (query && query.a && query.t) {
            get('userinvite?t=' + query.t + '&a=' + query.a, true).then(userInviteObj => {
                setInvite(userInviteObj)
                setLoginObj({ ...loginObj, email: userInviteObj.email })
            })
        } else {
            setInvalid(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const accept = async () => {
        await get('userinvite/accept?a=' + query.a + '&t=' + query.t, true)
        if (showingRegister) {
            history.push('/profile?account=' + query.a)
        } else {
            history.push('/?account=' + query.a)
        }
    }

    const decline = async () => {
        history.push('/')
    }

    const submit = async (e) => {
        e.stopPropagation()
        e.preventDefault()
        setWorking(true)
        setError('')
        if (showingRegister) {
            const result = await post("auth/register?token=" + query.t, loginObj)
            if (result && result.id) {
                await login()
            } else if (result.message) {
                setError(result.message)
                setWorking(false)
            }
        } else {
            await login()
        }
    }

    const login = async () => {
        const result = await post('auth/authenticate', { email: loginObj.email, password: loginObj.password }, true)
        if (result && result.id) {
            authUtils.setAuth(result)
            await accept()
        } else if (result.message) {
            setError(result.message)
        }
        setWorking(false)
    }

    const handleInputChange = (e) => {
        setLoginObj({ ...loginObj, [e.target.name]: e.target.value })
    }

    const auth = authUtils.auth()

    let registerText = 'Login'
    if (showingRegister) registerText = 'Register'
    if (working) registerText = 'Working'

    if (invalid) return <Typography variant="h4" align="center">Invalid Request</Typography>
    if (!invite) return <LoadingIcon />

    return (<Grid container alignContent="center" justify="center" className={classes.container}>
        <Paper elevation={6} className={classes.paper}>
            <Grid container justify="center">
                <Avatar className={classes.avatar} src={invite.account.avatar}>{invite.account.name[0]}</Avatar>
            </Grid>
            <Typography variant="h6">
                You have been invited by {invite.name} to view {invite.account.name}'s Meili Expedition Account
            </Typography>
            {(!auth || !auth.id) && <Grid container justify="center">
                <Button variant="contained" className={classes.button} color="primary" onClick={() => { setShowingLogin(true); setShowingRegister(false); setError(''); }}>Login</Button>
                <Button variant="contained" className={classes.button} color="secondary" onClick={() => { setShowingRegister(true); setError(''); }}>Register</Button>
            </Grid>}
            {Boolean(auth) && Boolean(auth.id) && <Grid container justify="center">
                <Button variant="contained" className={classes.button} color="primary" onClick={accept}>Accept</Button>
                <Button variant="contained" className={classes.button} color="secondary" onClick={decline}>Decline</Button>
            </Grid>}
            {(showingLogin || showingRegister) && <Container maxWidth="sm">
                <form onSubmit={submit}>
                    <TextField variant="outlined" margin="normal" required fullWidth label="Email Address" name="email" autoComplete="email" autoFocus onChange={handleInputChange} value={loginObj.email} />
                    <TextField variant="outlined" margin="normal" required fullWidth label="Password" name="password" type="password" autoComplete="current-password" value={loginObj.password} onChange={handleInputChange} />
                    {showingRegister && <TextField variant="outlined" margin="normal" required fullWidth name="confirmPassword" label="Confirm Password" type="password" autoComplete="current-password" value={loginObj.confirmPassword} onChange={handleInputChange} />}
                    <Grid container direction="column" alignItems="center">
                        {Boolean(error) && <div> <Typography style={{ marginBottom: '10px' }}>{error}</Typography></div>}
                        <div>
                            <Button variant="contained" color="primary" type="submit" disabled={working}>{registerText}</Button>
                        </div>
                    </Grid>
                </form>
            </Container>}
        </Paper>
    </Grid>)
}
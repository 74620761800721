import React from 'react';
import constants from '../../utils/constants'
import { logout } from 'store/actions'
import { useHistory } from 'react-router';
import { List, ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction, Divider, Avatar } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux';
import { SpeakerPhone, Map, History, Lock, AccountBalance, Domain, Person, Notifications, Close, ExitToApp, Event, DirectionsWalk, Group, Accessibility, Dashboard, Cached, Adjust } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles';

const myStyle = makeStyles(theme => ({
    close: {
        marginTop: theme.spacing(3)
    },
    container: {
        width: 250
    },
    version: {
        position: 'absolute',
        top: 5,
        right: 5
    }
}))

export default function MobileNav({ close, switchAccount, disabled = false }) {
    const history = useHistory()
    const dispatch = useDispatch()
    const account = useSelector(state => state.account)

    const onLogout = () => {
        dispatch(logout())
        history.push('/login')
        close()
    }

    const handleClick = (path) => {
        history.push('/' + path)
        close()
    }

    const refresh = () => {
        // eslint-disable-next-line
        window.location.href = window.location.href;
    }

    const classes = myStyle()

    let accountIcon = <Group fontSize="large" />
    if (account.accountType === 2) {
        accountIcon = <Domain fontSize="large" />
    } else if (account.accountType === 0) {
        accountIcon = <Accessibility fontSize="large" />
    }

    let accountLabel = 'Team'
    if (account.accountType === 2) {
        accountLabel = "Organisation"
    } else if (account.accountType === 0) {
        accountLabel = "Account"
    }

    return (
        <List className={classes.container}>
            <div className={classes.version}>v.{constants.version}</div>
            <ListItem className={classes.close}>
                <ListItemSecondaryAction>
                    <ListItemIcon onClick={close}>
                        <Close fontSize="large" />
                    </ListItemIcon>
                </ListItemSecondaryAction>
            </ListItem>
            <ListItem button onClick={() => handleClick('')} disabled={disabled}>
                <ListItemIcon>
                    <Dashboard fontSize="large" />
                </ListItemIcon>
                <ListItemText primary="Home" />
            </ListItem>
            <ListItem button onClick={() => handleClick('devices')} disabled={disabled}>
                <ListItemIcon>
                    <SpeakerPhone fontSize="large" />
                </ListItemIcon>
                <ListItemText primary="Devices" />
            </ListItem>
            <ListItem button onClick={() => handleClick('map')} disabled={disabled}>
                <ListItemIcon>
                    <Map fontSize="large" />
                </ListItemIcon>
                <ListItemText primary="Map" />
            </ListItem>
            <ListItem button onClick={() => handleClick('geofence')} disabled={disabled}>
                <ListItemIcon>
                    <Adjust fontSize="large" />
                </ListItemIcon>
                <ListItemText primary="Geofences" />
            </ListItem>
            <ListItem button onClick={() => handleClick('events')} disabled={disabled}>
                <ListItemIcon>
                    <Event fontSize="large" />
                </ListItemIcon>
                <ListItemText primary="Events" />
            </ListItem>
            <ListItem button onClick={() => handleClick('history')} disabled={disabled}>
                <ListItemIcon>
                    <History fontSize="large" />
                </ListItemIcon>
                <ListItemText primary="Device History" />
            </ListItem>
            <ListItem button onClick={() => handleClick('routeplanner')} disabled={disabled}>
                <ListItemIcon>
                    <DirectionsWalk fontSize="large" />
                </ListItemIcon>
                <ListItemText primary="Route Planner" />
            </ListItem>
            <ListItem button onClick={() => handleClick('security')} disabled={disabled}>
                <ListItemIcon>
                    <Lock fontSize="large" />
                </ListItemIcon>
                <ListItemText primary="Security" />
            </ListItem>
            <ListItem button onClick={() => handleClick('billing')} disabled={disabled}>
                <ListItemIcon>
                    <AccountBalance fontSize="large" />
                </ListItemIcon>
                <ListItemText primary="Billing" />
            </ListItem>
            <ListItem button onClick={() => handleClick('details')} disabled={disabled}>
                <ListItemIcon>
                    {accountIcon}
                </ListItemIcon>
                <ListItemText primary={accountLabel} />
            </ListItem>
            <ListItem button onClick={() => handleClick('profile')} disabled={disabled}>
                <ListItemIcon>
                    <Person fontSize="large" />
                </ListItemIcon>
                <ListItemText primary="Profile" />
            </ListItem>
            <ListItem button onClick={() => handleClick('notifications')} disabled={disabled}>
                <ListItemIcon>
                    <Notifications fontSize="large" />
                </ListItemIcon>
                <ListItemText primary="Notifications" />
            </ListItem>
            <Divider />
            <ListItem button onClick={switchAccount}>
                <ListItemIcon>
                    <Avatar alt={account.name} src={account.avatar}>{account.name[0]}</Avatar>
                </ListItemIcon>
                <ListItemText primary="Switch Organisation" />
            </ListItem>
            <Divider />
            <ListItem button onClick={onLogout}>
                <ListItemIcon>
                    <ExitToApp />
                </ListItemIcon>
                <ListItemText primary="Logout" />
            </ListItem>

            <ListItem button onClick={refresh}>
                <ListItemIcon>
                    <Cached />
                </ListItemIcon>
                <ListItemText primary="Refresh" />
            </ListItem>
        </List>
    )
}
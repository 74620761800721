export function logout() {
    return function (dispatch) {
        dispatch({ type: 'logout' })
    }
}

export function toggleTheme() {
    return function (dispatch) {
        dispatch({ type: 'toggleTheme' })
    }
}

export function setAuth(auth) {
    return function (dispatch) {
        dispatch({ type: 'setAuth', payload: auth })
    }
}

export function setDrawerOptions(options) {
    return function (dispatch) {
        dispatch({ type: 'drawerOptions', payload: options })
    }
}

export function setAccount(account) {
    return function (dispatch) {
        dispatch({ type: 'changeAccount', payload: account })
    }
}

export function setNotifications(notifications) {
    return function (dispatch) {
        dispatch({ type: 'setNotifications', payload: notifications })
    }
}

export function addNotifications(notifications) {
    return function (dispatch) {
        dispatch({ type: 'addNotifications', payload: notifications })
    }
}

export function setViewport(data) {
    return function (dispatch) {
        dispatch({ type: 'setViewport', payload: data })
    }
}

export function setView(view, value) {
    return function (dispatch) {
        dispatch({ type: 'setView', payload: { view, value } })
    }
}

export function setBilling(billing) {
    return function (dispatch) {
        dispatch({ type: 'setBilling', payload: billing })
    }
}

export function setRole(role) {
    return function (dispatch) {
        dispatch({ type: 'setRole', payload: role })
    }
}

export function setUser(user) {
    return function (dispatch) {
        dispatch({ type: 'setUser', payload: user })
    }
}

export function setLocation(location) {
    return function (dispatch) {
        dispatch({ type: 'setLocation', payload: location })
    }
}

export function setError(error) {
    return function (dispatch) {
        dispatch({ type: 'setError', payload: error })
    }
}

export function setOnlineOnly(onlineOnly) {
    return function (dispatch) {
        dispatch({ type: 'setOnlineOnly', payload: onlineOnly })
    }
}

export function setPath(path) {
    return function (dispatch) {
        dispatch({ type: 'setPath', payload: path })
    }
}
import React, { Fragment, useEffect, useState, useRef } from 'react';
import ActivateBilling from 'Components/ActivateBilling'
import ActivateAccount from 'Components/ActivateAccount'
import GenericMap from 'Components/Map'
import LoadingIcon from 'Components/LoadingIcon';
import { get, post } from 'utils/resources';
import { useSelector } from 'react-redux'
import { formatDate, getDistanceFromLatLonInKm } from 'utils/format'
import moment from 'moment'
import { makeStyles, Paper, Chip, Avatar, List, ListItem, ListItemText, Fab, ListItemSecondaryAction, FormControl, InputLabel, Select, MenuItem, Typography, Grid, Button, SwipeableDrawer, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Drawer, TextField } from '@material-ui/core';
import { useHistory } from 'react-router';
import PermissionsChecker from 'Components/PermissionsChecker';
import { useTheme } from '@material-ui/styles';
import { Code, PlayForWork } from '@material-ui/icons'
import Alert from 'Components/Alert';
let Microsoft = window.Microsoft;

const useStyles = makeStyles(theme => ({
    container: {
        height: '100%',
        width: '100%',
        display: 'table'
    },
    devices: {
        display: 'table-row',
        width: '100%',
    },
    fill: {
        display: 'table-row',
        width: '100%',
        height: '100%'
    },
    paper: {
        padding: theme.spacing(1),
        marginBottom: theme.spacing(2)
    },
    chipHolder: {
        display: 'flex',
        flexWrap: 'wrap',
        [theme.breakpoints.down("sm")]: {
            display: 'none'
        }
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    mobile: {
        display: 'none',
        [theme.breakpoints.down("sm")]: {
            display: 'block'
        }
    },
    history: {
        display: 'flex',
        height: '100%'
    },
    dates: {
        [theme.breakpoints.down("sm")]: {
            display: 'none'
        },
        overflowY: 'auto',
        height: 'calc(100vh - 165px)',
        width: 250
    },
    map: {
        flex: 1,
        display: 'table-row',
        width: "100%",
        height: "100%"
    },
    formControl: {
        marginBottom: theme.spacing(1)
    },
    none: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    listItem: {
        position: 'relative',
        '&:hover': {
            '& $debug': {
                opacity: 1
            }
        }
    },
    debug: {
        opacity: 0,
        zIndex: 99,
        position: 'absolute',
        right: 5,
        transition: 'opacity 0.3s ease'
    },
    debugMobile: {
        display: 'none',
        [theme.breakpoints.down("sm")]: {
            zIndex: 1000,
            display: 'block',
            position: 'fixed',
            bottom: 80,
            right: 10
        },
    }
}));

export default function RouteHistory() {
    const classes = useStyles()
    const [device, setDevice] = useState(null)
    const [devices, setDevices] = useState([])
    const [date, setDate] = useState('')
    const [dates, setDates] = useState([])
    const [loading, setLoading] = useState(true)
    const [loadingDates, setLoadingDates] = useState(false)
    const [showDebug, setShowDebug] = useState(false)
    const [map, setMap] = useState(null)
    const [bing, setBing] = useState(null)
    const [saveAs, setSaveAs] = useState(null)
    const [routeName, setRouteName] = useState('')
    const [alert, setAlert] = useState('')
    const billing = useSelector(state => state.billing)
    const auth = useSelector(state => state.auth)
    const role = useSelector(state => state.role)
    const chipHolder = useRef(null)
    const routeHistory = useHistory()
    const theme = useTheme()

    useEffect(() => {
        if (auth.isVerified && billing.active && role.devices) {
            get('device').then(devices => {
                devices = devices.filter(d => d.device.allowed && d.device.deviceLocation)
                setDevices(devices)
                if (devices.length) {
                    const params = new URLSearchParams(window.location.search)
                    const id = params.get('d')
                    if (id && devices.some(d => d.id === id)) {
                        setDevice(devices.find(d => d.id === id))
                    } else {
                        setDevice(devices[0])
                    }
                }
                setLoading(false)
            })
        } else {
            setLoading(false)
        }
    }, [auth, billing, role])

    useEffect(() => {
        setDate('')
        if (device) {
            setLoadingDates(true)
            const start = moment().subtract(1, 'month').startOf('day')
            get('devicelocation/{accountid}/' + device.id + '/' + start.toISOString() + '/' + moment().endOf('day').toISOString()).then(history => {
                let dates = {}
                let lastDate
                if (!history || !history.length) {
                    history = device.device.deviceLocation ? [device.device.deviceLocation] : []
                }
                for (let row of history.filter(h => (h.longitude !== 0 || h.latitude !== 0)).reverse()) {
                    let date = formatDate(row.created, null, true)
                    lastDate = date
                    if (!dates[date]) dates[date] = {
                        history: []
                    }
                    dates[date].history.push(row)
                }

                for (let date in dates) {
                    let item = dates[date]
                    item.total = 0
                    let previous
                    for (let row of item.history) {
                        // if (row.hdop > 2) continue
                        if (!previous) {
                            previous = row
                        } else {
                            item.total += getDistanceFromLatLonInKm(previous.latitude, previous.longitude, row.latitude, row.longitude)
                            previous = row
                        }
                    }
                }
                setDates(dates)
                if (lastDate) {
                    setDate(lastDate)
                }
                setLoading(false)
                setLoadingDates(false)
            })
        }
    }, [device])

    useEffect(() => {
        if (date && map && map.fitBounds) {
            const locations = dates[date].history.map(h => [h.latitude, h.longitude])
            map.fitBounds(locations)
        }
        if (date && bing) {
            try {
                const last = dates[date].history[dates[date].history.length - 1]
                bing.setView({
                    center: new Microsoft.Maps.Location(last.latitude, last.longitude)
                })
            } catch (e) { }
        }
        // eslint-disable-next-line
    }, [dates, date, map])

    const viewOnMap = (location) => {
        setShowDebug(false)
        if (!map) return
        if (map.fitBounds) {
            map.fitBounds([[location.latitude, location.longitude]])
        } else if (map.setView) {
            map.setView({
                center: new Microsoft.Maps.Location([location.latitude, location.longitude])
            })
        }
    }

    const saveRoute = async (e) => {
        e.preventDefault()
        const points = dates[saveAs].history.map(row => ({
            latitude: row.latitude, longitude: row.longitude, comment: '', colour: ''
        }))
        await post('route/{accountid}', {
            name: routeName,
            points
        })
        setAlert('Route Saved')
        setSaveAs(null)
        setRouteName('')
    }

    if (!auth.isVerified) return <ActivateAccount />

    if (!billing.active) {
        return <ActivateBilling goto={() => history.push('/billing')} />
    }

    let selectedDate, history
    if (date) {
        selectedDate = dates[date]
        history = selectedDate.history.slice().reverse()
    }

    const polyline = !!date ? [{
        location: history.map(row => [row.latitude, row.longitude]),
        option: {
            strokeColor: 'grey', strokeThickness: 5
        }
    }] : []

    const pushpins = !!date ? history.map((row, index) => {
        return {
            icon: device.registeredDeviceOptions.deviceAvatar,
            location: [row.latitude, row.longitude],
            color: device.registeredDeviceOptions.colour,
            addHandler: "mouseover", //on mouseover the pushpin, infobox shown
            infoboxOption: { title: '' },
            pushPinOption: {
                title: formatDate(row.created, 'HH:mm') + ' - ' + Math.round(row.speed) + 'kph',
                description: 'Pushpin',
                color: device.registeredDeviceOptions.colour || theme.palette.primary.main
            }
        }
    }) : []

    if (loading) return <LoadingIcon />

    if (!Array.isArray(devices)) return <LoadingIcon />

    if (!devices.length && role.devices) {
        return <Grid container direction="row" justify="center" alignItems="center" className={classes.container}>
            <Paper className={classes.none} >
                <Typography variant="h6" align="center" style={{ marginBottom: '20px' }}>No Devices Found</Typography>
                <Button variant="contained" color="primary" onClick={() => routeHistory.push('/devices?add=true')}>Add Device</Button>
            </Paper>
        </Grid>
    }

    let height = 0
    if (chipHolder.current) {
        height = window.innerHeight - chipHolder.current.offsetHeight - 64 - 80
    }

    return (
        <PermissionsChecker required="devices">
            <div className={classes.container}>
                {!!device && <div className={classes.devices}>
                    <Paper className={classes.paper} ref={chipHolder}>
                        <div className={classes.chipHolder}>
                            {devices.map(_device => {
                                return <Chip key={_device.id} className={classes.chip} avatar={<Avatar style={{ backgroundColor: _device.registeredDeviceOptions.colour }} src={_device.registeredDeviceOptions.deviceAvatar}>{_device.name[0]}</Avatar>} label={_device.name} onClick={() => setDevice(_device)} color={device && _device.id === device.id ? 'primary' : 'default'} />
                            })}
                        </div>
                        <div className={classes.mobile}>
                            <FormControl fullWidth className={classes.formControl}>
                                <InputLabel id="device-label">Device</InputLabel>
                                <Select labelId="device-label" id="device" value={device.id} onChange={e => setDevice(devices.find(d => d.id === e.target.value))}>
                                    {devices.map(d => <MenuItem value={d.id} key={d.id}>{d.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth className={classes.formControl}>
                                <InputLabel id="date-label">Date</InputLabel>
                                <Select labelId="date-label" id="date" value={date} onChange={e => setDate(e.target.value)}>
                                    {Object.keys(dates).reverse().map(d => <MenuItem value={d} key={d}>{d}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </div>
                    </Paper>
                </div>}
                {!!device && <Paper className={classes.fill} >
                    <div className={classes.history}>
                        {!!Object.keys(dates).length && <List component="nav" className={classes.dates} style={{ height }}>
                            {Object.keys(dates).reverse().map(row => <ListItem key={row} button selected={date === row} onClick={() => setDate(row)} className={classes.listItem}>
                                <div className={classes.debug}>
                                    {auth.role === 'Admin' && <Button style={{ marginRight: 5 }} variant="contained" onClick={() => setShowDebug(true)}>Debug</Button>}
                                    <Button variant="contained" onClick={() => setSaveAs(row)}>Save as</Button>
                                </div>
                                <ListItemText primary={row} />
                                <ListItemSecondaryAction>{dates[row].total.toFixed(2)}km</ListItemSecondaryAction>
                            </ListItem>)}
                        </List>}
                        <div className={classes.map}>
                            {!!date && <GenericMap
                                returnMap={setMap}
                                defaultShowMe={false}
                                returnBing={setBing}
                                ids={[device.id]}
                                devices={[device]}
                                polyline={polyline}
                                pushpins={pushpins}
                            />}
                            {!Boolean(date) && !loadingDates && <Grid container direction="row" justify="center" alignItems="center" className={classes.container}>
                                <Paper className={classes.none} >
                                    <Typography variant="h6" align="center">No Locations Sent</Typography>
                                </Paper>
                            </Grid>}
                            {auth.role === 'Admin' && <div className={classes.debugMobile}>
                                <Fab onClick={() => setShowDebug(true)} size="small" aria-label="Debug">
                                    <Code color={'primary'} />
                                </Fab>
                            </div>}
                        </div>
                    </div>
                </Paper>}
                <SwipeableDrawer onOpen={() => setShowDebug(true)} onClose={() => setShowDebug(false)} anchor="right" open={showDebug} disableSwipeToOpen={true}>
                    <Fragment>
                        <Button onClick={() => setShowDebug(false)}>Close</Button>
                        {!!date && !!history && <TableContainer style={{ maxWidth: '100vw' }}>
                            <Table size="small" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>DateTime</TableCell>
                                        <TableCell>Location</TableCell>
                                        <TableCell>Elevation</TableCell>
                                        <TableCell>Speed</TableCell>
                                        <TableCell>HDOP</TableCell>
                                        <TableCell>Satellites</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {history.map(h => <TableRow key={h.id}>
                                        <TableCell>{formatDate(h.created)}</TableCell>
                                        <TableCell>
                                            <div style={{ position: 'relative' }}>
                                                {h.latitude}, {h.longitude}
                                                <PlayForWork style={{ position: 'absolute', top: -3, cursor: 'pointer' }} size="small" onClick={() => viewOnMap(h)} />
                                            </div>
                                        </TableCell>
                                        <TableCell align="center">{h.elevation}</TableCell>
                                        <TableCell>{h.speed}</TableCell>
                                        <TableCell align="center">{h.hdop}</TableCell>
                                        <TableCell align="center">{h.satallites}</TableCell>
                                    </TableRow>)}
                                </TableBody>
                            </Table>
                        </TableContainer>}
                    </Fragment>
                </SwipeableDrawer>
                <Drawer open={Boolean(saveAs)} anchor="right">
                    <form style={{ width: 300, padding: 20 }} onSubmit={saveRoute}>
                        <Typography style={{ marginBottom: 10 }} variant="h6" align="center">Save As Route?</Typography>
                        <TextField required style={{ marginBottom: 10 }} fullWidth label="Route Name" value={routeName} onChange={e => setRouteName(e.target.value)} />
                        <Button style={{ width: 125, marginRight: 10 }} variant="contained" color="primary" type="submit">Save</Button>
                        <Button style={{ width: 125 }} variant="contained" onClick={() => { setSaveAs(null); setRouteName(''); }}>Cancel</Button>
                    </form>
                </Drawer>
                <Alert alert={alert} setAlert={setAlert} />
            </div>
        </PermissionsChecker >
    )
}
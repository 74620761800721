import { createStore, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import rootReducer from './reducers'
import thunk from 'redux-thunk'

const persistConfig = {
    key: 'root',
    storage: storage,
    stateReconciler: autoMergeLevel2,
    blacklist: ['notifications']
};
const pReducer = persistReducer(persistConfig, rootReducer);

let preloadedState
const persistedTheme = localStorage.getItem('theme')
const persistedViews = localStorage.getItem('views')

if (persistedTheme) {
    preloadedState = {
        theme: persistedTheme,
        views: persistedViews
    }
}

export const store = createStore(pReducer, applyMiddleware(thunk), preloadedState);
export const persistor = persistStore(store);
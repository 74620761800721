import React, { useState } from 'react';
import { TextField, Button, Grid, Container, Typography } from '@material-ui/core';
import QrReader from 'react-qr-reader'
import { Wallpaper } from '@material-ui/icons';
import { useRef } from 'react';
import { useEffect } from 'react';

export default function Step2({ device, setDevice, currentStep }) {
    const [scan, setScan] = useState(false)
    const [manual, setManual] = useState(false)
    const imeiRef = useRef()

    const onError = console.log

    const onScan = data => {
        if (!data) return
        setScan(false)
        setDevice({ ...device, imei: data })
        setManual(true)
    }

    useEffect(() => {
        if (manual && imeiRef.current) {
            imeiRef.current.focus()
        }
    }, [manual])

    useEffect(() => {
        if (currentStep !== 1) {
            setScan(false)
        }
    }, [currentStep])

    return (
        <Container maxWidth="sm">
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h6" align="center">
                        Scan the QR Code or manually enter the IMEI number
                    </Typography>
                    <Wallpaper style={{ fontSize: 72 }} />
                </Grid>
                <Grid item xs={6}>
                    <Button fullWidth variant="outlined" onClick={() => { setScan(true); setManual(false) }}>Scan Device</Button>
                </Grid>
                <Grid item xs={6}>
                    <Button fullWidth variant="outlined" onClick={() => { setManual(true); setScan(false) }}>Enter IMEI</Button>
                </Grid>
                <Grid item xs={12}>
                    {scan && <QrReader
                        delay={300}
                        onError={onError}
                        onScan={onScan}
                        style={{ width: 280, height: 280, margin: '10px auto' }}
                    />}
                    {manual && <TextField inputRef={imeiRef} id="imei" fullWidth type="text" label="IMEI" name="imei" onChange={e => setDevice({ ...device, imei: e.target.value })} value={device.imei} />}
                </Grid>
            </Grid>
        </Container>
    )
}
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { setView, setOnlineOnly } from 'store/actions'
import DeviceCard from './deviceCard'
import { Grid, InputAdornment, Table, TableCell, TableHead, TableRow, TableBody, TextField, IconButton, Fab, Tooltip, Drawer, Button, Typography, FormControlLabel, Switch } from '@material-ui/core';
import { SearchRounded, Apps, Toc, Add } from '@material-ui/icons';
import AddDevice from './addDevice'
import DeviceRow from './deviceRow'
import queryString from 'query-string'
import { makeStyles } from '@material-ui/styles';
import { destroy } from 'utils/resources';
import moment from 'moment'

const myStyle = makeStyles(theme => ({
    mobilePadding: {
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            marginBottom: 0,
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingBottom: theme.spacing(1)
        }
    },
    devices: {
        [theme.breakpoints.down("sm")]: {
            position: 'fixed',
            bottom: 68,
            width: '100%',
            height: 'calc(100% - 221px)',
            overflowY: 'auto',
            overflowX: 'hidden'
        }
    },
    mobileActions: {
        [theme.breakpoints.down("sm")]: {
            justifyContent: 'space-between'
        }

    }
}))


export default function Devices({ updateGroup, devices, groups, refresh, goTo }) {
    const classes = myStyle()
    const viewport = useSelector(state => state.viewport)
    const views = useSelector(state => state.views)
    const onlineOnly = useSelector(state => state.onlineOnly)
    const dispatch = useDispatch()

    const [searchText, setSearchText] = useState('')
    const [addingDevice, setAddingDevice] = useState(false)

    useEffect(() => {
        if (viewport.width < 768 && views.devices !== 'Grid') {
            dispatch(setView('devices', 'Grid'))
        } else if (!views.devices) {
            dispatch(setView('devices', 'Table'))
        }
        const query = queryString.parse(window.location.search)
        if (query.add) {
            setAddingDevice(true)
        }
        // eslint-disable-next-line
    }, [])

    const setGroups = function (id, data) {
        for (let group of groups) {
            let devices = group.registeredDevices.map(rd => rd.registeredDeviceId)
            if (devices.indexOf(id) > -1 && data.indexOf(group.id) === -1) {
                // remove the device from the group
                const i = devices.indexOf(id)
                devices.splice(i, 1)
                updateGroup({
                    id: group.id,
                    Name: group.name,
                    RegisteredDeviceIDs: Array.from(new Set(devices))
                })
            } else if (devices.indexOf(id) === -1 && data.indexOf(group.id) > -1) {
                // add the device to the group
                devices.push(id)
                updateGroup({
                    id: group.id,
                    Name: group.name,
                    RegisteredDeviceIDs: Array.from(new Set(devices))
                })
            }
        }
    }

    const finishAdding = () => {
        refresh()
        setAddingDevice(false)
    }

    const acknowledge = async (id) => {
        await destroy('device/{accountid}/' + id + '/acknowledgeSOS')
        refresh()
    }

    const view = views.devices || 'Table'

    const filtered = devices
        .filter(d => d.name.toLowerCase().indexOf(searchText.trim().toLowerCase()) > -1)
        .filter(d => onlineOnly ? d.device.lastOnline && moment(d.device.lastOnline) > moment().startOf('day') : true)
        .slice().sort((a, b) => {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
        })

    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.mobilePadding}>
                <TextField value={searchText} onChange={e => setSearchText(e.target.value)} fullWidth label="Search Devices" InputProps={{
                    endAdornment: (<InputAdornment position="end">
                        <SearchRounded />
                    </InputAdornment>)
                }}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.mobilePadding}>
                <Grid container direction="row" justify="flex-end" alignItems="center" style={{ height: '100%' }} className={classes.mobileActions}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={onlineOnly}
                                onChange={() => dispatch(setOnlineOnly(!onlineOnly))}
                                name="onlyOnline"
                                color="primary"
                            />
                        }
                        label="Online Only"
                    />
                    {viewport.width >= 768 && <Tooltip title="Card">
                        <IconButton color={view === 'Grid' ? 'primary' : 'default'} onClick={() => dispatch(setView('devices', 'Grid'))} ><Apps /></IconButton >
                    </Tooltip>}
                    {viewport.width >= 768 && <Tooltip title="Table">
                        <IconButton color={view === 'Table' ? 'primary' : 'default'} onClick={() => dispatch(setView('devices', 'Table'))}><Toc /></IconButton >
                    </Tooltip>}
                    <Tooltip title="Add" aria-label="add" >
                        <Fab color="primary" size="small" onClick={() => setAddingDevice(true)}>
                            <Add />
                        </Fab>
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.devices}>
                {view === 'Table' && <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="center">OSGB</TableCell>
                            <TableCell>Battery</TableCell>
                            <TableCell>Last Online</TableCell>
                            <TableCell>Group(s)</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filtered.map(device => {
                            const myGroups = groups.filter(g => g.registeredDevices.some(d => d.registeredDeviceId === device.id))
                            return <DeviceRow key={device.id} groups={groups} myGroups={myGroups} device={device} goTo={goTo} setGroups={setGroups} acknowledge={acknowledge} />
                        })}
                        {!filtered.length && <TableRow>
                            <TableCell colSpan="99" align="center">
                                <div style={{ marginBottom: '20px' }}>No Devices Found</div>
                                <Button variant="contained" color="primary" onClick={() => setAddingDevice(true)}>Add Device</Button>
                            </TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>}

                {view === 'Grid' && <Grid container justify="center" spacing={2}>
                    {filtered.map(device => <DeviceCard key={device.id} device={device} goTo={goTo} acknowledge={acknowledge} />)}
                    {!filtered.length && <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Typography variant="h6" align="center" style={{ margin: '20px 0' }}>{onlineOnly ? 'No Devices Online' : 'No Devices Found'}</Typography>
                        <Button variant="contained" color="primary" onClick={() => setAddingDevice(true)}>Add Device</Button>
                    </Grid>}
                </Grid>}
            </Grid>

            <Drawer anchor="right" open={addingDevice} onClose={() => setAddingDevice(false)}>
                <AddDevice close={finishAdding} />
            </Drawer>
        </Grid >
    )
}
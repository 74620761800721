import React, { useState } from 'react'
import { Button, Paper, SwipeableDrawer, Typography } from '@material-ui/core'
import AddDevice from '../account/devices/devices/addDevice'

export default function DevicesWizard({ devices, setStep, finish, refresh }) {
    const [addingDevice, setAddingDevice] = useState(false)

    const addDevice = () => {
        setAddingDevice(true)
    }

    const close = async () => {
        await refresh()
        setAddingDevice(false)
    }

    return (
        <div style={{ marginTop: 30 }}>
            <Paper style={{ padding: 20 }}>
                <Typography align="center" variant="h5">You Currently have {devices.length} Device{devices.length === 1 ? '' : 's'}</Typography>
                <div style={{ marginTop: 20, textAlign: 'center' }}>
                    <Button onClick={addDevice} variant="outlined">Register a New Device</Button>
                </div>
            </Paper>

            <div style={{ textAlign: 'center', marginTop: 10 }}>
                <Button onClick={() => setStep(1)} style={{ marginRight: 5 }}>Back</Button>
                <Button variant="contained" color="primary" onClick={finish} style={{ marginLeft: 5 }}>Finish</Button>
            </div>

            <SwipeableDrawer open={addingDevice} anchor="right" disableSwipeToOpen={true} onOpen={addDevice} onClose={() => setAddingDevice(false)}>
                <AddDevice close={close} />
            </SwipeableDrawer>
        </div>
    )
}
import React, { useEffect, useRef, useState, Fragment } from 'react';
import { Switch, Route, useHistory } from "react-router-dom";
import clsx from 'clsx';
import queryString from 'query-string'
import * as signalR from '@microsoft/signalr'

import constants from './utils/constants'
import { get, put, post } from './utils/resources'
import * as http from './utils/http'
import auth from './utils/auth'
import { setNotifications, addNotifications, setViewport, setBilling, setRole, setAccount, logout, toggleTheme, setAuth, setUser as storeSetUser, setLocation, setError } from './store/actions'

import Frontpage from './views/frontpage'

import Devices from './views/account/devices'
import Map from './views/account/map'
import Routes from './views/account/routehistory'
import RoutePlanner from './views/account/routeplanner'
import Events from './views/account/events'
import Geofencing from './views/account/geofence'

import Security from './views/admnistration/security'
import Billing from './views/admnistration/billing';
import Details from './views/admnistration/details';

import Profile from './views/user/profile';
import NotificationsPage from './views/user/notifications';

import Admin from './views/admin'

import CreateUser from './Components/drawer/createUser';
import CreateAccount from './Components/drawer/createAccount'

import MobileNav from 'Components/Navigation/MobileNav'
import Confirm from 'Components/Confirm'

import Wizard from './views/wizard'

import {
    AppBar, createMuiTheme, ThemeProvider, Drawer, IconButton, Toolbar, Divider, List, Badge, Typography, Paper,
    Grid, ListItem, Select, MenuItem, ListItemIcon, ListItemText, Avatar, Button, Snackbar, SwipeableDrawer, Link, TextField, FormControl, InputLabel
} from '@material-ui/core';

import AvatarGroup from '@material-ui/lab/AvatarGroup';
import CssBaseline from '@material-ui/core/CssBaseline';
import { deepPurple, teal } from '@material-ui/core/colors';
import { ChevronLeft, Menu, Notifications, ExitToApp, Add, Map as MapIcon, SettingsCell as SettingsCellIcon, Settings, ContactSupport, Home, /*DeveloperBoard*/ } from '@material-ui/icons';
import { Accounts, Administration, User } from './Components/Navigation/NavItems'
import AdminNav from 'Components/Navigation/Admin'
import Theme from './theme'
import { useDispatch, useSelector } from 'react-redux';

import LogoDark from 'Assets/Lockup-Horizontal-Dark.png'
import LogoLight from 'Assets/Lockup-Horizontal-White.png'
import { Alert as MuiAlert } from '@material-ui/lab'
import { formatDate } from 'utils/format';

const routesWithMaps = ['/map', '/history', '/devices']
const RouteResolver = {
    devices: 'Devices', map: 'Map', history: 'Route History', routeplanner: 'Route Planner',
    events: 'Events', security: 'Security', billing: 'Billing', details: 'Details', profile: 'Profile',
    settings: 'Settings', notifications: 'Notifications', admin: 'Admin', geofence: 'Geofencing', '': 'Meili'
};

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Main() {
    const history = useHistory()
    const viewport = useSelector(state => state.viewport)
    const [open, setOpen] = useState(viewport.width >= 960);
    const [activeItem, setActiveItem] = useState(history.location.pathname.split('/')[1])
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState({})
    const [creatingUser, setCreatingUser] = useState(false)
    const [creatingAccount, setCreatingAccount] = useState(false)
    const [accounts, setAccounts] = useState([])
    const [authObj, setAuthObj] = useState({ isVerified: true })
    const [alert, setAlert] = useState('')
    const [mobileNavOpen, setMobileNavOpen] = useState(false)
    const [accountMenuOpen, setAccountMenuOpen] = useState(false)
    const [sos, setSos] = useState({})
    const [showNotifications, setShowNotifications] = useState(false)
    const [refreshNeeded, setRefreshNeeded] = useState(false)
    const [showSupport, setShowSupport] = useState(false)
    const [supportTicket, setSupportTicket] = useState({
        subject: '', message: '', area: ''
    })

    const dispatch = useDispatch();
    const listener = useRef(null)
    const connection = useRef(null);
    const locationTimer = useRef(null)
    const errHandler = useRef(null)
    const versionTimer = useRef(null)

    const notifications = useSelector(state => state.notifications)
    const account = useSelector(state => state.account)
    const theme = useSelector(state => state.theme)
    const billing = useSelector(state => state.billing)
    const error = useSelector(state => state.error)
    const path = useSelector(state => state.path)

    const myTheme = createMuiTheme({
        palette: {
            type: theme,
            primary: { main: teal[500] },
            secondary: { main: deepPurple[500] }
        }
    })

    const toggleOpen = () => {
        setOpen(!open)
    }

    const nav = (name) => {
        history.push('/' + name)
        setActiveItem(name)
    }

    const checkAuth = async () => {
        const authObj = await auth.auth()
        if (authObj && authObj.id) {
            dispatch(setAuth(authObj))
            get('user').then(me => {
                if (!me) {
                    if (me.message && me.message === 'Authorization Failed') {
                        return checkAuth()
                    }
                    setCreatingUser(true)
                    setLoading(false)
                } else {
                    auth.setUser(me)
                    if (me.userSettings.theme.toLowerCase() !== theme) {
                        dispatch(toggleTheme())
                    }
                    setUser(me)
                }
            }).catch(e => {
                if (e.message && e.message === 'Authorization Failed') {
                    return checkAuth()
                }
                console.log('err', e)
                setCreatingUser(true)
            })
        } else {
            const path = window.location.pathname + window.location.search
            history.push('/login?redirect=' + path)
        }
    }

    const onUserCreated = (user) => {
        if (user) {
            setUser(user)
            getAccounts()
        }
        setCreatingUser(false)
    }

    const handleWindowSize = () => {
        dispatch(setViewport({ width: window.innerWidth, height: window.innerHeight }))
    }

    const afterUserLoad = () => {
        const authObj = auth.auth()
        getNotifications()
        if (authObj.isVerified) {
            getBilling()
        }
        getLocation(true)
        signalRConnect()
        get('accountuserrole/{accountid}/user/' + user.id).then(data => {
            if (!data || !data.role) {
                // means the account is bad, default to the first one, or logout
                if (accounts && accounts.length) {
                    setLoading(true)
                    setAccount(accounts[0])
                } else {
                    onLogout()
                }
            } else {
                dispatch(setRole(data.role))
                setLoading(false)
                if (errHandler.current) {
                    window.removeEventListener('error', handleError)
                }
                window.addEventListener('error', handleError)
                errHandler.current = true
            }
        })
    }

    const getAccounts = (newAccount) => {
        const query = queryString.parse(window.location.search)
        let accountId = newAccount && newAccount.id ? newAccount.id : query.account || query.a
        get('account').then(data => {
            if (!accountId) accountId = user.lastAccount
            if (!data.length) {
                setCreatingAccount(true)
            } else if (!account || !account.id || !data.some(d => d.id === account.id)) {
                // default to the first account
                let account
                if (accountId) account = data.find(a => a.id === accountId)
                if (!account) account = data[0]
                setAccounts(data)
                dispatch(setAccount(account))
                afterUserLoad()
            } else {
                setAccounts(data)
                if (accountId) {
                    let account = data.find(a => a.id === accountId)
                    if (account) dispatch(setAccount(account))
                    afterUserLoad()
                }
            }
        })
    }

    const onAccountCreated = (account) => {
        if (account) {
            getAccounts(account)
            setCreatingAccount(false)
        } else if (!accounts.length) {
            onLogout()
        } else {
            setCreatingAccount(false)
        }
    }

    const getNotifications = () => {
        // todo get the notifcations on a timer
        get('notification/new').then(data => dispatch(setNotifications(Array.isArray(data) ? data : [])))
    }

    const getBilling = () => {
        get('billing').then(data => {
            dispatch(setBilling(data))
        })
    }

    const signalRConnect = () => {
        const authObj = auth.auth()
        if (authObj && authObj.jwtToken) {
            dispatch(setAuth(authObj))
            setAuthObj(authObj)
            connection.current = new signalR.HubConnectionBuilder()
                .withUrl(constants.RESOURCES + 'hubs/notifications', { accessTokenFactory: () => authObj.jwtToken })
                .configureLogging(signalR.LogLevel.Warning)
                .withAutomaticReconnect()
                .build()


            connection.current.start().then(() => {
                connection.current.on('ReceiveMessage', async data => {
                    if (data.notificationSeverity === 3) {
                        const sos = { ...data, open: true }
                        const devices = await get('devices')
                        if (Array.isArray(devices)) {
                            const device = devices.find(d => d.device.imei === sos.imei)
                            if (device) {
                                sos.name = device.name
                                sos.id = device.id
                                setSos(sos)
                            }
                        }
                    } else {
                        setAlert(data.message)
                    }
                    dispatch(addNotifications(data))
                })
            }).catch(console.log)
        }
    }

    const changeAccount = (ev) => {
        setAccountMenuOpen(false)
        const id = typeof ev === 'string' ? ev : ev.target.value
        if (id === -1 || id === '-1') {
            setCreatingAccount(true)
        } else {
            const account = accounts.find(a => a.id === id)
            put('user/setaccount/' + account.id)
            dispatch(setAccount(account))
            setLoading(true)
            afterUserLoad(user)
            setTimeout(() => {
                if (history.location.pathname.split('/').length > 2) {
                    history.push('/')
                }
                setLoading(false)
            }, 150)
        }
    }

    const onLogout = () => {
        auth.removeUser();
        dispatch(logout());
        history.push('/login')
    }

    const sendVerification = async () => {
        await http.get('auth/verify-email')
        setAlert('Email Sent')
    }

    const handleError = async (event) => {
        try {
            post('debug/client', {
                accountId: account.id, userId: user.id,
                accountName: account.name,
                userFullname: `${user.firstName} ${user.surname}`,
                message: event ? event.message || event : 'Unknown Error',
                error: event.error.stack,
                path: window.location.pathname,
                userAgent: navigator.userAgent,
                version: constants.version
            }, true)
            console.log('ERROR DETECTED', account, user, error)
            event.preventDefault()
            event.stopPropagation()
        } catch (e) {

        }
        return false;
    }

    const compareVersion = async () => {
        const myVersion = constants.version
        const serverVersion = await get('version', true, true)
        if (serverVersion > myVersion) {
            setRefreshNeeded(true)
        }
    }

    const refresh = () => {
        // eslint-disable-next-line
        window.location.href = window.location.href;
    }

    const admin = () => {
        history.push('/admin')
    }

    const raiseSupportTicket = () => {
        setShowSupport(false)
    }

    const cancelSupport = () => {
        setSupportTicket({ subject: '', message: '', area: '' })
        setShowSupport(false)
    }

    useEffect(() => {
        if (authObj && authObj.message === 'Authorization Failed') {
            onLogout()
        }
        // eslint-disable-next-line
    }, [authObj])

    useEffect(() => {
        if (user.id) {
            getAccounts()
        }
        // eslint-disable-next-line
    }, [user])

    history.listen((location) => {
        setActiveItem(location.pathname.split('/')[1])
        getLocation()
    })

    useEffect(() => {
        if (viewport.width < 600) setOpen(false)
    }, [viewport.width])

    useEffect(() => {
        if (user && user.id) {
            dispatch(storeSetUser(user))
        }
    }, [user, dispatch])

    useEffect(() => {
        dispatch(setError(''))
        checkAuth()

        listener.current = window.addEventListener('resize', handleWindowSize)
        locationTimer.current = setInterval(getLocation, 10000)
        getLocation()

        compareVersion()
        versionTimer.current = setInterval(compareVersion, 1000 * 60);

        return function unmount() {
            // on unload
            window.removeEventListener('resize', listener.current)
            try {
                if (connection.current) {
                    connection.current.stop()
                    connection.current = null;
                }

                if (versionTimer.current) {
                    clearInterval(versionTimer.current)
                    versionTimer.current = null
                }

                if (locationTimer.current) {
                    clearInterval(locationTimer.current);
                    locationTimer.current = null;
                }
            } catch (e) { }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) //the empty array means this fires on on first load (and the return happen on unload)

    const getLocation = function (force) {
        if (force || routesWithMaps.some(l => history.location.pathname.indexOf(l) > -1) || history.location.pathname === '/' || history.location.pathname === '') {
            navigator.geolocation.getCurrentPosition(position => {
                dispatch(setLocation(position))
            })
        }
    }

    const getNotificationColour = (row) => {
        switch (row.notificationSeverity) {
            case 3:
                return myTheme.palette.error.main
            case 2:
                return myTheme.palette.info.main
            case 1:
                return myTheme.palette.primary.main
            case 0:
            default:
                return myTheme.palette.text.main
        }
    }

    const clearNotification = async (row) => {
        await post('notification/' + row.id)
        dispatch(setNotifications(await get('notification/new')))
    }

    const viewNotification = async (row) => {
        const _devices = await get('device/' + row.accountId, true)
        const device = _devices.find(d => d.device.imei === row.imei)
        if (!device) return
        if (row.accountId !== account.id) {
            const newAccount = accounts.find(a => a.id === row.accountId)
            if (newAccount) {
                dispatch(setAccount(newAccount))
            } else {
                return
            }
        }
        history.push('/devices/' + device.id + '?page=notifications')
        setShowNotifications(false)
    }

    const clearAll = async () => {
        await post('notification/acknowledge_all', true)
        dispatch(setNotifications(await get('notification/new')))
        setShowNotifications(false)
    }

    const classes = Theme()

    let showWizard = false
    if (!loading) {
        if (!account.hasOwnProperty('accountType') || (billing && billing.id && !billing.active)) {
            showWizard = true
        }
    }

    let title = loading ? '' : RouteResolver[activeItem] || ''
    if (showWizard) {
        title = 'Account Setup'
    } else if (activeItem && path && path.indexOf(title) === 0) {
        title = path
    }

    const sortedNotifications = [...notifications].sort((a, b) => b.notificationDate - a.notificationDate)

    return (
        <ThemeProvider theme={myTheme}>
            <div className={classes.root}>
                <CssBaseline />
                <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                    <Toolbar className={classes.toolbar}>
                        <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={toggleOpen} className={clsx(classes.menuButton, open && classes.menuButtonHidden)} >
                            <Menu />
                        </IconButton>
                        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>{title}</Typography>
                        <IconButton onClick={() => setShowSupport(true)}>
                            <ContactSupport />
                        </IconButton>
                        <AvatarGroup>
                            <Avatar className={classes.deepPurple} alt={user.name} src={user.avatar} />
                            <Avatar onClick={() => setAccountMenuOpen(true)} className={classes.deepPurple} alt={account.name} src={account.avatar ?? "/static/images/avatar/2.jpg"} />
                        </AvatarGroup>
                        {!showWizard && <IconButton color="inherit" onClick={() => setShowNotifications(true)}>
                            <Badge badgeContent={notifications.length} color="secondary">
                                <Notifications />
                            </Badge>
                        </IconButton>}
                        <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={() => setMobileNavOpen(!mobileNavOpen)} className={classes.mobileMenuButton} >
                            <Menu />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                {/* side menu */}
                <Drawer variant="permanent" open={open} classes={{ paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose) }}>
                    <div className={classes.toolbarIcon}>
                        <img src={theme === 'dark' ? LogoLight : LogoDark} height="50" alt="meili-logo" style={{ cursor: 'pointer' }} onClick={() => history.push('/')} />
                        <IconButton onClick={toggleOpen}>
                            <ChevronLeft />
                        </IconButton>
                    </div>
                    <Divider />
                    {activeItem !== 'admin' && <Fragment>
                        <List><Accounts nav={nav} activeItem={activeItem} disabled={showWizard} /></List>
                        <Divider />
                        <List><Administration nav={nav} activeItem={activeItem} disabled={showWizard} /></List>
                        <Divider />
                        <List style={{ marginBottom: 'auto' }}><User nav={nav} activeItem={activeItem} disabled={showWizard} /></List>
                    </Fragment>}
                    {activeItem === 'admin' && <List style={{ marginBottom: 'auto' }}>
                        <AdminNav />
                    </List>}

                    {Boolean(account.id) && <Divider />}
                    {Boolean(account.id) && Boolean(accounts.length) && <List>
                        <ListItem className={open ? classes.selectOpen : classes.selectClose}  >
                            <Select value={account.id} onChange={changeAccount} fullWidth={true} disableUnderline>
                                {accounts.map((account, index) => <ListItem button key={index} value={account.id}>
                                    <ListItemIcon>
                                        <Avatar alt={account.name} className={clsx(classes.small)} src={account.avatar ?? "/static/images/avatar/2.jpg"} />
                                    </ListItemIcon>
                                    <Typography variant="inherit" noWrap>
                                        {account.name}
                                    </Typography>
                                </ListItem>)}
                                <MenuItem value={-1}>
                                    <ListItemIcon>
                                        <Avatar className={classes.small}>
                                            <Add />
                                        </Avatar>
                                    </ListItemIcon>
                                    <ListItemText primary="Create New Org" />
                                </MenuItem>
                            </Select>
                        </ListItem>
                    </List>}

                    {authObj.role === 'Admin' && <Fragment>
                        <Divider />
                        <List>
                            {activeItem !== 'admin' && <ListItem button onClick={admin}>
                                <ListItemIcon>
                                    <Settings />
                                </ListItemIcon>
                                <ListItemText primary="Admin" />
                            </ListItem>}
                            {activeItem === 'admin' && <ListItem button onClick={() => history.push('/')}>
                                <ListItemIcon>
                                    <Home />
                                </ListItemIcon>
                                <ListItemText primary="Home" />
                            </ListItem>}
                        </List>

                    </Fragment>}
                    <Divider />
                    <List>
                        <ListItem button onClick={onLogout}>
                            <ListItemIcon>
                                <ExitToApp />
                            </ListItemIcon>
                            <ListItemText primary="Logout" />
                        </ListItem>
                    </List>
                </Drawer>
                {/* create user drawer */}
                <Drawer variant="temporary" anchor="right" open={creatingUser}>
                    <div style={{ width: '100vw' }}>
                        <CreateUser close={onUserCreated} />
                    </div>
                </Drawer>
                {/* create account drawer */}
                <Drawer variant="temporary" anchor="right" open={creatingAccount}>
                    <div style={{ width: '100vw' }}>
                        <CreateAccount close={onAccountCreated} />
                    </div>
                </Drawer>

                {/* mobile nav */}
                <SwipeableDrawer disableSwipeToOpen={true} variant="temporary" anchor="right" open={mobileNavOpen} onOpen={() => setMobileNavOpen(true)} onClose={() => setMobileNavOpen(false)}>
                    <MobileNav disabled={showWizard} close={() => setMobileNavOpen(false)} switchAccount={() => { setMobileNavOpen(false); setAccountMenuOpen(true); }} />
                </SwipeableDrawer>

                {/* change account */}
                <SwipeableDrawer disableSwipeToOpen={true} variant="temporary" anchor="right" open={accountMenuOpen} onOpen={() => setAccountMenuOpen(true)} onClose={() => setAccountMenuOpen(false)}>
                    <div style={{ padding: '20px' }}>
                        <Typography variant="h6" align="center">Select Organisation</Typography>
                        <List>
                            {accounts.map(account => <ListItem button key={account.id} onClick={() => changeAccount(account.id)}>
                                <ListItemIcon>
                                    <Avatar alt={account.name} src={account.avatar ?? "/static/images/avatar/2.jpg"} />
                                </ListItemIcon>
                                <Typography variant="inherit" noWrap>
                                    {account.name}
                                </Typography>
                            </ListItem>)}

                            <ListItem value={-1} button onClick={() => changeAccount('-1')} >
                                <ListItemIcon>
                                    <Avatar>
                                        <Add />
                                    </Avatar>
                                </ListItemIcon>
                                <ListItemText primary="Create New" />
                            </ListItem>
                            <Divider />
                            <ListItem button onClick={onLogout} >
                                <ListItemIcon>
                                    <Avatar>

                                        <ExitToApp />
                                    </Avatar>

                                </ListItemIcon>
                                <ListItemText primary="Logout" />
                            </ListItem>
                        </List>
                    </div>
                </SwipeableDrawer>

                <div className={classes.content}>
                    <div className={clsx(classes.appBarSpacer, classes.hideMob)} />
                    {loading && <div />}
                    {!loading && <div className={classes.container} disablegutters="true">
                        <Grid container className={classes.grid}>
                            <Grid item xs={12}>
                                {showWizard && <Wizard />}
                                {!showWizard && <Switch>
                                    <Route path="/map">
                                        <Map history={history} />
                                    </Route>
                                    <Route path="/history">
                                        <Routes history={history} />
                                    </Route>
                                    <Route path="/security">
                                        <Security history={history} />
                                    </Route>
                                    <Route path="/billing">
                                        <Billing history={history} />
                                    </Route>
                                    <Route path="/details">
                                        <Details history={history} reload={() => getAccounts(account)} />
                                    </Route>
                                    <Route path="/profile">
                                        <Profile history={history} reload={checkAuth} />
                                    </Route>
                                    <Route path="/notifications">
                                        <NotificationsPage history={history} />
                                    </Route>
                                    <Route path="/devices">
                                        <Devices history={history} />
                                    </Route>
                                    <Route path="/routeplanner">
                                        <RoutePlanner />
                                    </Route>
                                    <Route path="/events">
                                        <Events />
                                    </Route>
                                    <Route path="/geofence">
                                        <Geofencing />
                                    </Route>
                                    <Route path="/admin">
                                        <Admin />
                                    </Route>
                                    <Route path="/">
                                        <Frontpage />
                                    </Route>
                                </Switch>}
                            </Grid>
                        </Grid>
                    </div>}
                </div>
                {!authObj.isVerified && <AppBar position="fixed" color="secondary" className={clsx(classes.bottomBar, open && classes.bottomBarShift)}>
                    <Toolbar className={classes.toolbar}>
                        <Typography color="inherit">Please verify your Account</Typography>
                        <Button className={classes.verify} variant="contained" onClick={sendVerification}>Send Email</Button>
                    </Toolbar>
                </AppBar>}

                {/* mobile bottom actions */}
                <AppBar position="fixed" className={classes.bottomNavbar}>
                    <Toolbar className={classes.toolbar}>
                        <IconButton color="inherit" onClick={() => history.push('/map')} disabled={showWizard} className={classes.mobileButton}>
                            <MapIcon />
                            <div style={{ fontSize: 12 }}>Map</div>
                        </IconButton>
                        <IconButton color="inherit" onClick={() => history.push('/devices')} disabled={showWizard} className={classes.mobileButton}>
                            <SettingsCellIcon />
                            <div style={{ fontSize: 11 }}>Devices</div>
                        </IconButton>
                        <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={toggleOpen} className={clsx(classes.menuButton, open && classes.menuButtonHidden)} >
                            <Menu />
                        </IconButton>
                        <Typography component="h1" variant="h6" color="inherit" align="center" noWrap className={classes.title}>{title}</Typography>

                        {<IconButton color="inherit" onClick={() => setShowNotifications(true)} disabled={showWizard}>
                            <Badge badgeContent={notifications.length} color="secondary">
                                <Notifications fontSize="large" />
                            </Badge>
                        </IconButton>}
                        <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={() => setMobileNavOpen(!mobileNavOpen)} className={classes.mobileMenuButton} >
                            <Menu fontSize="large" />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <Snackbar open={!!alert} autoHideDuration={2000} onClose={() => setAlert('')}>
                    <Alert onClose={() => setAlert('')} severity="success">{alert}</Alert>
                </Snackbar>

                <Snackbar open={!!error} autoHideDuration={2000} onClose={() => dispatch(setError(null))}>
                    <Alert onClose={() => dispatch(setError(null))} severity="error">{error}</Alert>
                </Snackbar>

                <Confirm open={sos.open} header={sos.message} subheader={sos.name} confirmText={'View Devices'} cancelText={'Cancel'} onConfirm={() => history.push('/devices')} onCancel={() => setSos({ open: false })} />

                <SwipeableDrawer anchor="right" disableSwipeToOpen={true} open={showNotifications} onOpen={() => { }} onClose={() => setShowNotifications(false)} >
                    <div className={classes.notificationsDrawer}>
                        {Boolean(sortedNotifications.length) && <div>
                            <Typography variant="h5" align="center" style={{ marginBottom: 10 }}>Notifications</Typography>
                            <Button variant="outlined" fullWidth onClick={clearAll}>Clear All</Button>
                            {sortedNotifications.map((row) => {
                                const rowAccount = accounts.find(a => a.id === row.accountId)
                                return <Fragment key={row.id}>
                                    <div className={classes.notificationsRow}>
                                        <Typography variant="subtitle1">{formatDate(row.notificationDate)}</Typography>
                                        <Typography variant="body1">{rowAccount ? rowAccount.name + ' - ' : ''}{row.deviceName || row.imei}</Typography>
                                        <Typography variant="h6" align="center" style={{ color: getNotificationColour(row), marginBottom: 10 }}>{row.message}</Typography>
                                        <Grid container spacing={3}>
                                            <Grid item xs={6}>
                                                <Button size="small" variant="outlined" fullWidth onClick={() => clearNotification(row)}>Clear</Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button size="small" variant="outlined" fullWidth onClick={() => viewNotification(row)}>View</Button>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <Divider />
                                </Fragment>
                            })}
                            <Button variant="outlined" fullWidth color="primary" style={{ marginTop: 20 }}>View All Notifications</Button>
                        </div>}
                        {!Boolean(sortedNotifications.length) && <div>
                            <div style={{ textAlign: 'center' }}>
                                <Notifications style={{ fontSize: 50, margin: '30 0' }} color="primary" />
                            </div>
                            <Typography variant="h6" align="center">
                                No Notifications
                            </Typography>

                        </div>}
                    </div>
                </SwipeableDrawer>

                <SwipeableDrawer anchor="right" open={showSupport} disableSwipeToOpen={true} onOpen={() => { }} onClose={() => setShowSupport(false)}>
                    <div style={{ width: 350, padding: 30 }}>
                        <Typography variant="h5" align="center">Create Support Ticket</Typography>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextField fullWidth label="Subject" value={supportTicket.subject} onChange={e => setSupportTicket(t => ({ ...t, subject: e.target.value }))} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField variant="filled" fullWidth label="Message" value={supportTicket.message} multiline rows={4} onChange={e => setSupportTicket(t => ({ ...t, message: e.target.value }))} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="supportSectionLabel">Section</InputLabel >
                                    <Select labelId="supportSectionLabel" value={supportTicket.area} onChange={e => setSupportTicket(t => ({ ...t, area: e.target.value }))} >
                                        <MenuItem value="device">Device</MenuItem>
                                        <MenuItem value="map">Map</MenuItem>
                                        <MenuItem value="event">Events</MenuItem>
                                        <MenuItem value="route">Routes</MenuItem>
                                        <MenuItem value="billing">Billing</MenuItem>
                                        <MenuItem value="account">Account</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <Button variant="contained" color="primary" fullWidth onClick={raiseSupportTicket}>Send</Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button variant="contained" fullWidth onClick={cancelSupport}>Cancel</Button>
                            </Grid>
                        </Grid>
                    </div>
                </SwipeableDrawer>
            </div>

            <div className={clsx(classes.refreshWarning, refreshNeeded && classes.refreshWarningOpen)}>
                <Paper square={true}>
                    <Typography style={{ textAlign: 'center' }}>A new version of Meili is available <Link onClick={refresh}>Refresh</Link></Typography>
                </Paper>
            </div>
            <div className={classes.version}>v{constants.version}</div>
        </ThemeProvider>
    )
}
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { Container, Typography, TextField, FormControl, InputLabel, Select, MenuItem, Grid, Button } from '@material-ui/core'
import { post } from 'utils/resources'

const myTheme = makeStyles(theme => ({
    container: {
        padding: theme.spacing(2),
    },
    textField: {
        marginBottom: theme.spacing(1)
    },
    button: {
        margin: theme.spacing(1)
    }
}))

export default function AddUser({ close, roles }) {
    const [user, setUser] = useState({ email: '', roleId: roles[0].id })
    const [message, setMessage] = useState('')

    const save = async function () {
        setMessage('')
        await post('userinvite/{accountid}', user)
        close()
    }

    const setVal = function (ev) {
        setUser({ ...user, [ev.target.name]: ev.target.value })
    }

    const classes = myTheme()

    return <Container className={classes.container}>
        <Typography variant="h6" align="center">Invite User</Typography>
        <form onSubmit={save}>
            <TextField className={classes.textField} id="email" fullWidth type="email" label="Email Address" name="email" onChange={setVal} value={user.email} />
            <FormControl className={classes.formControl} fullWidth>
                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                <Select labelId="demo-simple-select-label" name="roleId" id="demo-simple-select" value={user.roleId} onChange={setVal}>
                    {roles.map(r => <MenuItem value={r.id}>{r.name}</MenuItem>)}
                </Select>
            </FormControl>
            {!!message && <Typography color="error">{message}</Typography>}
            <Grid container alignItems="center" justify="center">
                <Button className={classes.button} variant="contained" type="button" onClick={close}>Cancel</Button>
                <Button className={classes.button} variant="contained" color="primary" onClick={save}>Save</Button>
            </Grid>
        </form>
    </Container>
}
import { Grid, Card, CardContent, CardActions, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux'
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { setPath } from 'store/actions';
import DistanceBreakdown from './distanceBreakdown'

const myStyle = makeStyles(theme => ({
    container: {
        overflowY: 'auto',
        [theme.breakpoints.down("sm")]: {
            height: 'calc(100% - 47px)',
            position: 'fixed'
        }
    },
    cardHeader: {
        marginBottom: theme.spacing(2)
    },
    subtext: {
        fontSize: 16
    },
    subtext2: {
        fontSize: 24
    }
}))

export default function AdminDashboard() {
    const classes = myStyle()
    const history = useHistory()
    const dispatch = useDispatch()
    const [accounts, setAccounts] = useState({ active: 90, total: 900 })
    const [devices, setDevices] = useState({ active: 105, total: 200 })
    const [events, setEvents] = useState(47)
    const [routes, setRoutes] = useState(52)
    const [sos, setSos] = useState(2)
    const [support, setSupport] = useState({ new: 10, pending: 15 })

    const viewport = useSelector(state => state.viewport)
    console.log(viewport)

    useEffect(() => {
        dispatch(setPath('Admin Dashboard'))
    }, [])

    const graphHeight = (viewport.height - 198 - 64) / 2

    return (
        <Grid container spacing={3} className={classes.container}>
            <Grid item xs={12} sm={6} md={3} lg={3}>
                <Card>
                    <CardContent>
                        <Typography variant="body1" align="center" className={classes.cardHeader}>Active Accounts</Typography>
                        <Typography variant="h3" align="center">{accounts.active} <span className={classes.subtext2}>/ {accounts.total}</span></Typography>
                    </CardContent>
                    <CardActions style={{ justifyContent: 'center' }}>
                        <Button size="small" color="primary" onClick={() => history.push('/admin/accounts')}>View Accounts</Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
                <Card>
                    <CardContent>
                        <Typography variant="body1" align="center" className={classes.cardHeader}>Active Devices</Typography>
                        <Typography variant="h3" align="center">{devices.active} <span className={classes.subtext2}>/ {devices.total}</span></Typography>
                    </CardContent>
                    <CardActions style={{ justifyContent: 'center' }}>
                        <Button size="small" color="primary" onClick={() => history.push('/admin/devices')}>View Devices</Button>
                    </CardActions>
                </Card>

            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
                <Card>
                    <CardContent>
                        <Typography variant="body1" align="center" className={classes.cardHeader}>Upcoming Events</Typography>
                        <Typography variant="h3" align="center">{events}</Typography>
                    </CardContent>
                    <CardActions style={{ justifyContent: 'center' }}>
                        <Button size="small" color="primary" onClick={() => history.push('/admin/events')}>View Events</Button>
                    </CardActions>
                </Card>

            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
                <Card>
                    <CardContent>
                        <Typography variant="body1" align="center" className={classes.cardHeader}>Routes</Typography>
                        <Typography variant="h3" align="center">{routes}</Typography>
                    </CardContent>
                    <CardActions style={{ justifyContent: 'center' }}>
                        <Button size="small" color="primary" onClick={() => history.push('/admin/routes')}>View Routes</Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Typography variant="body1" align="center" className={classes.cardHeader}>Total Distance Over Time</Typography>
                        <div style={{ height: graphHeight }}>
                            <DistanceBreakdown />
                        </div>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
                <Card>
                    <CardContent>
                        <Typography variant="body1" align="center" className={classes.cardHeader}>SOS</Typography>
                        <Typography variant="h3" align="center">{sos}</Typography>
                    </CardContent>
                    <CardActions style={{ justifyContent: 'center' }}>
                        <Button size="small" color="primary" onClick={() => history.push('/admin/notifications')}>View Notifications</Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
                <Card>
                    <CardContent>
                        <Typography variant="body1" align="center" className={classes.cardHeader}>New Tickets</Typography>
                        <Typography variant="h3" align="center">{support.new}</Typography>
                    </CardContent>
                    <CardActions style={{ justifyContent: 'center' }}>
                        <Button size="small" color="primary" onClick={() => history.push('/admin/support')}>View Support</Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
                <Card>
                    <CardContent>
                        <Typography variant="body1" align="center" className={classes.cardHeader}>Pending Tickets</Typography>
                        <Typography variant="h3" align="center">{support.pending}</Typography>
                    </CardContent>
                    <CardActions style={{ justifyContent: 'center' }}>
                        <Button size="small" color="primary" onClick={() => history.push('/admin/support')}>View Support</Button>
                    </CardActions>
                </Card>
            </Grid>

        </Grid>
    )
}
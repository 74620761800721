import React from 'react'
import { ListItem, ListItemText, ListItemIcon } from '@material-ui/core'
import { SpeakerPhone, Map, History, Lock, AccountBalance, Domain, Person, Notifications, DirectionsWalk, Event, Group, Accessibility, Adjust } from '@material-ui/icons'
import { useSelector } from 'react-redux'

export function Accounts({ activeItem, nav, disabled = false }) {
    const handleClick = (name) => (event) => {
        nav(name)
    }

    return <div>
        <ListItem button selected={!disabled && activeItem === 'devices'} onClick={handleClick('devices')} disabled={disabled}>
            <ListItemIcon>
                <SpeakerPhone />
            </ListItemIcon>
            <ListItemText primary="Devices" />
        </ListItem>
        <ListItem button selected={!disabled && activeItem === 'map'} onClick={handleClick('map')} disabled={disabled}>
            <ListItemIcon>
                <Map />
            </ListItemIcon>
            <ListItemText primary="Map" />
        </ListItem>
        <ListItem button selected={!disabled && activeItem === 'events'} onClick={handleClick('events')} disabled={disabled}>
            <ListItemIcon>
                <Event />
            </ListItemIcon>
            <ListItemText primary="Events" />
        </ListItem>
        <ListItem button selected={!disabled && activeItem === 'history'} onClick={handleClick('history')} disabled={disabled}>
            <ListItemIcon>
                <History />
            </ListItemIcon>
            <ListItemText primary="Device History" />
        </ListItem>
        <ListItem button selected={!disabled && activeItem === 'routeplanner'} onClick={handleClick('routeplanner')} disabled={disabled}>
            <ListItemIcon>
                <DirectionsWalk />
            </ListItemIcon>
            <ListItemText primary="Route Planner" />
        </ListItem>
        <ListItem button selected={!disabled && activeItem === 'geofence'} onClick={handleClick('geofence')} disabled={disabled}>
            <ListItemIcon>
                <Adjust />
            </ListItemIcon>
            <ListItemText primary="Geofencing" />
        </ListItem>
    </div>
}

export function Administration({ activeItem, nav, disabled = false }) {
    const account = useSelector(state => state.account)
    const handleClick = (name) => (event) => {
        nav(name)
    }

    let accountIcon = <Group />
    if (account.accountType === 2) {
        accountIcon = <Domain />
    } else if (account.accountType === 0) {
        accountIcon = <Accessibility />
    }

    let accountLabel = 'Team'
    if (account.accountType === 2) {
        accountLabel = "Organisation"
    } else if (account.accountType === 0) {
        accountLabel = "Account"
    }

    return <div>
        <ListItem button selected={!disabled && activeItem === 'security'} onClick={handleClick('security')} disabled={disabled}>
            <ListItemIcon>
                <Lock />
            </ListItemIcon>
            <ListItemText primary="Security" />
        </ListItem>
        <ListItem button selected={!disabled && activeItem === 'billing'} onClick={handleClick('billing')} disabled={disabled}>
            <ListItemIcon>
                <AccountBalance />
            </ListItemIcon>
            <ListItemText primary="Billing" />
        </ListItem>
        <ListItem button selected={!disabled && activeItem === 'details'} onClick={handleClick('details')} disabled={disabled}>
            <ListItemIcon>
                {accountIcon}
            </ListItemIcon>
            <ListItemText primary={accountLabel} />
        </ListItem>
    </div>
}

export function User({ activeItem, nav, disabled = false }) {
    const handleClick = (name) => (event) => {
        nav(name)
    }

    return <div>
        <ListItem button selected={!disabled && activeItem === 'profile'} onClick={handleClick('profile')} disabled={disabled}>
            <ListItemIcon>
                <Person />
            </ListItemIcon>
            <ListItemText primary="Profile" />
        </ListItem>
        <ListItem button selected={!disabled && activeItem === 'notifications'} onClick={handleClick('notifications')} disabled={disabled}>
            <ListItemIcon>
                <Notifications />
            </ListItemIcon>
            <ListItemText primary="Notifications" />
        </ListItem>
    </div>
}
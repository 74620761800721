import moment from 'moment'
import { GT_WGS84, GT_OSGB } from 'utils/osgb'

export function formatDate(input, format, dateOnly) {
    if (!input) return ''
    const date = moment.utc(input)
    if (format) return date.local().format(format)
    if (dateOnly) return date.format('MMM Do YYYY')
    return moment(date).local().format('MMM Do YYYY HH:mm:ss')
}

export function formatInterval(input) {
    const diff = moment().utc() - moment.utc(input).local()
    return moment.duration(diff).humanize()
}

export function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    function deg2rad(deg) {
        return deg * (Math.PI / 180)
    }

    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2 - lat1);  // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2)
        ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d;
}



export function findCenter(arr) {
    var minX, maxX, minY, maxY;
    for (var i = 0; i < arr.length; i++) {
        minX = (arr[i][0] < minX || minX == null) ? arr[i][0] : minX;
        maxX = (arr[i][0] > maxX || maxX == null) ? arr[i][0] : maxX;
        minY = (arr[i][1] < minY || minY == null) ? arr[i][1] : minY;
        maxY = (arr[i][1] > maxY || maxY == null) ? arr[i][1] : maxY;
    }
    return [(minX + maxX) / 2, (minY + maxY) / 2];
}


export function locationToOsgb(lat, lng) {
    const wgs84 = new GT_WGS84()
    wgs84.setDegrees(lat, lng)
    const result = wgs84.getOSGB()
    const osgb = new GT_OSGB()
    osgb.setGridCoordinates(result.eastings, result.northings)
    const gridRef = osgb.getGridRef(1).split(' ')[0]
    let eastings = result.eastings + ''
    eastings = eastings.substring(eastings.length - 5, 4)
    let northings = result.northings + ''
    northings = northings.substring(northings.length - 5, 4)
    return {
        raw: { eastings: result.eastings, northings: result.northings, gridRef },
        clean: `${gridRef}${eastings}${northings}`
    }
}

export function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

export function rgbToHex(r, g, b) {
    function componentToHex(c) {
        var hex = c.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
    }
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
};


export function humanizeSeconds(sec_num) {
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    let result = ''

    if (hours) result += (hours + ' hours ')
    if (minutes) result += (minutes + ' minutes ')
    if (seconds && !hours && !minutes) result += (seconds + ' seconds ')
    return result
}

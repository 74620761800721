import { Divider, List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core'
import { CloudDownload, Filter1, QueryBuilder, RotateLeft, SignalCellularAlt, SimCard } from '@material-ui/icons'
import React from 'react'
import { formatDate } from 'utils/format'

export default function ({ device }) {
    const reset = () => {

    }

    const update = () => {

    }

    return (
        <div>
            <List>
                <ListItem>
                    <ListItemAvatar>
                        <SimCard />
                    </ListItemAvatar>
                    <ListItemText primary="IMEI" secondary={device.device.imei} />
                </ListItem>
                <ListItem>
                    <ListItemAvatar>
                        <QueryBuilder />
                    </ListItemAvatar>
                    <ListItemText primary="Last Online" secondary={formatDate(device.device.lastOnline)} />
                </ListItem>
                <ListItem>
                    <ListItemAvatar>
                        <Filter1 />
                    </ListItemAvatar>
                    <ListItemText primary="Version" secondary={device.device.version} />
                </ListItem>
                <ListItem>
                    <ListItemAvatar>
                        <SignalCellularAlt />
                    </ListItemAvatar>
                    <ListItemText primary="Signal Strength" secondary={device.device.signalStrength} />
                </ListItem>
                <Divider />
                <ListItem button onClick={update}>
                    <ListItemAvatar>
                        <CloudDownload />
                    </ListItemAvatar>
                    <ListItemText primary="Update Device" />
                </ListItem>
                <ListItem button onClick={reset}>
                    <ListItemAvatar>
                        <RotateLeft />
                    </ListItemAvatar>
                    <ListItemText primary="Factory Reset" />
                </ListItem>
            </List>
        </div>
    )
}
import React, { useEffect, useState, useRef } from "react"
import queryString from 'query-string'
import LoadingIcon from 'Components/LoadingIcon';
import { get } from 'utils/resources'
import { Typography, createMuiTheme, Link } from "@material-ui/core";
import { deepPurple, teal } from '@material-ui/core/colors';
import { makeStyles, ThemeProvider } from "@material-ui/styles";
import { AppBar, Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import LogoLight from 'Assets/Lockup-Horizontal-White.png'
import Map from './map'

const myStyle = makeStyles(() => ({
    invalid: {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    root: {
        height: '100%',
        width: '100%',
        position: 'fixed'
    },
    appBar: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    button: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 10
    }
}))

export default function PublicMap() {
    const [location, setLocation] = useState(null)
    const [share, setShare] = useState(null)
    const [loading, setLoading] = useState(true)
    const [invalid, setInvalid] = useState(false)
    const timer = useRef(null)
    const history = useHistory()

    const myTheme = createMuiTheme({
        palette: {
            primary: { main: teal[500] },
            secondary: { main: deepPurple[500] }
        }
    })
    const classes = myStyle()

    const getLocation = async () => {
        const result = await get(`devicelocation/${share.d}?t=${share.st}`, true, true)
        if (!result || !result.id) {
            setInvalid(true)
        } else {
            setLocation(result)
            if (timer.current) {
                clearTimeout(timer.current)
            }
            timer.current = setTimeout(getLocation, 5000)
        }
        setLoading(false)
    }

    useEffect(() => {
        (async () => {
            const query = queryString.parse(window.location.search)
            if (query.st && query.d) {
                setShare(query)
            } else {
                setInvalid(true)
                setLoading(false)
            }
        })()
        return function () {
            if (timer.current) {
                clearTimeout(timer.current)
            }
        }
    }, [])

    useEffect(() => {
        if (share) {
            getLocation()
        }
        // eslint-disable-next-line
    }, [share])

    if (loading) return <LoadingIcon />

    return (
        <ThemeProvider theme={myTheme}>

            {invalid && <div className={classes.invalid}>
                <AppBar position="absolute" >
                    <div className={classes.appBar}>
                        <Link href="https://meili.earth" target="_blank" alt="homepage">
                            <img src={LogoLight} height="50" width="auto" alt="meili-logo" />
                        </Link>
                        <div className={classes.button}>
                            <Button className={classes.buttonAction} onClick={() => history.push('/login')} variant="contained">Login/Register</Button>
                        </div>
                    </div>
                </AppBar>
                <Typography variant="h4" align="center">Link Expired or Not Started Yet</Typography>
            </div>}
            {!invalid && <div className={classes.root}>
                <Map location={location} />
            </div>}
        </ThemeProvider>
    )
}
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'utils/resources';
import { setBilling } from 'store/actions'
import { Container } from '@material-ui/core'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import Setup from './setup'
import Subscription from './subscription'
import LoadingIcon from 'Components/LoadingIcon';
import PermissionsChecker from 'Components/PermissionsChecker';
import ActivateAccountWarning from 'Components/ActivateAccount';


const stripePromise = loadStripe('pk_test_51IBQDqCFo9KIaGLz651Fq6fz21EauajcsZtqLN9fhigAAgOW9w1fjtsMfOcxLVHFqgcxTktHIeB76B4Xlg6NpE2O00YTSLrust')
export default function Billing() {
    const billing = useSelector(state => state.billing)
    const auth = useSelector(state => state.auth)
    const [plans, setPlans] = useState([])
    const [cards, setCards] = useState([])
    const [forceChange, setForceChange] = useState(false)
    const dispatch = useDispatch();

    useEffect(() => {
        if (auth.isVerified) {
            getPlans()
            getCards()
        }
    }, [auth])

    const getPlans = async function () {
        setPlans(await get('billing/{accountid}/subscriptions'))
    }

    const getCards = async function () {
        setCards(await get('billing/{accountid}/card'))
    }

    const getBilling = async function () {
        dispatch(setBilling(await get('billing/{accountid}')))
    }

    const refresh = function () {
        getPlans()
        getCards()
        getBilling()
    }

    if (!auth.isVerified) return <ActivateAccountWarning />

    if (!plans.length) return <LoadingIcon />

    return (
        <PermissionsChecker required="billing">
            <Container maxWidth="sm" disableGutters={true}>
                <Elements stripe={stripePromise} options={{ locale: navigator.language }}>
                    {(!billing.active || forceChange) && <Setup stripe={stripePromise} plans={plans} cards={cards} refresh={refresh} cancel={() => setForceChange(false)} forced={forceChange} />}
                    {billing.active && !forceChange && <Subscription stripe={stripePromise} plans={plans} cards={cards} refresh={refresh} changePlan={() => setForceChange(true)} />}
                </Elements>
            </Container>
        </PermissionsChecker>
    )
}
import LoadingIcon from 'Components/LoadingIcon';
import React, { useEffect, useState } from 'react';
import { get } from 'utils/resources';
import { findCenter } from 'utils/format'
import { Typography } from '@material-ui/core'
import DeviceButton from './deviceButton'
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import GenericMap from 'Components/Map';

const myStyle = makeStyles(theme => ({
    container: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    item: {
        padding: theme.spacing(1)
    },
    full: {
        flexGrow: 1
    }
}))

export default function DeviceGroup({ GroupId, goTo, updateGroup }) {
    const classes = myStyle()
    const [selected, setSelected] = useState([])
    const [locations, setLocations] = useState({})
    const [group, setGroup] = useState(null)

    const getGroup = async () => {
        const data = await get('devicegroup/{accountid}/' + GroupId)
        let _selected = Array.from(new Set(data.registeredDevices.map(d => d.registeredDevice.id)))
        _selected.forEach(getLocation)
        setGroup(data)
        setSelected(_selected)
    }

    const toggle = function (id) {
        let _selected = [...selected]
        const index = _selected.indexOf(id)
        if (index > -1) {
            let _locations = { ...locations }
            _selected.splice(index, 1)
            setSelected(_selected)
            delete _locations[id]
            setLocations(_locations)
        }
        else {
            _selected.push(id)
            setSelected(_selected)
            getLocation(id)
        }
    }

    const getLocation = async (id) => {
        const data = await get('devicelocation/{accountid}/' + id)
        let _locations = { ...locations }
        _locations[id] = data
        setLocations(_locations)
    }

    const remove = function (id) {
        const devices = group.registeredDevices.filter(d => d.registeredDeviceId !== id)
        updateGroup({
            id: this.state.group.id,
            Name: this.state.group.name,
            RegisteredDeviceIDs: Array.from(new Set(devices.map(d => d.registeredDeviceId)))
        }).then(getGroup)
    }

    useEffect(() => {
        getGroup()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!group) return <LoadingIcon />
    const pins = selected.map(deviceId => locations[deviceId]).filter(f => !!f)
    const center = findCenter(pins.map(p => ([Number(p.latitude), Number(p.longitude)])))
    return (
        <div className={classes.container}>
            <div className={classes.item}>
                <Typography variant="h6">{group.name}</Typography>
            </div>
            <div className={classes.item}>
                {group.registeredDevices.map(device => <DeviceButton key={device.id} device={device} goTo={goTo} selected={selected.indexOf(device.registeredDevice.id) > -1} remove={remove} toggle={toggle} />)}
            </div>
            <div className={clsx(classes.item, classes.full)}>
                {Boolean(pins.length) && <GenericMap
                    toggleDevice={toggle}
                    center={center}
                    devices={group.registeredDevices.map(d => d.registeredDevice)}
                    ids={selected}
                />}
                {Boolean(selected.length) && !Boolean(pins.length) && <LoadingIcon />}
                {!Boolean(selected.length) && !Boolean(pins.length) && <div style={{ textAlign: 'center', padding: '30px 0' }}>
                    <Typography as="h6">Click Devices to View their Location</Typography>
                </div>}
            </div>
        </div>
    );
}
import React, { useState } from 'react'
import { Typography, Grid, Card, CardContent, CardActionArea, Button, TextField } from '@material-ui/core'
import { Business, Group, Person } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

const myStyle = makeStyles(theme => ({
    header: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    }
}))

function getTypeDescription(val) {
    switch (val) {
        case 0: return 'For personal use with only one device'
        case 1: return 'If you need multiple devices used by multiple people'
        case 2: return 'For organisations with lots of devices and groups of teams'
        default: return null
    }
}

function getTypeLabel(val) {
    switch (val) {
        case 0: return 'Name'
        case 1: return 'Team Name'
        case 2: return 'Company Name'
        default: return null
    }
}

const types = ['Personal', 'Team/Family', 'Organisation']

export default function AccountWizard({ account, onSave }) {
    const classes = myStyle()
    const [accountType, setAccountType] = useState(account.accountType)
    const [name, setName] = useState(account.name)

    const save = function (e) {
        e.preventDefault()
        onSave({
            ...account,
            name,
            accountType
        })
    }

    return (
        <div>
            <Typography variant="h5" align="center" className={classes.header}>What type of account do you need?</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                    <Card raised={accountType === 0}>
                        <CardActionArea onClick={() => setAccountType(0)}>
                            <CardContent>
                                <Typography align="center" variant="h2">
                                    <Person fontSize="inherit" />
                                </Typography>
                                <Typography align="center" variant="h6">{types[0]}</Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Card raised={accountType === 1}>
                        <CardActionArea onClick={() => setAccountType(1)}>
                            <CardContent>
                                <Typography align="center" variant="h2">
                                    <Group fontSize="inherit" />
                                </Typography>
                                <Typography align="center" variant="h6">{types[1]}</Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Card raised={accountType === 2}>
                        <CardActionArea onClick={() => setAccountType(2)}>
                            <CardContent>
                                <Typography align="center" variant="h2">
                                    <Business fontSize="inherit" />
                                </Typography>
                                <Typography align="center" variant="h6">{types[2]}</Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                {accountType !== undefined && <Grid item xs={12}>
                    <form onSubmit={save}>
                        <Typography align="center" variant="h5">{types[accountType]}</Typography>
                        <Typography align="center">{getTypeDescription(accountType)}</Typography>
                        <TextField required fullWidth variant="outlined" label={getTypeLabel(accountType)} style={{ marginTop: 20 }} value={name} onChange={e => setName(e.currentTarget.value)} />
                        <div style={{ textAlign: 'center', marginTop: 20 }}>
                            <Button variant="contained" color="primary" type="submit">Next</Button>
                        </div>
                    </form>
                </Grid>}
            </Grid>
        </div>
    )
}
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Card, CardContent, Typography, Button, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { ZoomIn as ZoomInIcon, ZoomOut as ZoomOutIcon } from '@material-ui/icons'
import AvatarEditor from 'react-avatar-editor'
import PanoramaIcon from '@material-ui/icons/PanoramaOutlined';
import { full } from 'utils/resources'
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const myStyle = makeStyles(theme => ({
    card: {
        marginBottom: theme.spacing(3)
    },
    wrapper: {
    },
    toolbar: {
        background: theme.palette.background.paper,
        border: 0,
        color: 'black'
    },
    editor: {
        background: theme.palette.background.default,
        minHeight: 200,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    }
}))

export default function EventDetails({ event, setEvent }) {
    const classes = myStyle()
    const editor = useRef(null)
    const inputEl = useRef(null);
    const [sourceImage, setSourceImage] = useState(null);
    const [scale, setScale] = useState(1);
    const [editorContent, setEditorContent] = useState(EditorState.createEmpty())
    const [editHeader, setEditHeader] = useState(false)
    const [dateStr, setDateStr] = useState(Date.now())

    useEffect(() => {
        if (event.description) {
            const contentBlock = htmlToDraft(event.description);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                setEditorContent(editorState)
            }
        }
        // eslint-disable-next-line
    }, [])

    const onClickSave = () => {
        if (editor.current) {
            // If you want the image resized to the canvas size (also a HTMLCanvasElement)
            const canvasScaled = editor.current.getImageScaledToCanvas()
            setEvent({ ...event, header: canvasScaled.toDataURL("image/png") })
            setEditHeader(false)
            try {
                canvasScaled.toBlob(async function (blob) {
                    const formData = new FormData();
                    formData.append('File', blob, inputEl.current.files[0].name)
                    const options = full('event/' + event.id + '/header', {}, false, true)
                    const headers = new Headers()

                    headers.append('Authorization', options.options.headers.Authorization)

                    const myOptions = {
                        method: 'POST',
                        headers: headers,
                        body: formData
                    }
                    await fetch(options.url, myOptions)
                    setDateStr(Date.now())
                    inputEl.current.files = null
                });
            } catch (e) {
                console.log(e)
            }
        }
    }

    const ZoomIn = () => {
        setScale(scale + .1)
    }

    const ZoomOut = () => {
        setScale(scale - .1)
    }

    const handleUploadClick = async () => {
        if (inputEl.current.files[0]) {
            setSourceImage(inputEl.current.files[0])
            setEditHeader(true)
        }
    };

    const handleChange = (e) => {
        setEditorContent(e)
        setEvent({ ...event, description: draftToHtml(convertToRaw(e.getCurrentContent())) })
    }

    return (
        <Fragment>
            <Card className={classes.card}>
                <CardContent>
                    <Typography variant="h5">Header Image</Typography><Typography variant="caption">400x200</Typography>
                    <div style={{ textAlign: 'center' }}>
                        <div hidden={!editHeader}>
                            <AvatarEditor
                                ref={editor}
                                image={sourceImage}
                                width={400}
                                height={200}
                                scale={scale}
                            />
                            <div>
                                <IconButton color="primary" aria-label="Zoom In" component="span" onClick={ZoomIn}>
                                    <ZoomInIcon />
                                </IconButton>
                                <IconButton color="primary" aria-label="Zoom Out" component="span" onClick={ZoomOut}>
                                    <ZoomOutIcon />
                                </IconButton>
                                <Button onClick={onClickSave}>Save</Button>
                            </div>
                        </div>
                        <div hidden={editHeader}>
                            <input ref={inputEl} accept="image/*" style={{ display: 'none' }} id="contained-button-file" type="file" onChange={handleUploadClick} />
                            {Boolean(event.headerUrl) && <img height="200" width="400" src={event.headerUrl + '?' + dateStr} alt="header" />}
                            {!Boolean(event.headerUrl) && <PanoramaIcon style={{ height: 200, width: 400 }} />}
                            <div htmlFor="contained-button-file">
                                <Button onClick={() => inputEl.current.click()} variant="outlined" type="button">Upload Header Image</Button>
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
            <Card style={{ overflow: 'initial' }}>
                <CardContent>
                    <Typography variant="h5">Description</Typography>
                    <div>
                        <Editor
                            wrapperClassName={classes.wrapper}
                            toolbarClassName={classes.toolbar}
                            editorClassName={classes.editor}
                            editorState={editorContent}
                            onEditorStateChange={handleChange}
                        />
                    </div>
                </CardContent>
            </Card>
        </Fragment>
    )
}
import React from 'react';
import { TableRow, TableCell, LinearProgress, Typography, Box, Chip, MenuItem, Select, FormControl, Input, Button, makeStyles, Avatar, Checkbox, ListItemText } from '@material-ui/core';
import { formatInterval, locationToOsgb } from 'utils/format'

function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: '100%'
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    name: {
        justifyContent: 'flex-start'
    },
    avatar: {
        marginRight: theme.spacing(3)
    },
    sos: {
        animation: '$bgsos 1.5s infinite'
    },
    '@keyframes bgsos': {
        '0%': { backgroundColor: 'transparent' },
        '50%': { backgroundColor: theme.palette.error.main },
        '100%': { backgroundColor: 'transparent' }
    },
}));

export default function DeviceRow({ device, groups, myGroups, goTo, setGroups, acknowledge = () => { } }) {
    const classes = useStyles()
    const isSOS = device.device.sos
    const location = device.device.deviceLocation || {}

    return (
        <TableRow className={isSOS ? classes.sos : ''}>
            <TableCell>
                <Button fullWidth onClick={() => goTo(device.id + (isSOS ? '?page=notifications' : ''))} className={classes.name} startIcon={<Avatar className={classes.avatar} alt={device.name} src={device.registeredDeviceOptions.deviceAvatar} style={{ backgroundColor: device.registeredDeviceOptions.colour }} >{device.name[0]}</Avatar>} >
                    {device.name}
                </Button>
            </TableCell>
            <TableCell align="center">{locationToOsgb(location.latitude, location.longitude).clean}</TableCell>
            <TableCell>
                <LinearProgressWithLabel variant="determinate" value={device.device.battery} />
            </TableCell>
            <TableCell>{device.device.lastOnline ? formatInterval(device.device.lastOnline) + ' ago' : 'Never'}</TableCell>
            <TableCell>
                {!!groups.length && <FormControl className={classes.formControl}>
                    <Select
                        multiple
                        value={myGroups.map(g => g.id)}
                        onChange={e => setGroups(device.id, e.target.value)}
                        placeholder="Select Groups"
                        input={<Input />}
                        renderValue={(selected) => (
                            <div className={classes.chips}>
                                {selected.map((value) => {
                                    const group = myGroups.find(g => g.id === value)
                                    return <Chip key={group.id} label={group.name} className={classes.chip} />
                                })}
                            </div>
                        )}
                    >
                        {groups.map((group) => (
                            <MenuItem key={group.id} value={group.id}>
                                <Checkbox checked={myGroups.some(g => g.id === group.id)} />
                                <ListItemText primary={group.name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>}
                {!groups.length && 'No Groups'}
            </TableCell>
            <TableCell align="right">
                {device.device.allowed && <Button onClick={() => goTo(device.id)}>Settings</Button>}
                {isSOS && <Button style={{ marginLeft: 10 }} variant="contained" onClick={() => acknowledge(device.id)}>CLEAR SOS</Button>}
            </TableCell>
        </TableRow >
    )
}
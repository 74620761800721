import { TableCell, TableRow, Button, Menu, MenuItem, } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import React, { useState } from 'react'
import { formatDate } from 'utils/format';

const myStyle = makeStyles(theme => ({
    name: {
        display: 'flex',
        justifyContent: 'space-between'
    }
}))

export default function InviteRow({ invite, roles, resend, remove }) {
    const classes = myStyle()
    const [anchorEl, setAnchorEl] = useState(null);

    const buttonClick = (e) => {
        setAnchorEl(e.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const role = roles.find(r => r.id === invite.roleId)

    return (
        <TableRow>
            <TableCell>
                <div className={classes.name}>
                    <div>{invite.email}</div>
                    <div>{moment() < moment(invite.expires) ? 'Invite Expires ' + formatDate(invite.expires) : 'Invite Expired'}</div>
                </div>
            </TableCell>
            <TableCell>{role ? role.name : ''}</TableCell>
            <TableCell />
            <TableCell align="right">
                <Button onClick={buttonClick}>Options</Button>
                <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                    <MenuItem onClick={() => resend(invite)}>Resend</MenuItem>
                    <MenuItem onClick={() => remove(invite)}>Remove</MenuItem>
                </Menu>
            </TableCell>
        </TableRow>
    )
}
import React, { useEffect, useState, useRef } from 'react'
import PermissionsChecker from 'Components/PermissionsChecker';
import { Button, Paper, TextField, Typography, Grid, FormControl, InputLabel, Select, MenuItem, Chip, SwipeableDrawer, Checkbox, FormControlLabel } from '@material-ui/core'
import { Close, Delete } from '@material-ui/icons';
import GenericMap from 'Components/Map'
import Confirm from 'Components/Confirm'
import LoadingIcon from 'Components/LoadingIcon';
import { destroy, get, post, put } from 'utils/resources';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux'
import clsx from 'clsx';
import Devices from './devices'
import Options from './options'

const fenceTypes = ['Event Tracking', 'Enter', 'Exit']

const myStyle = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        paddingTop: theme.spacing(1),
        height: '100%',
        width: '100%',
        display: 'table',
        [theme.breakpoints.down("sm")]: {
            padding: 0
        }
    },
    content: {
        height: '100%',
        width: '100%',
        display: 'table-row',
        position: 'relative'
    },
    addFence: {
        marginBottom: 10,
        [theme.breakpoints.down("sm")]: {
            marginBottom: 0,
        }
    },
    fences: {
        position: 'absolute',
        top: 0,
        width: '25%',
        height: '100%',
        paddingRight: 16,
        overflowY: 'auto',
        [theme.breakpoints.down("sm")]: {
            top: 'auto',
            paddingRight: 0,
            width: '100%',
            height: 'auto',
        }
    },
    fencesBlank: {
        width: '40%',
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.down("sm")]: {
            width: '100%',
        }
    },
    fenceOptions: {
        position: 'absolute',
        top: 0,
        height: 60,
        width: '75%',
        left: '25%',
        [theme.breakpoints.down("sm")]: {
            display: 'none'
        }
    },
    mobileOptions: {
        display: 'none',
        [theme.breakpoints.down("sm")]: {
            width: '100%',
            display: 'flex',
            position: 'absolute',
            bottom: 0,
            justifyContent: 'space-evenly',
            alignItems: 'center',
            height: 60
        }
    },
    map: {
        position: 'absolute',
        top: 60,
        left: '25%',
        width: '75%',
        height: 'calc(100% - 60px)',
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.down("sm")]: {
            top: 70,
            width: '100%',
            left: 0,
            height: 'calc(100% - 60px - 70px)',
        }
    },
    mapBlank: {
        top: 0,
        left: '60%',
        width: '40%',
        height: '100%',
    },
    noFence: {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    fence: {
        cursor: 'pointer',
        minWidth: 240,
        padding: '10px 0 8px 5px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.divider}`,
        transition: theme.transitions.create("background-color", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        '&:hover': {
            backgroundColor: theme.palette.action.hover
        },
        [theme.breakpoints.down("sm")]: {
            display: 'none'
        }
    },
    selectedFence: {
        backgroundColor: theme.palette.action.hover
    },
    mobileFence: {
        display: 'none',
        paddingLeft: 10,
        paddingRight: 10,
        [theme.breakpoints.down("sm")]: {
            display: 'block'
        }
    },
    options: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    icon: {
        marginLeft: 5,
        cursor: 'pointer',
        transition: 'color 0.3s ease',
        '&:hover': {
            color: theme.palette.error.main
        }
    },
    primaryChip: {
        backgroundColor: theme.palette.primary.main,
    },
    successChip: {
        backgroundColor: theme.palette.success.main,
    },
    errorChip: {
        backgroundColor: theme.palette.error.main,
    }
}))

export default function Geofences() {
    const classes = myStyle()
    const [fences, setFences] = useState(null)
    const [fence, setFence] = useState(null)
    const [showFence, setShowFence] = useState(false)
    const [showDevices, setShowDevices] = useState(false)
    const [deleting, setDeleting] = useState(null)
    const [map, setMap] = useState(null)
    const [showOptions, setShowOptions] = useState(false)
    const location = useSelector(state => state.location)
    const nameInput = useRef(null)

    const getGeofences = async () => {
        let arr = await get('geofence')
        arr = arr.filter(a => a.geofenceType)
        setFences(arr)
        if (arr.length) {
            setFence(arr[0])
        }
    }

    const addFence = () => {
        let centerLatitude, centerLongitude
        if (map && map.getCenter) {
            const mapCenter = map.getCenter()
            centerLatitude = mapCenter.lat
            centerLongitude = mapCenter.lng
        } else {
            if (location && location.coords) {
                centerLatitude = location.coords.latitude
                centerLongitude = location.coords.longitude
            } else {
                centerLatitude = 53.6231179
                centerLongitude = -2.1639978
            }
        }
        setFence({
            name: 'New Fence',
            radius: 100,
            geofenceType: 1,
            centerLatitude,
            centerLongitude
        })
        setShowOptions(true)
    }

    const selectFence = e => {
        const id = e.target.value
        if (!id) return
        const found = fences.find(f => f.id === id)
        setFence(found)
    }

    const remove = (row, e) => {
        e.preventDefault()
        e.stopPropagation()
        setDeleting(row)
    }





    const confirmDelete = async () => {
        await destroy('geofence/{accountid}/' + deleting.id)
        getGeofences()
        setDeleting(null)
    }

    const setLocation = e => {
        if (fence) {
            setFence({ ...fence, centerLatitude: e.latlng.lat, centerLongitude: e.latlng.lng })
        }
    }

    const save = async () => {
        if (!fence) return
        if (fence.id) {
            await put('geofence/{accountid}/' + fence.id, fence)
            const index = fences.findIndex(f => f.id === fence.id)
            const arr = [...fences]
            arr[index] = fence
            setFences(arr)
        } else {
            const newFence = await post('geofence', fence)
            const arr = [...fences]
            arr.unshift(newFence)
            setFences(arr)
            setFence(newFence)
        }
        setShowOptions(false)
    }

    const cancelCreate = () => {
        setShowOptions(false)
        if (!fence.id) {
            if (fences.length) {
                setFence(fences[0])
            } else {
                setFence(null)
            }
        }
    }

    useEffect(() => {
        if (fence) {
            setTimeout(() => setShowFence(true), 195)
            if (map && map.setView) {
                map.setView([fence.centerLatitude, fence.centerLongitude])
            }
        }
        else setShowFence(false)
    }, [fence])

    useEffect(() => {
        if (map && map.setZoom) {
            map.setZoom(15)
        }
    }, [map])

    useEffect(() => {
        getGeofences()
    }, [])

    const colors = [classes.primaryChip, classes.successChip, classes.errorChip]

    return (
        <PermissionsChecker required="devices">
            {!fences && <LoadingIcon />}
            {Boolean(fences) && <Paper className={classes.root}>
                <div className={classes.content}>
                    <div className={clsx(classes.fences, fence ? '' : classes.fencesBlank)}>
                        <Button variant="outlined" className={classes.addFence} fullWidth onClick={addFence}>Add Geofence</Button>
                        {fences.map((row, index) => {
                            return <div className={clsx(classes.fence, fence && fence.id === row.id ? classes.selectedFence : '')} key={row.id} onClick={() => setFence(row)}>
                                <div><Typography variant="body2">{row.name}</Typography></div>
                                <div className={classes.options}>
                                    <Chip className={colors[row.geofenceType]} label={fenceTypes[row.geofenceType]} />
                                    <Checkbox checked={row.active} />
                                    <Delete className={classes.icon} onClick={e => remove(row, e)} />
                                </div>
                            </div>
                        })}
                        <div className={classes.mobileFence}>
                            <FormControl fullWidth>
                                <Select value={fence ? fence.id : ''} onChange={selectFence}>
                                    <MenuItem value=''>--Select Geofence--</MenuItem>
                                    {fences.map(f => <MenuItem value={f.id} key={f.id}>{f.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    {Boolean(fence) && <div className={classes.fenceOptions}>
                        <Grid container spacing={3}>
                            <Grid item xs={3} >
                                <TextField inputRef={nameInput} label="Name" value={fence.name} onChange={e => setFence({ ...fence, name: e.target.value })} fullWidth />
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <InputLabel>Alert On</InputLabel>
                                    <Select value={fence.geofenceType} onChange={e => setFence({ ...fence, geofenceType: parseInt(e.target.value) })}>
                                        <MenuItem value={1}>Alert on Enter</MenuItem>
                                        <MenuItem value={2}>Alert on Exit</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField fullWidth label="Radius (meters)" type="number" InputLabelProps={{ shrink: true, }} value={fence.radius} onChange={e => setFence({ ...fence, radius: parseInt(e.target.value) })} />
                            </Grid>
                            <Grid item xs={2} >
                                <FormControlLabel
                                    label="Active" className={classes.icon} control={<Checkbox checked={fence.active} onChange={e => setFence({ ...fence, active: !fence.active })} />} />
                            </Grid>
                            <Grid item xs={3} >
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Button variant="contained" style={{ marginTop: 10 }} fullWidth color="secondary" onClick={() => setShowDevices(true)}>Devices</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button variant="contained" style={{ marginTop: 10 }} fullWidth color="primary" onClick={save}>{fence.id ? 'Save' : 'Create'}</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>}

                    {Boolean(fence) && <div className={classes.mobileOptions}>
                        <Button variant="contained" color="default" onClick={() => setShowOptions(true)}>Options</Button>
                        <Button variant="contained" color="primary" onClick={save}>Save</Button>
                        <Button variant="contained" color="secondary" onClick={() => setShowDevices(true)}>Devices</Button>
                    </div>}
                    <div className={clsx(classes.map, fence ? '' : classes.mapBlank)}>
                        {showFence && <GenericMap defaultLocation={[fence.centerLatitude, fence.centerLongitude]} fences={[fence]} hideOptions={true} defaultShowMe={false} onClick={setLocation} returnMap={setMap} />}
                        {!fence && <div className={classes.noFence}>
                            <Typography variant="h5">Select/Add a Geofence</Typography>
                        </div>}
                    </div>
                </div>
            </Paper>}
            <Confirm header="Delete Geofence" open={Boolean(deleting)} subheader={deleting ? "Are you sure you want to delete " + deleting.name + "?" : ""}
                cancelText="Cancel" confirmText="Delete" onCancel={() => setDeleting(false)} onConfirm={confirmDelete}
            />
            <SwipeableDrawer anchor="right" disableSwipeToOpen={true} open={showDevices} onOpen={() => setShowDevices(true)} onClose={() => setShowDevices(false)}>
                <Devices geofence={fence} setGeofence={setFence} close={() => setShowDevices(false)} />
            </SwipeableDrawer>
            <SwipeableDrawer anchor="right" disableSwipeToOpen={true} open={showOptions} onOpen={() => setShowOptions(true)} onClose={cancelCreate}>
                <Options geofence={fence} setGeofence={setFence} close={save} cancel={cancelCreate} />
            </SwipeableDrawer>
        </PermissionsChecker >
    )
}
import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import { Alert as MuiAlert } from '@material-ui/lab'

function MyAlert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

export default function Alert({ alert, setAlert, status }) {
    return <Snackbar open={!!alert} autoHideDuration={2000} onClose={() => setAlert('')}>
        <MyAlert onClose={() => setAlert('')} severity={status || "success"}>{alert}</MyAlert>
    </Snackbar>
}
import React, { useEffect, useState, Fragment } from 'react'
import { Typography, Accordion, AccordionSummary, AccordionDetails, List, ListItem, Checkbox, ListItemText, ListItemAvatar, ListItemSecondaryAction, Avatar, Button } from '@material-ui/core'
import LoadingIcon from 'Components/LoadingIcon'
import { get } from 'utils/resources'
import { ExpandMore } from '@material-ui/icons'
import { patch } from 'utils/resources'

async function setGeofenceDevices(id, arr) {
    return await patch('geofence/{accountid}/' + id + '/device', {
        registeredDeviceIds: arr
    })
}

export default function GeofenceDevices({ geofence, setGeofence = () => { }, close = () => { } }) {
    const [devices, setDevices] = useState(null)
    const [groups, setGroups] = useState(null)
    const [ids, setIds] = useState([...geofence.registeredDevices.map(d => d.registeredDeviceId)])

    const toggleDevice = async id => {
        const arr = [...ids]
        const index = arr.indexOf(id)
        if (index === -1) {
            arr.push(id)
        } else {
            arr.splice(index, 1)
        }
        setIds(arr)
        const newGeofence = await setGeofenceDevices(geofence.id, arr)
        setGeofence({ ...geofence, registeredDevices: newGeofence.registeredDevices })
    }

    const onSelectGroup = async group => {
        const groupIds = group.registeredDevices.map(d => d.registeredDeviceId)
        const arr = [...ids]
        for (const id of groupIds) {
            const index = arr.indexOf(id)
            if (index === -1) {
                arr.push(id)
            }
        }
        setIds(arr)
        const newGeofence = await setGeofenceDevices(geofence.id, arr)
        setGeofence({ ...geofence, registeredDevices: newGeofence.registeredDevices })
    }

    useEffect(() => {
        get('device').then(setDevices)
        get('devicegroup').then(setGroups)
    }, [])

    if (!geofence) return null
    if (!devices || !groups) return <LoadingIcon />

    return (
        <div style={{ width: 300 }}>
            <Accordion defaultExpanded style={{ margin: 0 }}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography variant="h6">Devices</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ display: 'block', maxHeight: '33%', overflow: 'auto' }}>
                    <List component="nav">
                        {devices.map(device => {
                            return <ListItem key={device.id} dense button onClick={() => toggleDevice(device.id)}>
                                <ListItemAvatar>
                                    <Fragment>
                                        <Avatar style={{ backgroundColor: device.registeredDeviceOptions.colour }} alt={device.name} src={device.registeredDeviceOptions.deviceAvatar}>{device.name[0]}</Avatar>
                                    </Fragment>
                                </ListItemAvatar>
                                <ListItemText primary={device.name} id={device.id} />
                                <ListItemSecondaryAction>
                                    <Checkbox edge="start" color="primary" checked={ids.indexOf(device.id) > -1} onClick={() => toggleDevice(device.id)} tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': device.id }} />
                                </ListItemSecondaryAction>
                            </ListItem>
                        })}
                    </List>
                </AccordionDetails>
            </Accordion>

            {Boolean(groups.length) && <Accordion style={{ margin: 0 }}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography variant="h6">Groups</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ display: 'block', maxHeight: '33%', overflow: 'auto' }}>
                    {!!groups && !!groups.length && <List component="nav">
                        {groups.map(group => <ListItem key={group.id} button onClick={() => onSelectGroup(group)}>
                            <ListItemText primary={group.name} id={group.id} />
                            <ListItemSecondaryAction>({group.registeredDevices.length})</ListItemSecondaryAction>
                        </ListItem>)}
                    </List>}
                </AccordionDetails>
            </Accordion>}

            <div style={{ width: '100%', padding: 20 }}>
                <Button variant="contained" fullWidth color="primary" onClick={close}>Close</Button>
            </div>
        </div>
    )
}
import React from 'react'
import LightGif from '../Assets/LoadingGIFLight.gif'
import DarkGif from '../Assets/LoadingGIFWhite.gif'
import { useSelector } from 'react-redux';

export default function LoadingIcon({ height = 80, colour = 'dark' }) {
    const theme = useSelector(state => state.theme)
    return <div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img src={theme === 'dark' && colour === 'dark' ? DarkGif : LightGif} height={height} alt="loading" />
    </div>
}
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Stepper, Step, StepLabel, Container, Button } from '@material-ui/core'
import { get, put } from 'utils/resources';
import { setAccount } from 'store/actions';
import LoadingIcon from 'Components/LoadingIcon';

import Account from './account'
import Billing from '../admnistration/billing'
import Devices from './devices'


function getSteps() {
    return ['Configure Account', 'Setup Billing', 'Create Devices']
}

export default function Wizard() {
    const [currentAccount, setCurrentAccount] = useState(null)
    const [devices, setDevices] = useState([])
    const [step, setStep] = useState(0)
    const account = useSelector(state => state.account)
    const billing = useSelector(state => state.billing)
    const steps = getSteps()
    const dispatch = useDispatch()

    useEffect(() => {
        if (account) get('account/' + account.id).then(setCurrentAccount)
    }, [account])

    useEffect(() => {
        if (billing && account) {
            if (account.hasOwnProperty('accountType') && billing.id && billing.active) {
                getDevices()
                setStep(2)
            } else if (account.hasOwnProperty('accountType')) {
                setStep(1)
            }
        }
    }, [billing, account])

    const getDevices = async () => {
        setDevices(await get('device'))
    }

    const saveAccount = async (obj) => {
        const result = await put('account/' + account.id, obj)
        setCurrentAccount(result)
        setStep(1)
    }

    const finish = () => {
        dispatch(setAccount(currentAccount))
    }

    if (!currentAccount) return <LoadingIcon />

    return (
        <div>
            <Stepper activeStep={step}>
                {steps.map((label, index) => {
                    return <Step key={label} completed={step > index}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                })}
            </Stepper>

            <Container maxWidth="md">
                {step === 0 && <div>
                    <Account account={currentAccount} onSave={saveAccount} />
                </div>}
                {step === 1 && <div style={{ marginTop: 20 }}>
                    <Billing />
                    <div style={{ textAlign: 'center', marginTop: 20 }}>
                        <Button onClick={() => setStep(0)} style={{ marginRight: 5 }}>Back</Button>
                        <Button disabled={!billing.active} style={{ marginLeft: 5 }} variant="contained" color="primary" onClick={() => setStep(2)}>Next</Button>
                    </div>
                </div>}
                {step === 2 && <div>
                    <Devices devices={devices} setStep={setStep} finish={finish} refresh={getDevices} />
                </div>}
            </Container>
        </div>
    )
}
import { Card, CardContent, Typography, FormControlLabel, Checkbox, FormControl, InputAdornment, IconButton, InputLabel, OutlinedInput, Select, MenuItem, Chip, Button, SwipeableDrawer, Avatar, Tooltip } from '@material-ui/core'
import { FileCopy } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import React, { Fragment, useEffect, useState } from 'react'
import Alert from 'Components/Alert';
import { destroy, get, patch, post } from 'utils/resources';

const myStyle = makeStyles(theme => ({
    card: {
        marginBottom: theme.spacing(2)
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}))

export default function EventAttendees({ event, setEvent }) {
    const [alert, setAlert] = useState(null)
    const [devices, setDevices] = useState([])
    const [groups, setGroups] = useState([])
    const [selectedDevices, setSelectedDevices] = useState([])
    const [eventDevices, setEventDevices] = useState([])
    const [addingGroup, setAddingGroup] = useState(false)
    const [addingDevice, setAddingDevice] = useState(false)
    const [group, setGroup] = useState(undefined)
    const classes = myStyle()
    const inviteUrl = window.location.origin + '/event/' + event.id

    useEffect(() => {
        get('device').then(setDevices)
        get('devicegroup').then(setGroups)
        getEventDevices()
        // eslint-disable-next-line
    }, [])

    const getEventDevices = () => {
        get('event/{accountid}/' + event.id + '/devices').then(setEventDevices)
    }

    const copy = () => {
        setAlert('Link Copied to Clipboard')
    }

    const selectGroup = async e => {
        e.preventDefault();
        const _group = groups.find(g => g.id === group)
        await patch('event/{accountid}/' + event.id + '/devices', {
            registeredDeviceIds: _group.registeredDevices.map(d => d.registeredDeviceId)
        })
        getEventDevices()
        setAddingGroup(false)
        setGroup(null)
    }

    const selectDevice = e => {
        const id = e.currentTarget.name
        const index = selectedDevices.indexOf(id)
        const arr = [...selectedDevices]
        if (index === -1) {
            arr.push(id)
        } else {
            arr.splice(index, 1)
        }
        setSelectedDevices(arr)
    }

    const addDevices = async e => {
        e.preventDefault()
        await Promise.all(selectedDevices.map(id => {
            return patch('event/{accountid}/' + event.id + '/devices', {
                registeredDeviceIds: [id]
            })
        }))
        getEventDevices()
        setSelectedDevices([])
        setAddingDevice(false)
    }

    const handleDelete = async device => {
        await destroy('event/{accountid}/' + event.id + '/devices/' + device.registeredDeviceId || device.id)
        getEventDevices()
    }

    const attending = async device => {
        if (device.confirmed) {
            await post('event/' + event.id + '/devices/' + device.registeredDeviceId + '/unconfirm', null, true)
        } else {
            await post('event/' + event.id + '/devices/' + device.registeredDeviceId + '/confirm', null, true)
        }
        getEventDevices()
    }

    const availableDevices = devices.filter(d => !eventDevices.some(ed => ed.registeredDeviceId === d.id))

    return (
        <Fragment>
            <Card className={classes.card}>
                <CardContent>
                    <Typography variant="h5">Public</Typography>
                    <div>
                        <FormControlLabel control={<Checkbox checked={event.public} onChange={e => setEvent({ ...event, public: !event.public })} name="checkedB" color="primary" />} label="Available to Public?" />
                    </div>
                    {event.public && <CopyToClipboard text={inviteUrl} onCopy={copy}>
                        <FormControl fullWidth>
                            <InputLabel style={{ left: 13, top: 2 }}>Invite URL</InputLabel>
                            <OutlinedInput value={inviteUrl} onChange={e => e.preventDefault()} endAdornment={
                                <InputAdornment position="end">
                                    <IconButton aria-label="copy url" edge="end" >{<FileCopy />}</IconButton>
                                </InputAdornment>
                            } />
                        </FormControl>
                    </CopyToClipboard>}

                </CardContent>
            </Card>
            <Card className={classes.card}>
                <CardContent>
                    <Typography variant="h5" style={{ marginBottom: 10 }}>Devices
                        <Button variant="contained" color="primary" style={{ marginLeft: 10 }} onClick={() => setAddingDevice(true)} size="small">Add Devices</Button>
                        <Button variant="contained" color="secondary" style={{ marginLeft: 10 }} onClick={() => setAddingGroup(true)} size="small">Add Group</Button>
                    </Typography>
                    {Boolean(eventDevices.length) && <Typography variant="subtitle2">click devices to toggle attendance</Typography>}
                    <div className={classes.chips}>
                        {eventDevices.map(ed => {
                            let chip
                            const device = devices.find(d => d.id === ed.registeredDeviceId)
                            if (!device) {
                                chip = <Chip key={ed.id} label={ed.registeredDeviceName}
                                    color={ed.confirmed ? 'primary' : 'default'}
                                    avatar={<Avatar src={ed.deviceAvatar}>{ed.registeredDeviceName ? ed.registeredDeviceName[0] : ''}</Avatar>}
                                    onDelete={() => handleDelete(ed)}
                                    onClick={() => attending(ed)}
                                />
                            } else {
                                chip = <Chip
                                    key={ed.id}
                                    avatar={<Avatar style={{ backgroundColor: device.registeredDeviceOptions.colour }} src={device.registeredDeviceOptions.deviceAvatar}>{device.name[0]}</Avatar>}
                                    label={device.name}
                                    color={ed.confirmed ? 'primary' : 'default'}
                                    onDelete={() => handleDelete(ed)}
                                    onClick={() => attending(ed)}
                                />
                            }
                            return <Tooltip key={ed.id} title={ed.confirmed ? 'Attending' : 'Pending'} arrow placement="top">{chip}</Tooltip>
                        })}
                    </div>
                </CardContent>
            </Card>
            <SwipeableDrawer open={addingGroup} onOpen={() => setAddingGroup(true)} onClose={() => setAddingGroup(false)} disableSwipeToOpen anchor="right">
                <form style={{ width: 300, padding: 20 }} onSubmit={selectGroup}>
                    <Typography variant="h5">Select Group</Typography>
                    <FormControl fullWidth >
                        <InputLabel>Group</InputLabel>
                        <Select value={group} onChange={e => setGroup(e.target.value)} style={{ marginBottom: 20 }}>
                            {groups.map(group => <MenuItem key={group.id} value={group.id}>{group.name} ({group.registeredDevices.length})</MenuItem>)}
                        </Select>
                    </FormControl>
                    <Button style={{ width: 125, marginRight: 10 }} variant="contained" color="primary" type="submit">Select</Button>
                    <Button style={{ width: 125 }} variant="contained" onClick={() => setAddingGroup(false)}>Cancel</Button>
                </form>
            </SwipeableDrawer>
            <SwipeableDrawer open={addingDevice} onOpen={() => setAddingGroup(true)} onClose={() => setAddingDevice(false)} disableSwipeToOpen anchor="right">
                <form style={{ width: 300, padding: 20 }} onSubmit={addDevices}>
                    <Typography variant="h5" align="center">Add Devices</Typography>
                    {!Boolean(availableDevices.length) && <Typography variant="body1" align="center" style={{ margin: '10px 0' }}>No Devices Available</Typography>}
                    {availableDevices.map(device => {
                        return <FormControlLabel
                            key={device.id}
                            fullWidth
                            control={
                                <Checkbox
                                    checked={selectedDevices.indexOf(device.id) > -1}
                                    onChange={selectDevice}
                                    name={device.id}
                                    color="primary"
                                />
                            }
                            label={device.name}
                        />
                    })}
                    <Button style={{ width: 125, marginRight: 10 }} variant="contained" color="primary" type="submit">Select</Button>
                    <Button style={{ width: 125 }} variant="contained" onClick={() => setAddingDevice(false)}>Cancel</Button>
                </form>
            </SwipeableDrawer>
            <Alert alert={alert} setAlert={setAlert} />
        </Fragment>
    )
}
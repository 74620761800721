import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import { post } from 'utils/http'
import { Card, CardContent, Grid, makeStyles, TextField, Typography, Button, Link } from '@material-ui/core'
import LoadingIcon from 'Components/LoadingIcon'
import logo from 'Assets/Lockup-Colour.png'

const myStyles = makeStyles(theme => ({
    container: {
        height: '100%',
        position: 'absolute',
        backgroundImage: 'url(https://source.unsplash.com/user/meiligps/likes)',
        backgroundRepeat: 'no-repeat',
        backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        opacity: 0.9,
        borderRadius: 20
    },
    button: {
        marginTop: theme.spacing(1)
    },
    message: {
        marginTop: theme.spacing(2)
    }
}))

export default function ResetPassword() {
    const classes = myStyles()
    const [error, setError] = useState('')
    const [ready, setReady] = useState(false)
    const [passwords, setPasswords] = useState({ token: '', password: '', confirmPassword: '' })
    const [resetFinished, setResetFinished] = useState(false)
    const [resetError, setResetError] = useState('')

    useEffect(() => {
        const query = queryString.parse(window.location.search)
        if (!query.token) {
            return setError('Invalid Link')
        }

        post('auth/validate-reset-token', { token: query.token }).then(res => {
            if (res.message === 'Token is valid') {
                setPasswords({ password: '', confirmPassword: '', token: query.token })
                setReady(true)
            } else {
                setError(res.message || 'Link is no longer valid')
            }
        })
    }, [])

    const handleInput = e => {
        setPasswords({ ...passwords, [e.target.name]: e.target.value })
    }

    const resetPassword = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        const response = await post('auth/reset-password', passwords)
        if (response && response.message === 'Password reset successful, you can now login') {
            setResetFinished(true)
        } else {
            setResetError(response.message || 'Reset Failed, please try again')
        }
    }

    if (!ready && !error) return <LoadingIcon />

    return (<Grid container alignContent="center" justify="center" className={classes.container}>
        <Card className={classes.paper}>
            <CardContent>
                <div style={{ textAlign: 'center' }}>
                    <img src={logo} className="logo-img" alt="meili-logo" />
                </div>
                {!!error && <Typography variant="h5" color="error" align="center">{error}</Typography>}
                {!error && <form onSubmit={resetPassword} method="get">
                    <Typography variant="h5" align="center">Enter your new password below</Typography>
                    <TextField variant="outlined" margin="normal" required fullWidth label="New Password" name="password" type="password" autoFocus onChange={handleInput} />
                    <TextField variant="outlined" margin="normal" required fullWidth label="Repeat Password" name="confirmPassword" type="password" autoFocus onChange={handleInput} />
                    <Button className={classes.button} type="submit" variant="contained" fullWidth color="primary">Reset</Button>
                    {resetFinished && <Typography className={classes.message} variant="body1">Password Reset <Link href="/login">Go To Login</Link></Typography>}
                    {!!resetError && <Typography className={classes.message} variant="body1" align="center" color="error">{resetError}</Typography>}
                </form>}
            </CardContent>
        </Card>
    </Grid>)
}
import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useSelector } from 'react-redux';
import { useHistory, useParams } from "react-router";
import { Button, Drawer, SwipeableDrawer, Paper, TextField, Typography, Grid, List, ListItem, ListItemIcon, ListItemText, FormControl, InputLabel, Select, MenuItem, Menu, Fab } from '@material-ui/core'
import { Comment, Palette, Map, Image, Save } from '@material-ui/icons';
import { makeStyles, useTheme } from '@material-ui/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { CirclePicker } from 'react-color'
import domtoimage from 'dom-to-image'
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { get, post, put } from 'utils/resources';
import { getDistanceFromLatLonInKm, array_move, humanizeSeconds } from 'utils/format';
import { getRoute, routeToMapX } from 'utils/map'
import LoadingIcon from 'Components/LoadingIcon';
import Alert from 'Components/Alert';
import Confirm from 'Components/Confirm'
import PermissionsChecker from 'Components/PermissionsChecker';
import GenericMap from 'Components/Map'
import Geofence from './geofence'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import PinListItem from './listItem'

const pinsWidth = 250

const myStyle = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        paddingTop: theme.spacing(1),
        height: '100%',
        width: '100%',
        display: 'table',
        [theme.breakpoints.down("sm")]: {
            padding: 0
        }
    },
    content: {
        height: '100%',
        width: '100%',
        display: 'table-row',
        position: 'relative'
    },
    header: {
        display: 'table-row',
        width: '100%',
        [theme.breakpoints.down("sm")]: {
            display: 'none'
        }
    },
    headerContent: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(1)
    },
    subcontent: {
        position: 'relative',
        height: '100%'
    },
    pins: {
        position: 'absolute',
        top: 0,
        width: pinsWidth,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down("sm")]: {
            display: 'none'
        }
    },
    pinHolder: {
        flexGrow: 1,
        overflowY: 'auto'
    },
    map: {
        position: 'absolute',
        left: pinsWidth,
        top: 0,
        width: `calc(100% - ${pinsWidth}px)`,
        height: '100%',
        [theme.breakpoints.down("sm")]: {
            width: '100%',
            left: 0
        }
    },
    zoom: {
        bottom: 10,
        height: 60,
        left: 'calc(50% - 58px)',
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        backgroundColor: 'transparent',
        zIndex: 999,
    },
    fab: {
        marginRight: 1,
        marginLeft: 1
    },
    zoomIcons: {
        fontSize: 30
    },
    mobileoptions: {
        display: 'none',
        position: 'fixed',
        bottom: 80,
        right: 10,
        zIndex: 9999999,
        [theme.breakpoints.down("sm")]: {
            display: 'block'
        }
    }
}))

function getFromLs(key) {
    const saved = window.localStorage.getItem('meili-route-' + key)
    if (saved) {
        try {
            return JSON.parse(saved)
        } catch (e) {
            return null
        }
    }
    return null
}

function saveToLs(key, value) {
    value.lastSaved = new Date()
    window.localStorage.setItem('meili-route-' + key, typeof value === 'object' ? JSON.stringify(value) : value)
}

function removeFromLs(key) {
    window.localStorage.removeItem('meili-route-' + key)
}

async function processRoute(points, type) {
    if (!points || !points.length) return null
    switch (type) {
        case 0:
            type = 'foot'
            break;
        case 1:
            type = 'bike'
            break;
        case 2:
            type = 'car'
            break;
        default:
            type = 'foot';
            break;

    }
    const result = await getRoute(points, type)
    const route = result.routes[0]
    return { geometry: route.geometry.coordinates.map(l => l.reverse()), distance: route.distance, duration: Math.round(route.duration), legs: route.legs }
}

export default function SingleRoute() {
    const { id } = useParams()
    const history = useHistory()
    const classes = myStyle()
    const theme = useTheme()

    const account = useSelector(state => state.account)
    const [route, setRoute] = useState(null)
    const [points, setPoints] = useState(null)
    const [legs, setLegs] = useState(null)
    const [alert, setAlert] = useState(null)
    const [error, setError] = useState(null)
    const [center, setCenter] = useState([53.6231179, -2.1639978])
    const [overrideCenter, setOverrideCenter] = useState(0)
    const [map, setMap] = useState(null)
    const [bing, setBing] = useState(null)
    const [saving, setSaving] = useState(false)
    const [savingAs, setSavingAs] = useState(false)
    const [newName, setNewName] = useState(false)
    const [commenting, setCommenting] = useState(-1)
    const [colouring, setColouring] = useState(-1)
    const [showExportOptions, setShowExportOptions] = useState(false)
    const [travelType, setTravelType] = useState(0)
    const [autoRoute, setAutoRoute] = useState(true)
    const [anchorEl, setAnchorEl] = useState(null)
    const [menuItem, setMenuItem] = useState(null)
    const [newerAvailable, setNewerAvailable] = useState(false)
    const [confirmRemove, setConfirmRemove] = useState(-1)
    const [geofence, setGeofence] = useState(false)
    const commentRef = useRef()
    const saveasRef = useRef()
    const calculatingRoute = useRef(false)

    const handleRouting = async (obj) => {
        const toCheck = obj || points
        if (!toCheck || calculatingRoute.current) return
        calculatingRoute.current = true
        const waypoints = toCheck.filter(p => !p.hidden)

        async function go() {
            const result = await processRoute(waypoints, travelType)
            const legs = result.geometry.map(l => {
                l.latitude = l[0]
                l.longitude = l[1]
                l.hidden = true
                l.waypoint = false
                return l
            })
            const _points = [...toCheck]
            result.legs.forEach((leg, index) => {
                _points[index + 1].distance = leg.distance / 1000
            })
            setLegs(legs)
            setRoute({ ...route, distance: result.distance, duration: Math.round(result.duration), points: _points, autoRoute, travelType })
            setPoints(_points)
        }

        if (route && autoRoute && toCheck && toCheck.length && toCheck.length > 1) {
            if (!route.autoRoute || !route.routePoints || route.travelType !== travelType || route.routePoints.filter(p => !p.hidden).length !== toCheck.length || obj) {
                await go()
            } else {
                let reload = false
                route.routePoints.filter(p => !p.hidden).forEach((p, i) => {
                    if (p.latitude !== toCheck[i].latitude || p.longitude !== toCheck[i].longitude) reload = true
                })
                if (reload) {
                    await go()
                }
            }
        }
        calculatingRoute.current = false
        setOverrideCenter(overrideCenter + 1)
    }

    useEffect(() => {
        (async () => {
            setRoute(null)
            navigator.geolocation.getCurrentPosition(position => {
                setCenter([position.coords.latitude, position.coords.longitude])
                setOverrideCenter(99)
            })
            if (id === 'new') {
                // create a new blank one as we need an ID for geofencing
                const newRoute = await post('route', { name: 'Unnamed Route', distance: 0, duration: 0, travelType: 0, routePoints: [] })
                return history.push('/routeplanner/' + newRoute.id)
            }
            const obj = getFromLs(id)
            if (obj) {
                setRoute(obj)
                setPoints(obj.routePoints.slice().filter(p => !p.hidden).map(p => {
                    p.id = uuid()
                    return p
                }))
                const hidden = obj.routePoints.slice().filter(p => p.hidden)
                if (hidden.length && !legs) setLegs(hidden)

                setTravelType(obj.travelType || 0)
                setAutoRoute(obj.autoRoute === undefined ? true : obj.autoRoute)

                const savedRoute = id === 'new' ? null : await get('route/{accountid}/' + id)
                if (savedRoute && moment(savedRoute.updated) > moment(obj.lastSaved)) {
                    // its newer so let the load the route from the db instead
                    setNewerAvailable(true)
                }
            } else {
                await loadFromDb()
            }
        })()
        // eslint-disable-next-line
    }, [id, account])

    useEffect(() => {
        if (points && points.length && map.fitBounds) {
            map.fitBounds(points.map(r => ([r.latitude, r.longitude])))
        } else if (points && points.length && bing) {
            try {
                const last = points[points.length - 1]
                bing.setView({
                    center: new window.Microsoft.Maps.Location(last.latitude, last.longitude)
                })
            } catch (e) { }
        }
        // eslint-disable-next-line
    }, [map, bing])

    useEffect(() => {
        if (commentRef.current) commentRef.current.focus()
        // eslint-disable-next-line
    }, [commentRef.current])

    useEffect(() => {
        if (saveasRef.current) saveasRef.current.focus()
        // eslint-disable-next-line
    }, [saveasRef.current])

    useEffect(() => {
        handleRouting()
        // eslint-disable-next-line
    }, [travelType])

    useEffect(() => {
        if (autoRoute) {
            handleRouting()
        }
        // eslint-disable-next-line
    }, [autoRoute])

    useEffect(() => {
        if (route) {
            localSave()
        }
        // eslint-disable-next-line
    }, [route])

    useEffect(() => {
        if (legs && legs.length) {
            localSave()
        }
        // eslint-disable-next-line
    }, [legs])

    const loadFromDb = async () => {
        setNewerAvailable(false)
        const data = await get('route/{accountid}/' + id)
        setPoints(data.routePoints.slice().filter(p => !p.hidden))
        const hidden = data.routePoints.slice().filter(p => p.hidden)
        if (hidden.length && !legs) setLegs(hidden)
        setTravelType(data.travelType || 0)
        setAutoRoute(data.autoRoute === undefined ? true : data.autoRoute)
        setRoute(data)
    }

    // eslint-disable-next-line
    const localSave = useCallback(() => {
        if (route) {
            const id = route.id || account.id + '-1'
            const current = getFromLs(id)
            if (current) current.routePoints.forEach(p => delete p.highlight)
            const _points = [...points]
            _points.forEach(p => delete p.highlight)
            if (legs && legs.length && autoRoute) {
                legs.forEach(l => {
                    if (Array.isArray(l)) {
                        _points.push({ latitude: l[0], longitude: l[1], hidden: true, waypoint: false })
                    } else {
                        _points.push(l)
                    }
                })
            }
            if (!current || JSON.stringify(current) !== JSON.stringify({ ...route, routePoints: _points, travelType, autoRoute })) {
                saveToLs(id, { ...route, routePoints: _points, travelType, autoRoute })
            }
        }
    })

    const save = async (back) => {
        if (!route.name) return setError('Routes must have a name')
        setSaving(false)
        const obj = { ...route, routePoints: points.slice(), travelType, autoRoute }
        if (legs && legs.length && autoRoute) {
            legs.forEach(l => {
                if (Array.isArray(l)) {
                    obj.routePoints.push({ latitude: l[0], longitude: l[1], hidden: true, waypoint: false })
                } else {
                    obj.routePoints.push(l)
                }
            })
        }
        if (route.id) {
            await put('route/{accountid}/' + route.id, obj)
        } else {
            await post('route/{accountid}', obj)
        }
        removeFromLs(route.id || account.id + '-1')
        setAlert('Route Saved')
        if (back) history.push('/routeplanner')
    }

    const discard = () => {
        removeFromLs(route.id || account.id + '-1')
        history.push('/routeplanner')
    }

    const mobilesave = () => {
        setSaving(true)
    }

    const saveAs = () => {
        setNewName(route.name)
        setSavingAs(true)
    }

    const saveAsFinish = async (e) => {
        e.preventDefault()
        const newRoute = { ...route, id: null, name: newName }
        await post('route', newRoute)
        history.push('/routeplanner')
    }

    const addPin = (location) => {
        const current = location || map.getCenter()
        const _points = [...points]
        _points.push({ latitude: current.lat || current.latitude, longitude: current.lng || current.longitude, comment: '', id: uuid() })
        setPoints(_points)
        handleRouting(_points)
    }

    const setPinlocation = (index, location) => {
        const _points = [...points]
        _points[index] = { ...points[index], latitude: location.lat || location.latitude, longitude: location.lng || location.longitude }
        setPoints(_points)
        handleRouting(_points)
    }

    const moveItem = useCallback((dragIndex, hoverIndex) => {
        const _points = [...points]
        array_move(_points, dragIndex, hoverIndex)
        setPoints(_points)
        handleRouting(_points)
    }, [points]);

    const remove = (index) => {
        const _points = [...points]
        _points.splice(index, 1)
        setPoints(_points)
        setConfirmRemove(-1)
        setAnchorEl(null)
        handleRouting(_points)
    }

    const highlight = (index) => {
        const _points = [...points]
        _points.forEach((p, i) => p.highlight = i === index)
        setPoints(_points)
    }

    const updateComment = (e) => {
        const _points = [...points]
        _points[commenting].comment = e.target.value
        setPoints(_points)
        setRoute({ ...route, _points })
    }

    const setColour = (index, colour) => {
        const _points = [...points]
        _points[index].colour = colour
        setPoints(_points)
        setRoute({ ...route, _points })
        setColouring(-1)
    }

    const exportToPng = () => {
        setShowExportOptions(false)
        setAlert('Exporting')
        const id = 'leaflet-' + (route.id || '-1')
        domtoimage.toJpeg(document.getElementById(id)).then((dataUrl) => {
            const link = document.createElement('a')
            link.download = (route.name || 'route') + '.jpeg';
            link.href = dataUrl;
            link.click()

        })
    }

    const exportToGpx = () => {
        setShowExportOptions(false)
        setAlert('Exporting')
        const url = 'data:text/json;charset=utf-8,' + routeToMapX(route);
        const link = document.createElement('a');
        link.download = `${route.name || 'route'}.gpx`;
        link.href = url;
        document.body.appendChild(link);
        link.click();
    }

    const onMapClick = (e) => {
        addPin(e.latlng || { lat: e.location.latitude, lng: e.location.longitude })
    }

    const clickMenu = (e, i) => {
        setAnchorEl(e.currentTarget)
        setMenuItem(i)
    }

    const comment = () => {
        setCommenting(menuItem)
        setAnchorEl(null)
    }

    const colour = () => {
        setColouring(menuItem)
        setAnchorEl(null)
    }

    if (!route) return <LoadingIcon />

    const markers = points.map((pin, index) => {
        return {
            location: [pin.latitude, pin.longitude],
            draggable: true,
            color: pin.colour || theme.palette.primary.main,
            border: pin.highlight ? theme.palette.secondary.main : '',
            isRoute: true,
            pushPinOption: {
                title: pin.comment,
                open: Boolean(pin.comment)
            },
            infoboxOption: {},
            content: index + 1,
            onMove: setPinlocation
        }
    })

    let totalDistance = 0;
    const runningTotals = [0]

    points.forEach((pin, index) => {
        if (!index) return
        let distance = pin.distance
        if (!autoRoute) {
            const previous = points[index - 1]
            distance = getDistanceFromLatLonInKm(pin.latitude, pin.longitude, previous.latitude, previous.longitude)
        }
        pin.distance = distance
        totalDistance += distance
        runningTotals.push(totalDistance);
    })


    let polylines = [{
        location: points.map(row => [row.latitude, row.longitude]),
        option: {
            strokeColor: 'slategrey', strokeThickness: 5
        }
    }]

    if (autoRoute && legs && legs.length) {
        polylines = [{
            location: legs.map(l => ([l.latitude, l.longitude])),
            option: {
                strokeColor: 'slategrey', strokeThickness: 5
            }
        }]
    }

    if (points.length <= 1) polylines = undefined

    let duration = ''
    if (autoRoute && route.duration) {
        duration = humanizeSeconds(route.duration)
    }

    return (<PermissionsChecker required="devices">
        <Paper className={classes.root}>
            <div className={classes.header}>
                <div className={classes.headerContent}>
                    <div style={{ width: pinsWidth, paddingRight: 5 }}>
                        <TextField fullWidth label="Route Name" value={route.name} onChange={e => setRoute({ ...route, name: e.target.value })} error={!route.name.trim()} />
                    </div>
                    <div style={{ marginTop: 'auto', position: 'relative', flexGrow: 1 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ flexGrow: 1, paddingLeft: 5, paddingRight: 5 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="auto-route-label">Auto Route</InputLabel>
                                    <Select labelId="auto-route-label" id="travel-type" value={autoRoute} onChange={e => setAutoRoute(e.target.value)} >
                                        <MenuItem value={true}>Yes</MenuItem>
                                        <MenuItem value={false}>No</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div style={{ flexGrow: 1, paddingLeft: 5, paddingRight: 5 }}>
                                <FormControl fullWidth disabled={!autoRoute}>
                                    <InputLabel id="travel-type-label">Travel Type</InputLabel>
                                    <Select labelId="travel-type-label" id="travel-type" value={autoRoute ? travelType : 0} onChange={e => setTravelType(e.target.value)} >
                                        <MenuItem value={0}>Walking</MenuItem>
                                        <MenuItem value={1}>Cycling</MenuItem>
                                        <MenuItem value={2}>Driving</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                                <Button variant="contained" style={{ marginRight: 5 }} color="primary" size="medium" onClick={save}>Save</Button>
                                <Button variant="contained" style={{ marginRight: 5 }} color="secondary" size="medium" onClick={saveAs}>Save As</Button>
                                <Button variant="contained" style={{ marginRight: 5 }} size="medium" onClick={() => setGeofence(true)} color="primary" >Geofence</Button>
                                <Button variant="contained" style={{ marginRight: 5 }} size="medium" onClick={discard}>Discard</Button>
                                <Button variant="contained" size="medium" onClick={() => setShowExportOptions(true)}>Export</Button>
                                {showExportOptions && <div style={{ position: 'absolute', right: 0, zIndex: 999, top: 40 }}><ClickAwayListener onClickAway={() => setShowExportOptions(false)}>

                                    <Paper>
                                        <List>
                                            <ListItem button onClick={exportToPng}>
                                                <ListItemIcon><Image /></ListItemIcon>
                                                <ListItemText>as JPEG</ListItemText>
                                            </ListItem>
                                            <ListItem button onClick={exportToGpx} disabled={!markers.length}>
                                                <ListItemIcon><Map /></ListItemIcon>
                                                <ListItemText>as GPX</ListItemText>
                                            </ListItem>
                                        </List>
                                    </Paper>
                                </ClickAwayListener></div>}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className={classes.content}>
                <div className={classes.subcontent}>
                    <div className={classes.pins}>
                        <div style={{ paddingRight: 10 }}>
                            <Button fullWidth onClick={() => addPin()} variant="outlined">Add Pin</Button>
                        </div>
                        <div className={classes.pinHolder}>

                            <DndProvider backend={HTML5Backend}>
                                {points.map((pin, index) => {
                                    return <PinListItem key={index} id={pin.id} pin={pin} index={index} runningTotal={runningTotals[index]} deleting={confirmRemove === index} highlight={highlight} clickMenu={clickMenu} remove={setConfirmRemove} moveItem={moveItem} />
                                })}
                            </DndProvider>
                            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                                <MenuItem onClick={comment}>
                                    <ListItemIcon>
                                        <Comment fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="inherit">Comment</Typography>
                                </MenuItem>
                                <MenuItem onClick={colour}>
                                    <ListItemIcon>
                                        <Palette fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="inherit">Colour</Typography>
                                </MenuItem>
                            </Menu>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: 10, marginTop: 10 }}>
                            <div>
                                {Boolean(duration) && <Typography variant="caption">Duration</Typography>}
                                {Boolean(duration) && <Typography color="primary"><strong>{duration}</strong></Typography>}
                                {!Boolean(duration) && <Typography>{'Total'}</Typography>}
                            </div>
                            <div style={{ textAlign: 'right' }}>
                                {Boolean(duration) && <Typography variant="caption" align="right">Distance</Typography>}
                                <Typography color="primary" align="right"><strong>{totalDistance.toFixed(2)}km</strong></Typography>
                            </div>
                        </div>
                    </div>
                    <div className={classes.map}>
                        <GenericMap follow={false} id={route.id} defaultLocation={center} resetCenter={overrideCenter} pushpins={markers} rawPushpin={true}
                            polyline={polylines} returnMap={setMap} returnBing={setBing} onClick={onMapClick} markerClick={setConfirmRemove} defaultShowMe={false} />
                    </div>
                </div>
            </div>
            <div className={classes.mobileoptions}>
                <Fab color="primary" onClick={mobilesave}>
                    <Save />
                </Fab>
            </div>
        </Paper>
        <Alert alert={alert} setAlert={setAlert} />
        <Alert alert={error} setAlert={setError} status="error" />

        <Confirm
            header="Newer Route Detected"
            subheader="there is a newer version of this route saved, do you want to download that instead?"
            confirmText="Yes Please"
            cancelText="No Thanks"
            onConfirm={loadFromDb}
            onCancel={() => setNewerAvailable(false)}
            open={newerAvailable}
        />


        <Drawer open={saving} anchor="right">
            <div style={{ padding: 10 }}>
                <Typography variant="h5" align="center">Save Route</Typography>
                <form onSubmit={save}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField fullWidth label="Route Name" value={route.name} onChange={e => setRoute({ ...route, name: e.target.value })} error={!route.name.trim()} />
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" fullWidth type="submit" color="primary">Save</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" fullWidth type="button" onClick={() => setSaving(false)}>Cancel</Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Drawer>

        <Drawer open={savingAs} anchor="right">
            <div style={{ padding: 10 }}>
                <Typography variant="h5" align="center">Save Route As</Typography>
                <form onSubmit={saveAsFinish}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField inputRef={saveasRef} label="Route Name" fullWidth required value={newName} onChange={e => setNewName(e.target.value)} />
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" fullWidth type="submit" color="primary">Save</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" fullWidth type="button" onClick={() => setSavingAs(false)}>Cancel</Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Drawer>
        <SwipeableDrawer open={commenting > -1} disableSwipeToOpen={true} onOpen={() => { }} onClose={() => setCommenting(-1)}>
            <div style={{ padding: 10 }}>
                <form onSubmit={e => { e.preventDefault(); setCommenting(-1) }}>
                    <Typography variant="h5" align="center" style={{ marginBottom: 5 }}>Add Comment</Typography>
                    <TextField label="Comment" fullWidth inputRef={commentRef} value={commenting > -1 ? points[commenting].comment : ''} onChange={updateComment} style={{ marginBottom: 5 }} />
                    <Button variant="contained" type="submit" fullWidth color="primary">Save</Button>
                </form>
            </div>
        </SwipeableDrawer >
        <Drawer open={colouring > -1}>
            <div style={{ padding: '10px 20px' }}>
                <Typography variant="h5" align="center" style={{ marginBottom: 5 }}>Set Colour</Typography>
                {Boolean(points[menuItem]) && <CirclePicker value={points[menuItem].colour} onChange={e => setColour(menuItem, e.hex)} />}
            </div>
        </Drawer>

        <SwipeableDrawer open={geofence} disableSwipeToOpen={true} onOpen={() => { }} onClose={() => setGeofence(false)} anchor="right"  >
            <Geofence route={route} onClose={() => setGeofence(false)} />
        </SwipeableDrawer>

        <Confirm header="Remove Point?" subheader="Are you sure you want to remove this point?" open={confirmRemove > -1} confirmText="Yes" cancelText="No"
            onConfirm={() => remove(confirmRemove)} onCancel={() => setConfirmRemove(-1)}
        />
    </PermissionsChecker>)
}
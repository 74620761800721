import { makeStyles } from '@material-ui/core';
const drawerWidth = 240;

export default makeStyles(theme => ({
    root: {
        display: "flex",
        height: '100%',
        [theme.breakpoints.down("sm")]: {
            display: 'table',
            width: '100%'
        }
    },
    toolbar: {
        paddingRight: 24,
        [theme.breakpoints.down("sm")]: {
            paddingRight: 12,
            paddingLeft: 12
        }
    },
    toolbarIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0 8px",
        ...theme.mixins.toolbar
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: 'auto',
        [theme.breakpoints.down("sm")]: {
            display: 'none'
        }
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        [theme.breakpoints.down("sm")]: {
            width: '100%'
        }
    },
    bottomNavbar: {
        display: 'none',
        [theme.breakpoints.down("sm")]: {
            display: 'table-row',
            width: '100%',
            top: 'auto',
            bottom: 0,
        }
    },
    mobileButton: {
        '& .MuiIconButton-label': {
            display: 'block'
        }
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    bottomBar: {
        top: 'auto',
        bottom: 0,
        paddingLeft: 56,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    bottomBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        paddingLeft: 0,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton: {
        marginRight: 36,
        [theme.breakpoints.down("sm")]: {
            display: 'none'
        }
    },
    menuButtonHidden: {
        display: "none"
    },
    mobileMenuButton: {
        display: "none",
        [theme.breakpoints.down("sm")]: {
            display: 'block'
        }
    },
    deepPurple: {
        color: "#fff",
        backgroundColor: "#673ab7",
    },
    title: {
        flexGrow: 1
    },
    selectOpen: {
        paddingRight: 0
    },
    selectClose: {
        paddingRight: 0,
        paddingLeft: 5
    },
    drawerPaper: {
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        [theme.breakpoints.down("sm")]: {
            display: 'none'
        }
    },
    drawerPaperClose: {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(7)
        }
    },
    appBarSpacer: theme.mixins.toolbar,
    hideMob: {
        [theme.breakpoints.down("sm")]: {
            display: 'none'
        }
    },
    content: {
        flexGrow: 1,
        height: "100%",
        overflow: "auto",
        minHeight: '-webkit-fill-available',
        [theme.breakpoints.down("sm")]: {
            display: 'table-row',
            height: '100%',
            width: '100%'
        }
    },
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        height: 'calc(100% - 64px)',
        overflowY: 'auto',
        [theme.breakpoints.down("sm")]: {
            height: 'calc(100% - 68px)',
            position: 'relative',
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
        }
    },
    grid: {
        height: '100%'
    },
    verify: {
        marginLeft: theme.spacing(3)
    },
    notificationsDrawer: {
        padding: theme.spacing(3),
        [theme.breakpoints.down("sm")]: {
        }
    },
    notificationsRow: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.grey.main}`
    },
    notificationsActions: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    refreshWarning: {
        height: 20,
        bottom: -31,
        zIndex: 1200,
        position: 'fixed',
        backgroundColor: theme.palette.background.paper,
        width: '100%',
        transition: theme.transitions.create('bottom', { duration: theme.transitions.duration.standard }),
    },
    refreshWarningOpen: {
        bottom: 4
    },
    version: {
        position: 'fixed',
        bottom: 0,
        right: 10,
        [theme.breakpoints.down("sm")]: {
            display: 'none'
        }
    }
}))
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { setView } from 'store/actions'
import EditUser from './edit';
import UserRow from './userRow'
import UserCard from './userCard'
import InviteRow from './inviteRow'
import InviteCard from './inviteCard'
import AddUser from './addUser'
import { Grid, TextField, InputAdornment, Tooltip, Fab, IconButton, Table, TableHead, TableRow, TableCell, TableBody, Drawer } from '@material-ui/core';
import { SearchRounded, Apps, Toc, Add } from '@material-ui/icons'
import { destroy, post } from 'utils/resources';
import Alert from 'Components/Alert'

export default function Users({ users, invites, roles, getUsers, getInvites }) {
    const viewport = useSelector(state => state.viewport)
    const view = useSelector(state => state.views.users)
    const dispatch = useDispatch();

    const [searchText, setSearchText] = useState('')
    const [addingUser, setAddingUser] = useState(false)
    const [editingUser, setEditingUser] = useState(null)
    const [alert, setAlert] = useState('')

    const updateFilter = (e) => {
        setSearchText(e.target.value)
    }

    const filteredUsers = users.filter(u => {
        let fullname = u.user.firstName + ' ' + u.user.surname
        return fullname.toLowerCase().indexOf(searchText.toLowerCase()) > -1
    })

    const filteredInvites = invites.filter(i => {
        return i.email.toLowerCase().indexOf(searchText.toLocaleLowerCase()) > -1
    })

    const editUser = function (user) {
        setEditingUser(user)
    }

    const closeDrawer = function () {
        getUsers()
        getInvites()
        setAddingUser(false)
        setEditingUser(null)
    }

    const resend = async function (row) {
        await post('/userinvite/{accountid}/resend/' + row.id)
        setAlert('Invite Resent')
    }

    const removeInvite = async function (row) {
        await destroy('/userinvite/{accountid}?id=' + row.id)
        getInvites()
    }

    useEffect(() => {
        if (viewport.width < 768 && view !== 'Grid') {
            dispatch(setView('users', 'Grid'))
        } else if (!view) {
            dispatch(setView('users', 'Table'))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                    value={searchText} onChange={updateFilter}
                    fullWidth
                    label="Search Users"
                    InputProps={{
                        endAdornment: (<InputAdornment position="end">
                            <SearchRounded />
                        </InputAdornment>)
                    }}
                />
            </Grid>
            <Grid item md={6} lg={6} xl={6}>
                <Grid container direction="row" justify="flex-end" alignItems="flex-end" style={{ height: '100%' }}>
                    {viewport.width >= 768 && <Tooltip title="Card">
                        <IconButton color={view === 'Grid' ? 'primary' : 'default'} onClick={() => dispatch(setView('users', 'Grid'))} ><Apps /></IconButton >
                    </Tooltip>}
                    {viewport.width >= 768 && <Tooltip title="Table">
                        <IconButton color={view === 'Table' ? 'primary' : 'default'} onClick={() => dispatch(setView('users', 'Table'))}><Toc /></IconButton >
                    </Tooltip>}
                    <Tooltip title="Add" aria-label="add" >
                        <Fab color="primary" size="small" onClick={() => setAddingUser(true)}>
                            <Add />
                        </Fab>
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                {view === 'Table' && <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Role</TableCell>
                            <TableCell>SMS Notifications</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredUsers.map(user => {
                            return <UserRow key={user.id} user={user} edit={() => editUser(user)} getUsers={getUsers} />
                        })}
                        {filteredInvites.map(invite => {
                            return <InviteRow key={'i_' + invite.id} invite={invite} roles={roles} resend={resend} remove={removeInvite} />
                        })}
                    </TableBody>
                </Table>}

                {view === 'Grid' && <Grid container spacing={3} justify="center">
                    {filteredUsers.map(user => <UserCard key={user.id} user={user} roles={roles} editUser={() => editUser(user)} getUsers={getUsers} />)}
                    {filteredInvites.map(invite => <InviteCard key={invite.id} invite={invite} roles={roles} remove={removeInvite} resend={resend} />)}
                </Grid>}
            </Grid>
            <Drawer anchor="right" open={addingUser} onClose={() => setAddingUser(false)}>
                <AddUser close={closeDrawer} roles={roles} />
            </Drawer>
            <Drawer anchor="right" open={Boolean(editingUser)} onClose={() => setEditingUser(null)}>
                <EditUser close={closeDrawer} currentUser={editingUser} roles={roles} />
            </Drawer>
            <Alert setAlert={setAlert} alert={alert} />
            {/* <Snackbar open={!!alert} autoHideDuration={2000} onClose={() => setAlert('')}>
                <Alert onClose={() => setAlert('')} severity="success">{alert}</Alert>
            </Snackbar> */}
        </Grid >
    )
}
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux'
import { get, put } from 'utils/resources'

import LoadingIcon from 'Components/LoadingIcon';
import ActivateBilling from 'Components/ActivateBilling'
import ActivateAccount from 'Components/ActivateAccount'

import Devices from './devices/devices';
import Groups from './groups/groups';
import Group from './groups/group';
import Device from './devices/device';
import PermissionsChecker from 'Components/PermissionsChecker'
import { Tabs, Tab, Paper, makeStyles } from '@material-ui/core'
import { useHistory, useLocation, Switch, Route } from 'react-router';

const myStyle = makeStyles(theme => ({
    container: {
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        [theme.breakpoints.down("sm")]: {
            padding: 0,
            height: '100%'
        }
    }
}))

export default function DevicesTemplate() {
    const classes = myStyle()

    const history = useHistory()
    const location = useLocation()
    const [devices, setDevices] = useState([])
    const [groups, setGroups] = useState([])
    const [pathname, setPathName] = useState(history.location.pathname)
    const [loading, setLoading] = useState(true)
    const timer = useRef()

    const auth = useSelector(state => state.auth)
    const billing = useSelector(state => state.billing)

    useEffect(() => {
        if (auth.isVerified && billing.active) {
            getDevices()
            getGroups()
            timer.current = setInterval(getDevices, 10000)
        } else if (billing.id) {
            setLoading(false)
        }
        return function () {
            if (timer.current) clearInterval(timer.current)
        }
    }, [auth, billing])

    useEffect(() => {
        setPathName(location.pathname)
    }, [location])

    const getDevices = async () => {
        setDevices(await get('device'))
        setLoading(false)
    }

    const getGroups = async () => {
        setGroups(await get('devicegroup'))
    }

    const refresh = function () {
        getDevices()
        getGroups()
    }

    const updateGroup = async function (group) {
        await put('devicegroup/{accountid}/' + group.id, group)
        refresh()
    }

    const goTo = function (route) {
        history.push('/devices/' + route)
        setPathName('/devices/' + route)
    }

    const split = pathname.split('/')
    let route = split[2] || ""
    let id
    if (route === 'groups' && split[3]) {
        id = split[3]
    }

    if (loading) return <LoadingIcon />

    if (!auth.isVerified) {
        return <ActivateAccount />
    }
    if (billing.id && !billing.active) {
        return <ActivateBilling goto={() => history.push('billing')} />
    }

    if (!Array.isArray(devices)) {
        return <LoadingIcon />
    }

    return (
        <PermissionsChecker required="devices">
            <Paper className={classes.container} style={{ height: route === 'groups' && id ? '100%' : '' }}>
                {(!route || (route === 'groups' && !id)) && <Tabs centered value={route} onChange={(e, v) => goTo(v)} >
                    <Tab label="Devices" value={''} />
                    <Tab label="Groups" value={'groups'} />
                </Tabs>}
                <Switch>
                    <Route path="/devices/groups/:id">
                        <Group history={history} GroupId={id} goTo={goTo} />
                    </Route>
                    <Route path="/devices/groups" exact>
                        <Groups history={history} devices={devices} groups={groups} goTo={goTo} refresh={refresh} />
                    </Route>
                    <Route path="/devices/:id">
                        <Device id={route} history={history} close={getDevices} />
                    </Route>
                    <Route path="/devices" exact>
                        <Devices history={history} devices={devices} groups={groups} updateGroup={updateGroup} goTo={goTo} refresh={refresh} />
                    </Route>
                </Switch>
                {/* {route === '' && <Devices history={history} devices={devices} groups={groups} updateGroup={updateGroup} goTo={goTo} refresh={refresh} />}
                {route === 'groups' && !id && <Groups history={history} devices={devices} groups={groups} goTo={goTo} refresh={refresh} />}
                {route === 'groups' && id && <Group history={history} GroupId={id} goTo={goTo} />}
                {route && route !== 'groups' && <Device id={route} history={history} close={getDevices} />} */}
            </Paper>
        </PermissionsChecker>
    )
}
import React from 'react'
import { Switch, Route } from 'react-router'
import Edit from './edit'
import View from './view'

export default function Event() {
    return (
        <Switch>
            <Route path="/events/:id/view">
                <View />
            </Route>
            <Route path="/events/:id/edit">
                <Edit />
            </Route>
        </Switch>)
}
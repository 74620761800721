import React from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setPath } from 'store/actions'

export default function AdminNotifications() {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setPath('Admin | Notifications'))
    }, [])

    return (
        <div></div>
    )
}
import { Container, TextField, Button, makeStyles, Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { post } from 'utils/resources'

const mytheme = makeStyles(theme => ({
    container: {
        padding: theme.spacing(2),
        paddingTop: theme.spacing(8),
        textAlign: 'center'
    },
    textField: {
        marginBottom: theme.spacing(1)
    },
    button: {
        margin: theme.spacing(1)
    }
}))


export default function CreateAccount({ close }) {
    const [name, setName] = useState('')
    const [message, setMessage] = useState('')
    const classes = mytheme()

    const submit = async e => {
        e.preventDefault()
        const account = await post('account/create', { name })
        if (!account.id) {
            setMessage(account.message || 'Invalid')
        } else {
            close(account)
        }
    }

    return (<Container className={classes.container} maxWidth="sm">
        <Typography variant="h6">Create Organisation</Typography>
        <form onSubmit={submit}>
            <TextField className={classes.textField} id="name" fullWidth type="text" label="Name" name="name" onChange={e => setName(e.target.value)} value={name} />
            {!!message && <Typography color="error">{message}</Typography>}
            <Grid container alignItems="center" justify="center">
                <Button className={classes.button} variant="contained" type="button" onClick={() => close()}>Cancel</Button>
                <Button className={classes.button} variant="contained" color="primary" type="submit">Save</Button>
            </Grid>
        </form>
    </Container>)
}
import { Card, CardContent, Container, FormControl, Grid, IconButton, Input, InputAdornment, InputLabel, Link, Typography } from '@material-ui/core'
import { Send } from '@material-ui/icons'
import { makeStyles, useTheme } from '@material-ui/styles'
import LoadingIcon from 'Components/LoadingIcon'
import GenericMap from 'Components/Map'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { formatDate } from 'utils/format'
import { get } from 'utils/resources'

const myStyle = makeStyles(theme => ({
    card: {
        marginBottom: theme.spacing(2)
    }
}))

export default function ViewEvent() {
    const [event, setEvent] = useState(null)
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")
    const { id } = useParams()
    const theme = useTheme()
    const classes = myStyle()

    useEffect(() => {
        get('event/{accountid}/' + id).then(setEvent)
    }, [id])

    if (!event) return <LoadingIcon />

    const invite = (e) => {
        e.preventDefault()
        setMessage("Invite Sent")
        // send an invite somewhere?
    }

    if (!event) return <LoadingIcon />

    const marker = event ? {
        location: [event.locationLatitude, event.locationLongitude],
        draggable: true,
        color: theme.palette.primary.main,
        pushPinOption: {},
        infoboxOption: {},
    } : {}

    return (
        <Container maxWidth="lg">
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={8}>
                    <Card>
                        <CardContent>
                            <Typography variant="h3" align="center">{event.name}</Typography>
                            <div style={{ textAlign: 'center' }}><img src={event.headerUrl} alt="header" /></div>
                            <div dangerouslySetInnerHTML={{ __html: event.description }} />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" align="center">Date/Time</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography>Start Date</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography><strong>{formatDate(event.startDate, null, true)}</strong></Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography>Start Time</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography><strong>{formatDate(event.startDate, 'HH:mm')}</strong></Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography>End Time</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography><strong>{formatDate(event.endDate, 'HH:mm')}</strong></Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Card className={classes.card}>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" align="center">Location</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <div style={{ height: 200, marginBottom: 10 }}>
                                        <GenericMap fixed hideOptions smallZoom defaultShowMe={false} pushpins={[marker]} defaultLocation={[event.locationLatitude, event.locationLongitude]} />
                                    </div>
                                    <Typography align="center">
                                        <Link href={`https://www.google.com/maps/dir/?api=1&destination=${event.locationLatitude},${event.locationLongitude}`} target="_blank">Directions</Link>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Card className={classes.card}>
                        <CardContent>
                            <form onSubmit={invite}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h5" align="center">Attend</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="emailAddress">Email Address</InputLabel>
                                            <Input
                                                id="emailAddress"
                                                type="email"
                                                onChange={e => setEmail(e.target.value)}
                                                value={email}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton type="submit">
                                                            <Send />
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body1" align="center">{message}</Typography>
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                    </Card>
                </Grid>

            </Grid>
        </Container>
    )
}
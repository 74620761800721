import React, { useState } from 'react';
import { Grid, InputAdornment, Table, TableCell, TableHead, TableRow, TableBody, TextField, Fab, Tooltip, Drawer, Button, Typography, Dialog, DialogTitle, DialogContentText, DialogActions, DialogContent } from '@material-ui/core';
import { SearchRounded, Add } from '@material-ui/icons';
import AddGroup from './addGroup'
import GroupRow from './groupRow'
import GroupCard from './groupCard'
import { makeStyles } from '@material-ui/styles';
import { destroy } from 'utils/resources';

const myStyle = makeStyles(theme => ({
    table: {
        [theme.breakpoints.down("sm")]: {
            display: 'none'
        }
    },
    mobile: {
        display: 'none',
        [theme.breakpoints.down("sm")]: {
            display: 'block'
        }
    },
    mobilePadding: {
        [theme.breakpoints.down("sm")]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingBottom: theme.spacing(1)
        }
    }
}))

export default function Groups({ groups, refresh, devices, updateGroup, goTo }) {
    const classes = myStyle()
    const [searchText, setSearchText] = useState('')
    const [editing, setEditing] = useState(null)
    const [addingGroup, setAddingGroup] = useState(false)
    const [deleting, setDeleting] = useState(false);
    const [deletingGroup, setDeletingGroup] = useState(null);

    const updateFilter = (e) => {
        setSearchText(e.target.value)
    }

    const finishAdding = () => {
        refresh()
        setAddingGroup(false)
    }

    const onDelete = (group) => {
        setDeletingGroup(group)
        setDeleting(true)
    }

    const onDeleteConfirm = async (doIt) => {
        setDeleting(false)
        if (doIt) {
            await destroy('devicegroup/{accountid}/' + deletingGroup.id)
            setDeletingGroup(null)
            refresh()
        }
    }

    let _groups = groups.slice()
    if (searchText) {
        const text = searchText.toLowerCase()
        _groups = _groups.filter(d => d.name.toLowerCase().indexOf(text) > -1)
    }

    return (
        <Grid container >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.mobilePadding}>
                <TextField
                    value={searchText} onChange={updateFilter}
                    fullWidth
                    label="Search Groups"
                    InputProps={{
                        endAdornment: (<InputAdornment position="end">
                            <SearchRounded />
                        </InputAdornment>)
                    }}
                />
            </Grid>
            <Grid item md={6} lg={6} xl={6} className={classes.mobilePadding}>
                <Grid container direction="row" justify="flex-end" alignItems="flex-end" style={{ height: '100%' }}>
                    <Tooltip title="Add" aria-label="add" >
                        <Fab color="primary" size="small" onClick={() => setAddingGroup(true)}>
                            <Add />
                        </Fab>
                    </Tooltip>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Devices</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {_groups.map(group => <GroupRow key={group.id} group={group} goTo={goTo} devices={devices} updateGroup={updateGroup} edit={() => { setAddingGroup(true); setEditing(group); }} onDelete={() => onDelete(group)} />)}
                        {!_groups.length && <TableRow>
                            <TableCell colSpan="99" align="center">
                                <div style={{ marginBottom: '20px' }}>No Groups Found</div>
                                <Button variant="contained" color="primary" onClick={() => setAddingGroup(true)}>Add Group</Button>
                            </TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
                <Grid container className={classes.mobile}>
                    {_groups.map(group => <GroupCard key={group.id} group={group} goTo={goTo} devices={devices} updateGroup={updateGroup} edit={() => { setAddingGroup(true); setEditing(group); }} onDelete={() => onDelete(group)} />)}
                    {!_groups.length && <Typography variant="h5" align="center">No Groups Found</Typography>}
                </Grid>
            </Grid>

            <Drawer anchor="right" open={addingGroup} onClose={() => { setAddingGroup(false); setEditing(null); }}>
                <AddGroup close={finishAdding} existingGroup={editing} devices={devices} />
            </Drawer>

            {Boolean(deletingGroup) && <Dialog open={deleting} onClose={() => onDeleteConfirm(false)} aria-labelledby="responsive-dialog-title" >
                <DialogTitle id="responsive-dialog-title">{"Delete Group?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to delete {deletingGroup.name}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => onDeleteConfirm(false)} color="primary">No, Cancel</Button>
                    <Button onClick={() => onDeleteConfirm(true)} color="primary" autoFocus>Yes, Delete!</Button>
                </DialogActions>
            </Dialog>}
        </Grid>
    )
}

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Dashboard from './dashboard'
import Ticket from './ticket'

export default function SupportTemplate() {
    return (
        <Switch>
            <Route path="/admin/support/:id">
                <Ticket />
            </Route>
            <Route path="/admin/support">
                <Dashboard />
            </Route>
        </Switch>
    )
}
import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Devices from './devices'
import Device from './device'

export default function AdminDevices() {
    return (
        <Switch>
            <Route path='/admin/devices/:id'>
                <Device />
            </Route>
            <Route path='/admin/devices'>
                <Devices />
            </Route>
        </Switch>
    )
}
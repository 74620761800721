import { Container, Button, makeStyles, Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { post } from 'utils/resources'
import SwipeableViews from 'react-swipeable-views';
import Step1 from './step1'
import Step2 from './step2'
import Step3 from './step3'

const mytheme = makeStyles(theme => ({
    container: {
        padding: theme.spacing(2),
        textAlign: 'center',
        width: '100vw',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    textField: {
        marginBottom: theme.spacing(1)
    },
    button: {
        margin: theme.spacing(1)
    },
    steps: {
        flexGrow: 1,
    },
    views: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}))

export default function AddDevice({ close }) {
    const [device, setDevice] = useState({ name: '', imei: '' })
    const [message, setMessage] = useState('')
    const [stepIndex, setStepIndex] = useState(0)

    const save = async function () {
        setMessage('')
        post('device', device).then(newDevice => {
            if (newDevice && newDevice.id) close()
            else if (newDevice && newDevice.message) {
                setMessage(newDevice.message)
            } else {
                setMessage('Something went wrong')
            }
        })
    }

    const classes = mytheme()

    const steps = [
        <Step1 key={0} device={device} setDevice={setDevice} />,
        <Step2 key={1} device={device} setDevice={setDevice} currentStep={stepIndex} />,
        <Step3 key={2} device={device} setDevice={setDevice} />
    ]

    let stepDisabled = false
    if (stepIndex === 1 && !device.imei) {
        stepDisabled = true
    }
    if (stepIndex === 2 && !device.name) {
        stepDisabled = true
    }

    return <Container className={classes.container}>
        <Typography variant="h5" align="center">New Device Wizard</Typography>
        <div className={classes.steps}>
            <div className={classes.views}>
                <SwipeableViews index={stepIndex} style={{ height: 400 }} slideStyle={{ overflow: 'hidden' }}>
                    {steps.map(step => step)}
                </SwipeableViews>
            </div>
        </div>

        <div>
            {!!message && <Typography color="error">{message}</Typography>}
            <Grid container alignItems="center" justify="center">
                {!stepIndex && <Button className={classes.button} variant="contained" type="button" onClick={close}>Cancel</Button>}
                {Boolean(stepIndex) && <Button variant="contained" className={classes.button} onClick={() => setStepIndex(i => i - 1)}>Back</Button>}
                {stepIndex !== steps.length - 1 && <Button color="primary" className={classes.button} disabled={stepDisabled} variant="contained" onClick={() => setStepIndex(i => i + 1)}>Next</Button>}
                {stepIndex === steps.length - 1 && <Button disabled={!device.imei} className={classes.button} variant="contained" color="primary" onClick={save}>Save</Button>}
            </Grid>
        </div>
    </Container>
}
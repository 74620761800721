import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Grid, TextField, FormLabel, Slider, Button, makeStyles, Accordion, AccordionSummary, AccordionDetails, Typography, LinearProgress, Box, SwipeableDrawer, Drawer, Table, FormControl, InputLabel, TableContainer, TableHead, TableRow, TableCell, TableBody, Select, MenuItem } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ExpandMore } from '@material-ui/icons'
import SelectAvatar from 'Components/SelectAvatar'
import Advanced from './advanced'
import { destroy, get, post } from 'utils/resources';
import { useHistory } from 'react-router';
import { HuePicker } from 'react-color'
import { useSelector } from 'react-redux';
import { formatDate } from 'utils/format';
import About from './about'
import Alert from 'Components/Alert';
import moment from 'moment'

const mytheme = makeStyles(theme => ({
    textField: {
        marginBottom: theme.spacing(3)
    },
    button: {
        margin: theme.spacing(1)
    },
    sliderContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    slider: {
        width: '80%',
        [theme.breakpoints.down("sm")]: {
            width: '75%'
        }
    },
    accordian: {
        width: '100%',
        // marginTop: '20px',
        '&::before': {
            height: '0 !important'
        }
    },
    accordianSummary: {
        padding: 0
    },
    mobile: {
        [theme.breakpoints.down("sm")]: {
            position: 'fixed',
            height: 'calc(100% - 123px)',
            overflowY: 'auto',
            padding: 10
        }
    }
}))

function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

export default function BasicSettings({ device, save, settings, setVal, setSettings, options, setOptions, getDevice, close }) {
    const classes = mytheme()
    const history = useHistory()
    const auth = useSelector(state => state.auth)
    const account = useSelector(state => state.account)
    const [accounts, setAccounts] = useState([])
    const [deleting, setDeleting] = useState(false)
    const [debugging, setDebugging] = useState(false)
    const [debug, setDebug] = useState([])
    const [debugLevel, setDebugLevel] = useState("0")
    const [showAbout, setShowAbout] = useState(false)
    const [alert, setAlert] = useState('')
    const [moving, setMoving] = useState(false)
    const [destinationAccount, setDestinationAccount] = useState(null)
    const interval = useRef(null)

    const setFrequency = function (e, v) {
        setSettings({ ...settings, gpsUpdateFrequency: v })
    }

    const setSet = function (e) {
        setSettings({ ...settings, [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value })
    }

    const deleteDevice = async (remove) => {
        if (remove) {
            await destroy('device/{accountid}/' + device.id)
            setDeleting(false)
            close()
            history.push('/devices')
        } else {
            setDeleting(false)
        }
    }

    useEffect(() => {
        setDebug([]);
        if (debugging) {
            (async () => {
                setDebug(await get('debug/' + device.id + '?debugLevel=' + debugLevel, true))
            })()

            if (interval.current) {
                clearInterval(interval.current)
            }

            interval.current = setInterval(async () => {
                setDebug(await get('debug/' + device.id + '?debugLevel=' + debugLevel, true))
            }, 5000)
        } else {
            if (interval.current) {
                clearInterval(interval.current)
            }
        }

        return () => {
            if (interval.current) {
                setDebug([])
                clearInterval(interval.current)
            }
        }
    }, [debugging, device.id, debugLevel])

    useEffect(() => {
        get('account').then(setAccounts)
    }, [])

    const changeDebugLevel = async (e) => {
        setDebugLevel(e.target.value)
    }

    const sms = async (e) => {
        e.preventDefault()
        e.stopPropagation()
        await post('device/{accountid}/requestlocation')
        setAlert('Location Request Sent')
    }

    const move = async () => {
        await post(`device/{accountid}/${device.id}/move/${destinationAccount}`)
        setMoving(false)
        close()
        history.push('/devices')
    }

    const active = device.lastOnline && moment(device.lastOnline) > moment().startOf('day')
    const updateInterval = settings.updateInterval
    let stale = false
    if (active) {
        stale = moment.utc().subtract(updateInterval * 2, 'seconds') > moment.utc(device.lastOnline)
    }

    const marks = [{ value: 1, label: 'Power Save' }, { value: 2, label: 'Balanced' }, { value: 3, label: 'High Accuracy' }]
    const debugRows = debug.slice().reverse()

    return (
        <Grid item xs={12} sm={10} md={10} lg={6} xl={6} className={classes.mobile}>
            <SelectAvatar src={device.registeredDeviceOptions.deviceAvatar} colour={options.colour || '#757575'} name={device.name ? device.name[0] : ''} endpoint={device.isOwner ? 'device/{accountid}/' + device.id + '/options/avatar' : 'device/{accountid}/' + device.id + '/options/user/avatar'} cb={getDevice} />
            <form onSubmit={save}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <TextField className={classes.textField} fullWidth type="text" label="Name" name="name" onChange={setVal} value={device.name} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div className={classes.textField}>
                            <FormLabel>Colour</FormLabel>
                            <div style={{ top: 10, position: 'relative', width: '100%' }} >
                                <HuePicker color={options.colour || '#757575'} width={'100%'} onChange={e => setOptions({ ...options, colour: e.hex })} />
                            </div>
                        </div>
                    </Grid>
                </Grid>

                <div className={classes.textField}>
                    <FormLabel>Battery</FormLabel>
                    <LinearProgressWithLabel value={device.device.battery} color={device.device.battery <= 25 ? 'secondary' : 'primary'} />
                </div>

                {device.isOwner && auth.role === 'Admin' && <Fragment>
                    <FormLabel>Update Frequency</FormLabel>
                    <div className={classes.sliderContainer}>
                        <Slider valueLabelDisplay="off" className={classes.slider} min={1} max={3} marks={marks} value={settings.gpsUpdateFrequency} onChange={setFrequency} track={false} />
                    </div>
                    <Accordion elevation={0} className={classes.accordian} >
                        <AccordionSummary className={classes.accordianSummary} expandIcon={<ExpandMore />}>
                            <Typography variant="subtitle1" align="center">Advanced Settings</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: 0 }}>
                            <Advanced settings={settings} save={save} setSet={setSet} device={device.device} />
                        </AccordionDetails>
                    </Accordion>
                </Fragment>}
            </form>
            <Grid container justify="center">
                {accounts.length > 1 && device.isOwner && <Button className={classes.button} color="secondary" variant="contained" onClick={() => setMoving(true)}>Move</Button>}
                <Button className={classes.button} variant="contained" onClick={() => setDeleting(true)}>Delete</Button>
                <Button className={classes.button} variant="contained" color="primary" onClick={save}>Save</Button>
                {auth.role === 'Admin' && <Button className={classes.button} variant="contained" color="secondary" onClick={() => setDebugging(true)}>Debug</Button>}
                <Button className={classes.button} variant="contained" color="secondary" onClick={() => setShowAbout(true)}>About</Button>
                {stale && <Button className={classes.button} variant="contained" color="error" onClick={sms}>Request Location</Button>}
            </Grid>

            <Dialog open={deleting} onClose={() => deleteDevice()} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">{"Delete Device?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">Are you sure you want to delete this device</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => deleteDevice()} color="primary" autoFocus>Cancel</Button>
                    <Button onClick={() => deleteDevice(true)} color="primary">Confirm</Button>
                </DialogActions>
            </Dialog>

            <SwipeableDrawer disableSwipeToOpen={true} open={debugging} anchor="right" onOpen={() => { }} onClose={() => setDebugging(false)} >
                <Fragment>
                    <Button onClick={() => setDebugging(false)}>Close</Button>
                    <div style={{ padding: 5 }}>
                        <Select value={debugLevel} onChange={changeDebugLevel} fullWidth>
                            <MenuItem value="0">All</MenuItem>
                            <MenuItem value="1">Errors</MenuItem>
                            <MenuItem value="2">Warning</MenuItem>
                            <MenuItem value="3">Info</MenuItem>
                            <MenuItem value="4">Status</MenuItem>
                        </Select>
                    </div>
                    <TableContainer>
                        <Table size="small" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date/Time</TableCell>
                                    <TableCell>Level</TableCell>
                                    <TableCell>Message</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {debugRows.map(d => <TableRow key={d.id}>
                                    <TableCell>{formatDate(d.logDate)}</TableCell>
                                    <TableCell>{d.type}</TableCell>
                                    <TableCell>{d.message}</TableCell>
                                </TableRow>)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Fragment>
            </SwipeableDrawer>

            <SwipeableDrawer disableSwipeToOpen={true} open={showAbout} anchor="right" onOpen={() => { }} onClose={() => setShowAbout(false)} >
                <About device={device} />
            </SwipeableDrawer>

            <Drawer open={moving} anchor="right">
                <div style={{ padding: 20, width: 400 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h5">Move Device to Another Account</Typography>
                            <Typography>Moving removes all shares for this device</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>Destination Account</InputLabel>
                                <Select value={destinationAccount} onChange={e => setDestinationAccount(e.target.value)}>
                                    {accounts.filter(a => a.id !== account.id).map(a => <MenuItem value={a.id}>{a.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <Button fullWidth variant="contained" color="primary" disabled={!destinationAccount} onClick={move}>Move</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button fullWidth variant="contained" onClick={() => setMoving(false)}>Cancel</Button>
                        </Grid>
                    </Grid>
                </div>

            </Drawer>

            <Alert alert={alert} setAlert={setAlert} />
        </Grid>
    )
}
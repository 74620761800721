import React, { Component } from 'react';
import classSet from 'classnames';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';
import { locationToOsgb } from 'utils/format';

const key = "AlSnmDbOGnexN9nHfCtj4KG3mJKpSGgyx8P-lw9i916OGFRw-UpPJqImbv0YZCTv"

let map = {},
    Microsoft,
    infobox = {},
    scriptURL = "https://www.bing.com/api/maps/mapcontrol?callback=bingmapsCallback"

class ReactBingmaps extends Component {
    constructor(props) {
        super(props)
        this.state = {
            center: null,
            osgb: null
        }
        this.createRegularPolygons = this.createRegularPolygons.bind(this)
    }


    componentDidMount() {
        if (document.querySelector('script[src="' + scriptURL + '"]') === null) {
            this.loadScript(scriptURL);
            window.bingmapsCallback = function () {
                Microsoft = window.Microsoft;
                this.afterDependencyLoad();
            }.bind(this);
        } else {
            let count = 0
            let self = this
            function wait() {
                if (window.Microsoft) {
                    try {
                        Microsoft = window.Microsoft
                        self.reactBingmaps(self.props, Microsoft)
                    } catch (e) {
                        setTimeout(wait, 200)
                    }
                } else {
                    if (count < 10) {
                        count++
                        setTimeout(wait, 200)
                    }
                }
            }
            setTimeout(wait, 200)
        }
        if (Microsoft) {
            this.reactBingmaps(this.props, Microsoft);
        }
    }
    afterDependencyLoad() {
        try {
            this.reactBingmaps(this.props, Microsoft)
        }
        catch (exception) {
            console.log("Error loading Microsoft bingmaps");
        }
    }
    componentWillUnmount() {
        try {
            let mapReference = this.props.id ? ('#' + this.props.id) : '.react-bingmaps';
            if (map[mapReference])
                //map[mapReference].dispose();

                map[mapReference] = undefined;
            infobox = {};
        }
        catch (exception) {
            console.log(exception);
        }
    }
    loadScript(url) {
        var script = document.createElement("script")
        script.type = "text/javascript";
        script.async = true;
        script.defer = true;
        script.src = url;
        document.getElementsByTagName("head")[0].appendChild(script);
    }

    componentDidUpdate(prevProps) {
        let mapReference = prevProps.id ? ('#' + prevProps.id) : '.react-bingmaps';
        if (this.props.center.join() !== prevProps.center.join()) {
            this.setMapCenter(this.props.center, mapReference);
        }
        if (this.props.zoom !== prevProps.zoom) {
            this.setMapZoom(this.props.zoom, mapReference);
        }
        if (this.props.mapTypeId !== prevProps.mapTypeId) {
            this.setMapTypeId(this.props.mapTypeId, prevProps.center, prevProps.heading, mapReference);
        }
        if (this.props.navigationBarMode !== prevProps.navigationBarMode) {
            this.setMapNavigationBarMode(this.props.navigationBarMode, mapReference);
        }
        if (this.props.supportedMapTypes !== prevProps.supportedMapTypes) {
            this.setMapSupportedMapTypes(this.props.supportedMapTypes, mapReference);
        }
        if (this.props.disableStreetside !== prevProps.disableStreetside) {
            this.setDisableStreetside(this.props.disableStreetside, mapReference);
        }
        if (this.props.pushPins !== prevProps.pushPins) {
            this.setPushPins(this.props.pushPins, mapReference);
        }
        if (this.props.infoboxes !== prevProps.infoboxes) {
            this.setInfoboxes(this.props.infoboxes, "infoboxes", mapReference);
        }
        if (this.props.infoboxesWithPushPins !== prevProps.infoboxesWithPushPins) {
            this.setInfoboxesWithPushPins(this.props.infoboxesWithPushPins, "infoboxesWithPushPins", mapReference);
        }
        if (this.props.regularPolygons !== prevProps.regularPolygons || this.state.osgb) {
            this.createRegularPolygons(this.props.regularPolygons, mapReference);
        }
        if (this.props.boundary !== prevProps.boundary) {
            this.setBoundary(this.props.boundary, mapReference);
        }
        if (this.props.mapOptions !== prevProps.mapOptions) {
            this.setMapOptions(this.props.mapOptions, mapReference);
        }
        if (this.props.polyline !== prevProps.polyline) {
            this.setPolyline(this.props.polyline, mapReference);
        }
        if (this.props.directions !== prevProps.directions) {
            this.setDirections(this.props.directions, mapReference);
        }
    }

    reactBingmaps(props, Microsoft) {
        const {
            center,
            mapTypeId,
            zoom,
            navigationBarMode,
            supportedMapTypes,
            heading,
            pushPins,
            disableStreetside,
            infoboxes,
            infoboxesWithPushPins,
            getLocation,
            regularPolygons,
            boundary,
            mapOptions,
            polyline,
            directions,
            mapHandlers
        } = props;
        if (Microsoft) {
            let mapReference = props.id ? ('#' + props.id) : '.react-bingmaps';
            if (!map[mapReference]) {
                const newMap = new Microsoft.Maps.Map(mapReference, {
                    credentials: key,
                    showDashboard: this.props.showDashboard === false ? false : undefined,
                    showMapTypeSelector: false,
                    showTermsLink: false,
                    showZoomButtons: false,
                    showLocateMeButton: false,
                    disablePanning: this.props.fixed,
                    disableZooming: this.props.fixed
                });
                map[mapReference] = newMap
                if (this.props.onClick) {
                    Microsoft.Maps.Events.addHandler(newMap, 'click', e => {
                        this.props.onClick(e)
                    })
                }
                if (this.props.centerChanged) {
                    Microsoft.Maps.Events.addHandler(newMap, 'viewchangeend', () => {
                        this.props.centerChanged(newMap.getCenter())
                    })
                }
                Microsoft.Maps.Events.addHandler(newMap, 'rightclick', e => {
                    console.log(e)
                    this.setState({
                        center: [e.location.latitude, e.location.longitude],
                        osgb: locationToOsgb(e.location.latitude, e.location.longitude)
                    })
                })
                this.props.setMap(newMap)

                // try {
                //     Microsoft.Maps.Events.addHandler(newMap, 'viewchange', (e) => {
                //         this.props.setZoom(map[mapReference].getZoom())
                //         const center = map[mapReference].getCenter()
                //         this.props.setCenter([center.latitude, center.longitude])
                //     })
                // } catch (e) {
                //     console.log(e)
                // }
            }
            this.setMapCenter(center, mapReference);
            this.setMapTypeId(mapTypeId, center, heading, mapReference);
            this.setMapZoom(zoom, mapReference);
            this.setMapNavigationBarMode(navigationBarMode, mapReference);
            this.setMapSupportedMapTypes(supportedMapTypes, mapReference);
            this.setDisableStreetside(disableStreetside, mapReference);
            this.setPushPins(pushPins, mapReference);
            this.setInfoboxes(infoboxes, "infoboxes", mapReference);
            this.setInfoboxesWithPushPins(infoboxesWithPushPins, "infoboxesWithPushPins", mapReference);
            this.setGetLocation(getLocation, mapReference);
            this.createRegularPolygons(regularPolygons, mapReference);
            this.setBoundary(boundary, mapReference);
            this.setMapOptions(mapOptions, mapReference);
            this.setPolyline(polyline, mapReference);
            this.setDirections(directions, mapReference);
            this.setMapHandlers(mapHandlers, mapReference)
        }
    }

    setMapCenter(center, mapReference) {
        if (map[mapReference] && center && center[0] && center[1]) {
            map[mapReference].setView({
                center: new Microsoft.Maps.Location(center[0], center[1])
            });
        }
    }
    setMapTypeId(mapTypeId, center, heading, mapReference) {
        if (map[mapReference] && mapTypeId) {
            let isBirdEyeAvailable = false;
            if (mapTypeId === "birdseye" && center && center[0] && center[1]) {
                let location = new Microsoft.Maps.Location(center[0], center[1]);
                Microsoft.Maps.getIsBirdseyeAvailable(location, Microsoft.Maps.Heading[heading], (onResponse => { isBirdEyeAvailable = onResponse; }));
            }
            if (mapTypeId) {
                map[mapReference].setView({
                    mapTypeId: isBirdEyeAvailable ? Microsoft.Maps.MapTypeId.birdseye : Microsoft.Maps.MapTypeId[mapTypeId]
                });
            }
        }
    }
    setMapZoom(zoom, mapReference) {
        if (map[mapReference] && zoom) {
            map[mapReference].setView({
                zoom: zoom
            });
        }
    }
    setMapNavigationBarMode(navigationBarMode, mapReference) {
        if (map[mapReference] && navigationBarMode) {
            map[mapReference].setView({
                navigationBarMode: navigationBarMode
            });
        }
    }
    setMapSupportedMapTypes(supportedMapTypes, mapReference) {
        if (map[mapReference] && supportedMapTypes) {
            map[mapReference].setView({
                supportedMapTypes: supportedMapTypes.map((id) => Microsoft.Maps.MapTypeId[id])
            });
        }
    }
    setDisableStreetside(disableStreetside, mapReference) {
        if (map[mapReference] && disableStreetside) {
            map[mapReference].setView({
                disableStreetside: disableStreetside
            });
        }
    }
    setPushPins(pushPins, mapReference) {
        if (map[mapReference]) {
            let removed = false
            for (var i = map[mapReference].entities.getLength() - 1; i >= 0; i--) {
                var pushpin = map[mapReference].entities.get(i);
                if (pushpin instanceof Microsoft.Maps.Pushpin) {
                    removed = true
                    map[mapReference].entities.removeAt(i);
                }
            }
            setTimeout(() => {
                if (pushPins) {
                    for (var pushPinIndex = 0; pushPinIndex < pushPins.length; pushPinIndex++) {
                        const myPushPin = pushPins[pushPinIndex]
                        const myIndex = pushPinIndex
                        if (myPushPin.location && myPushPin.location[0] && myPushPin.location[1]) {
                            const location = new Microsoft.Maps.Location(myPushPin.location[0], myPushPin.location[1]);
                            const option = myPushPin.option ? myPushPin.option : {}
                            if (option && option.anchor && option.anchor[0] && option.anchor[1]) {
                                option.anchor = new Microsoft.Maps.Point(option.anchor[0], option.anchor[1])
                            }
                            if (myPushPin.draggable) {
                                option.draggable = true
                            }
                            const pushpin = new Microsoft.Maps.Pushpin(location, option);

                            map[mapReference].entities.push(pushpin);

                            if (option && option.draggable) {
                                Microsoft.Maps.Events.addHandler(pushpin, 'dragend', e => {
                                    if (myPushPin && myPushPin.onMove) {
                                        myPushPin.onMove(myIndex, e.location)
                                    }
                                })
                                Microsoft.Maps.Events.addHandler(pushpin, 'click', e => {
                                    if (this.props.markerClick) {
                                        this.props.markerClick(myIndex)
                                    }
                                })
                            }
                        }
                    }
                }
            }, removed ? 10 : 0)

        }
    }
    setInfoboxes(infoboxes, infoboxCreateType, mapReference) {
        if (map[mapReference] && infoboxes) {
            for (var i = 0; infobox[infoboxCreateType] && i < infobox[infoboxCreateType].length; i++) {
                infobox[infoboxCreateType][i].setMap(null);
            }
            infobox[infoboxCreateType] = [];
            if (infoboxes) {
                for (var infoboxIndex = 0; infoboxIndex < infoboxes.length; infoboxIndex++) {
                    if (infoboxes[infoboxIndex].location && infoboxes[infoboxIndex].location[0] && infoboxes[infoboxIndex].location[1]) {
                        let location = new Microsoft.Maps.Location(infoboxes[infoboxIndex].location[0], infoboxes[infoboxIndex].location[1]);
                        let option = infoboxes[infoboxIndex] ? infoboxes[infoboxIndex].option : null;
                        if (option.htmlContent) {
                            option.htmlContent = ReactDOMServer.renderToStaticMarkup(option.htmlContent);
                        }
                        infobox[infoboxCreateType].push(new Microsoft.Maps.Infobox(location, option));
                        infobox[infoboxCreateType][infoboxIndex].setMap(map[mapReference]);
                        if (infoboxes[infoboxIndex].addHandler) {
                            Microsoft.Maps.Events.addHandler(infobox[infoboxCreateType][infoboxIndex], infoboxes[infoboxIndex].addHandler.type,
                                function (callback, data) { this.MakeCallback(callback, data) }.bind(this, infoboxes[infoboxIndex].addHandler.callback, infoboxes[infoboxIndex].addHandler.callbackData));
                        }
                    }
                }
            }
        }
    }
    setInfoboxesWithPushPins(infoboxesWithPushPins, infoboxCreateType, mapReference) {
        if (map[mapReference]) {
            //Remove existing Infoboxes
            var i;
            for (i = 0; infobox[infoboxCreateType] && i < infobox[infoboxCreateType].length; i++) {
                infobox[infoboxCreateType][i].setMap(null);
            }

            //Remove existing Pushpins
            for (i = map[mapReference].entities.getLength() - 1; i >= 0; i--) {
                var pushpin = map[mapReference].entities.get(i);
                if (pushpin instanceof Microsoft.Maps.Pushpin) {
                    map[mapReference].entities.removeAt(i);
                }
            }

            infobox[infoboxCreateType] = [];

            //Add Infoboxes with Pushpins
            if (infoboxesWithPushPins) {
                for (var infoboxWithPushPinIndex = 0; infoboxWithPushPinIndex < infoboxesWithPushPins.length; infoboxWithPushPinIndex++) {
                    const myPushPin = infoboxesWithPushPins[infoboxWithPushPinIndex]
                    const myIndex = infoboxWithPushPinIndex
                    if (infoboxesWithPushPins[infoboxWithPushPinIndex].location) {
                        //Set Location
                        let location = new Microsoft.Maps.Location(infoboxesWithPushPins[infoboxWithPushPinIndex].location[0], infoboxesWithPushPins[infoboxWithPushPinIndex].location[1]);

                        //Set Infobox Option
                        let infoboxOption = infoboxesWithPushPins[infoboxWithPushPinIndex].infoboxOption ? infoboxesWithPushPins[infoboxWithPushPinIndex].infoboxOption : null

                        //ConvertToHtml if Obj
                        if (infoboxOption.htmlContent) {
                            infoboxOption.htmlContent = ReactDOMServer.renderToStaticMarkup(infoboxOption.htmlContent);
                        }

                        //If Handler added, initially hide Infobox
                        if (infoboxesWithPushPins[infoboxWithPushPinIndex].addHandler) {
                            infoboxOption["visible"] = false;
                        }

                        //Set Pushpin Option
                        let pushPinOption = infoboxesWithPushPins[infoboxWithPushPinIndex].pushPinOption ? infoboxesWithPushPins[infoboxWithPushPinIndex].pushPinOption : {}

                        //Initilize if anchor for Pushpin
                        if (pushPinOption.anchor && pushPinOption.anchor[0] && pushPinOption.anchor[1]) {
                            pushPinOption.anchor = new Microsoft.Maps.Point(pushPinOption.anchor[0], pushPinOption.anchor[1])
                        }

                        if (myPushPin.draggable) {
                            pushPinOption.draggable = true
                        }

                        // if(myPushPin.icon) {
                        //     pushPinOption.icon = myPushPin.icon.replace()
                        // }

                        //Set Infobox
                        infobox[infoboxCreateType].push(new Microsoft.Maps.Infobox(location, infoboxOption));
                        infobox[infoboxCreateType][infoboxWithPushPinIndex].setMap(map[mapReference]);

                        //Set Infobox Callback if any
                        if (infoboxesWithPushPins[infoboxWithPushPinIndex].infoboxAddHandler) {
                            Microsoft.Maps.Events.addHandler(infobox[infoboxCreateType][infoboxWithPushPinIndex], infoboxesWithPushPins[infoboxWithPushPinIndex].infoboxAddHandler.type,
                                function (callback, data) { this.MakeCallback(callback, data) }.bind(this, infoboxesWithPushPins[infoboxWithPushPinIndex].infoboxAddHandler.callback, infoboxesWithPushPins[infoboxWithPushPinIndex].infoboxAddHandler.callbackData));
                        }

                        //Set Pushpin				
                        let pushpin = new Microsoft.Maps.Pushpin(location, pushPinOption);
                        map[mapReference].entities.push(pushpin);

                        //Set Pushpin Callback if any
                        if (infoboxesWithPushPins[infoboxWithPushPinIndex].pushPinAddHandler) {
                            Microsoft.Maps.Events.addHandler(pushpin, infoboxesWithPushPins[infoboxWithPushPinIndex].pushPinAddHandler.type,
                                function (callback, data) { this.MakeCallback(callback, data) }.bind(this, infoboxesWithPushPins[infoboxWithPushPinIndex].pushPinAddHandler.callback, infoboxesWithPushPins[infoboxWithPushPinIndex].pushPinAddHandler.callbackData));
                        }

                        //Set InfoboxesWithPushPins handler if any
                        if (infoboxesWithPushPins[infoboxWithPushPinIndex].addHandler) {
                            this.setInfoboxesWithPushPinsHandler(infobox[infoboxCreateType][infoboxWithPushPinIndex], pushpin, infoboxesWithPushPins[infoboxWithPushPinIndex].addHandler);
                        }

                        if (pushPinOption && pushPinOption.draggable) {
                            Microsoft.Maps.Events.addHandler(pushpin, 'dragend', e => {
                                if (myPushPin && myPushPin.onMove) {
                                    myPushPin.onMove(myIndex, e.location)
                                }
                            })
                            Microsoft.Maps.Events.addHandler(pushpin, 'click', e => {
                                if (this.props.markerClick) {
                                    this.props.markerClick(myIndex)
                                }
                            })
                        }
                    }
                }
            }
        }
    }
    setGetLocation(getLocation, mapReference) {
        if (map[mapReference] && getLocation) {
            if (getLocation.addHandler) {
                Microsoft.Maps.Events.addHandler(map[mapReference], getLocation.addHandler,
                    function (callback, e) {
                        if (typeof e.getX !== "undefined" && typeof e.getY !== "undefined") {
                            let point = new Microsoft.Maps.Point(e.getX(), e.getY());
                            let location = e.target.tryPixelToLocation(point);
                            this.MakeCallback(callback, location);
                        }
                        else {
                            this.MakeCallback(callback, "Event: " + getLocation.addHandler);
                        }
                    }.bind(this, getLocation.callback)
                );
            }
            else {
                Microsoft.Maps.Events.addHandler(map[mapReference], "click",
                    function (callback, e) {
                        if (typeof e.getX !== "undefined" && typeof e.getY !== "undefined") {
                            let point = new Microsoft.Maps.Point(e.getX(), e.getY());
                            let location = e.target.tryPixelToLocation(point);
                            this.MakeCallback(callback, location);
                        }
                        else {
                            this.MakeCallback(callback, "Event: " + getLocation.addHandler);
                        }
                    }.bind(this, getLocation.callback)
                );
            }
        }
    }
    setInfoboxesWithPushPinsHandler(infobox, pushpin, addHandler, mapReference) {
        if (addHandler === "mouseover") {
            Microsoft.Maps.Events.addHandler(pushpin, addHandler, function () {
                infobox.setOptions({ visible: true });
            });
            Microsoft.Maps.Events.addHandler(pushpin, "mouseout", function () {
                infobox.setOptions({ visible: false });
            });
        }
        else {
            Microsoft.Maps.Events.addHandler(pushpin, addHandler, function () {
                infobox.setOptions({ visible: true });
            });
        }
    }
    MakeCallback(callback, data, mapReference) {
        data ? callback(data) : callback();
    }
    createRegularPolygons(regularPolygons, mapReference) {
        var self = this
        if (map[mapReference]) {
            for (var i = map[mapReference].entities.getLength() - 1; i >= 0; i--) {
                var regularPolygon = map[mapReference].entities.get(i);
                if (regularPolygon instanceof Microsoft.Maps.Polygon) {
                    map[mapReference].entities.removeAt(i);
                }
            }
            if (regularPolygons) {
                for (let regularPolygonIndex = 0; regularPolygonIndex < regularPolygons.length; regularPolygonIndex++) {
                    if (regularPolygons[regularPolygonIndex].center &&
                        regularPolygons[regularPolygonIndex].center[0] &&
                        regularPolygons[regularPolygonIndex].center[1]) {
                        let location = new Microsoft.Maps.Location(regularPolygons[regularPolygonIndex].center[0], regularPolygons[regularPolygonIndex].center[1]);
                        let radius = regularPolygons[regularPolygonIndex].radius ? regularPolygons[regularPolygonIndex].radius : 0;
                        let points = regularPolygons[regularPolygonIndex].points ? regularPolygons[regularPolygonIndex].points : 0;
                        let option = regularPolygons[regularPolygonIndex].option ? regularPolygons[regularPolygonIndex].option : {};

                        // eslint-disable-next-line
                        Microsoft.Maps.loadModule('Microsoft.Maps.SpatialMath', function () {
                            var locations = Microsoft.Maps.SpatialMath.getRegularPolygon(location, radius, points, Microsoft.Maps.SpatialMath.DistanceUnits.Miles);
                            var polygon = new Microsoft.Maps.Polygon(locations, option);
                            map[mapReference].entities.push(polygon);
                        });
                    }
                }
            }

            if (this.state.osgb) {
                const location = new Microsoft.Maps.Location(self.state.center[0], self.state.center[1]);
                Microsoft.Maps.loadModule('Microsoft.Maps.SpatialMath', function () {
                    var locations = Microsoft.Maps.SpatialMath.getRegularPolygon(location, 50 / 1000, 36, Microsoft.Maps.SpatialMath.DistanceUnits.Miles);
                    var polygon = new Microsoft.Maps.Polygon(locations, {
                        strokeThickness: 2,
                        fillColor: 'blue',
                        color: 'blue',
                    });
                    map[mapReference].entities.push(polygon);
                });


                if (infobox.osgb) {
                    infobox.osgb.setMap(null)
                }

                infobox.osgb = new Microsoft.Maps.Infobox(location, {
                    title: `OSGB ${self.state.osgb.clean}`,
                    visible: true
                });
                infobox.osgb.setMap(map[mapReference]);
            }
        }
    }
    setBoundary(boundary, mapReference) {
        if (map[mapReference] && boundary) {

            for (var i = map[mapReference].entities.getLength() - 1; i >= 0; i--) {
                var regularPolygon = map[mapReference].entities.get(i);
                if (regularPolygon instanceof Microsoft.Maps.Polygon) {
                    map[mapReference].entities.removeAt(i);
                }
            }

            // var boundaryLocation;
            // if(boundary.option && 
            // 	boundary.option.entityType && 
            // 		!(boundary.option.entityType.includes("Postcode"))){
            // 	boundaryLocation = new Microsoft.Maps.Location(boundary.location[0], boundary.location[1]);
            // }
            // else{
            // 	boundaryLocation = boundary.location
            // }

            var boundaryLocation = boundary.location ? boundary.location : null;

            var geoDataRequestOptions = boundary.option ? boundary.option : {};
            var polygonStyle = boundary.polygonStyle ? boundary.polygonStyle : null;
            var search = boundary.search ? boundary.search : null;
            if (!search && boundaryLocation) {
                Microsoft.Maps.loadModule('Microsoft.Maps.SpatialDataService', function () {
                    Microsoft.Maps.SpatialDataService.GeoDataAPIManager.getBoundary(boundaryLocation, geoDataRequestOptions, map[mapReference], function (data) {
                        if (data.results && data.results.length > 0) {
                            map[mapReference].entities.push(data.results[0].Polygons);
                        }
                    }, polygonStyle, function errCallback(networkStatus, statusMessage) {
                        console.log(networkStatus);
                        console.log(statusMessage);
                    });
                });
            }
            else {
                Microsoft.Maps.loadModule(['Microsoft.Maps.SpatialDataService', 'Microsoft.Maps.Search'], function () {
                    var searchManager = new Microsoft.Maps.Search.SearchManager(map[mapReference]);
                    var geocodeRequest = {
                        where: search,
                        callback: function (geocodeResult) {
                            if (geocodeResult && geocodeResult.results && geocodeResult.results.length > 0) {
                                map[mapReference].setView({ bounds: geocodeResult.results[0].bestView });
                                Microsoft.Maps.SpatialDataService.GeoDataAPIManager.getBoundary(geocodeResult.results[0].location, geoDataRequestOptions, map[mapReference], function (data) {
                                    if (data.results && data.results.length > 0) {
                                        map[mapReference].entities.push(data.results[0].Polygons);
                                    }
                                }, polygonStyle, function errCallback(networkStatus, statusMessage) {
                                    console.log(networkStatus);
                                    console.log(statusMessage);
                                });
                            }
                        },
                    };
                    searchManager.geocode(geocodeRequest);
                });
            }
        }
    }
    setMapOptions(mapOptions, mapReference) {
        if (map[mapReference] && mapOptions) {
            map[mapReference].setOptions(mapOptions);
        }
    }
    setPolyline(polyline, mapReference) {
        if (map[mapReference] && polyline) {

            for (var i = map[mapReference].entities.getLength() - 1; i >= 0; i--) {
                var ref = map[mapReference].entities.get(i);
                if (ref instanceof Microsoft.Maps.Polyline) {
                    map[mapReference].entities.removeAt(i);
                }
            }

            if (Array.isArray(polyline)) {
                polyline.forEach(pl => {
                    var polylineLocations = pl.location ? pl.location : null;
                    var polylineOption = pl.option ? pl.option : null;

                    var polylineLocationsAsMapLocations = [];
                    for (var polylineLocationIndex = 0;
                        polylineLocationIndex < polylineLocations.length
                        && polylineLocations[polylineLocationIndex][0]
                        && polylineLocations[polylineLocationIndex][1];
                        polylineLocationIndex++) {
                        polylineLocationsAsMapLocations.push(
                            new Microsoft.Maps.Location(polylineLocations[polylineLocationIndex][0],
                                polylineLocations[polylineLocationIndex][1])
                        )
                    }

                    if (polylineLocationsAsMapLocations.length !== 0) {
                        var polylineObject = new Microsoft.Maps.Polyline(polylineLocationsAsMapLocations, polylineOption);
                        map[mapReference].entities.push(polylineObject);
                    }
                })
            } else {

                var polylineLocations = polyline.location ? polyline.location : null;
                var polylineOption = polyline.option ? polyline.option : null;

                var polylineLocationsAsMapLocations = [];
                for (var polylineLocationIndex = 0;
                    polylineLocationIndex < polylineLocations.length
                    && polylineLocations[polylineLocationIndex][0]
                    && polylineLocations[polylineLocationIndex][1];
                    polylineLocationIndex++) {
                    polylineLocationsAsMapLocations.push(
                        new Microsoft.Maps.Location(polylineLocations[polylineLocationIndex][0],
                            polylineLocations[polylineLocationIndex][1])
                    )
                }

                if (polylineLocationsAsMapLocations.length !== 0) {
                    var polylineObject = new Microsoft.Maps.Polyline(polylineLocationsAsMapLocations, polylineOption);
                    map[mapReference].entities.push(polylineObject);
                }
            }
        }
    }
    setDirections(directions, mapReference) {
        if (map[mapReference] && directions) {
            var inputPanel = directions.inputPanel ? directions.inputPanel : null;
            var renderOptions = directions.renderOptions ? directions.renderOptions : null;
            var requestOptions = directions.requestOptions ? directions.requestOptions : null;
            var wayPoints = directions.wayPoints ? directions.wayPoints : [];
            var wayPointsAsDirection = [];

            Microsoft.Maps.loadModule('Microsoft.Maps.Directions', function () {
                var directionsManager = new Microsoft.Maps.Directions.DirectionsManager(map[mapReference]);
                directionsManager.clearAll();
                if (inputPanel) {
                    directionsManager.showInputPanel(inputPanel);
                }
                if (renderOptions) {
                    if (renderOptions.itineraryContainer) {
                        renderOptions.itineraryContainer = document.getElementById(renderOptions.itineraryContainer);
                    }
                    directionsManager.setRenderOptions(renderOptions);
                }
                if (requestOptions) {
                    let distanceUnit = requestOptions.distanceUnit ?
                        Microsoft.Maps.Directions.DistanceUnit[requestOptions.distanceUnit] :
                        null;
                    let routeMode = requestOptions.routeMode ?
                        Microsoft.Maps.Directions.RouteMode[requestOptions.routeMode] :
                        null;
                    let routeAvoidance = requestOptions.routeAvoidance ?
                        Microsoft.Maps.Directions.RouteAvoidance[requestOptions.routeAvoidance] :
                        null;
                    let routeOptimization = requestOptions.routeOptimization ?
                        Microsoft.Maps.Directions.RouteOptimization[requestOptions.routeOptimization] :
                        null;
                    let timeType = requestOptions.timeType ?
                        Microsoft.Maps.Directions.TimeTypes[requestOptions.timeType] :
                        null;

                    let vehicleSpec = requestOptions.vehicleSpec ?
                        requestOptions.vehicleSpec :
                        null;
                    let maxRoutes = requestOptions.maxRoutes ?
                        requestOptions.maxRoutes :
                        null;
                    let routeDraggable = requestOptions.routeDraggable ?
                        requestOptions.routeDraggable :
                        null;
                    let routeIndex = requestOptions.routeIndex ?
                        requestOptions.routeIndex :
                        null;
                    let time = requestOptions.time ?
                        requestOptions.time :
                        null;

                    directionsManager.setRequestOptions(Object.assign({},
                        distanceUnit && { distanceUnit },
                        maxRoutes && { maxRoutes },
                        routeMode && { routeMode },
                        routeAvoidance && { routeAvoidance },
                        routeOptimization && { routeOptimization },
                        timeType && { timeType },
                        vehicleSpec && { vehicleSpec },
                        routeDraggable && { routeDraggable },
                        routeIndex && { routeIndex },
                        time && { time }
                    ));
                }

                for (var wayPointsIndex = 0; wayPointsIndex < wayPoints.length; wayPointsIndex++) {
                    let address = wayPoints[wayPointsIndex].address ? wayPoints[wayPointsIndex].address : null
                    let location = wayPoints[wayPointsIndex].location
                        && wayPoints[wayPointsIndex].location[0]
                        && wayPoints[wayPointsIndex].location[1]
                        ? new Microsoft.Maps.Location(wayPoints[wayPointsIndex].location[0], wayPoints[wayPointsIndex].location[1])
                        : null;
                    let isViaPoint = wayPoints[wayPointsIndex].isViaPoint ? wayPoints[wayPointsIndex].isViaPoint : null;

                    wayPointsAsDirection.push(
                        new Microsoft.Maps.Directions.Waypoint({
                            address,
                            location,
                            isViaPoint
                        })
                    )
                }
                if (wayPointsAsDirection.length !== 0) {
                    for (var wayPointsAsDirectionIndex = 0; wayPointsAsDirectionIndex < wayPointsAsDirection.length; wayPointsAsDirectionIndex++) {
                        directionsManager.addWaypoint(wayPointsAsDirection[wayPointsAsDirectionIndex]);
                    }
                    directionsManager.calculateDirections();
                }
            });
        }
    }
    setMapHandlers(mapHandlers, mapReference) {
        if (map[mapReference] && mapHandlers) {
            for (var mapHandlerIndex = 0; mapHandlerIndex < mapHandlers.length; mapHandlerIndex++) {
                var mapHandler = mapHandlers[mapHandlerIndex];
                if (mapHandler.addHandler) {
                    Microsoft.Maps.Events.addHandler(map[mapReference], mapHandler.addHandler,
                        function (callback, e) {
                            var callbackData = {
                                event: e,
                                map: map[mapReference]
                            };
                            this.MakeCallback(callback, callbackData);
                        }.bind(this, mapHandler.callback)
                    );
                }
                else {
                    Microsoft.Maps.Events.addHandler(map[mapReference], "click",
                        function (callback, e) {
                            var callbackData = {
                                event: e,
                                map: map[mapReference]
                            };
                            this.MakeCallback(callback, callbackData);
                        }.bind(this, mapHandler.callback)
                    );
                }
            }
        }
    }
    render() {
        return (
            <div id={this.props.id} style={{ height: '100%' }} className={classSet('react-bingmaps', this.props.className)}>
            </div>
        );
    }
}

export default ReactBingmaps;

ReactBingmaps.propTypes = {
    center: PropTypes.arrayOf(PropTypes.number),
    mapTypeId: PropTypes.string,
    navigationBarMode: PropTypes.string,
    supportedMapTypes: PropTypes.arrayOf(PropTypes.string),
    heading: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    zoom: PropTypes.number,
    pushPins: PropTypes.arrayOf(
        PropTypes.shape({
            location: PropTypes.arrayOf(PropTypes.number),
            option: PropTypes.object,
            addHandler: PropTypes.shape({
                "type": PropTypes.string,
                "callback": PropTypes.func
            })
        })
    ),
    disableStreetside: PropTypes.bool,
    infoboxes: PropTypes.arrayOf(
        PropTypes.shape({
            location: PropTypes.arrayOf(PropTypes.number),
            option: PropTypes.object,
            addHandler: PropTypes.shape({
                "type": PropTypes.string,
                "callback": PropTypes.func
            })
        })
    ),
    infoboxesWithPushPins: PropTypes.arrayOf(
        PropTypes.shape({
            location: PropTypes.arrayOf(PropTypes.number),
            addHandler: PropTypes.string,
            infoboxOption: PropTypes.object,
            pushPinOption: PropTypes.object,
            infoboxAddHandler: PropTypes.shape({
                "type": PropTypes.string,
                "callback": PropTypes.func
            }),
            pushPinAddHandler: PropTypes.shape({
                "type": PropTypes.string,
                "callback": PropTypes.func
            })
        })
    ),
    getLocation: PropTypes.object,
    regularPolygons: PropTypes.arrayOf(
        PropTypes.shape({
            center: PropTypes.arrayOf(PropTypes.number),
            radius: PropTypes.number,
            points: PropTypes.number,
            option: PropTypes.object
        })
    ),
    boundary: PropTypes.shape({
        location: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.arrayOf(PropTypes.string)]),
        option: PropTypes.object,
        polygonStyle: PropTypes.object,
        search: PropTypes.string,
    }),
    mapOptions: PropTypes.object,
    polyline: PropTypes.arrayOf(PropTypes.shape({
        location: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
        option: PropTypes.object
    })),
    directions: PropTypes.object,
    mapHandlers: PropTypes.arrayOf(PropTypes.object)
}
ReactBingmaps.defaultProps = {
    center: undefined,
    mapTypeId: undefined,
    navigationBarMode: undefined,
    supportedMapTypes: undefined,
    heading: 0,
    pushPins: undefined,
    disableStreetside: true,
    infoboxes: undefined,
    infoboxesWithPushPins: undefined,
    zoom: undefined,
    getLocation: undefined,
    regularPolygons: undefined,
    boundary: undefined,
    mapOptions: undefined,
    polyline: undefined,
    directions: undefined,
    mapHandlers: undefined
}
import React, { useRef, useState } from 'react'
import { Avatar, Button, makeStyles, IconButton } from '@material-ui/core'
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import { full } from 'utils/resources'
import AvatarEditor from 'react-avatar-editor'

const mytheme = makeStyles(theme => ({
    avaterContainer: {
        textAlign: 'center',
        marginBottom: theme.spacing(2)
    },
    input: {
        display: "none"
    },
    avatar: {
        margin: '10px auto',
        width: theme.spacing(10),
        height: theme.spacing(10),
        fontSize: '32px',
    }
}))

export default function SelectAvatar({ src, name, endpoint, cb, colour }) {
    const inputEl = useRef(null);
    const classes = mytheme()

    const [editorClosed, setEditorClosed] = useState(true);
    const [sourceImage, setSourceImage] = useState(src);
    const [scale, setScale] = useState(1.2);
    var editor = null

    const onClickSave = () => {
        if (editor) {
            // If you want the image resized to the canvas size (also a HTMLCanvasElement)
            const canvasScaled = editor.getImageScaledToCanvas()
            canvasScaled.toBlob(async function (blob) {
                const formData = new FormData();
                formData.append('File', blob, inputEl.current.files[0].name)

                const options = full(endpoint, {})
                const headers = new Headers()

                headers.append('Authorization', options.options.headers.Authorization)

                const myOptions = {
                    method: 'POST',
                    headers: headers,
                    body: formData
                }
                await fetch(options.url, myOptions)
                inputEl.current.files = null
                cb()
            });
        }
        setEditorClosed(true);
    }

    const ZoomIn = () => {
        setScale(scale + .1)
    }
    const ZoomOut = () => {
        setScale(scale - .1)
    }


    const handleUploadClick = async () => {
        setSourceImage(inputEl.current.files[0])
        setEditorClosed(false);
    };

    const cancel = async () => {
        inputEl.current.files = null
        setEditorClosed(true);
    }

    const setEditorRef = (editorObject) => editor = editorObject

    return (
        <form className={classes.avaterContainer}>
            <div hidden={editorClosed}>
                <AvatarEditor
                    ref={setEditorRef}
                    image={sourceImage}
                    width={200}
                    height={200}
                    border={50}
                    scale={scale}
                    borderRadius={100}
                />
                <div>
                    <IconButton color="primary" aria-label="Zoom In" component="span" onClick={ZoomIn}>
                        <ZoomInIcon />
                    </IconButton>
                    <IconButton color="primary" aria-label="Zoom Out" component="span" onClick={ZoomOut}>
                        <ZoomOutIcon />
                    </IconButton>
                    <Button variant="contained" style={{ marginRight: '5px' }} onClick={cancel} type="button">Cancel</Button>
                    <Button variant="contained" color="primary" onClick={onClickSave} type="button">Save</Button>
                </div>
            </div>
            <div hidden={!editorClosed}>
                <Avatar className={classes.avatar} style={{ backgroundColor: colour || 'transparent' }} src={src} >
                    {name ? name[0] : ''}
                </Avatar>
                <input ref={inputEl} accept="image/*" className={classes.input} id="contained-button-file" type="file" onChange={handleUploadClick} />
                <label htmlFor="contained-button-file">
                    <Button onClick={() => inputEl.current.click()} variant="outlined" type="button">Upload Avatar</Button>
                </label>
            </div>

        </form>
    )
}
import React, { useState } from 'react';
import { Container, TextField, Button, makeStyles, Grid, Typography, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, Input } from '@material-ui/core';
import { post, put } from 'utils/resources'

const mytheme = makeStyles(theme => ({
    container: {
        padding: theme.spacing(2),
        width: 400,
        [theme.breakpoints.down("sm")]: {
            width: '100vw',
        }
    },
    formControl: {
        width: '100%',
        marginBottom: theme.spacing(1)
    },
    textField: {
        marginBottom: theme.spacing(1)
    },
    button: {
        margin: theme.spacing(1)
    }
}))

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function AddGroup({ close, existingGroup, devices }) {
    const [group, setGroup] = useState({ name: existingGroup ? existingGroup.name : '', registeredDeviceIDs: existingGroup ? existingGroup.registeredDevices.map(d => d.registeredDeviceId) : [] })
    const [message, setMessage] = useState('')

    const save = async function () {
        setMessage('')
        let newGroup
        if (existingGroup) {
            newGroup = await put('devicegroup/{accountid}/' + existingGroup.id, group)
        } else {
            newGroup = await post('devicegroup', group)
        }
        if (newGroup && newGroup.id) close()
        else if (newGroup && newGroup.message) {
            setMessage(newGroup.message)
        } else {
            setMessage('Something went wrong')
        }
    }

    const setVal = function (ev) {
        setGroup({ ...group, [ev.target.name]: ev.target.value })
    }

    const setDevices = function (ev) {
        setGroup({ ...group, registeredDeviceIDs: ev.target.value })
    }

    const classes = mytheme()

    return <Container className={classes.container}>
        <Typography variant="h6" align="center">{existingGroup ? 'Edit' : 'Add'} Group</Typography>
        <form onSubmit={save}>
            <TextField className={classes.textField} id="name" fullWidth type="text" label="Name" name="name" onChange={setVal} value={group.name} />

            <FormControl className={classes.formControl}>
                <InputLabel id="demo-mutiple-checkbox-label">Devices</InputLabel>
                <Select
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    multiple
                    value={group.registeredDeviceIDs}
                    onChange={setDevices}
                    input={<Input />}
                    renderValue={(selected) => devices.filter(d => selected.indexOf(d.id) > -1).map(s => s.name).join(', ')}
                    MenuProps={MenuProps}
                >
                    {devices.map((device) => (
                        <MenuItem key={device.id} value={device.id}>
                            <Checkbox checked={group.registeredDeviceIDs.indexOf(device.id) > -1} />
                            <ListItemText primary={device.name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {!!message && <Typography color="error">{message}</Typography>}
            <Grid container alignItems="center" justify="center">
                <Button className={classes.button} variant="contained" type="button" onClick={close}>Cancel</Button>
                <Button className={classes.button} variant="contained" color="primary" onClick={save}>Save</Button>
            </Grid>
        </form>
    </Container>
}
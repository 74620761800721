import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd';
import { Typography } from '@material-ui/core'
import { Close, Settings, DragHandle } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { ItemTypes } from './ItemTypes'

const myStyle = makeStyles(theme => ({
    pin: {
        height: 43,
        display: 'flex',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    highlight: {
        fontWeight: 'bold',
        color: theme.palette.error.main
    },
    icon: {
        cursor: 'pointer',
        transition: 'color 0.3s ease',
        '&:hover': {
            color: theme.palette.primary.main
        }
    },
    remove: {
        cursor: 'pointer',
        transition: 'color 0.3s ease',
        '&:hover': {
            color: theme.palette.error.main
        }
    }
}))

export default function PinListItem({ pin, id, index, runningTotal, deleting = false, highlight = () => { }, clickMenu = () => { }, remove = () => { }, moveItem = () => { } }) {
    const classes = myStyle()
    const ref = useRef()
    const [{ handlerId }, drop] = useDrop({
        accept: ItemTypes.PIN,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) {
                return;
            }
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            moveItem(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });
    const [{ isDragging }, drag] = useDrag({
        item: {
            id, index, type: ItemTypes.PIN
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));

    return (
        <div ref={ref} className={classes.pin} style={{ opacity }} onMouseEnter={() => highlight(index)} onMouseLeave={() => highlight(-1)} type={ItemTypes.PIN} data-handler-id={handlerId}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginRight: 10, cursor: 'grab' }}>
                <DragHandle className={deleting ? classes.highlight : ''} />
            </div>
            <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                {!!pin.comment && <Typography className={deleting ? classes.highlight : ''}>{pin.comment}</Typography>}
                {!!pin.distance && <Typography className={deleting ? classes.highlight : ''} variant={pin.comment ? 'caption' : 'body1'}>{pin.distance.toFixed(2)}km {index ? `(${runningTotal.toFixed(2)}km)` : ''}</Typography>}
                {!pin.distance && <Typography className={deleting ? classes.highlight : ''} variant={pin.comment ? 'caption' : 'body1'}>0.00km</Typography>}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginRight: 10 }}>
                <Settings className={classes.icon} onClick={e => clickMenu(e, index)} />
                <Close className={classes.remove} onClick={() => remove(index)} />
            </div>
        </div>
    )
}
import React, { useEffect, useState } from 'react';
import { get } from 'utils/resources'
import Users from './users/users';
import Roles from './roles/roles';
import PermissionsChecker from 'Components/PermissionsChecker';
import { Paper, Tabs, Tab, makeStyles } from '@material-ui/core';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(3)
    }
}))


export default function Security({ history }) {
    const classes = useStyles()
    const [users, setUsers] = useState([])
    const [roles, setRoles] = useState([])
    const [invites, setInvites] = useState([])
    const location = useLocation()
    const auth = useSelector(state => state.auth)

    useEffect(() => {
        getUsers()
        getRoles()
        getInvites()
        get('accountuserrole/{accountid}/roles/endpoints')
    }, [auth])

    const getUsers = async () => {
        setUsers(await get('AccountUserRole'))
    }

    const getRoles = async () => {
        setRoles(await get('AccountUserRole/{accountid}/roles'))
    }

    const getInvites = async () => {
        setInvites(await get('userinvite/{accountid}'))
    }

    const route = location.pathname.split('/')[2] || "users"

    return (
        <PermissionsChecker required="accountAdmin">
            <Paper className={classes.paper}>
                <Tabs centered value={route} onChange={(e, v) => history.push('/security/' + v)} >
                    <Tab label="Users" value={'users'} />
                    <Tab label="Roles" value={'roles'} />
                </Tabs>
                {route === 'users' && <Users history={history} users={users} roles={roles} invites={invites} getUsers={getUsers} getInvites={getInvites} />}
                {route === 'roles' && <Roles history={history} roles={roles} users={users} getRoles={getRoles} update={role => this.addRole(role)} />}
            </Paper>
        </PermissionsChecker>
    )
}
import React, { Fragment } from 'react'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { setPath } from 'store/actions'
import { Grid, TextField, InputAdornment, FormControlLabel, Switch, Table, TableHead, TableRow, TableCell, TableBody, Paper, Link, Drawer, Button } from '@material-ui/core'
import { SearchRounded } from '@material-ui/icons'

export default function AdminDevicesAll() {
    const dispatch = useDispatch()
    const history = useHistory()
    const [devices, setDevices] = useState([])
    const [searchText, setSearchText] = useState('')
    const [filters, setFilters] = useState({
        onlineOnly: false,
    })
    const [showFilters, setShowFilters] = useState(false)

    const search = () => {
        setShowFilters(false)
    }

    useEffect(() => {
        dispatch(setPath('Admin | Devices'))
    }, [])

    let filtered = [...devices]
    if (searchText) {
        filtered = filtered.filter(a => a.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
    }
    if (filters.onlineOnly) {
        filtered = filtered.filter(a => moment(a.lastOnline).isSame(moment(), 'day'))
    }

    return (
        <Fragment>
            <Paper style={{ padding: 20 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <TextField value={searchText} onChange={e => setSearchText(e.target.value)} fullWidth label="Search Devices" InputProps={{
                            endAdornment: (<InputAdornment position="end">
                                <SearchRounded />
                            </InputAdornment>)
                        }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Grid container direction="row" justify="flex-end" alignItems="center" style={{ height: '100%' }}>
                            <Button variant="contained" color="primary" size="medium" onClick={() => setShowFilters(true)}>Filters</Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Account</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Last Online</TableCell>
                                    <TableCell>Version</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!filtered.length && <TableRow>
                                    <TableCell colSpan="99" align="center">
                                        <div style={{ marginBottom: '20px' }}>No Devices Found</div>
                                    </TableCell>
                                </TableRow>}
                                {Boolean(filtered.length) && filtered.map(row => {
                                    return <TableRow key={row.id}>
                                        <TableCell><Link onClick={() => history.push('/admin/devices/' + row.id)}>{row.account.name}</Link></TableCell>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>{row.devicelocation.createdAt}</TableCell>
                                        <TableCell>{row.version}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid >
            </Paper>

            <Drawer open={showFilters} anchor="right">
                <div style={{ width: 400, padding: 20 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Switch checked={filters.onlineOnly} onChange={() => setFilters(f => ({ ...f, onlineOnly: !f.onlineOnly }))} name="onlyOnline" color="primary" />
                                }
                                label="Online Today Only"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" color="primary" onClick={search} fullWidth>Search</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" fullWidth onClick={() => setShowFilters(false)}>Cancel</Button>
                        </Grid>
                    </Grid>
                </div>
            </Drawer>
        </Fragment>
    )
}
import * as base from './http'
import constants from "./constants";
import { store } from '../store/store'

const nonAccountRoutes = ['account', 'user', 'userinvite', 'notification', 'usersettings']

function parseUrl(url, skipAccount) {
    if (url.indexOf('http') !== 0) {
        const state = store.getState()
        const account = state.account
        let addAccountId = nonAccountRoutes.indexOf(url.split('/')[0].toLowerCase()) === -1 && !skipAccount
        url = constants.RESOURCES + url
        if (addAccountId || url.indexOf('{accountid}') > -1) {
            if (url.indexOf('{accountid}') > -1) {
                url = url.replace('{accountid}', account.id)
            } else {
                url += '/' + account.id
            }
        }
    }
    return url
}

export function get(url, skipAccount, skipAuth, signal) {
    return base.get(parseUrl(url, skipAccount), skipAuth, signal)
}

export function put(url, body, signal) {
    return base.put(parseUrl(url), body, signal)
}

export function post(url, body, skipAccount, signal) {
    return base.post(parseUrl(url, skipAccount), body, signal)
}

export function patch(url, body, signal) {
    return base.patch(parseUrl(url), body, signal)
}

export function destroy(url, body, skipAccount, signal) {
    return base.destroy(parseUrl(url, skipAccount), body, signal)
}

export function http(url, options, signal) {
    return base.http(parseUrl(url), options, signal)
}

export function full(url, options, skipAuth, skipAccount, signal) {
    return { url: parseUrl(url, skipAccount), options: base.options(options, skipAuth) }
}
import constants from "./constants";
import { store } from '../store/store'
import { setError } from '../store/actions'
import authUtils from './auth'

function updateOptions(options, skipAuth, signal) {
    const state = store.getState()
    const update = { ...options };
    if (signal && !update.signal) {
        update.signal = signal
    }
    const auth = authUtils.auth()
    if (!update.headers) update.headers = {}
    if (!update.headers['Content-Type']) update.headers['Content-Type'] = 'application/json'
    update.headers.credentials = 'same-origin'

    if (auth && auth.jwtToken) {
        update.headers.Authorization = 'Bearer ' + auth.jwtToken
    }
    if (state.account && state.account.id) {
        update.headers['x-account-id'] = state.account.id
    }
    update.credentials = 'include'
    if (update.body && typeof update.body === 'object') {
        update.body = JSON.stringify(update.body)
    }

    if (skipAuth) {
        delete update.headers.Authorization
    }

    return update;
}

function responseParser(response, url, options) {
    let auth
    if (response.status === 401) {
        // token expired so refresh
        if (url.indexOf('refresh-token') === -1) {
            auth = localStorage.getItem('auth')
            if (auth && typeof auth === 'string') {
                try {
                    auth = JSON.parse(auth)
                } catch (e) {

                }
            }
            console.log('token expired', auth)
            if (auth && auth.id) {
                return post(constants.AUTH + 'auth/refresh-token/' + auth.id, undefined, true).then(r => responseParser(r, url, options)).then(data => {
                    if (data === 'token expired') {
                        localStorage.removeItem('auth')
                        window.location = '/login'
                        throw data
                    }
                    localStorage.setItem('auth', JSON.stringify(data))
                    return http(url, options)
                })
            } else {
                localStorage.removeItem('auth')
                window.location = '/login'
            }
        } else {
            localStorage.removeItem('auth')
            window.location = '/login'
        }
    }

    if (response.status !== 404 && response.status >= 400 && response.status < 500 && response.status !== 401) {
        return response.json().then(data => {
            if (data.message === 'Invalid token') {
                return authUtils.refresh(auth).then(() => {
                    if (data === 'token expired') {
                        localStorage.removeItem('auth')
                        window.location = '/login'
                        throw data
                    } else {
                        return http(url, options)
                    }
                })
            } else {
                store.dispatch(setError(data.message))
                throw new Error(response.statusText)
            }
        }).catch(() => {
            throw new Error(response.statusText)
        })
    }
    if (!response.ok) {
        throw new Error(response.statusText)
    }
    if (response.headers && response.headers.get('content-type') && response.headers.get('content-type').indexOf('json') > -1) {
        return response.json()
    } else if (typeof response.text === 'function') {
        return response.text()
    } else return response
}

function parseUrl(url) {
    if (url.indexOf('http') !== 0) {
        url = constants.AUTH + url
    }
    return url
}

function parseError(err) {
    console.log(err)
    // if (err.message === 'Failed to fetch') window.location = '/notfound'
}

export function http(url, options, skipAuth, signal) {
    return fetch(parseUrl(url), updateOptions(options, skipAuth, signal)).then(r => responseParser(r, url, options)).catch(parseError)
};

export function get(url, skipAuth, signal) {
    let options = updateOptions({
        method: 'GET',
        cors: 'no-cors',
        signal
    })
    return fetch(parseUrl(url), updateOptions(options, skipAuth)).then(r => responseParser(r, url, options)).catch(parseError)
}

export function put(url, body, signal) {
    let options = updateOptions({
        method: 'PUT',
        body,
        signal
    })
    return fetch(parseUrl(url), updateOptions(options)).then(r => responseParser(r, url, options)).catch(parseError)
}

export function post(url, body, skipAuth, signal) {
    let options = updateOptions({
        method: 'POST',
        body,
        signal
    })
    return fetch(parseUrl(url), updateOptions(options, skipAuth)).then(r => responseParser(r, url, options)).catch(parseError)
}

export function patch(url, body, signal) {
    let options = updateOptions({
        method: 'PATCH',
        body,
        signal
    })
    return fetch(parseUrl(url), updateOptions(options)).then(r => responseParser(r, url, options)).catch(parseError)
}

export function destroy(url, body, signal) {
    let options = updateOptions({
        method: 'DELETE',
        body,
        signal
    })
    return fetch(parseUrl(url), updateOptions(options)).then(r => responseParser(r, url, options)).catch(parseError)
}

export function options(options, skipAuth) {
    return updateOptions(options, skipAuth)
}
import React from 'react'
import { Card, CardHeader, IconButton, CardActions, CardMedia, makeStyles } from '@material-ui/core';
import { Delete, Edit, PanoramaOutlined, Visibility } from '@material-ui/icons'
import { formatDate } from 'utils/format';
import { useHistory } from 'react-router';

const myStyle = makeStyles(theme => ({
    media: {
        height: 100
    },
    placeholder: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    delete: {
        marginLeft: 'auto'
    }
}))

export default function EventCard({ event, remove }) {
    const history = useHistory()
    const classes = myStyle()
    return <Card elevation={3}>
        <CardHeader
            title={event.name}
            subheader={formatDate(event.startDate)}
        />
        {!!event.headerUrl && <CardMedia
            image={event.headerUrl}
            title={event.name}
            className={classes.media}
        />}
        {!event.headerUrl && <div className={classes.placeholder}><PanoramaOutlined style={{ height: 100, width: 200 }} /></div>}
        <CardActions disableSpacing>
            <IconButton onClick={() => history.push('/events/' + event.id + '/view')}>
                <Visibility />
            </IconButton>
            <IconButton onClick={() => history.push('/events/' + event.id + '/edit')}>
                <Edit />
            </IconButton>
            <IconButton className={classes.delete} onClick={() => remove(event)}>
                <Delete />
            </IconButton>
        </CardActions>
    </Card>
}
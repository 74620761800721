import { Grid, InputAdornment, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Fab, Card, CardContent, CardActions, Typography, Button } from '@material-ui/core'
import { SearchRounded, Add } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import Alert from 'Components/Alert'
import Confirm from 'Components/Confirm'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { getDistanceFromLatLonInKm, humanizeSeconds } from 'utils/format'
import { destroy, get } from 'utils/resources'

import RouteRow from './routeRow'

const myStyle = makeStyles(theme => ({
    container: {
        [theme.breakpoints.down("sm")]: {
            display: 'block',
            position: 'fixed',
            height: 'calc(100% - 68px)',
            width: '100%',
            overflowX: 'hidden',
            overflowY: 'auto'
        }
    },
    table: {
        [theme.breakpoints.down("sm")]: {
            display: 'none'
        }
    },
    mobile: {
        display: 'none',
        [theme.breakpoints.down("sm")]: {
            display: 'block'
        }
    },
    center: {
        justifyContent: 'center'
    }
}))

export default function RoutePlannerTemplate() {
    const [routes, setRoutes] = useState([])
    const [searchText, setSearchText] = useState('')
    const [removing, setRemoving] = useState(null)
    const [alert, setAlert] = useState('')
    const history = useHistory()
    const account = useSelector(state => state.account)
    const classes = myStyle()

    useEffect(() => {
        getRoutes()
    }, [])

    useEffect(() => {
        if (routes.some(r => !r.id)) return
        const str = localStorage.getItem('meili-route-' + account.id + '-1')
        if (str) {
            try {
                const cachedRoute = JSON.parse(str)
                setRoutes([...routes, cachedRoute])
            } catch (e) { }
        }
        // eslint-disable-next-line
    }, [routes])

    const getRoutes = async () => {
        setRoutes(await get('route/{accountid}'))
    }

    const remove = async () => {
        if (removing.id) {
            await destroy('route/{accountid}/' + removing.id)
        } else {
            localStorage.removeItem('meili-route-' + account.id + '-1')
        }
        setAlert('Route Deleted')
        setRemoving(null)
        getRoutes()
    }

    const filteredRoutes = routes.filter(r => r.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1).slice().sort((a, b) => a.name.localeCompare(b.name))

    return (
        <Card className={classes.container}>
            <CardContent>
                <Grid container>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <TextField value={searchText} onChange={e => setSearchText(e.target.value)} fullWidth label="Search Routes" InputProps={{
                            endAdornment: (<InputAdornment position="end">
                                <SearchRounded />
                            </InputAdornment>)
                        }} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                        <Grid container direction="row" justify="flex-end" alignItems="flex-end" style={{ height: '100%' }}>
                            <Tooltip title="Add" aria-label="add" >
                                <Fab color="primary" size="small" onClick={() => history.push('/routeplanner/new')}>
                                    <Add />
                                </Fab>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Distance</TableCell>
                                    <TableCell>Duration</TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredRoutes.map(route => <RouteRow key={route.id} route={route} remove={() => setRemoving(route)} />)}
                                {!filteredRoutes.length && <TableRow>
                                    <TableCell colSpan="99" align="center">No Routes Found</TableCell>
                                </TableRow>}
                            </TableBody>
                        </Table>
                        <div className={classes.mobile}>
                            <Grid container spacing={2}>
                                {!filteredRoutes.length && <Grid item xs={12}>
                                    <Typography variant="h5" align="center">No Routes Found</Typography>
                                </Grid>}
                                {filteredRoutes.map(route => {
                                    let distance = route.distance ? route.distance / 1000 : 0
                                    if (!route.distance) {
                                        let legs = (route.points || []).filter(p => p.hidden)
                                        if (!legs.length) legs = (route.points || [])
                                        legs.forEach((pin, index) => {
                                            if (!index) return 0
                                            const previous = legs[index - 1]
                                            distance += getDistanceFromLatLonInKm(pin.latitude, pin.longitude, previous.latitude, previous.longitude)
                                        })
                                    }
                                    return <Grid item xs={12} key={route.id}>
                                        <Card>
                                            <CardContent>
                                                <Typography variant="h5" align="center">{route.name}</Typography>
                                                <Typography variant="h6" align="center">{distance.toFixed(2)}km {route.duration && route.autoRoute ? ' - ' + humanizeSeconds(route.duration) : ''}</Typography>
                                            </CardContent>
                                            <CardActions className={classes.center}>
                                                <Button variant="contained" color="primary" onClick={() => history.push('/routeplanner/' + route.id)}>View</Button>
                                                <Button variant="contained" onClick={() => setRemoving(route)}>Remove</Button>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                })}
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </CardContent>
            <Confirm header="Delete Route?" subheader={'Are you sure you want to delete ' + (removing ? removing.name : '')} open={Boolean(removing)} onCancel={() => setRemoving(null)} onConfirm={remove} />
            <Alert alert={alert} setAlert={setAlert} />
        </Card>
    )
}
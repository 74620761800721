import { TableCell, TableRow, Button, Menu, MenuItem, Avatar, } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react'

const useStyles = makeStyles(theme => ({
    name: {
        display: 'flex',
        alignItems: 'center'
    },
    avatar: {
        marginRight: theme.spacing(1)
    }
}))

export default function UserRow({ user, edit }) {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState(null);

    const buttonClick = (e) => {
        setAnchorEl(e.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <TableRow>
            <TableCell>
                {!!user.user.firstName && <div className={classes.name}>
                    <Avatar className={classes.avatar} src={user.user.avatar} alt={user.user.firstName + ' ' + user.user.surname}>{user.user.firstName[0]}</Avatar>
                    <div>{user.user.firstName} {user.user.surname}</div>
                </div>}
            </TableCell>
            <TableCell>{user.role.name}</TableCell>
            <TableCell>{user.receiveSOSSMS ? 'Enabled' : 'Disabled'}</TableCell>
            <TableCell align="right">
                <Button onClick={buttonClick}>Options</Button>
                <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                    <MenuItem onClick={edit}>Edit</MenuItem>
                    <MenuItem>Remove</MenuItem>
                </Menu>
            </TableCell>
        </TableRow>
    )
}
import React from 'react'
import { useEffect } from 'react';
import { useParams } from "react-router";
import { useDispatch } from 'react-redux'
import { setPath } from 'store/actions';

export default function SupportTicket() {
    const { id } = useParams()
    const dispatch = useDispatch()
    const [account, setAccount] = useEffect(null)
    const [ticket, setTicket] = useEffect(null)

    useEffect(() => {
        dispatch(setPath('Admin | Support | Ticket 1'))
    }, [])
    return (
        <div></div>
    )
}
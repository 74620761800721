import React, { useEffect, useState, Fragment } from "react"
import { FormControlLabel, Switch, TextField, Typography, Accordion, AccordionSummary, AccordionDetails, List, ListItem, Checkbox, ListItemText, ListItemAvatar, ListItemSecondaryAction, Avatar, Button } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { get, post, patch } from 'utils/resources'
import LoadingIcon from 'Components/LoadingIcon'
import { destroy } from "utils/http"

async function setGeofenceDevices(id, arr) {
    return await patch('geofence/{accountid}/' + id + '/device', {
        registeredDeviceIds: arr
    })
}

export default function RouteGeofnce({ route, onClose = () => { } }) {
    const [geofence, setGeofence] = useState(-1)
    const [devices, setDevices] = useState(null)
    const [groups, setGroups] = useState(null)

    const toggleGeofence = async () => {
        if (geofence && geofence.id) {
            await destroy('geofence/{accountid}/' + geofence.id)
            setGeofence(-1)
        } else {
            setGeofence(await post('geofence', {
                name: route.name + ' Geofence',
                active: true,
                routeId: route.id,
                geofenceType: 0,
                radius: 10
            }))
        }
    }

    const toggleDevice = async (id) => {
        const arr = [...geofence.registeredDevices.map(d => d.registeredDeviceId)]
        const index = arr.indexOf(id)
        if (index === -1) {
            arr.push(id)
        } else {
            arr.splice(index, 1)
        }
        const newGeofence = await setGeofenceDevices(geofence.id, arr)
        setGeofence({ ...geofence, registeredDevices: newGeofence.registeredDevices })
    }

    const onSelectGroup = async group => {
        const groupIds = group.registeredDevices.map(d => d.registeredDeviceId)
        const arr = [...geofence.registeredDevices.map(d => d.registeredDeviceId)]
        for (const id of groupIds) {
            const index = arr.indexOf(id)
            if (index === -1) {
                arr.push(id)
            }
        }
        const newGeofence = await setGeofenceDevices(geofence.id, arr)
        setGeofence({ ...geofence, registeredDevices: newGeofence.registeredDevices })
    }

    useEffect(() => {
        get('geofence').then(geofences => {
            setGeofence(geofences.find(g => g.routeId === route.id))
        })
        get('device').then(setDevices)
        get('devicegroup').then(setGroups)
    }, [route])

    if (geofence === -1 || !devices || !groups) {
        return <div style={{ width: 300, padding: 10 }}>
            <LoadingIcon />
        </div>
    }

    const ids = geofence && geofence.registeredDevices ? geofence.registeredDevices.map(d => d.registeredDeviceId) : []

    return (
        <div style={{ width: 300 }}>
            <div style={{ padding: 20 }}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={geofence !== -1 && geofence}
                            onChange={toggleGeofence}
                            color="primary"
                        />
                    }
                    label="Enable Geofence"
                />
            </div>

            {geofence && geofence.id && <div>
                <div style={{ padding: 20 }}>
                    <TextField
                        fullWidth
                        label="Radius (meters)"
                        type="number"
                        value={geofence.radius}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>

                <Accordion defaultExpanded style={{ margin: 0 }}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography variant="h6">Devices</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: 'block', maxHeight: '33%', overflow: 'auto' }}>
                        <List component="nav">
                            {devices.map(device => {
                                return <ListItem key={device.id} dense button onClick={() => toggleDevice(device.id)}>
                                    <ListItemAvatar>
                                        <Fragment>
                                            <Avatar style={{ backgroundColor: device.registeredDeviceOptions.colour }} alt={device.name} src={device.registeredDeviceOptions.deviceAvatar}>{device.name[0]}</Avatar>
                                        </Fragment>
                                    </ListItemAvatar>
                                    <ListItemText primary={device.name} id={device.id} />
                                    <ListItemSecondaryAction>
                                        <Checkbox edge="start" color="primary" checked={ids.indexOf(device.id) > -1} onClick={() => toggleDevice(device.id)} tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': device.id }} />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            })}
                        </List>
                    </AccordionDetails>
                </Accordion>

                {Boolean(groups.length) && <Accordion style={{ margin: 0 }}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography variant="h6">Groups</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: 'block', maxHeight: '33%', overflow: 'auto' }}>
                        {!!groups && !!groups.length && <List component="nav">
                            {groups.map(group => <ListItem key={group.id} button onClick={() => onSelectGroup(group)}>
                                <ListItemText primary={group.name} id={group.id} />
                                <ListItemSecondaryAction>({group.registeredDevices.length})</ListItemSecondaryAction>
                            </ListItem>)}
                        </List>}
                    </AccordionDetails>
                </Accordion>}

            </div>}

            <div style={{ padding: 20 }}>
                <Button variant="contained" color="primary" fullWidth onClick={onClose}>Close</Button>
            </div>
        </div>
    )
}
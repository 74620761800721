import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { setAccount } from 'store/actions'
import { get } from 'utils/resources'
import { Paper, Table, TableHead, TableRow, TableCell, TableBody, Button, Avatar, Typography, Grid, Card, CardHeader, CardActionArea } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router';
import ActivateAccountWarning from 'Components/ActivateAccount';
import ActivateBilling from 'Components/ActivateBilling'
import LoadingIcon from 'Components/LoadingIcon';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(3),
        [theme.breakpoints.down("sm")]: {
            height: 'calc(100% - 68px)',
            width: '100%',
            overflowY: 'auto',
            position: 'fixed'
        }
    },
    name: {
        display: 'flex',
        alignItems: 'center'
    },
    avatar: {
        marginRight: theme.spacing(3),
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    button: {
        marginLeft: theme.spacing(3)
    },
    table: {
        [theme.breakpoints.down("sm")]: {
            display: 'none'
        }
    },
    mobile: {
        display: 'none',
        [theme.breakpoints.down("sm")]: {
            display: 'block'
        }
    }
}))


export default function Notifications() {
    const classes = useStyles()
    const notifications = useSelector(state => state.notifications)
    const auth = useSelector(state => state.auth)
    const billing = useSelector(state => state.billing)
    const dispatch = useDispatch()
    const history = useHistory()

    const [accounts, setAccounts] = useState([])
    const [devices, setDevices] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (auth.isVerified && billing.active) {
            get('account').then(data => setAccounts(data))
        }
    }, [auth, billing, notifications])

    useEffect(() => {
        (async () => {
            let devices = []
            for (const account of accounts) {
                if (account.active) {
                    const data = await get('device/' + account.id, true)
                    if (Array.isArray(data)) {
                        devices.push(...data)
                    }
                }
            }
            setDevices(devices)
        })()
    }, [accounts])

    useEffect(() => {
        if (devices && devices.length) {
            setLoading(false)
        }
    }, [devices])

    const viewDevice = function (account, device) {
        dispatch(setAccount(account))
        history.push('/devices/' + device.id + '?page=notifications')
    }

    if (!auth.isVerified) return <ActivateAccountWarning />

    if (!billing.active) {
        return <ActivateBilling goto={() => history.push('/billing')} />
    }

    if (loading) return <LoadingIcon />

    const grouped = accounts.map(account => {
        const rows = notifications.filter(n => n.accountId === account.id).slice().reverse()
        const _devices = devices.filter(d => d.accountId === account.id && rows.some(n => n.imei === d.device.imei))
        return {
            account,
            devices: _devices.map(d => ({
                device: d,
                rows: rows.filter(r => r.imei === d.device.imei)
            }))
        }
    })

    return (
        <Paper className={classes.paper}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>Organisation</TableCell>
                        <TableCell>Device</TableCell>
                        <TableCell align="center">Notifications</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {grouped.filter(g => g.devices.length).map(group => {
                        return <Fragment key={group.account.id}>
                            <TableRow>
                                <TableCell>
                                    <div className={classes.name}>
                                        <Avatar size="" className={classes.avatar} alt={group.account.name} src={group.account.avatar}>{group.account.name[0]}</Avatar>
                                        {group.account.name}
                                    </div>
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell align="center">{group.devices.reduce((a, b) => a + b.rows.length, 0)}</TableCell>
                            </TableRow>
                            {group.devices.map(device => <TableRow key={device.device.accountId + '_' + device.device.id}>
                                <TableCell></TableCell>
                                <TableCell>
                                    <div className={classes.name}>
                                        <Avatar className={classes.avatar} alt={device.device.name} src={device.device.registeredDeviceOptions.deviceAvatar}>{device.device.name[0]}</Avatar>
                                        {device.device.name}
                                        <Button className={classes.button} size="small" variant="contained" onClick={() => viewDevice(group.account, device.device)}>View</Button>
                                    </div>
                                </TableCell>
                                <TableCell align="center">{device.rows.length}</TableCell>
                            </TableRow>)}
                        </Fragment>
                    })}
                    {!grouped.filter(g => g.devices.length).length && <TableRow>
                        <TableCell colSpan="99" align="center">No Notifications</TableCell>
                    </TableRow>}
                </TableBody>
            </Table >
            <div className={classes.mobile}>
                {grouped.filter(g => g.devices.length).map(group => {
                    return <div key={group.account.id}>
                        <Grid container alignContent="center" justify="center">
                            <Avatar size="" className={classes.avatar} alt={group.account.name} src={group.account.avatar}>{group.account.name[0]}</Avatar>
                            <Typography variant="h6" align="center" style={{ marginBottom: '10px' }}> {group.account.name}</Typography>
                        </Grid>
                        <Grid container spacing={2}>
                            {group.devices.map(device => {
                                return <Grid item xs={12} key={device.device.accountId + '_' + device.device.id}>
                                    <Card variant="outlined">
                                        <CardActionArea onClick={() => viewDevice(group.account, device.device)}>
                                            <CardHeader
                                                avatar={<Avatar className={classes.avatar} alt={device.device.name} src={device.device.registeredDeviceOptions.deviceAvatar}>{device.device.name[0]}</Avatar>}
                                                title={device.device.name}
                                                subheader={device.rows.length + ' Notification(s) click to view'}
                                            />
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            })}
                        </Grid>
                    </div>
                })}
                {!grouped.filter(g => g.devices.length).length && <Typography variant="h5" align="center">No Notifications</Typography>}
            </div>
        </Paper >
    )
}
import { Card, CardContent, Grid, Typography } from '@material-ui/core'
import { ErrorOutline } from '@material-ui/icons'
import React from 'react'

export default function NotFound() {
    return (
        <Grid container justify="center" alignItems="center" style={{ height: '100%' }}>
            <Card style={{ padding: 20 }}>
                <CardContent >
                    <Typography align="center">
                        <ErrorOutline style={{ fontSize: 60 }} color="error" />
                    </Typography>
                    <Typography align="center" variant="h3">Page Not Found</Typography>
                </CardContent>
            </Card>
        </Grid>
    )
}
import { Container, FormControlLabel, FormGroup, makeStyles, Paper, TextField, Switch, Button, Snackbar, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get, put } from 'utils/resources';
import { toggleTheme, setUser as setGlobalUser } from 'store/actions'
import LoadingIcon from 'Components/LoadingIcon';
import SelectAvatar from 'Components/SelectAvatar'
import { Alert as MuiAlert } from '@material-ui/lab'
import MuiPhoneNumber from 'material-ui-phone-number'
import { post } from 'utils/http';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const mytheme = makeStyles(theme => ({
    outer: {
        [theme.breakpoints.down("sm")]: {
            height: 'calc(100% - 40px)'
        }
    },
    container: {
        padding: theme.spacing(2),
        textAlign: 'center',
        height: '100%',
        overflowY: 'auto',
        [theme.breakpoints.down("sm")]: {
            height: 'calc(100% - 68px)',
            position: 'fixed',
            overflowY: 'auto'
        }
    },
    textField: {
        marginBottom: theme.spacing(1),
        textAlign: 'left'
    }
}))

export default function Profile({ reload }) {
    const [user, setUser] = useState({})
    const [settings, setSettings] = useState({})
    const [alert, setAlert] = useState(false)
    const [error, setError] = useState('')
    const [passwords, setPasswords] = useState({ currentPassword: '', newPassword: '', confirmPassword: '' })

    const dispatch = useDispatch()
    const theme = useSelector(state => state.theme)

    useEffect(() => {
        getUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getUser = async () => {
        setUser(await get('user'))
        setSettings(await get('usersettings'))
        reload()
    }

    const setUserVal = function (e) {
        setUser({ ...user, [e.target.name]: e.target.value })
    }

    const setPhoneNumber = function (v) {
        setSettings({ ...settings, smsContactNumber: v })
    }

    const changeTheme = function () {
        setSettings({ ...settings, theme: theme === 'dark' ? 'light' : 'dark' })
        dispatch(toggleTheme())
    }

    const save = async function () {
        await put('user/' + user.id, {
            title: user.title,
            firstName: user.firstName,
            surname: user.surname
        })
        await put('usersettings', {
            smsContactNumber: (settings.smsContactNumber || '').split(' ').join(''),
            defaultMapType: settings.defaultMapType,
            theme: settings.theme
        })

        dispatch(setGlobalUser(await get('user')))
        setAlert(true)
    }

    const updatePassField = e => {
        setPasswords({ ...passwords, [e.target.name]: e.target.value })
    }

    const changePassword = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        let response = await post('auth/authorized-reset-password', passwords)
        if (response && response.message === 'Password reset successful, you can now login') {
            setAlert(true)
        } else {
            let err = response.message || 'Change Failed'
            if (response.errors) {
                if (response.errors.NewPassword && response.errors.NewPassword.length) {
                    err = response.errors.NewPassword[0]
                } else if (response.errors.ConfirmPassword && response.errors.ConfirmPassword.length) {
                    err = response.errors.ConfirmPassword[0]
                }
            }
            setError(err)
        }
    }

    const classes = mytheme()

    if (!user.id) return <LoadingIcon />

    return (
        <Container maxWidth="sm" disableGutters={true} className={classes.outer}>
            <Paper className={classes.container}>
                <SelectAvatar src={user.avatar} name={user.firstName || ' '} endpoint={'user/profile/avatar'} cb={getUser} />
                <form style={{ marginBottom: '20px' }}>
                    <TextField className={classes.textField} id="title" fullWidth type="text" label="Title" name="title" onChange={setUserVal} value={user.title} />
                    <TextField className={classes.textField} id="firstName" fullWidth type="text" label="First Name" name="firstName" onChange={setUserVal} value={user.firstName} />
                    <TextField className={classes.textField} id="lastName" fullWidth type="text" label="Last Name" name="surname" onChange={setUserVal} value={user.surname} />
                    <MuiPhoneNumber onlyCountries={['gb']} className={classes.textField} defaultCountry={'gb'} id="smsContactNumber" fullWidth label="SOS Mobile Number" onChange={setPhoneNumber} value={settings.smsContactNumber} />
                    <FormControl className={classes.textField} fullWidth>
                        <InputLabel id="debug-label">Default Map Type</InputLabel>
                        <Select labelId="debug-label" id="debug" value={settings.defaultMapType || 0} name="defaultMapType" onChange={e => setSettings({ ...settings, defaultMapType: e.target.value })}>
                            <MenuItem value={0}>Default</MenuItem>
                            <MenuItem value={1}>Ordnance Survery</MenuItem>
                            <MenuItem value={2}>Aerial</MenuItem>
                        </Select>
                    </FormControl>
                    <FormGroup>
                        <FormControlLabel
                            control={<Switch checked={theme === 'dark'} onChange={changeTheme} />}
                            label="Dark Mode"
                        />
                    </FormGroup>
                    <Button variant="contained" color="primary" onClick={save}>Save</Button>
                </form>
                <form onSubmit={changePassword} method="get">
                    <TextField className={classes.textField} required fullWidth type="password" label="Current Password" name="currentPassword" onChange={updatePassField} />
                    <TextField className={classes.textField} required fullWidth type="password" label="New Password" name="newPassword" onChange={updatePassField} />
                    <TextField className={classes.textField} required fullWidth type="password" label="Repeat Password" name="confirmPassword" onChange={updatePassField} />
                    <Button variant="contained" type="submit" color="primary">Change</Button>
                </form>
            </Paper>
            <Snackbar open={alert} autoHideDuration={3000} onClose={() => setAlert(false)}>
                <Alert onClose={() => setAlert(false)} severity="success">Changes Saved!</Alert>
            </Snackbar>
            <Snackbar open={Boolean(error)} autoHideDuration={4000} onClose={() => setError(false)}>
                <Alert onClose={() => setError(null)} severity="error">{error}</Alert>
            </Snackbar>

        </Container >
    )
}
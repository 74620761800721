import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import { post, put, destroy } from 'utils/resources';
import Cards from './cards'
import AddCard from './card'
import { FormControlLabel, Paper, Radio, Typography, RadioGroup, Button, LinearProgress } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    card: {
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(2),
    },
    text: {
        paddingTop: '5px'
    },
    full: {
        flexGrow: 1,
        textAlign: 'left',
        paddingTop: '5px'
    },
    button: {
        margin: theme.spacing(1)
    }
}))

export default function SetupSubscription({ plans, cards, refresh, forced, cancel }) {
    const classes = useStyles()
    const billing = useSelector(state => state.billing)
    const [selected, setPlan] = useState(null)
    const [addingCard, setAddingCard] = useState(false)
    const [working, setWorking] = useState(false)
    const [cancelling, setCancelling] = useState(false)
    const [error, setError] = useState('')

    useEffect(() => {
        if (billing.subscriptionType && billing.subscriptionType !== 'Free') {
            setPlan(billing.subscriptionType)
        } else {
            setPlan(plans[0].id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const save = async function (method) {
        if (!method || !method.id) method = null
        setWorking(true)

        if (method) {
            await put('billing/{accountid}/card', {
                paymentMethod: method.id,
                isDefault: true
            })
        }

        const result = await post('billing/{accountid}/subscription', {
            PaymentMethod: method ? method.id : billing.stripeCustomer.invoiceSettings.defaultPaymentMethodId,
            Customer: billing.stripeCustomerId,
            Price: selected
        })
        if (result.id && result.status === 'active') {
            setAddingCard(false)
            setWorking(false)
            refresh()
            cancel()
        } else {
            setError('Payment Failed')
            setWorking(false)
        }
    }

    const cardAdded = async function (method) {
        const result = await post('billing/{accountid}/card', {
            paymentMethod: method.id,
            isDefault: true
        })
        if (result.message) {
            setError(result.message)
        } else {
            setError('')
            setAddingCard(false)
            refresh()
        }
    }

    const cancelSubscription = async function (cancel) {
        if (cancel) {
            await destroy('billing/{accountid}/subscription', {
                subscription: billing.stripeSubscriptionId
            })
            refresh()
        }
        setCancelling(false)
    }

    return (
        <Fragment>
            <Paper className={classes.paper}>
                <Typography variant="h5">Subscription</Typography>
                <RadioGroup name="plan" value={selected} onChange={e => setPlan(e.target.value)}>
                    {plans.map(plan => {
                        if (plan.recurring != null) {
                            return <div key={plan.id} className={classes.card} onClick={() => setPlan(plan.id)}>

                                <FormControlLabel value={plan.id} control={<Radio />} label={plan.name} />
                                <Typography variant="h6" className={classes.full}>{plan.recurring.interval === 'month' ? 'Monthly' : 'Annually'}{plan.nickname ? ' - ' + plan.nickname : ''}</Typography>
                                <Typography variant="h6" className={classes.text}>£{plan.unitAmount / 100}</Typography>

                            </div>
                        }
                    })}
                </RadioGroup>

            </Paper>
            <Paper className={classes.paper}>
                <Typography variant="h5">Payment Method</Typography>
                {!!cards.length && <Cards cards={cards || []} refresh={refresh} />}
                {addingCard && <AddCard onSave={cardAdded} onCancel={() => setAddingCard(false)} subscribe={true} cancel={forced} billing={billing} />}
                {!addingCard && <div style={{ textAlign: 'right' }}><Button variant="contained" onClick={() => setAddingCard(true)}>Add Card</Button></div>}
            </Paper>
            {!addingCard && billing.active && <Button className={classes.button} disabled={working} variant="contained" onClick={cancel}>Go Back</Button>}
            {!addingCard && <Button className={classes.button} disabled={working || !cards || !cards.length} variant="contained" color='primary' onClick={save}>Subscribe</Button>}
            {!addingCard && billing.active && <Button className={classes.button} disabled={working} variant="contained" onClick={() => setCancelling(true)} color='red'>Cancel Subscription</Button>}
            {!working && error && <Typography variant="body1" color="error">{error}</Typography>}
            {working && <LinearProgress />}
            <Dialog open={cancelling} onClose={() => cancelSubscription()} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">{"Cancel Subscription?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">Are you sure you want to cancel your subscription</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => cancelSubscription()} color="primary">Cancel</Button>
                    <Button onClick={() => cancelSubscription(true)} color="primary" autoFocus>Confirm</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}
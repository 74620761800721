import React from 'react';
import { Switch, Route } from 'react-router-dom'
import Dashboard from './dashboard'
import Accounts from './accounts'
import Devices from './devices'
import Events from './events'
import Routes from './routes'
import Notifications from './notifications'
import Support from './support'

export default function Admin() {
    return (
        <Switch>
            <Route path="/admin/accounts">
                <Accounts />
            </Route>
            <Route path="/admin/devices">
                <Devices />
            </Route>
            <Route path="/admin/events">
                <Events />
            </Route>
            <Route path="/admin/routes">
                <Routes />
            </Route>
            <Route path="/admin/notifications">
                <Notifications />
            </Route>
            <Route path="/admin/support">
                <Support />
            </Route>
            <Route path="/admin">
                <Dashboard />
            </Route>
        </Switch>
    )
}
import React from 'react';
import GenericMap from 'Components/Map'
import { formatDate } from 'utils/format'

export default function ({ devices = [], ids = [], forceCenter, toggleDevice, events, groups, selectGroup }) {
    const _devices = devices.filter(d => ids.some(id => id === d.id))
    const pushpin = _devices.length ? _devices.map(d => ({
        icon: d.registeredDeviceOptions.deviceAvatar,
        location: [d.device.deviceLocation.latitude, d.device.deviceLocation.longitude],
        addHandler: "mouseover",
        infoboxOption: { title: '', description: '' },
        pushPinOption: { title: formatDate(d.device.deviceLocation.created, 'HH:mm'), description: 'Pushpin', color: 'blue' }
    })) : []

    const polygon = _devices.length ? _devices.map(d => ({
        center:
            [d.device.deviceLocation.latitude, d.device.deviceLocation.longitude],
        radius: d.device.deviceLocation.hdop / 10 / 5,
        points: 36,
        option: {
            fillColor: "rgba(0,0,0,0.5)",
            strokeThickness: 2
        }
    })) : []

    return (
        <div style={{ height: '100%' }}>
            <GenericMap
                osgb={true}
                pushpin={pushpin}
                polygon={polygon}
                devices={devices}
                ids={ids}
                resetCenter={forceCenter}
                toggleDevice={toggleDevice}
                events={events}
                groups={groups.filter(g => g.registeredDevices.length)}
                selectGroup={selectGroup}
            />
        </div>
    )
}
import React, { Fragment, useState } from 'react';
import { FormControlLabel, TextField, Grid, RadioGroup, Radio, Button, Typography, Container, makeStyles } from '@material-ui/core'
import { post, put } from 'utils/resources'

const myTheme = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    textField: {
        marginBottom: theme.spacing(2)
    },
    button: {
        margin: theme.spacing(1)
    }
}))

export default function AddRole({ existing, close }) {
    const classes = myTheme()
    const [role, setRole] = useState(existing ? {
        name: existing.name || '',
        devices: existing.devices || 0,
        accountAdmin: existing.accountAdmin || 0,
        billing: existing.billing || 0,
        inviteUsers: existing.inviteUsers || 0,
        events: existing.events || 0
    } : {})
    const [message, setMessage] = useState('')

    const save = async function () {
        setMessage('')
        if (existing.id) {
            await put('accountuserrole/{accountid}/roles/' + existing.id, role)
        } else {
            await post('accountuserrole/{accountid}/roles', role)
        }
        close()
    }

    const setVal = function (ev) {
        setRole({ ...role, [ev.target.name]: ev.target.value })
    }

    const setPerm = function (ev) {
        setRole({ ...role, [ev.target.name]: parseInt(ev.target.value) })
    }

    const groups = [{
        name: 'devices',
        label: 'Devices',
        desc: 'View/Edit Devices and Locations'
    }, {
        name: 'accountAdmin',
        label: 'Administration',
        desc: 'Alter Account and Permissions'
    }, {
        name: 'billing',
        label: 'Billing',
        desc: 'View/Setup Billing'
    }, {
        name: 'inviteUsers',
        label: 'User Invites',
        desc: 'Invite or Revoke Account Access'
    }, {
        name: 'events',
        label: 'Events',
        desc: 'View and Manage Events'
    }]

    return (
        <Container className={classes.container} maxWidth="sm">
            <Typography variant="h6" align="center">{existing && existing.id ? 'Change' : 'Add'} Role</Typography>
            <form onSubmit={save}>
                <TextField className={classes.textField} fullWidth type="text" label="Name" name="name" onChange={setVal} value={role.name} />

                {groups.map((group, index) => <Fragment key={index}>
                    <Typography variant="h6">{group.label}</Typography>
                    <Typography variant="subtitle1">{group.desc}</Typography>
                    <RadioGroup row aria-label="position" name={group.name} value={role[group.name]} defaultValue="top" onChange={setPerm} className={classes.textField}>
                        <FormControlLabel value={0} control={<Radio color="primary" />} label="None" labelPlacement="bottom" />
                        <FormControlLabel value={1} control={<Radio color="primary" />} label="Read" labelPlacement="bottom" />
                        <FormControlLabel value={2} control={<Radio color="primary" />} label="Write" labelPlacement="bottom" />
                    </RadioGroup>
                </Fragment>)}

                {!!message && <Typography color="error">{message}</Typography>}
                <Grid container alignItems="center" justify="center">
                    <Button className={classes.button} variant="contained" type="button" onClick={close}>Cancel</Button>
                    <Button className={classes.button} variant="contained" color="primary" onClick={save}>Save</Button>
                </Grid>
            </form>
        </Container>
    )
}
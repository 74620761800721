import { TableCell, TableRow, Button, Menu, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { getDistanceFromLatLonInKm, humanizeSeconds } from 'utils/format'
import moment from 'moment'

const myStyle = makeStyles({
    name: {
        justifyContent: 'flex-start'
    },
})

export default function RouteRoute({ route, remove = () => { } }) {
    const history = useHistory()
    const classes = myStyle()
    const [anchorEl, setAnchorEl] = useState(null);
    const [unsaved, setUnsaved] = useState(false)
    const [outofdate, setOutofdate] = useState(false)

    useEffect(() => {
        if (route.id) {
            const str = localStorage.getItem('meili-route-' + route.id)
            if (str) {
                try {
                    const obj = JSON.parse(str)
                    if (JSON.stringify(obj) !== JSON.stringify(route)) {
                        setUnsaved(true)
                        if (obj.lastSaved && obj.updated) {
                            if (moment(obj.lastSaved) < moment(obj.updated)) {
                                setOutofdate(true)
                            }
                        }
                    }
                } catch (e) { }
            }
        } else {
            setUnsaved(true)
        }
        // eslint-disable-next-line
    }, [])

    const view = () => {
        history.push('/routeplanner/' + (route.id || 'new'))
    }

    const removeCache = () => {
        localStorage.removeItem('meili-route-' + route.id)
        setUnsaved(false)
        setAnchorEl(null)
    }

    const buttonClick = (e) => {
        setAnchorEl(e.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    let distance = route.distance ? route.distance / 1000 : 0
    if (!route.distance) {
        let legs = (route.points || []).filter(p => p.hidden)
        if (!legs.length) legs = route.points || []
        legs.forEach((pin, index) => {
            if (!index) return 0
            const previous = legs[index - 1]
            distance += getDistanceFromLatLonInKm(pin.latitude, pin.longitude, previous.latitude, previous.longitude)
        })
    }

    return (<TableRow>
        <TableCell><Button className={classes.name} fullWidth onClick={view}>{route.name} {unsaved && '(UNSAVED)'} {outofdate && '(OUT OF DATE)'}</Button></TableCell>
        <TableCell>{distance.toFixed(2)}km</TableCell>
        <TableCell>{route.duration && route.autoRoute ? humanizeSeconds(route.duration) : ''}</TableCell>
        <TableCell align="right">
            <Button onClick={buttonClick}>Options</Button>
            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                {unsaved && Boolean(route.id) && <MenuItem onClick={removeCache}>Clear Unsaved Content</MenuItem>}
                <MenuItem onClick={view}>View</MenuItem>
                <MenuItem onClick={remove}>Remove</MenuItem>
            </Menu>
        </TableCell>
    </TableRow>)
}
import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Accounts from './accounts'
import Account from './account'

export default function AdminAccounts() {
    return (
        <Switch>
            <Route path='/admin/accounts'>
                <Accounts />
            </Route>
            <Route path='/admin/accounts/:id'>
                <Account />
            </Route>
        </Switch>
    )
}
import React, { useState } from 'react'
import { TableRow, TableCell, MenuItem, Button, Menu, Avatar, withStyles, Badge, makeStyles } from '@material-ui/core'
import { AvatarGroup } from '@material-ui/lab'
import moment from 'moment'

const StyledBadge = withStyles((theme) => ({
    badge: {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: '$ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}))(Badge);

const useStyles = makeStyles(() => ({
    avatar: {
        border: 'none'
    },
    name: {
        justifyContent: 'flex-start'
    }
}))

export default function GroupRow({ group, goTo, edit, onDelete }) {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState(null);

    const buttonClick = (e) => {
        setAnchorEl(e.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <TableRow key={group.id}>
            <TableCell>
                <Button fullWidth onClick={() => goTo('groups/' + group.id)} className={classes.name} size="large" >{group.name}</Button>
            </TableCell>
            <TableCell>
                <AvatarGroup>
                    {group.registeredDevices.map(d => {
                        if (moment(d.registeredDevice.device.lastOnline).isSame(new Date(), 'day')) {
                            return <StyledBadge key={d.id} overlap="circle" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot" className={classes.avatar}>
                                <Avatar style={{ backgroundColor: d.registeredDevice.registeredDeviceOptions.colour }} alt={d.registeredDevice.name} src={d.registeredDevice.registeredDeviceOptions.deviceAvatar}>{d.registeredDevice.name[0]}</Avatar>
                            </StyledBadge>
                        } else {
                            return <Avatar style={{ backgroundColor: d.registeredDevice.registeredDeviceOptions.colour }} key={d.id} className={classes.avatar} alt={d.registeredDevice.name} src={d.registeredDevice.registeredDeviceOptions.deviceAvatar}>{d.registeredDevice.name[0]}</Avatar>
                        }
                    })}
                </AvatarGroup>
            </TableCell>
            <TableCell align="right">
                <Button onClick={buttonClick}>Options</Button>
                <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                    <MenuItem onClick={() => goTo('groups/' + group.id)}>View</MenuItem>
                    <MenuItem onClick={edit}>Edit</MenuItem>
                    <MenuItem onClick={onDelete}>Remove</MenuItem>
                </Menu>
            </TableCell>
        </TableRow>
    )
}
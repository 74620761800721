import React, { Fragment, useEffect, useState } from 'react'
import { Card, CardContent, Typography, FormControl, InputLabel, Select, MenuItem, Button } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/styles'
import Leaflet from 'Components/Map/leaflet'
import { getDistanceFromLatLonInKm } from 'utils/format';

const myStyle = makeStyles(theme => ({
    card: {
        marginBottom: theme.spacing(3)
    }
}))

export default function EventRoute({ event, setEvent, routes }) {
    const [map, setMap] = useState(null)
    const classes = myStyle()
    const theme = useTheme()

    const route = event.routeId ? routes.find(r => r.id === event.routeId) : null

    useEffect(() => {
        if (map && route) {
            map.fitBounds(route.routePoints.map(point => [point.latitude, point.longitude]))
        }
    }, [map, route])

    let distance = route && route.distance ? route.distance / 1000 : 0;
    const points = route ? route.routePoints.filter(p => !p.hidden) : []
    const markers = points.map((pin, index) => {
        if (index && !route.distance) {
            const prev = route.routePoints[index - 1]
            distance += getDistanceFromLatLonInKm(prev.latitude, prev.longitude, pin.latitude, pin.longitude)
        }
        return {
            location: [pin.latitude, pin.longitude],
            color: pin.colour || theme.palette.primary.main,
            pushPinOption: {},
            infoboxOption: {},
            content: pin.comment || index + 1,
        }
    })

    let legs = route ? route.routePoints.filter(p => p.hidden) : []
    if (!legs.length && route) {
        legs = route.routePoints
    }
    const polylines = [{
        location: legs.map(row => [row.latitude, row.longitude]),
        option: {
            strokeColor: 'grey', strokeThickness: 5
        }
    }]

    return (
        <Fragment>
            <Card className={classes.card}>
                <CardContent>
                    <Typography variant="h5">Route
                        <Button style={{ float: 'right' }} variant="contained" color="secondary" onClick={() => setEvent({ ...event, routeId: null })}>Set No Route</Button>
                    </Typography>
                    <FormControl fullWidth style={{ marginBottom: 5 }}>
                        <InputLabel id="route">Select Route</InputLabel>
                        <Select labelId="route" value={event.routeId} onChange={e => setEvent({ ...event, routeId: e.target.value })}>
                            {routes.map(r => <MenuItem value={r.id}>{r.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                    {Boolean(route) && <Fragment>
                        <div style={{ height: 400 }}><Leaflet polylines={polylines} markers={markers} center={[53.6231179, -2.1639978]} setMap={setMap} /></div>
                        <Typography variant="body1">{points.length} Points - {distance.toFixed(2)}km</Typography>
                    </Fragment>}
                </CardContent>
            </Card>
        </Fragment>
    )
}
import LoadingIcon from 'Components/LoadingIcon';
import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Polyline, Circle, ScaleControl, useMap, useMapEvents, Tooltip } from 'react-leaflet'
import L from 'leaflet'
import { makeStyles, useTheme } from '@material-ui/styles';
import clsx from 'clsx';
import { locationToOsgb } from 'utils/format'

const myStyle = makeStyles(theme => ({
    icon: {
        borderRadius: '50%',
        border: `2px solid grey`
    },
    error: {
        animation: '$sos 1.5s infinite'
    },
    map: {
        height: '100%',
        width: '100%'
    },
    '@keyframes sos': {
        '0%': { borderColor: 'grey' },
        '50%': { borderColor: theme.palette.error.main },
        '100%': { borderColor: 'grey' }
    }
}))

function ChangeZoom({ zoom }) {
    const map = useMap();
    const center = map.getCenter()
    map.setView(center, zoom)
    return null;
}

function ViewChanged({ setZoom, setCenter }) {
    const map = useMap();
    const calcCenter = () => {
        const center = map.getCenter()
        setCenter([center.lat, center.lng])
    }
    useMapEvents({
        zoomend: (e) => setZoom(e.target._zoom),
        dragstart: calcCenter,
        dragend: calcCenter
    })
    return null
}

function LocationMarker({ onClick, onContext = () => { }, onMove = () => { } }) {
    useMapEvents({
        contextmenu(e) {
            onContext(e)
        },
        click(e) {
            onClick(e)
        },
        moveend(e) {
            onMove(e)
        }
    })
    return null
}

export default function Leaflet({ center, markers, polylines, circles, zoom, className = '', setZoom = () => { }, overrideCenter = 0, setMap = () => { }, fixed = false, id = -1, onMove = () => { }, onClick = () => { }, markerClick = () => { } }) {
    const classes = myStyle()
    const maintheme = useTheme()
    const [loading, setLoading] = useState(true)
    const [myCenter, setMyCenter] = useState(null)
    const [osgb, setOsgb] = useState(null)

    useEffect(() => {
        setMyCenter(center)
        // eslint-disable-next-line
    }, [overrideCenter])

    useEffect(() => {
        if (center) {
            setLoading(false)
        }
    }, [center])

    const onContext = e => {
        setOsgb({
            center: [e.latlng.lat, e.latlng.lng],
            osgb: locationToOsgb(e.latlng.lat, e.latlng.lng)
        })
    }

    const clickDetected = e => {
        setOsgb(null)
        onClick(e)
    }

    if (loading) return <LoadingIcon />

    return <div className={clsx(classes.map, className)} id={'leaflet-' + id}>
        <MapContainer center={myCenter} zoom={13} style={{ height: '100%' }} zoomControl={false} whenCreated={setMap} scrollWheelZoom={!fixed} doubleClickZoom={!fixed} >
            <ChangeZoom zoom={zoom} />
            <ViewChanged setZoom={setZoom} setCenter={setMyCenter} />
            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {!!markers && markers.map((marker, index) => {
                if (!marker) return null
                const props = {
                    draggable: marker.draggable,
                    autoPan: marker.autoPan,
                    eventHandlers: {
                        moveend: e => marker.onMove ? marker.onMove(index, e.target._latlng) : {},
                        click: e => markerClick(index, e)
                    }
                }
                if (marker.icon) {
                    const style = `
                    width: 32px; 
                    height: 32px;
                    border-radius: 50%;
                    border: 2px solid ${marker.outline || 'grey'};
                    `
                    props.icon = L.divIcon({
                        html: `<img src="${marker.icon}" style="${style}" />`,
                        className: "",
                        iconAnchor: [16, 16],
                    })
                } else if (marker.isRoute) {
                    let outline = ''
                    if (marker.outline) outline = marker.outline;
                    const style = `
                    background-color: ${marker.color || maintheme.palette.primary.main};
                    width: 1rem;
                    height: 1rem;
                    left: 0.45rem;
                    top: 0.5rem;
                    display: flex;
                    opacity: 0.9;
                    position: relative;
                    border-radius: 100%;
                    border: 1px solid ${outline || marker.border || '#FFFFFF'}`
                    props.icon = L.divIcon({
                        className: "",
                        iconAnchor: [16, 16],
                        html: `<span style="${style}" id="marker-${marker.id || index}"></span>`
                    })
                } else {
                    let outline = ''
                    if (marker.outline) outline = marker.outline;
                    const style = `
                    background-color: ${marker.color || maintheme.palette.primary.main};
                    width: 2rem;
                    height: 2rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    left: -1rem;
                    top: -1rem;
                    opacity: 0.9;
                    position: relative;
                    border-radius: 2rem 2rem 0;
                    transform: rotate(45deg);
                    border: 1px solid ${outline || marker.border || '#FFFFFF'}`
                    props.icon = L.divIcon({
                        className: "",
                        iconAnchor: [0, 24],
                        html: `<span style="${style}" id="marker-${marker.id || index}">
                        ${marker.content ? `<span style="transform: rotate(-45deg);">${marker.content}</span>` : ''} 
                        </span>`
                    })
                }
                return <Marker key={'marker_' + index} position={marker.location} {...props} >
                    {Boolean(marker.infoboxOption.title) && <Popup>{marker.infoboxOption.title}</Popup>}
                    {Boolean(marker.pushPinOption.title) && <Tooltip permanent={marker.pushPinOption.open} opacity="0.8" direction={'bottom'} >{marker.pushPinOption.title}</Tooltip>}
                </Marker>
            })}
            {!!polylines && polylines.map((polyline, index) => {
                return <Polyline
                    key={'line_' + index}
                    positions={polyline.location}
                    color={polyline.option.strokeColor}
                    stroke={true}
                    opacity={polyline.option.opacity || 1}
                    weight={polyline.option.strokeThickness || 3}
                />
            })}
            {!!circles && circles.map((circle, index) => {
                if (!circle) return null
                return <Circle
                    key={'circle_' + index}
                    center={circle.center}
                    radius={circle.radius * 1000}
                    pathOptions={{
                        color: circle.option.color || circle.option.strokeColor,
                        fillColor: circle.option.fillColor,
                        fill: true, fillOpacity: 0.6
                    }}
                    stroke={circle.option.strokeThickness ? true : false}
                />
            })}
            {Boolean(osgb) && <Circle
                center={osgb.center}
                pathOptions={{ fillColor: 'blue' }}
                radius={50}>
                <Tooltip permanent><div style={{ textAlign: 'center' }}>
                    OSGB {osgb.osgb.clean}
                </div></Tooltip>
            </Circle>}
            <ScaleControl />
            <LocationMarker onClick={clickDetected} onContext={onContext} onMove={onMove} />
        </MapContainer >
    </div>
}
import React from 'react'
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core'

export default function Confirm({ header, subheader, open, confirmText = "Agree", cancelText = "Disagree", skipDisagree = false, onConfirm = () => { }, onCancel = () => { } }) {
    return <Dialog open={Boolean(open)} onClose={onCancel} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{header}</DialogTitle>
        <DialogContent>
            <DialogContentText>{subheader}</DialogContentText>
        </DialogContent>
        <DialogActions>
            {!skipDisagree && <Button autoFocus onClick={onCancel} color="primary">{cancelText}</Button>}
            <Button onClick={onConfirm} color="primary" autoFocus>{confirmText}</Button>
        </DialogActions>
    </Dialog>

}
import React from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setPath } from 'store/actions'

export default function AdminAccountsSingle() {
    const { id } = useParams()
    const dispatch = useDispatch()
    useEffect(() => {
        // get the account
        dispatch(setPath('Admin | Account 1'))
    }, [id])
    return (
        <div></div>
    )
}
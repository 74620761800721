import React from 'react';
import { Grid, Typography, makeStyles, Avatar, Card, CardActionArea } from '@material-ui/core'
import { Sms } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(3),
        cursor: 'pointer',
    },
    nameHolder: {
        display: 'flex',
        alignItems: 'center'
    },
    avatar: {
        marginRight: theme.spacing(2)
    },
    input: {
        marginTop: theme.spacing(1)
    }
}))

export default function UserCard({ user, roles, editUser }) {
    const classes = useStyles()

    const role = roles.find(r => r.id === user.roleId)

    return (
        <Grid item xs={12} sm={8} md={6} lg={4} xl={3}>
            <Card elevation={3} >
                <CardActionArea onClick={editUser} className={classes.paper}>
                    <div className={classes.nameHolder}>
                        <Avatar className={classes.avatar} src={user.user.avatar}>{user.user.firstName[0] ? user.user.firstName[0] : 'No Name'}</Avatar>
                        <Typography variant="h6">{user.user.firstName} {user.user.surname}</Typography>
                    </div>
                    <Grid container justify="center" className={classes.input} spacing={3}>
                        <Grid item xs={6}>
                            <Typography variant="body1">{role ? role.name : ''}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container direction="row" justify="flex-start" alignItems="center" >
                                <Sms color={user.receiveSOSSMS ? 'primary' : 'disabled'} /> <div style={{ marginLeft: '10px' }}>SMS {user.receiveSOSSMS ? 'enabled' : 'disabled'}</div>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardActionArea>
            </Card>
        </Grid>
    );
}
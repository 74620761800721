import moment from 'moment';
import { get, post } from './http';

export default {
    user() {
        let user = localStorage.getItem('user')
        if (!user) return null
        if (typeof user === 'string') {
            try {
                user = JSON.parse(user)
            }
            catch (e) {
                return null
            }
        }
        return user
    },
    setUser(user) {
        localStorage.setItem('user', JSON.stringify(user))
        if (user.theme) {
            document.body.className = 'dark'
        }
    },
    auth() {
        let auth = window.auth
        if (!auth) {
            auth = localStorage.getItem('auth')
            if (auth && typeof auth === 'string') {
                try {
                    auth = JSON.parse(auth)
                }
                catch (e) {
                    return null
                }
            }
        }
        if (!auth) return null;
        return auth
    },
    setAuth(auth, rememberMe) {
        window.auth = auth
        if (rememberMe) {
            localStorage.setItem('auth', JSON.stringify(auth))
        } else {
            localStorage.removeItem('auth')
        }
        this.handleExpiration(auth.jwtTokenExpiration)
    },
    async checkAuth() {
        let auth = this.auth()
        if (auth && auth.id) {
            // if (auth.jwtTokenExpiration && moment(auth.jwtTokenExpiration) < moment()) {
            console.log('refreshing token')
            auth = await this.refresh(auth)
            return auth
        } else {
            return
        }
    },
    async refresh(auth) {
        const result = await post('auth/refresh-token/' + auth.id, undefined, true)
        if (result) {
            this.setAuth(result, !!localStorage.getItem('auth'))
        }
        return result
    },
    checkUser() {
        let auth = this.auth()
        if (auth && auth.id) {
            return get("AccountUser")
        } else {
            return Promise.resolve()
        }
    },
    removeUser() {
        localStorage.removeItem('auth')
        localStorage.removeItem('user')
        localStorage.removeItem('reduxPersist:user')
    },
    parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    },
    handleExpiration(expirationDate) {
        let timeToExpire = moment(expirationDate).diff(moment())
        if (window.expirationTimer) {
            clearTimeout(window.expirationTimer)
        }
        console.log('expiring in ' + timeToExpire / 1000, expirationDate)
        window.expirationTimer = setTimeout(() => {
            console.log('refreshing token')
            // refreshtoken
            let auth = this.auth()
            this.refresh(auth)
        }, timeToExpire - (1000 * 60))
    }
}
import React, { useState } from 'react'
import { Button, ButtonGroup, Menu, MenuItem } from '@material-ui/core'
import { Settings } from '@material-ui/icons'

export default function DeviceButton({ device, selected, toggle, goTo, remove }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const buttonClick = (e) => {
        setAnchorEl(e.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <ButtonGroup variant="contained" style={{ marginRight: 10 }}>
            <Button color={selected ? "primary" : 'default'} onClick={() => toggle(device.registeredDevice.id)}>{device.registeredDevice.name}</Button>
            <Button onClick={buttonClick}><Settings /></Button>
            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={() => toggle(device.registeredDevice.id)}>{selected ? 'Hide from Map' : 'Add to Map'}</MenuItem>
                <MenuItem onClick={() => goTo(device.registeredDevice.id)}>View Details</MenuItem>
                <MenuItem onClick={() => remove(device.registeredDeviceId)}>Remove From Group</MenuItem>
            </Menu>
        </ButtonGroup>)
}
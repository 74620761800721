import React, { useState, useEffect, useRef } from 'react';
import { get } from 'utils/resources'
import ActivateBilling from 'Components/ActivateBilling'
import Map from './map'
import PermissionsChecker from 'Components/PermissionsChecker'
import ActivateAccount from 'Components/ActivateAccount'
import { Paper, makeStyles, Typography, Grid, Button } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import LoadingIcon from 'Components/LoadingIcon';
import queryString from 'query-string'
import moment from 'moment'
import { useTheme } from '@material-ui/styles';

const styles = makeStyles(theme => ({
    container: {
        height: '100%',
        width: '100%',
        display: 'table'
    },
    root: {
        padding: theme.spacing(2),
        height: '100%',
        width: '100%',
        display: 'table',
        [theme.breakpoints.down("sm")]: {
            padding: 0
        }
    },
    content: {
        height: '100%',
        width: '100%',
        display: 'table-row'
    },
    header: {
        display: 'table-row',
        width: '100%'
    },
    chipHolder: {
        display: 'flex',
        flexWrap: 'wrap',
        [theme.breakpoints.down("sm")]: {
            display: 'none'
        }
    },
    mobile: {
        display: 'none',
        [theme.breakpoints.down("sm")]: {
            display: 'none',
            padding: theme.spacing(2)
        }
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    formControl: {
        marginBottom: theme.spacing(1)
    },
    none: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    }
}));



export default function MapTemplate() {
    const history = useHistory()
    const classes = styles()
    const [devices, setDevices] = useState([])
    const [groups, setGroups] = useState([])
    const [events, setEvents] = useState([])
    const [loading, setLoading] = useState(true)
    const [deviceIds, setDeviceIds] = useState([])
    const [forceCenter, setForceCenter] = useState(0)
    const billing = useSelector(state => state.billing)
    const auth = useSelector(state => state.auth)
    const role = useSelector(state => state.role)
    const theme = useTheme()
    const timer = useRef()

    const eventColours = [theme.palette.primary.main, theme.palette.secondary.main, theme.palette.success.main, theme.palette.warning.main, theme.palette.error.main]

    const getDevices = async () => {
        if (auth.isVerified && billing.active && role.devices > 0) {
            const devices = await get('device')
            if (Array.isArray(devices)) {
                setDevices(devices.filter(d => d.device.deviceLocation && d.device.allowed))
            }
            setGroups(await get('devicegroup'))
            const results = await get('event')
            results.forEach((r, i) => r.color = eventColours[i] || 'grey')
            setEvents(results)
            setLoading(false)
        }
    }

    useEffect(() => {
        if (auth.isVerified && billing.active && role.devices > 0) {
            const query = queryString.parse(window.location.search)
            let ls = localStorage.getItem('meili-map-devices')
            if (ls) {
                try {
                    ls = JSON.parse(ls)
                    setDeviceIds(ls)
                } catch (e) {
                }
            } else if (query.d) {
                setDeviceIds(Array.isArray(query.d) ? query.d : [query.d])
            }
            getDevices()
        } else {
            setLoading(false)
        }
        // eslint-disable-next-line
    }, [auth, billing, role])

    useEffect(() => {
        timer.current = setInterval(getDevices, 10000)
        return function () {
            if (timer.current) clearInterval(timer.current)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (devices.length && !deviceIds.length) {
            const devicesThatChangedToday = devices.filter(d => (d.device.deviceLocaiton && moment(d.device.deviceLocation.created).diff(moment(), 'days') === 0) || d.device.sos)
            if (devicesThatChangedToday.length) {
                setDeviceIds(devicesThatChangedToday.map(d => d.id))
            } else {
                // get the most recent changed
                const mostRecent = devices.filter(d => d.device.deviceLocation).slice().sort((a, b) => Number(new Date(b.device.deviceLocation.created)) - Number(new Date(a.device.deviceLocation.created)))[0]
                setDeviceIds([mostRecent.id])
            }
        }
        // eslint-disable-next-line
    }, [devices, history])

    useEffect(() => {
        if (deviceIds.length) localStorage.setItem('meili-map-devices', JSON.stringify(deviceIds))
    }, [deviceIds])

    const toggleDevice = function (id) {
        let ids = [...deviceIds]
        const index = ids.indexOf(id)
        if (index > -1) {
            ids.splice(index, 1)
        }
        else {
            ids.push(id)
        }

        setDeviceIds(ids)
        setForceCenter(forceCenter + 1)
    }

    const selectGroup = function (group) {
        const ids = group.registeredDevices.map(d => d.registeredDeviceId)
        const arr = Array.from(new Set([...deviceIds].concat(ids)))
        setDeviceIds(arr)
    }

    if (!auth.isVerified) return <ActivateAccount />

    if (!billing.active) {
        return <ActivateBilling goto={() => history.push('/billing')} />
    }

    if (loading) return <LoadingIcon />

    if (!Array.isArray(devices)) return <LoadingIcon />

    if (!devices.length && role.devices) {
        return <Grid container direction="row" justify="center" alignItems="center" className={classes.container}>
            <Paper className={classes.none} >
                <Typography variant="h6" align="center" style={{ marginBottom: '20px' }}>No Devices Found</Typography>
                <Button variant="contained" color="primary" onClick={() => history.push('/devices?add=true')}>Add Device</Button>
            </Paper>
        </Grid>
    }

    return (
        <PermissionsChecker required="devices">
            <Paper className={classes.root}>
                <div className={classes.content}>
                    <Map ids={deviceIds.filter(id => devices.some(d => d.id === id))} devices={devices} groups={groups} events={events} toggleDevice={toggleDevice} selectGroup={selectGroup} forceCenter={forceCenter} />
                </div>
                {!devices.length && <Typography variant="h6" align="center">No Devices Found</Typography>}
            </Paper>
        </PermissionsChecker >
    )
}
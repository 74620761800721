import React from 'react'
import { Switch, Route } from 'react-router'

import Events from './events'
import Event from './event'
import PermissionsChecker from 'Components/PermissionsChecker'

export default function EventsTemplate() {
    return (
        <PermissionsChecker required="events">
            <Switch>
                <Route exact path="/events">
                    <Events />
                </Route>
                <Route path="/events/:id">
                    <Event />
                </Route>
            </Switch>
        </PermissionsChecker>
    )
}
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Block } from '@material-ui/icons'
import LoadingIcon from './LoadingIcon'
import { Card, CardContent, Container, Typography, makeStyles } from '@material-ui/core'

const mytheme = makeStyles(() => ({
    icon: {
        fontSize: '40px'
    }
}))

export default function PermissionsChecker({ children, required }) {
    const classes = mytheme()
    const role = useSelector(state => state.role)
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setLoading(false)
    }, [role])

    if (loading) return <LoadingIcon />
    if (role && role[required] === 0) return <Container maxWidth="sm">
        <Card variant="outlined">
            <CardContent>
                <Typography align="center" variant="h5"><Block color="error" className={classes.icon} /></Typography>
                <Typography variant="h6" align="center">You do not have the required permissions to view this page</Typography>
            </CardContent>
        </Card>
    </Container>
    return children
}
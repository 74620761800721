import React, { useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow, Button, Menu, MenuItem } from '@material-ui/core'
import { useHistory } from 'react-router';
import { formatDate } from 'utils/format';
import { makeStyles } from '@material-ui/styles';

const myStyle = makeStyles(theme => ({
    name: {
        justifyContent: 'flex-start'
    },
    table: {
        [theme.breakpoints.down("sm")]: {
            display: 'none'
        }
    },
    mobile: {
        display: 'none',
        [theme.breakpoints.down("sm")]: {
            display: 'block',
            position: 'fixed',
            width: '100%',
            height: 'calc(100% - 68px)',
            overflowY: 'auto'
        }
    }
}))

function Row({ event, remove }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const history = useHistory()
    const classes = myStyle()
    const buttonClick = (e) => {
        setAnchorEl(e.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    return (<TableRow>
        <TableCell>
            <Button fullWidth className={classes.name} onClick={() => history.push('/events/' + event.id + '/edit')}>{event.name}</Button>
        </TableCell>
        <TableCell>{formatDate(event.startDate)}</TableCell>
        <TableCell>{formatDate(event.endDate)}</TableCell>
        <TableCell>{event.devicesCount}</TableCell>
        <TableCell>{event.route ? event.route.name + (event.route.distance ? ` (${(event.route.distance / 1000).toFixed(2)}km)` : '') : 'No Route'}</TableCell>
        <TableCell align="right">
            <Button onClick={buttonClick}>Options</Button>
            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={() => history.push('/events/' + event.id + '/edit')}>Edit</MenuItem>
                <MenuItem onClick={() => history.push('/events/' + event.id + '/view')}>View</MenuItem>
                <MenuItem onClick={() => remove(event)}>Remove</MenuItem>
            </Menu>
        </TableCell>
    </TableRow>)
}

export default function EventsTable({ events, remove }) {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>End Date</TableCell>
                    <TableCell>Attendees</TableCell>
                    <TableCell>Route</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {events.map(event => <Row key={event.id} remove={remove} event={event} />)}
                {!events.length && <TableRow>
                    <TableCell colSpan="99" align="center">No Events Found</TableCell>
                </TableRow>}
            </TableBody>
        </Table>
    )
}
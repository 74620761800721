import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import logo from 'Assets/Lockup-Colour.png'
import queryString from 'query-string'
import CookieConsent from "react-cookie-consent";

import { post } from 'utils/http'
import auth from 'utils/auth';
import i18n from 'utils/i18n';
import { useHistory } from 'react-router';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://meili.earth">Meili GPS</Link>{' '}
            {new Date().getFullYear()}
        </Typography>
    );
}

const styles = makeStyles(theme => ({
    outer: {
        display: "table",
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
    },
    root: {
        height: '100%',
        position: 'absolute'
    },
    image: {
        backgroundImage: 'url(https://source.unsplash.com/user/meiligps/likes)',
        backgroundRepeat: 'no-repeat',
        backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    imageHolder: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    logo: {
        marginTop: 10,
        width: 150,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.down("sm")]: {
            margin: 0,
            padding: theme.spacing(2),
            display: 'block',
            backgroundColor: theme.palette.grey[50],
            opacity: 0.9,
            borderRadius: 10
        }
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    clickable: {
        cursor: 'pointer'
    },
    form: {
        [theme.breakpoints.down("sm")]: {
            top: 0,
            left: 0,
            position: 'fixed',
            height: '100%',
            width: '100%',
            backgroundImage: 'url(https://source.unsplash.com/user/meiligps/likes)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            padding: theme.spacing(8, 4),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }
    },
    cookieHolder: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        height: '100%',
        width: '100%',
        zIndex: 999,
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
    },
    cookie: {
        backgroundColor: theme.palette.background.default,
        borderRadius: 10,
        padding: theme.spacing(3),
        textAlign: 'center',
        width: 300
    }
}));

export default function Login() {
    const [state, setState] = useState({
        loggedin: false,
        isError: false,
        running: false,
        loginFailed: false,
        fieldsMissing: false,
        forgetting: false,
        errorMsg: '',
        cookieConsented: window.location.protocol === 'http:' ? true : document.cookie && document.cookie.indexOf("MeiliGps=true") > -1,
        register: window.location.pathname === '/register',
        errors: [],
        FirstName: '',
        LastName: '',
        Password: '',
        ConfirmPassword: '',
        acceptTerms: false,
        Email: '',
        forgotMessage: ''
    })

    const [rememberMe, setRememberMe] = useState(true)

    const history = useHistory()
    const classes = styles()

    const handleInputChange = function (e) {
        if (state.isError) {
            setState({ ...state, errors: [], isError: false });
        }

        setState({ ...state, [e.target.name]: e.target.value });
    }

    const login = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (!state.cookieConsented) {
            return setState({ ...state, errors: ['Cookie Consent Required'] })
        }
        setState({
            ...state,
            running: true,
            isError: false,
            errorMsg: '',
            errors: []
        })
        if (!state.Email) {
            let errors = state.errors;
            errors.push("Email field is blank")
            setState({
                ...state,
                isError: true, errors: errors, running: false
            })
        }
        if (!state.Password) {
            let errors = state.errors;
            errors.push("Password field is blank")
            setState({
                ...state,
                isError: true, errors: errors, running: false
            })
        }

        if (state.isError) {
            return;
        }
        post("auth/authenticate", {
            Email: state.Email,
            Password: state.Password
        }, true).then(result => {
            const query = queryString.parse(window.location.search)
            if (result && result.id) {
                auth.setAuth(result, rememberMe)
                history.push(query.redirect || '/')
            } else {

                var errors = state.errors;
                errors.push(result.message);
                setState({
                    ...state,
                    errors,
                    isError: true,
                    loginFailed: true,
                    running: false
                })

            }
        }).catch(err => {
            console.log(err)
            setState({
                ...state,
                isError: true,
                loginFailed: true,
                running: false
            })
        })
    }

    const forgot = function () {
        setState({ ...state, forgetting: true })
    }

    const forgetPost = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setState({ ...state, forgotMessage: '' })
        await post('auth/forgot-password', { email: state.Email })
        setState({ ...state, forgotMessage: 'Reminder Email Sent' })
    }

    const register = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (!state.cookieConsented) {
            return setState({ ...state, errors: ['Cookie Consent Required'] })
        }
        setState({
            ...state,
            running: true,
            isError: false,
            errorMsg: '',
            errors: []
        })
        if (!state.Email || !state.Password || !state.ConfirmPassword) {
            return setState({
                ...state,
                isError: true, errors: ["Email or password blank"], running: false
            })
        }
        if (state.Password !== state.ConfirmPassword) {
            return setState({
                ...state,
                running: false,
                isError: true, errors: ['Password do not Match']
            })
        }

        post("auth/register", {
            Email: state.Email,
            Password: state.Password,
            ConfirmPassword: state.ConfirmPassword,
            AcceptTerms: state.acceptTerms
        }).then(result => {
            if (result && result.id) {
                auth.setAuth(result, rememberMe)
                history.push('/')
            } else {
                let errors = []
                for (let prop in result.errors) {
                    errors.push(...result.errors[prop])
                }
                setState({
                    ...state,
                    errors,
                    isError: true,
                    running: false
                })
            }
        }).catch(err => {
            setState({
                ...state,
                running: false
            })
        })
    }


    const registerToggle = function () {
        setState({
            ...state,
            errors: [],
            errorMsg: '',
            register: !state.register
        })
    }

    const registerText = state.running ? i18n.t('login.working') : i18n.t('login.register')

    return (

        <main className={classes.outer}>
            <CookieConsent overlay={true} disableStyles={true} cookieName="MeiliGps"
                overlayClasses={classes.cookieHolder}
                containerClasses={classes.cookie}
                contentClasses="MuiTypography-root MuiTypography-h6"
                onAccept={() => setState({ ...state, cookieConsented: true })}
                buttonClasses="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary"
                buttonStyle={{ marginTop: 20 }}
                style={{ zIndex: 9999 }}
            >
                This website uses cookies to enhance the user experience.
            </CookieConsent>
            <Grid container component="main" className={classes.root}>
                <Grid item xs={false} sm={4} md={8} className={classes.image} />
                <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
                    {!state.forgetting && <form className={classes.form} onSubmit={(e) => state.register ? register(e) : login(e)}>
                        <div className={classes.paper}>
                            <div className={classes.imageHolder}>
                                <img src={logo} className={classes.logo} alt="meili-logo" />
                            </div>
                            <TextField variant="outlined" margin="normal" required fullWidth label="Email Address" name="Email" autoComplete="email" type="email" autoFocus onChange={handleInputChange} value={state.Email} />
                            <TextField variant="outlined" margin="normal" required fullWidth name="Password" label="Password" type="password" autoComplete="current-password" onChange={handleInputChange} value={state.password} />
                            {state.register && <TextField variant="outlined" margin="normal" required fullWidth name="ConfirmPassword" label="Confirm Password" type="password" autoComplete="current-password" value={state.ConfirmPassword} onChange={handleInputChange} />}
                            {state.register && <Grid container>
                                <Grid item xs={12}>
                                    <Link href="https://meili.earth/terms" target="_blank">Terms & Conditions</Link>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel control={<Checkbox required checked={state.acceptTerms} onChange={e => setState({ ...state, acceptTerms: e.target.checked })} color="primary" />} label="Accept Terms & Conditions" />
                                </Grid>
                            </Grid>}
                            {state.register && <Button fullWidth type="submit" variant="contained" color="primary" style={{ marginBottom: 10 }}>{registerText}</Button>}
                            {!state.register && <Button fullWidth type="submit" variant="contained" color="primary" className={(state.running ? 'loading' : '')}>{state.running ? "Working" : "Login"} </Button>}
                            {!!state.errors.length && <ul>
                                {state.errors.map(err => <Alert severity="warning">{err}</Alert>)}
                            </ul>}
                            {!state.register && <Grid container>
                                <Grid item xs>
                                    <FormControlLabel control={<Checkbox value="remember" checked={rememberMe} onClick={() => setRememberMe(v => !v)} color="primary" />} label="Keep me logged in" />
                                </Grid>
                            </Grid>}

                            <Grid container>
                                <Grid item xs>
                                    <Link variant="body2" className={classes.clickable} onClick={forgot}>
                                        {i18n.t('login.forgot')}
                                    </Link>
                                </Grid>
                                <Grid item>
                                    {!state.register && <Link onClick={registerToggle} variant="body2" className={classes.clickable}>
                                        {i18n.t('login.dontHaveAccount')} {i18n.t('login.registerHere')}
                                    </Link>}
                                    {state.register && <Link onClick={registerToggle} variant="body2" className={classes.clickable} >
                                        {i18n.t('login.alreadyHaveAccount')}  {i18n.t('login.loginHere')}
                                    </Link>}

                                </Grid>
                            </Grid>
                            <Box mt={5}>
                                <Copyright />
                            </Box>
                        </div>
                    </form>}
                    {state.forgetting && <form className={classes.form} onSubmit={forgetPost}>
                        <div className={classes.paper}>
                            <img src={logo} className={classes.logo} alt="meili-logo" />
                            <Typography variant="body1">Enter your email address</Typography>
                            <TextField variant="outlined" margin="normal" required fullWidth label="Email Address" name="Email" autoComplete="email" type="email" autoFocus onChange={handleInputChange} value={state.Email} />
                            <Grid container spacing={2}>
                                <Grid xs={6} item>
                                    <Button variant="contained" type="submit" color="primary" fullWidth>Send Reminder</Button>
                                </Grid>
                                <Grid xs={6} item>
                                    <Button variant="contained" type="button" fullWidth onClick={() => setState({ ...state, forgetting: false })}>Back</Button>
                                </Grid>
                                {!!state.forgotMessage && <Grid item xs={12}>
                                    <Typography variant="body2" align="center">{state.forgotMessage}</Typography>
                                </Grid>}
                            </Grid>
                            <Box mt={5}>
                                <Copyright />
                            </Box>
                        </div>
                    </form>}
                </Grid>
            </Grid>
        </main >
    );
}
import React, { useEffect, useState } from 'react';
import { Container, makeStyles, Paper, TextField, Button, Select, MenuItem, Grid } from '@material-ui/core';
import { destroy, get, put } from 'utils/resources';
import SelectAvatar from 'Components/SelectAvatar'
import LoadingIcon from 'Components/LoadingIcon';
import Confirm from 'Components/Confirm';
import { useHistory } from "react-router-dom";


const mytheme = makeStyles(theme => ({
    container: {
        padding: theme.spacing(2),
        textAlign: 'center'
    },
    textField: {
        textAlign: 'left',
        marginBottom: theme.spacing(1)
    }
}))


export default function Details({ reload }) {
    const classes = mytheme()
    const history = useHistory()
    const [account, setAccount] = useState(null)
    const [removing, setRemoving] = useState(false)

    useEffect(() => {
        getAccount()
    }, [])

    const getAccount = async () => {
        setAccount(await get('account/{accountid}'))
    }

    const afterChange = function () {
        getAccount()
        reload()
    }

    const save = async function () {
        await put('account/{accountid}', account)
        reload()
    }

    const setVal = function (e) {
        setAccount({ ...account, [e.target.name]: e.target.value })
    }

    const removeAccount = async () => {
        await destroy('account/{accountid}')
        history.push('/login')
    }

    if (!account) return <LoadingIcon />

    let label = "Organsation Name"
    if (account.accountType === 0) label = "Account Name"
    else if (account.accountType === 1) label = "Team Name"

    return (
        <Container maxWidth="sm" disableGutters={true}>
            <Paper className={classes.container}>
                <SelectAvatar src={account.avatar} name={account.name} endpoint={'account/{accountid}/avatar'} cb={afterChange} />
                <form onSubmit={save}>
                    <TextField className={classes.textField} fullWidth type="text" label={label} name="name" onChange={setVal} value={account.name} />
                    <Select className={classes.textField} fullWidth label="Account Type" name="accountType" onChange={setVal} value={account.accountType}>
                        <MenuItem value={0}>Personal</MenuItem>
                        <MenuItem value={1}>Family/Team</MenuItem>
                        <MenuItem value={2}>Organisation</MenuItem>
                    </Select>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" onClick={save} style={{ marginRight: 10 }}>Save</Button>
                            <Button variant="contained" color="secondary" onClick={() => setRemoving(true)} style={{ marginLeft: 10 }}>Delete Account</Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
            <Confirm
                header="Delete Organisation"
                subheader={<span>Are you sure you want to delete your whole organisation? <br />This will remove all devices and users.</span>}
                confirmText="Yes, Delete It"
                cancelText="No, cancel"
                open={removing}
                onCancel={() => setRemoving(false)}
                onConfirm={removeAccount}
            />
        </Container >
    )
};
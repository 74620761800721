import React, { useState } from 'react'
import { Paper, Container, Typography, Snackbar, Button } from "@material-ui/core";
import { Alert as MuiAlert } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles';
import { get } from 'utils/http'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const useStyles = makeStyles(theme => ({
    paper: {
        textAlign: 'center',
        padding: theme.spacing(3)
    },
    title: {
        marginBottom: theme.spacing(3)
    }
}))

export default function ActivateAccountWarning() {
    const classes = useStyles()
    const [alert, setAlert] = useState('')

    const sendVerification = async () => {
        await get('auth/verify-email')
        setAlert('Email Sent')
    }

    return (
        <Container maxWidth="sm">
            <Paper className={classes.paper}>
                <Typography variant="h5" className={classes.title}>You must verify your Account to see this page</Typography>
                <Button variant="contained" onClick={sendVerification}>Send Verification Email</Button>
            </Paper>
            <Snackbar open={!!alert} autoHideDuration={2000} onClose={() => setAlert('')}>
                <Alert onClose={() => setAlert('')} severity="success">{alert}</Alert>
            </Snackbar>
        </Container>
    )
}
import React, { useEffect, useState } from 'react';
import GenericMap from 'Components/Map';
import { Grid, Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/styles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
    map: {
        height: '500px',
        [theme.breakpoints.down("sm")]: {
            height: 'calc(100%)'
        }
    }
}))

export default function Map({ device }) {
    const classes = useStyles()
    const theme = useTheme()
    const [reset, setReset] = useState(0)
    const viewport = useSelector(state => state.viewport)
    const location = device.device.deviceLocation

    useEffect(() => {
        setReset(reset + 1)
        // eslint-disable-next-line
    }, [device.device.deviceLocation])

    const style = {}
    if (viewport.width >= theme.breakpoints.values.md) {
        style.height = viewport.height - 400
    }

    return (<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.map} style={style}>
        {Boolean(location) && <GenericMap devices={[device]} ids={[device.id]} resetCenter={reset} />}
        {!Boolean(location) && <Typography variant="h6" align="center">No Locations Sent</Typography>}
    </Grid>)
}
import { Card, CardContent, Grid, IconButton, Tooltip, Fab, Drawer, Typography, TextField, Button, InputAdornment } from '@material-ui/core'
import { Toc, Add, Event, SearchRounded } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Calendar from './calendar'
import Table from './table'
import { setView } from 'store/actions'
import moment from 'moment'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { makeStyles } from '@material-ui/styles'
import { useHistory } from 'react-router'
import { get, post, destroy } from 'utils/resources'
import Confirm from 'Components/Confirm'
import Alert from 'Components/Alert'
import EventCard from './eventCard';
import { Fragment } from 'react'


const myStyle = makeStyles(theme => ({
    container: {
        [theme.breakpoints.down("sm")]: {
            display: 'none'
        }
    },
    mobile: {
        display: 'none',
        [theme.breakpoints.down("sm")]: {
            display: 'block',
            position: 'fixed',
            height: 'calc(100% - 68px)',
            width: '100%',
            overflowX: 'hidden',
            overflowY: 'auto'
        }
    }
}))

export default function Events() {
    const [events, setEvents] = useState([])
    const [addingEvent, setAddingEvent] = useState(false)
    const [newEvent, setNewEvent] = useState({ startDate: moment(), name: '' })
    const [deletingEvent, setDeletingEvent] = useState(null)
    const [alert, setAlert] = useState(null)
    const [searchText, setSearchText] = useState('')
    const views = useSelector(state => state.views)
    const viewport = useSelector(state => state.viewport)
    const account = useSelector(state => state.account)
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        // get events
        get('event/{accountid}').then(setEvents)
        // setEvents(getAll())
    }, [])

    const createEvent = async (e) => {
        e.preventDefault()
        try {
            const event = await post('event/{accountid}', { ...newEvent, organiser: account.name })
            // post to create then go to the edit page
            history.push('/events/' + event.id + '/edit')
            setAddingEvent(false)
        } catch (e) {
            console.log(e)
        }
    }

    const addEvent = (date) => {
        setNewEvent({ startDate: date || moment(), endDate: date || moment(), name: '', allDay: true })
        setAddingEvent(true)
    }

    const removeEvent = (event) => {
        setDeletingEvent(event)
    }

    const onDeleteConfirm = async () => {
        await destroy('event/{accountid}/' + deletingEvent.id)
        setDeletingEvent(null)
        setAlert('Event Deleted')
        setEvents(await get('event/{accountid}'))
    }

    const view = views.events || 'calendar'
    const classes = myStyle()

    const filteredEvents = events.filter(e => !searchText ? true : e.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1)

    return (
        <Fragment>
            <div className={classes.mobile}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div style={{ padding: 5 }}>
                            <TextField value={searchText} onChange={e => setSearchText(e.target.value)} fullWidth label="Search Events" InputProps={{
                                endAdornment: (<InputAdornment position="end">
                                    <SearchRounded />
                                </InputAdornment>)
                            }} />
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'right', paddingRight: 10 }}>
                        <Fab color="primary" size="small" onClick={() => addEvent()}>
                            <Add />
                        </Fab>
                    </Grid>

                    {filteredEvents.map(event => <Grid key={event.id} item xs={12}>
                        <EventCard event={event} remove={removeEvent} />
                    </Grid>)}

                    {!filteredEvents.length && <Grid item xs={12}>
                        <Typography variant="h5" align="center">
                            No Events Found
                        </Typography>
                    </Grid>}
                </Grid>

            </div>
            <Card className={classes.container}>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div style={{ padding: 5, marginBottom: 10 }}>
                                <TextField value={searchText} onChange={e => setSearchText(e.target.value)} fullWidth label="Search Events" InputProps={{
                                    endAdornment: (<InputAdornment position="end">
                                        <SearchRounded />
                                    </InputAdornment>)
                                }}
                                />
                            </div>
                        </Grid>
                        <Grid item sm={6}>
                            <Grid container direction="row" justify="flex-end" alignItems="flex-end">
                                {viewport.width >= 768 && <Tooltip title="Calendar">
                                    <IconButton color={view === 'calendar' ? 'primary' : 'default'} onClick={() => dispatch(setView('events', 'calendar'))} ><Event /></IconButton >
                                </Tooltip>}
                                {viewport.width >= 768 && <Tooltip title="Table">
                                    <IconButton color={view === 'table' ? 'primary' : 'default'} onClick={() => dispatch(setView('events', 'table'))}><Toc /></IconButton >
                                </Tooltip>}
                                <Tooltip title="Add" aria-label="add" >
                                    <Fab color="primary" size="small" onClick={() => addEvent()}>
                                        <Add />
                                    </Fab>
                                </Tooltip>
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            {view === 'calendar' && <Calendar events={filteredEvents} addEvent={addEvent} />}
                            {view === 'table' && <Table events={filteredEvents} remove={removeEvent} />}
                        </Grid>
                    </Grid>
                </CardContent>
                <Drawer open={Boolean(addingEvent)} anchor="right">
                    <form style={{ width: 300, padding: 20 }} onSubmit={createEvent}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <Typography style={{ marginBottom: 10 }} variant="h6" align="center">Create Event</Typography>

                            <TextField required style={{ marginBottom: 10 }} fullWidth label="Event Name" value={newEvent.name} onChange={e => setNewEvent({ ...newEvent, name: e.target.value })} />
                            <KeyboardDatePicker required fullWidth variant="inline" style={{ marginBottom: 10 }} format="YYYY-MM-DD" margin="normal" label="Event Date" value={newEvent.startDate} onChange={d => setNewEvent({ ...newEvent, startDate: d })} />

                            <Button style={{ width: 125, marginRight: 10 }} variant="contained" color="primary" type="submit">Save</Button>
                            <Button style={{ width: 125 }} variant="contained" onClick={() => setAddingEvent(false)}>Cancel</Button>
                        </MuiPickersUtilsProvider>
                    </form>
                </Drawer>
                <Confirm open={Boolean(deletingEvent)} onCancel={() => setDeletingEvent(null)} header="Delete Event?" subheader={`Are you sure you want to delete ${deletingEvent ? deletingEvent.name : ''}?`} onConfirm={onDeleteConfirm} />
                <Alert alert={alert} setAlert={setAlert} />
            </Card>
        </Fragment>
    )
}
import { Typography, Button, makeStyles } from '@material-ui/core';
import React, { useState, Fragment } from 'react';
import { useSelector } from 'react-redux'
import { destroy, put } from 'utils/resources';

const useStyles = makeStyles(theme => ({
    card: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(1)
    },
    full: {
        flexGrow: 1,
        margin: 'auto 0'
    },
    button: {
        marginRight: theme.spacing(1)
    }
}))

export default function SavedCards({ refresh, cards }) {
    const classes = useStyles()
    const billing = useSelector(state => state.billing)
    const [settingDefault, setSettingDefault] = useState(false)

    const removeCard = async function (card) {
        await destroy('billing/{accountid}/card?PaymentMethod=' + card.id)
        refresh()
    }

    const setDefault = async function (card) {
        if (settingDefault) return
        setSettingDefault(true)
        await put('billing/{accountid}/card', {
            paymentMethod: card.id,
            isDefault: true
        })
        setSettingDefault(false)
        refresh()
    }

    const defaultCard = billing.stripeCustomer.invoiceSettings.defaultPaymentMethodId

    return (
        <div>
            {cards.map(card => <div key={card.id} className={classes.card}>
                <Typography variant="h4" color="primary">
                    <div className={"fab fa-cc-" + card.card.brand} />
                </Typography>
                <div className={classes.full}>
                    <Typography className="mar-r10">XXXX-XXXX-XXXX-{card.card.last4}  - expires {card.card.expMonth}/{card.card.expYear}</Typography>
                </div>
                {card.id !== defaultCard && <Fragment>
                    <Button className={classes.button} variant="contained" color="primary" size="small" onClick={() => setDefault(card)} disabled={settingDefault}>Set Default</Button>
                    <Button variant="contained" size="small" color="secondary" onClick={() => removeCard(card)} disabled={settingDefault}>Remove</Button>
                </Fragment>}
                {card.id === defaultCard && <Fragment>
                    <Typography className={classes.button}>DEFAULT</Typography>
                </Fragment>}
            </div>)}
        </div>
    )
}
import React, { Fragment, useState, useEffect } from 'react'
import { Grid, Table, TableRow, TableHead, TableCell, TableBody, Typography, Button, Drawer, Container, TextField, makeStyles, List, ListItem, ListItemSecondaryAction, ListItemText, IconButton, Checkbox, FormControlLabel, Switch } from '@material-ui/core'
import { formatDate } from 'utils/format'
import { post, destroy, get } from 'utils/resources'
import { Delete, Send } from '@material-ui/icons'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import LoadingIcon from 'Components/LoadingIcon'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import copy from 'copy-to-clipboard';

const mytheme = makeStyles(theme => ({
    container: {
        padding: theme.spacing(4),
        width: 400,
        height: '100%'
    },
    sharingContainer: {
        height: '100%'
    },
    textField: {
        marginBottom: theme.spacing(3)
    },
    button: {
        margin: theme.spacing(1)
    },
    table: {
        [theme.breakpoints.down("sm")]: {
            display: 'none'
        }
    },
    mobile: {
        display: 'none',
        [theme.breakpoints.down("sm")]: {
            display: 'block'
        }
    },
    link: {

    }
}))

export default function Sharing({ device, getDevice, setAlert }) {
    const classes = mytheme()
    const [sharing, setSharing] = useState(false)
    const [shareRange, setShareRange] = useState(false)
    const [share, setShare] = useState({ email: '', startDate: null, endDate: null })
    const [errors, setErrors] = useState({ startDate: null, endDate: null })
    const [isPublic, setIsPublic] = useState(false)
    const [publicShares, setPublicShares] = useState([])
    const [loading, setLoading] = useState(true)

    const setShareVal = function (e) {
        setShare({ ...share, [e.target.name]: e.target.value })
    }

    const cancelSharing = function () {
        setSharing(false)
        setErrors({ startDate: '', endDate: '' })
        setShare({ email: '', startDate: null, endDate: null })
        setShareRange(false)
    }

    const sendShare = async function (e) {
        e.preventDefault()
        if (shareRange) {
            let errs = {}
            if (!share.startDate) errs.startDate = 'Required'
            if (!share.endDate) errs.endDate = 'Required'
            setErrors(errs)
            if (Object.keys(errs).length) return
        }
        if (isPublic) {
            let query = `devicelocation/{accountid}/${device.id}/share?`
            if (shareRange) {
                query += `startDate=${share.startDate.startOf('day').format('YYYY-MM-DD')}&endDate=${share.endDate.endOf('day').format('YYYY-MM-DD')}`
            }
            console.log(query)
            const result = await post(query)
            copy(window.location.origin + '/pmap?st=' + result.token + '&d=' + device.id)
            setAlert('Link Added to Clipboard')
            await getPublic()
        } else {
            const body = {
                registeredDeviceId: device.id,
                email: share.email
            }
            if (shareRange) {
                body.startDate = share.startDate
                body.endDate = share.endDate
            }
            await post('share', body)
            setAlert('Invite Sent')
        }
        cancelSharing()
        setShare({ email: '', startDate: null, endDate: null })
        setShareRange(false)
        getDevice()
    }

    const cancelShare = async (share) => {
        await destroy('share/{accountid}/' + share.id)
        setAlert('Share Removed')
        getDevice()
    }

    const deletePublicShare = async (share) => {
        await destroy(`devicelocation/{accountid}/${device.id}/share/${share.id}`)
        setAlert('Share Removed')
        getPublic()
    }

    const resendShare = async (share) => {
        await post('share/{accountid}/resend/' + share.id)
        setAlert('Invite Resent')
        getDevice()
    }

    const copyLink = () => {
        setAlert('Link Copied')
    }

    const getPublic = async () => {
        const results = await get(`devicelocation/{accountid}/${device.id}/share`)
        setPublicShares(results.filter(r => !r.deleted))
        setLoading(false)
    }

    useEffect(() => {
        getPublic()
        // eslint-disable-next-line
    }, [])

    if (loading) return <LoadingIcon />

    const shares = device.registeredDeviceOptions.sharing.filter(s => !s.deleted).slice().sort((a, b) => new Date(b.created) - new Date(a.created))

    return (
        <Grid item xs={12} sm={10} md={10} lg={8} xl={6} className={classes.sharingContainer}>
            <div align="center">
                <Button variant="contained" onClick={() => setSharing(true)} color="primary">Share</Button>
            </div>
            {!shares.length && !publicShares.length && <Fragment>
                <Typography style={{ marginTop: 20 }} variant="h5" align="center">Device Not Shared</Typography>
            </Fragment>}
            {(Boolean(shares.length) || Boolean(publicShares.length)) && <Fragment>
                {Boolean(shares.length) && <Fragment>
                    <Typography variant="h5">Private Sharing</Typography>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Email</TableCell>
                                <TableCell>Date Range</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {shares.map(row => <TableRow key={row.id}>
                                <TableCell>{row.email}</TableCell>
                                <TableCell>{row.startDate !== '0001-01-01T00:00:00' ? formatDate(row.startDate, null, true) + ' to ' + formatDate(row.endDate, null, true) : 'No Limit'}</TableCell>
                                <TableCell>{row.deleted ? 'Deleted' : row.accepted ? 'Accepted' : 'Pending'}</TableCell>
                                <TableCell align="right">
                                    {Boolean(row.accepted) && !Boolean(row.deleted) && <Button size="small" variant="contained" onClick={() => cancelShare(row)}>Revoke</Button>}
                                    {!Boolean(row.accepted) && !Boolean(row.deleted) && <Button style={{ marginRight: '5px' }} size="small" variant="contained" color="secondary" onClick={() => resendShare(row)}>Resend</Button>}
                                    {!Boolean(row.accepted) && !Boolean(row.deleted) && <Button size="small" variant="contained" onClick={() => cancelShare(row)}>Cancel</Button>}
                                </TableCell>
                            </TableRow>)}
                        </TableBody>
                    </Table>

                    <List className={classes.mobile}>
                        {shares.map(row => <ListItem key={row.id}>
                            <ListItemText primary={row.email} secondary={row.deleted ? 'Deleted' : row.accepted ? 'Accepted' : 'Pending'} />
                            <ListItemSecondaryAction>
                                {!Boolean(row.accepted) && !Boolean(row.deleted) && <IconButton edge="end" onClick={() => resendShare(row)}>
                                    <Send />
                                </IconButton>}
                                <IconButton edge="end" onClick={() => cancelShare(row)}>
                                    <Delete />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>)}
                    </List>
                </Fragment>}

                {Boolean(publicShares.length) && <Fragment>
                    <Typography variant="h5" style={{ marginTop: 20 }}>Public Sharing</Typography>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Link</TableCell>
                                <TableCell>Date Range</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {publicShares.map(row => <TableRow key={row.id}>
                                <TableCell>
                                    <CopyToClipboard text={window.location.origin + '/pmap?st=' + row.token + '&d=' + row.registeredDeviceId} onCopy={copyLink}>
                                        <Button size="small" variant="contained" color="primary">Copy Link</Button>
                                    </CopyToClipboard>
                                </TableCell>
                                <TableCell>{row.startDate ? (formatDate(row.startDate, null, true) + ' to ' + formatDate(row.endDate, null, true)) : 'No Limit'}</TableCell>
                                <TableCell align="right">
                                    <Button size="small" variant="contained" onClick={() => deletePublicShare(row)}>Delete</Button>
                                </TableCell>
                            </TableRow>)}
                        </TableBody>
                    </Table>

                    <List className={classes.mobile}>
                        {publicShares.map(row => <ListItem key={row.id}>
                            <CopyToClipboard text={window.location.origin + '/pmap?st=' + row.token + '&d=' + row.registeredDeviceId} onCopy={copyLink}>
                                <Button variant="contained" color="primary">Copy Link</Button>
                            </CopyToClipboard>
                            <ListItemSecondaryAction>
                                <IconButton edge="end" onClick={() => deletePublicShare(row)}>
                                    <Delete />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>)}
                    </List>
                </Fragment>}

            </Fragment>}

            <Drawer open={sharing} anchor={'right'} onClose={cancelSharing}>
                <Container className={classes.container}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <form onSubmit={sendShare}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="h6" align="center">Share Device</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<Switch checked={isPublic} onChange={() => setIsPublic(p => !p)} />}
                                        label="Public"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {!isPublic && <TextField required className={classes.textField} fullWidth type="email" label="Email Address" name="email" onChange={setShareVal} value={share.email} />}
                                    {isPublic && <Typography style={{ marginTop: 15, marginBottom: 10 }}>Anyone with the link can view the location of this device</Typography>}
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel label="Limit By Date" control={<Checkbox checked={shareRange} onChange={e => setShareRange(e.target.checked)} />} />
                                </Grid>
                            </Grid>
                            {shareRange && <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <DatePicker errorText={errors.startDate} required fullWidth label="Start Date" value={share.startDate} format="YYYY-MM-DD" variant="inline" disableToolbar autoOk onChange={d => setShare({ ...share, startDate: d })} />
                                </Grid>
                                <Grid item xs={6}>
                                    <DatePicker errorText={errors.endDate} required fullWidth label="End Date" value={share.endDate} format="YYYY-MM-DD" variant="inline" disableToolbar autoOk onChange={d => setShare({ ...share, endDate: d })} />
                                </Grid>
                            </Grid>}
                            <Grid container justify="center" spacing={2}>
                                <Button className={classes.button} variant="contained" type="button" onClick={cancelSharing}>Cancel</Button>
                                <Button className={classes.button} variant="contained" color="primary" type="submit">{isPublic ? 'Get Link' : 'Share'}</Button>
                            </Grid>
                        </form>
                    </MuiPickersUtilsProvider>
                </Container>
            </Drawer>
        </Grid >
    )
}
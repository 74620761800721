import React, { Fragment, useEffect, useState } from 'react';
import { Switch, Route, useHistory } from "react-router-dom";
import queryString from 'query-string'
import { CssBaseline } from '@material-ui/core';

import Login from './views/login';
import Invite from './views/login/invite';
import Verify from './views/login/verifty'
import Reset from './views/login/reset';
import Main from './main';
import AcceptDevice from './views/login/acceptDevice'
import NotFound from './views/notfound'
import Event from './views/event'
import PublicMap from './views/publicmap'

import authUtils from './utils/auth'
import i18n from './utils/i18n';
import LoadingIcon from 'Components/LoadingIcon';


export default function App() {
  const [loaded, setLoaded] = useState(false)
  const [languageLoaded, setLanguageLoaded] = useState(false)
  const history = useHistory()

  useEffect(() => {
    const auth = authUtils.auth()
    const query = queryString.parse(window.location.search)
    if (auth && auth.id) {
      // authUtils.handleExpiration(auth.jwtToken)
      authUtils.checkAuth().then(result => {
        setLoaded(true)
        if (!result || !result.id) {
          if (query.redirect) history.push('/login?redirect=' + query.redirect)
          else {
            if (window.location.pathname !== '/') history.push('/login?redirect=' + window.location.pathname)
            else history.push('/login')
          }
        }
      })
    } else {
      setLoaded(true)
      if (query.redirect) history.push('/login?redirect=' + query.redirect)
      else {
        let path = window.location.pathname
        if (['/login', '/', '/register', '/invite', '/auth/verify-email', '/share/invite', '/auth/reset-password', '/event/', '/pmap', '/admin'].indexOf(path) === -1) {
          if (path.indexOf('/event/') !== 0) {
            history.push('/login?redirect=' + window.location.pathname)
          }
        }
        else if (!path || path === '/') {
          if (query.a) {
            history.push('/login?redirect=/?a=' + query.a)
          } else {
            history.push('/login')
          }
        }
      }
    }

    i18n.on('initialized', () => setLanguageLoaded(true))
    // eslint-disable-next-line
  }, [])

  if (!loaded || !languageLoaded) {
    return <LoadingIcon />
  }

  return (
    <Fragment>
      <CssBaseline />
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/register">
          <Login />
        </Route>
        <Route path="/invite">
          <Invite />
        </Route>
        <Route path="/share/invite">
          <AcceptDevice />
        </Route>
        <Route path="/auth/verify-email">
          <Verify />
        </Route>
        <Route path="/auth/reset-password">
          <Reset />
        </Route>
        <Route path="/notfound">
          <NotFound />
        </Route>
        <Route path="/event/:id">
          <Event />
        </Route>
        <Route path="/pmap">
          <PublicMap />
        </Route>
        <Route path="/">
          <Main />
        </Route>
      </Switch>
    </Fragment>
  );
}
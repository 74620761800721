import React, { Fragment, useState } from 'react';
import GenericMap from 'Components/Map'
import { Grid, Card, Typography, Tooltip, Avatar, CardActionArea, Button, CardActions, Badge, IconButton } from '@material-ui/core';
import { Battery20, Battery30, Battery50, Battery60, Battery80, Battery90, BatteryAlert, BatteryFull, Sms } from '@material-ui/icons'
import clsx from 'clsx';
import { formatDate } from 'utils/format';
import moment from 'moment';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { post } from 'utils/resources';
import Alert from 'Components/Alert';

const useStyles = makeStyles(theme => ({
    nameHolder: {
        display: 'flex'
    },
    badge: {
        marginRight: theme.spacing(2),
        top: 5,
        marginBottom: 10
    },
    avatar: {
        marginTop: theme.spacing(0.5),
        marginRight: theme.spacing(2)
    },
    card: {
        [theme.breakpoints.down("sm")]: {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            marginBottom: theme.spacing(2)
        }
    },
    content: {
        position: 'relative',
        '&:hover': {
            '& $sosInfo': {
                bottom: 0
            }
        }
    },
    sos: {
        animation: '$bgsos 1.5s infinite'
    },
    '@keyframes bgsos': {
        '0%': { backgroundColor: theme.palette.background.paper },
        '50%': { backgroundColor: theme.palette.error.main },
        '100%': { backgroundColor: theme.palette.background.paper }
    },
    sosInfo: {
        textAlign: 'center',
        zIndex: 99999,
        position: 'absolute',
        bottom: -50,
        width: '100%',
        transition: 'bottom 0.3s ease',
        '& button': {
            margin: '0 auto'
        },
        [theme.breakpoints.down("sm")]: {
            bottom: 0,
            position: 'initial'
        }
    }
}))

const StyledBadge = withStyles((theme) => ({
    badge: {
        backgroundColor: theme.palette.grey['100'],
        color: theme.palette.grey['100'],
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: '$ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
        '&.MuiBadge-colorPrimary': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.main
        },
        '&.MuiBadge-colorError': {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.error.main
        }
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    }
}))(Badge);

export default function DeviceCard({ device, goTo, acknowledge = () => { } }) {
    const [alert, setAlert] = useState('')

    const classes = useStyles()
    const location = device.device.deviceLocation
    const battery = device.device.battery
    const isSOS = device.device.sos

    const onClick = function () {
        goTo(device.id + '?page=map')
    }

    const sms = async (e) => {
        e.preventDefault()
        e.stopPropagation()
        await post('device/{accountid}/requestlocation')
        setAlert('Location Request Sent')
    }

    const active = device.device.lastOnline && moment(device.device.lastOnline) > moment().startOf('day')
    const updateInterval = device.device.deviceSettings.updateInterval
    let stale = false
    if (active) {
        stale = moment.utc().subtract(updateInterval * 2, 'seconds') > moment.utc(device.device.lastOnline)
    }

    const props = {}
    if (isSOS) {
        props.color = 'error'
    } else if (active && !stale) {
        props.color = 'primary'
    }

    return (
        <Grid item xs={12} sm={6} md={4} lg={3} className={classes.card}>
            <Card elevation={3} className={clsx(isSOS ? classes.sos : '', classes.content)}>
                <CardActionArea onClick={() => !device.device.allowed ? {} : goTo(device.id + (isSOS ? '?page=notifications' : ''))}>
                    <div style={{ padding: '10px' }}>
                        <Grid container>
                            <Grid item style={{ flexGrow: 1 }}>
                                <div className={classes.nameHolder}>
                                    <Tooltip title={"imei " + device.device.imei} placement="top">
                                        <Fragment>
                                            {active && <StyledBadge className={classes.badge} overlap="circle" anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} variant="dot" {...props}>
                                                <Avatar src={device.registeredDeviceOptions.deviceAvatar} style={{ backgroundColor: device.registeredDeviceOptions.colour }}>{device.name[0]}</Avatar>
                                            </StyledBadge>}
                                            {!active && <Avatar className={classes.avatar} src={device.registeredDeviceOptions.deviceAvatar} style={{ backgroundColor: device.registeredDeviceOptions.colour }}>{device.name[0]}</Avatar>}
                                        </Fragment>
                                    </Tooltip>
                                    <div>
                                        <Typography variant="h6">{device.name}</Typography>
                                        <Typography variant="subtitle2">{formatDate(device.device.lastOnline)}{stale ? ' (stale)' : ''}</Typography>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item>
                                <Tooltip title={battery + '%'} placement="top" >
                                    <div>
                                        {battery < 10 && <BatteryAlert color="error" />}
                                        {battery >= 10 && battery < 20 && <Battery20 color="action" />}
                                        {battery >= 20 && battery < 30 && <Battery30 color="primary" />}
                                        {battery >= 30 && battery < 50 && <Battery50 color="primary" />}
                                        {battery >= 50 && battery < 60 && <Battery60 color="primary" />}
                                        {battery >= 60 && battery < 80 && <Battery80 color="primary" />}
                                        {battery >= 80 && battery < 90 && <Battery90 color="primary" />}
                                        {battery >= 90 && <BatteryFull color="primary" />}
                                    </div>
                                </Tooltip>
                                {stale && false && <Tooltip title="SMS Location" aria-label="add" >
                                    <IconButton size="small" onClick={sms}>
                                        <Sms />
                                    </IconButton>
                                </Tooltip>}
                            </Grid>
                        </Grid>
                    </div>

                </CardActionArea>
                <div style={{ height: '160px' }}>
                    {Boolean(location) && <GenericMap
                        id={device.id}
                        goTo={onClick}
                        ids={[device.id]}
                        devices={[device]}
                        hideOptions={true}
                        hideZoom={true}
                        fixed={true}
                        osgb={true}
                        defaultShowMe={false}
                    />}
                    {!Boolean(location) && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <Typography variant="h6" align="center">No Location Sent</Typography>
                    </div>}
                </div>
                {isSOS && <CardActions className={classes.sosInfo}>
                    <Button variant="contained" color="primary" onClick={() => acknowledge(device.id)}>Clear SOS</Button>
                </CardActions>}
            </Card>
            <Alert alert={alert} setAlert={setAlert} />
        </Grid>
    );
}
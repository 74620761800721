import React, { Fragment, useEffect, useState, useCallback, useRef } from 'react';
import BingMap from './react-bingmaps'
import LeafletMap from './leaflet'
import { Tooltip, Paper, Avatar, List, ListItem, ListItemText, Typography, Container, ListItemSecondaryAction, Checkbox, Divider, ListItemAvatar, Fab, IconButton, SwipeableDrawer, Menu, Badge, MenuItem, TextField, Grid, Button, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';
import AdjustIcon from '@material-ui/icons/Adjust';
import TimelineIcon from '@material-ui/icons/Timeline';
import MapIcon from '@material-ui/icons/Map';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import SettingsCellIcon from '@material-ui/icons/SettingsCell'
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles, useTheme, withStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { formatDate, locationToOsgb } from 'utils/format'
import { get } from 'utils/resources';
import { lookupPostcode } from 'utils/postcode';
import { ExpandMore, FilterCenterFocus } from '@material-ui/icons';
import clsx from 'clsx';
import moment from 'moment'
import GeofenceDrawer from './geofence'

let Microsoft = window.Microsoft;

const drawerWidth = 55;
const styles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    options: {
        position: 'absolute',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: 5,
        zIndex: 999,
        '& button': {
            margin: '0 2px'
        }
    },
    optionsMenu: {
        position: 'absolute',
        top: 55,
        right: 10,
        zIndex: 999
    },
    badge: {
        marginRight: theme.spacing(2),
        top: 5,
        marginBottom: 10
    },
    disabled: {
        cursor: 'default',
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            backgroundColor: 'grey',
            content: '""',
            opacity: '0.8'
        }
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        position: 'absolute',
        top: 0,
        opacity: 0.8, //this causes glitches on leaflet js on mobile
        zIndex: 999,
        transition: theme.transitions.create('opacity', { duration: theme.transitions.duration.standard }),
        [theme.breakpoints.down("sm")]: {
            width: 60
        },
        '&:hover': {
            opacity: 1,
        },
    },
    left: {
        left: 0
    },
    right: {
        right: 0
    },
    list: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
        borderTom: "1px solid rgba(0, 0, 0, 0.12)"
    },
    listItemIconSelected: {
        color: "green",
        [theme.breakpoints.down("sm")]: {
            fontSize: 30
        }
    },
    listItemIcon: {
        color: "red",
        [theme.breakpoints.down("sm")]: {
            fontSize: 30
        },
        minWidth: 'auto'
    },
    drawerPaper: {
        width: drawerWidth,
        top: 170,
        height: 'auto',
        background: 'none',
        marginRight: 35,
        marginTop: 5,
        [theme.breakpoints.down("sm")]: {
            width: 60
        }
    },
    map: {
        height: '100%'
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    },
    drawerContainer: {
        width: 320
    },
    drawerContent: {
        padding: theme.spacing(3)
    },
    avatar: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            backgroundColor: 'grey',
            content: '""',
            opacity: '0'
        }
    },
    avatarDisabled: {
        '&::after': {
            opacity: '0.5'
        }
    },
    zoom: {
        bottom: 10,
        height: 60,
        left: 'calc(50% - 49px)',
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        backgroundColor: 'transparent',
        zIndex: 999
    },
    smallZoom: {
        bottom: 6,
        left: 'calc(50% - 42px)',
    },
    fab: {
        marginRight: 1,
        marginLeft: 1
    },
    zoomIcons: {
        fontSize: 30
    },
    smallZoomIcons: {
        fontSize: 24
    },
    leaflet: {
        position: 'absolute'
    },
    hide: {
        opacity: 0
    },
    bottomDrawer: {
        display: 'none',
        [theme.breakpoints.down("sm")]: {
            display: 'block',
        },
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        width: '100%',
        zIndex: 1199,
        bottom: -20,
        transition: theme.transitions.create('bottom', { duration: theme.transitions.duration.standard }),
    },
    bottomDrawerOpen: {
        bottom: 0
    },
    target: {
        display: 'none',
        [theme.breakpoints.down("sm")]: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        position: 'absolute',
        top: 'calc(50% - 15px)',
        left: 'calc(50% - 15px)',
        color: theme.palette.info.main,
        zIndex: 1199,
        opacity: 0,
        transition: theme.transitions.create('opacity', { duration: theme.transitions.duration.standard }),
    },
    targetOpen: {
        opacity: 1,
    }
}));

const StyledBadge = withStyles((theme) => ({
    badge: {
        backgroundColor: theme.palette.grey['100'],
        color: theme.palette.grey['100'],
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: '$ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
        '&.MuiBadge-colorPrimary': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.main
        },
        '&.MuiBadge-colorError': {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.error.main
        }
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}))(Badge);

function getFromLs(name) {
    try {
        let item = localStorage.getItem(name)
        if (item) {
            item = JSON.parse(item)
            return item
        }
    } catch (e) {

    }
    return null
}

export default function GenericMap({
    id, follow = true, osgb = false, polyline = [], defaultLocation = null, pushpins = null, rawPushpin = false, smallZoom = false, fences = [],
    hideOptions = false, events = [], devices = [], groups = null, ids = [], hideZoom = false, resetCenter = 0, defaultShowMe = true, fixed = false, defaultGoToMe = false,
    returnMap = () => { }, returnBing = () => { }, goTo = () => { }, onContextMenu = () => { }, onClick = () => { }, markerClick = () => { }, toggleDevice = () => { }, selectGroup = () => { }
}) {
    const user = useSelector(store => store.user)

    const [options, setOptions] = useState({
        polyline: true,
        markers: true,
        circle: true,
        mapType: user && user.userSettings ? user.userSettings.defaultMapType : 0,
        me: !defaultShowMe ? false : true
    })

    const [showDevices, setShowDevices] = useState(false)
    const [zoom, setZoom] = useState(13)
    const [center, setCenter] = useState(defaultLocation)
    const [map, setMap] = useState(null)
    const [bing, setBing] = useState(null)
    const [routes, setRoutes] = useState(null)
    const [showRoutes, setShowRoutes] = useState(false)
    const [selectedRoutes, setSelectedRoutes] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null);
    const [search, setSearch] = useState(false)
    const [postCode, setPostCode] = useState('')
    const [shownEvents, setShownEvents] = useState(null)
    const [showName, setShowName] = useState(false)
    const [centerOsgb, setCenterOsgb] = useState({ result: '', show: false })
    const [showGeofences, setShowGeofences] = useState(false)
    const [allFences, setAllFences] = useState([])
    const [shownFences, setShownFences] = useState([])

    const viewport = useSelector(store => store.viewport)
    const location = useSelector(store => store.location)
    const osgbTimer = useRef()
    const searchInput = useRef()
    const theme = useTheme()

    const classes = styles()

    // eslint-disable-next-line
    const handleChangeCenter = useCallback(() => {
        if (!follow) return
        if (ids && ids.length) {
            if (!options.mapType && map && (ids.length > 1 || options.me)) {
                const centers = pushpins ? pushpins.map(p => p.location) : ids.map(id => {
                    const device = devices.find(d => d.id === id)
                    return [device.device.deviceLocation.latitude, device.device.deviceLocation.longitude]
                })
                if (location && location.coords && options.me) {
                    centers.push([location.coords.latitude, location.coords.longitude])
                }
                map.fitBounds(centers)
            } else if (!pushpins) {
                const device = devices.find(d => d.id === ids[0])
                changeCenter([device.device.deviceLocation.latitude, device.device.deviceLocation.longitude])
            }
        } else if (location && options.me && location.coords) {
            changeCenter([location.coords.latitude, location.coords.longitude])
        } else if (location && defaultGoToMe && location.coords) {
            changeCenter([location.coords.latitude, location.coords.longitude])
        } else if (pushpins && pushpins.length) {
            if (!options.mapType && map && pushpins.length > 1) {
                map.fitBounds(pushpins.map(p => p.location))
            } else {
                changeCenter(pushpins.map(p => p.location)[0])
            }
        } else {
            changeCenter(defaultLocation || [53.6231179, -2.1639978])
        }
    })

    // eslint-disable-next-line
    const changeCenter = mycenter => {
        if (search) setSearch(false)
        if (postCode) setPostCode('')
        if (!options.mapType) {
            if (map) map.setView(mycenter)
            else setCenter(mycenter)
        } else if (options.mapType) {
            if (bing && Microsoft) bing.setView({
                center: new Microsoft.Maps.Location(mycenter[0], mycenter[1])
            })
            else setCenter(mycenter)
        }
    }

    useEffect(() => {
        if (user && user.userSettings) {
            get('route/{accountid}').then(results => {
                setRoutes(results)
            })
            get('geofence').then(data => setAllFences(data.filter(f => f.geofenceType)))
        }
    }, [user])

    useEffect(() => {
        if (events.length && routes) {
            const lsEvents = getFromLs('meili-map-events')
            if (lsEvents) setShownEvents(lsEvents)
            const lsRoutes = getFromLs('meili-map-routes')
            if (lsRoutes) setSelectedRoutes(lsRoutes)
        }
    }, [events, routes])

    useEffect(handleChangeCenter, [pushpins, options.me])

    useEffect(handleChangeCenter, [defaultGoToMe])

    useEffect(() => {
        handleChangeCenter()
        // eslint-disable-next-line
    }, [resetCenter])

    useEffect(() => {
        if (viewport.width <= 960) {
            setShowName(true)
        } else {
            setShowName(false)
        }
    }, [viewport])

    useEffect(() => {
        handleChangeCenter()
        // eslint-disable-next-line
    }, [map])

    useEffect(() => {
        if (!Microsoft && window.Microsoft) Microsoft = window.Microsoft
        if (bing && center && center.length === 2 && center[0] !== undefined) {
            bing.setView({
                center: new Microsoft.Maps.Location(center[0], center[1]),
                zoom: zoom
            })
        }
        // eslint-disable-next-line
    }, [bing])

    useEffect(() => {
        if (searchInput.current) searchInput.current.focus()
        // eslint-disable-next-line
    }, [searchInput.current])

    useEffect(() => {
        if (selectedRoutes) {
            localStorage.setItem('meili-map-routes', JSON.stringify(selectedRoutes))
        }
    }, [selectedRoutes])

    useEffect(() => {
        if (shownEvents) {
            localStorage.setItem('meili-map-events', JSON.stringify(shownEvents))
        }
    }, [shownEvents])

    const showSearch = () => {
        setSearch(true)
    }

    const mapCreated = (map) => {
        if (map._v8Map) {
            setBing(map)
            returnBing(map)
        } else {
            if (map && fixed) map.dragging.disable()
            setMap(map)
            returnMap(map)
        }
    }

    const toggleOption = function (prop) {
        setOptions({ ...options, [prop]: !options[prop] })
    }

    const setMapType = function (type) {
        if (options.mapType && bing && !type) {
            const _center = bing.getCenter()
            setCenter([_center.latitude, _center.longitude])
            if (map) map.setView([_center.latitude, _center.longitude], zoom)
        } else if (!options.mapType && map && type) {
            const _center = map.getCenter()
            setCenter([_center.lat, _center.lng])
            if (bing) {
                bing.setView({
                    center: new Microsoft.Maps.Location(_center.lat, _center.lng)
                })
            }
        }

        if (type === 1 && zoom < 12) {
            // set the zoom to 12 as anything beyond that doesnt show 
            setZoom(12)
        }

        setOptions({ ...options, mapType: type })
        setAnchorEl(null)
    }

    const toggleDevices = () => {
        setShowDevices(true)
    }

    const onSelectGroup = function (group) {
        setShowDevices(false)
        selectGroup(group)
    }

    const onSelectEvent = async function (event) {
        const arr = [...(shownEvents || [])]
        const _selectedRoutes = [...(selectedRoutes || [])]
        if (arr.some(e => e.id === event.id)) {
            // remove the route
            const index = shownEvents.findIndex(e => e.id === event.id)
            arr.splice(index, 1)
            if (event.routeId) {
                const rIndex = _selectedRoutes.indexOf(event.routeId)
                if (rIndex > -1) {
                    _selectedRoutes.splice(rIndex, 1)
                }
            }
            setShownEvents(arr)
            setSelectedRoutes(_selectedRoutes)
        } else {
            arr.push(event)
            if (event.routeId) {
                const index = _selectedRoutes.indexOf(event.routeId)
                if (index === -1) {
                    _selectedRoutes.push(event.routeId)
                }
            }

            const groupDevices = await get('event/{accountid}/' + event.id + '/devices')
            event.devices = groupDevices
            setShownEvents(arr)
            setSelectedRoutes(_selectedRoutes)
            selectGroup({ registeredDevices: groupDevices.filter(gd => gd.registeredDeviceId).map(gd => ({ registeredDeviceId: gd.registeredDeviceId })) })
        }
    }

    const toggleRoute = (id) => {
        const _selectedRoutes = [...(selectedRoutes || [])]
        const index = _selectedRoutes.indexOf(id)
        if (index > -1) {
            _selectedRoutes.splice(index, 1)
        } else {
            _selectedRoutes.push(id)
        }
        setSelectedRoutes(_selectedRoutes)
    }

    const zoomIn = function () {
        if (zoom > 17) return
        if (!options.mapType && map) {
            map.zoomIn()
        } else if (options.mapType && bing) {
            bing.setView({
                zoom: bing.getZoom() + 1
            })
        }
    }
    const zoomOut = function () {
        if (zoom < 1) return
        if (!options.mapType && map) {
            map.zoomOut()
        } else if (options.mapType && bing) {
            bing.setView({
                zoom: bing.getZoom() - 1
            })
        }
    }

    const findMe = () => {
        if (location && location.coords) {
            changeCenter([location.coords.latitude, location.coords.longitude])
        }
    }

    const findDevice = (e, device) => {
        e.preventDefault();
        if (device.device.deviceLocation && device.device.deviceLocation.longitude && device.device.deviceLocation.latitude) {
            changeCenter([device.device.deviceLocation.latitude, device.device.deviceLocation.longitude])
        }
    }

    const findPostCode = async (e) => {
        e.preventDefault();
        const results = await lookupPostcode(postCode)
        if (results && results.longitude && results.latitude) {
            changeCenter([results.latitude, results.longitude])
        }
    }

    const changeEventColor = (e, event) => {
        e.preventDefault()
    }

    const setLeafletCenter = center => {
        if (map) {
            map.setView([center.latitude, center.longitude], zoom)
        }
    }

    const leadfletOnMove = e => {
        if (fixed || !map || !osgb) return
        const center = map.getCenter()
        const result = locationToOsgb(center.lat, center.lng)
        if (centerOsgb.result !== result.clean) {
            setCenterOsgb({ result: result.clean, show: true })
            if (osgbTimer.current) clearTimeout(osgbTimer.current)
            osgbTimer.current = setTimeout(() => setCenterOsgb(c => ({ ...c, show: false })), 3000)
        }
    }

    const toggleFence = id => {
        const arr = [...shownFences]
        const index = arr.indexOf(id)
        if (index === -1) {
            arr.push(id)
        } else {
            arr.splice(index, 1)
        }
        setShownFences(arr)
    }

    const pushpin = pushpins || ids.map(id => {
        const d = devices.find(device => device.id === id)
        if (!d) return null
        let outline = 'grey';
        const event = (shownEvents || []).find(e => e.devices.some(d => d.registeredDeviceId === id))
        if (event) outline = event.color || 'grey'
        const osgbResult = locationToOsgb(d.device.deviceLocation.latitude, d.device.deviceLocation.longitude)
        const osbgText = osgbResult.raw.eastings && osgbResult.raw.northings ? <div style={{ textAlign: 'center' }}>OSGB {osgbResult.clean}</div> : ''
        return {
            outline,
            icon: d.registeredDeviceOptions.deviceAvatar,
            color: d.registeredDeviceOptions.colour,
            location: [d.device.deviceLocation.latitude, d.device.deviceLocation.longitude],
            addHandler: "mouseover",
            error: d.device.sos,
            name: d.name,
            infoboxOption: {
                title: <div>
                    {formatDate(d.device.deviceLocation.created)}
                    {Boolean(osbgText) ? <br /> : ''}
                    {osbgText}
                </div>
            },
            pushPinOption: {
                title: osgb && fixed && osbgText ? osbgText : d.name + (d.device.sos ? ' - SOS' : ''),
                open: showName || (osgb && osbgText),
                description: 'Pushpin',
                color: d.registeredDeviceOptions.colour || theme.palette.primary.main
            }
        }
    })

    if (location && location.coords && user && options.me) {
        pushpin.push({
            icon: user.avatar,
            location: [location.coords.latitude, location.coords.longitude],
            addHandler: "mouseover", //on mouseover the pushpin, infobox shown
            infoboxOption: { title: formatDate(new Date(location.timestamp), 'HH:mm') },
            pushPinOption: {
                title: 'You',
                description: 'Pushpin',
                color: theme.palette.primary.main
            }
        })
    }

    const polygon = ids.map(id => {
        const d = devices.find(device => device.id === id)
        if (!d) return null
        return {
            center:
                [d.device.deviceLocation.latitude, d.device.deviceLocation.longitude],
            radius: d.device.deviceLocation.hdop / 10 / 5,
            points: 36,
            option: {
                fillColor: "rgba(0,0,0,0.5)",
                strokeThickness: 2
            }
        }
    })

    if ((fences && fences.length) || shownFences.length) {
        const fenceColors = {
            stroke: [theme.palette.primary.main, theme.palette.success.main, theme.palette.error.main],
            fill: [theme.palette.primary.light, theme.palette.success.light, theme.palette.error.light]
        }
        if (fences) {
            fences.filter(f => f.geofenceType).forEach(f => {
                polygon.push({
                    center: [f.centerLatitude, f.centerLongitude],
                    radius: (f.radius || 100) / 1000,
                    points: 36,
                    option: {
                        strokeThickness: 2,
                        fillColor: fenceColors.fill[f.geofenceType],
                        color: fenceColors.stroke[f.geofenceType],
                    }
                })
            })
        }
        shownFences.forEach(id => {
            const f = allFences.find(fence => fence.id === id)
            if (!f) return
            polygon.push({
                center: [f.centerLatitude, f.centerLongitude],
                radius: (f.radius || 100) / 1000,
                points: 36,
                option: {
                    strokeThickness: 2,
                    fillColor: fenceColors.fill[f.geofenceType],
                    color: fenceColors.stroke[f.geofenceType],
                }
            })
        })
    }

    const shownroutes = [...polyline];
    (selectedRoutes || []).forEach(routeId => {
        const route = routes.find(r => r.id === routeId)
        if (!route) return
        let color = 'grey';
        const event = (shownEvents || []).find(e => e.routeId === routeId)
        if (event) color = event.color || 'grey'
        let legs = route.routePoints.filter(p => p.hidden)
        if (!legs.length) legs = route.routePoints
        const legsWithComments = route.routePoints.filter(l => l.comment)
        legsWithComments.forEach(l => pushpin.push({
            location: [l.latitude, l.longitude],
            addHandler: "mouseover", //on mouseover the pushpin, infobox shown
            infoboxOption: {},
            isRoute: true,
            color: l.colour || theme.palette.primary.main,
            pushPinOption: {
                title: l.comment,
                open: true
            }
        }))
        shownroutes.push({
            color,
            location: legs.map(row => [row.latitude, row.longitude]),
            option: {
                strokeColor: color || theme.palette.primary.main, strokeThickness: 3
            }
        })
    })

    const cleanCenter = center && !isNaN(center[0]) ? center : [53.6231179, -2.1639978]

    return (
        <Fragment>
            <div style={{ zIndex: 1, height: '100%', width: '100%', position: 'relative', cursor: fixed ? 'pointer' : 'default' }} onClick={goTo} onContextMenu={onContextMenu}>
                {!hideOptions && <div className={classes.options}>
                    <Tooltip title="Search" arrow>
                        <Fab onClick={showSearch} size="small" aria-label="Search">
                            <SearchIcon color={'primary'} />
                        </Fab>
                    </Tooltip>
                    <Tooltip title="Toggle Markers" arrow>
                        <Fab onClick={() => toggleOption('markers')} size="small" aria-label="Toggle Markers">
                            <RoomIcon color={options.markers ? 'primary' : 'error'} />
                        </Fab>
                    </Tooltip>
                    <Tooltip title="Geofencing" arrow>
                        <Fab onClick={() => setShowGeofences(true)} size="small" aria-label="Geofencing">
                            <AdjustIcon color="primary" />
                        </Fab>
                    </Tooltip>
                    <Tooltip title="Set Devices" arrow>
                        <Fab onClick={toggleDevices} size="medium" color="primary" aria-label="Set Devices">
                            <SettingsCellIcon />
                        </Fab>
                    </Tooltip>
                    <Tooltip title={"Set Routes"} arrow>
                        <Fab onClick={() => setShowRoutes(true)} size="small" aria-label="Show Routes">
                            <TimelineIcon color={'primary'} />
                        </Fab>
                    </Tooltip>
                    <Tooltip title="Map Type" arrow>
                        <Fab onClick={e => setAnchorEl(e.currentTarget)} size="small" aria-label="Select Map Type">
                            <MapIcon color={'primary'} />
                        </Fab>
                    </Tooltip>

                    <Tooltip title="Find Me" arrow>
                        <Fab onClick={findMe} size="small" aria-label="Find Me">
                            <LocationSearchingIcon color={'primary'} />
                        </Fab>
                    </Tooltip>
                    <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                        <MenuItem onClick={() => setMapType(0)}>Default</MenuItem>
                        <MenuItem onClick={() => setMapType(1)}>Ordnance Survey</MenuItem>
                        <MenuItem onClick={() => setMapType(2)}>Aerial</MenuItem>
                    </Menu>
                </div>}
                <LeafletMap
                    onMove={leadfletOnMove}
                    center={cleanCenter}
                    polylines={shownroutes && shownroutes.length ? shownroutes : undefined}
                    markers={options.markers ? pushpin : undefined}
                    circles={polygon}
                    setZoom={(e) => setZoom(e)}
                    fixed={fixed}
                    setCenter={(e) => setCenter(e)}
                    setMap={mapCreated}
                    onClick={onClick}
                    markerClick={markerClick}
                    overrideCenter={1}
                    className={clsx(classes.map, classes.leaflet, options.mapType !== 0 ? classes.hide : '')}
                />
                {options.mapType > 0 && <BingMap
                    id={id}
                    center={cleanCenter}
                    className={classes.map}
                    mapTypeId={options.mapType === 1 ? "ordnanceSurvey" : "aerial"}
                    polyline={shownroutes && shownroutes.length ? shownroutes : undefined}
                    infoboxesWithPushPins={options.markers && !rawPushpin ? pushpin : undefined}
                    pushPins={options.markers && rawPushpin ? pushpin : undefined}
                    regularPolygons={polygon}
                    showZoomButtons={false}
                    zoom={zoom}
                    fixed={fixed}
                    setZoom={(e) => setZoom(e)}
                    setCenter={(e) => setCenter(e)}
                    setMap={mapCreated}
                    onClick={onClick}
                    markerClick={markerClick}
                    centerChanged={setLeafletCenter}
                />}
                {!hideZoom && <Paper className={`${classes.zoom} ${smallZoom ? classes.smallZoom : null}`} elevation={0}>
                    <Fab onClick={zoomOut} className={classes.fab} size={smallZoom ? 'small' : 'medium'}>
                        <ZoomOutIcon className={`${classes.zoomIcons} ${smallZoom ? classes.smallZoomIcons : ''}`} />
                    </Fab>
                    <Fab onClick={zoomIn} className={classes.fab} size={smallZoom ? 'small' : 'medium'}>
                        <ZoomInIcon className={`${classes.zoomIcons} ${smallZoom ? classes.smallZoomIcons : ''}`} />
                    </Fab>
                </Paper>}
            </div>

            {Array.isArray(devices) && <SwipeableDrawer disableSwipeToOpen={true} open={showDevices} onOpen={() => setShowDevices(true)} onClose={() => setShowDevices(false)} >
                <div className={classes.drawerContainer}>
                    <Accordion defaultExpanded style={{ margin: 0 }}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography variant="h6">Devices</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ display: 'block', maxHeight: '33%', overflow: 'auto' }}>
                            {Boolean(location) && Boolean(location.coords) && <List>
                                <ListItem dense button onClick={() => toggleOption('me')}>
                                    <ListItemAvatar>
                                        <Avatar className={classes.avatar} src={user.avatar} alt={user.firstName + ' ' + user.lastName}>{((user.firstName || user.surname) || ' ')[0]}</Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={'My Location'} id={-1} />
                                    <ListItemSecondaryAction>
                                        <Checkbox edge="start" color="primary" checked={options.me} onClick={() => toggleOption('me')} disableRipple inputProps={{ 'aria-labelledby': -1 }} />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </List>}
                            {Boolean(location) && Boolean(location.coords) && <Divider />}
                            {Boolean(toggleDevice) && <List component="nav">
                                {devices.map(device => {
                                    const active = device.device.lastOnline && moment(device.device.lastOnline) > moment().startOf('day')
                                    const updateInterval = device.device.deviceSettings.updateInterval
                                    let stale = false
                                    if (active) {
                                        stale = moment.utc().subtract(updateInterval * 2, 'seconds') > moment.utc(device.device.lastOnline)
                                    }

                                    const props = {}
                                    if (device.device.sos) {
                                        props.color = 'error'
                                    } else if (active && !stale) {
                                        props.color = 'primary'
                                    }
                                    return <ListItem key={device.id} dense button onClick={() => toggleDevice(device.id)}>
                                        <ListItemAvatar>
                                            <Fragment>
                                                {active && <StyledBadge className={classes.badge} overlap="circle" anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} variant="dot"  {...props}>
                                                    <Avatar src={device.registeredDeviceOptions.deviceAvatar} className={classes.avatar} style={{ backgroundColor: device.registeredDeviceOptions.colour }}>{device.name[0]}</Avatar>
                                                </StyledBadge>}
                                                {!active && <Avatar style={{ backgroundColor: device.registeredDeviceOptions.colour }} className={classes.avatar} alt={device.name} src={device.registeredDeviceOptions.deviceAvatar}>{device.name[0]}</Avatar>}
                                            </Fragment>
                                        </ListItemAvatar>
                                        <ListItemText primary={device.name} id={device.id} />
                                        <ListItemSecondaryAction>
                                            <Checkbox edge="start" color="primary" checked={ids.indexOf(device.id) !== -1} onClick={() => toggleDevice(device.id)} tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': device.id }} />
                                            <IconButton onClick={(e) => findDevice(e, device)} size="small" aria-label="Find Device">
                                                <LocationSearchingIcon fontSize="small" />
                                            </IconButton >
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                })}
                            </List>}
                        </AccordionDetails>
                    </Accordion>

                    {Boolean(groups) && Boolean(groups.length) && <Accordion style={{ margin: 0 }}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography variant="h6">Groups</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ display: 'block', maxHeight: '33%', overflow: 'auto' }}>
                            {!!groups && !!groups.length && <List component="nav">
                                {groups.map(group => <ListItem key={group.id} button onClick={() => onSelectGroup(group)}>
                                    <ListItemText primary={group.name} id={group.id} />
                                    <ListItemSecondaryAction>({group.registeredDevices.length})</ListItemSecondaryAction>
                                </ListItem>)}
                            </List>}
                        </AccordionDetails>
                    </Accordion>}

                    {Boolean(events.length) && <Accordion defaultExpanded={Boolean(shownEvents) && Boolean(shownEvents.length)} style={{ margin: 0 }}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography variant="h6">Events</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ display: 'block', maxHeight: '33%', overflow: 'auto' }}>
                            <List component="nav">
                                {events.map(event => <ListItem key={event.id} dense button onClick={() => onSelectEvent(event)}>
                                    <ListItemAvatar onClick={e => changeEventColor(e, event)}>
                                        <Avatar className={classes.avatar} style={{ backgroundColor: event.color }}>{''}</Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={event.name} id={event.id} />
                                    <ListItemSecondaryAction>
                                        <Checkbox edge="start" color="primary" checked={(shownEvents || []).some(e => e.id === event.id)} onClick={() => onSelectEvent(event)} disableRipple inputProps={{ 'aria-labelledby': -1 }} />
                                    </ListItemSecondaryAction>
                                </ListItem>)}
                            </List>
                        </AccordionDetails>
                    </Accordion>}
                </div>

            </SwipeableDrawer>}

            {Boolean(routes) && Boolean(routes.length) && <SwipeableDrawer disableSwipeToOpen={true} open={showRoutes} onOpen={() => setShowRoutes(true)} onClose={() => setShowRoutes(false)} >
                <Container className={classes.drawerContainer}>
                    <Typography variant="h6" align="center" className={classes.drawerContent}>Select Routes</Typography>
                    <List component="nav">
                        {routes.slice().sort((a, b) => a.name.localeCompare(b.name)).map(route => <ListItem key={route.id} dense button onClick={() => toggleRoute(route.id)}>
                            <ListItemText primary={route.name} id={route.id} />
                            <ListItemSecondaryAction>
                                <Checkbox edge="start" checked={(selectedRoutes || []).indexOf(route.id) !== -1} onClick={() => toggleRoute(route.id)} tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': route.id }} />
                            </ListItemSecondaryAction>
                        </ListItem>)}
                    </List>
                </Container>
            </SwipeableDrawer>}

            <SwipeableDrawer disableSwipeToOpen={true} open={search} onOpen={() => setSearch(true)} onClose={() => setSearch(false)}>
                <form onSubmit={findPostCode}>
                    <Container className={classes.drawerContainer}>
                        <Typography variant="h6" align="center" className={classes.drawerContent}>Search by Post Code</Typography>
                        <TextField fullWidth required inputRef={searchInput} label="Post Code" style={{ marginBottom: 10 }} value={postCode} onChange={e => setPostCode(e.target.value)} />
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Button variant="contained" fullWidth color="primary" type="submit">Search</Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button variant="contained" fullWidth onClick={() => setSearch(false)}>Cancel</Button>
                            </Grid>
                        </Grid>
                    </Container>
                </form>
            </SwipeableDrawer>


            <SwipeableDrawer disableSwipeToOpen={true} open={showGeofences} onOpen={() => setShowGeofences(true)} onClose={() => setShowGeofences(false)}>
                <GeofenceDrawer enabled={shownFences} fences={allFences} addFence={() => { }} onFinish={() => setShowGeofences(false)} toggleFence={toggleFence} />
            </SwipeableDrawer>

            <div className={clsx(classes.target, Boolean(centerOsgb.show) ? classes.targetOpen : '')}>
                <FilterCenterFocus size="large" style={{ fontSize: 30 }} />
            </div>

            {osgb && <div className={clsx(classes.bottomDrawer, Boolean(centerOsgb.show) ? classes.bottomDrawerOpen : '')}>
                <Typography variant="body2" style={{ textAlign: 'center' }}>
                    OSGB {centerOsgb.result}
                </Typography>
            </div>}
        </Fragment >
    );
}


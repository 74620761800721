import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import authUtils from 'utils/auth'
import { destroy, full, get } from 'utils/resources'
import { post } from 'utils/http';
import { Grid, Paper, Typography, makeStyles, Button, TextField, Container, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import LoadingIcon from 'Components/LoadingIcon'
import { useHistory } from 'react-router'
import logo from 'Assets/Lockup-Colour.png'

const myStlyes = makeStyles(theme => ({
    container: {
        height: '100%',
        position: 'absolute',
        backgroundImage: 'url(https://source.unsplash.com/user/meiligps/likes)',
        backgroundRepeat: 'no-repeat',
        backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        padding: theme.spacing(3),
        opacity: 0.9,
        borderRadius: 20
    },
    button: {
        margin: theme.spacing(1)
    }
}))

export default function AcceptDevice() {
    const classes = myStlyes()
    const [invite, setInvite] = useState(null)
    const [query, setQuery] = useState(null)
    const [loginObj, setLoginObj] = useState({ email: '', password: '', confirmPassword: '', acceptTerms: true })
    const [working, setWorking] = useState(false)
    const [showingLogin, setShowingLogin] = useState(false)
    const [showingRegister, setShowingRegister] = useState(false)
    const [error, setError] = useState('')
    const [accounts, setAccounts] = useState([])
    const [accountId, setAccountId] = useState('')

    const history = useHistory()

    useEffect(() => {
        const query = queryString.parse(window.location.search)
        setQuery(query)

        const options = full('share?a=' + query.a + '&t=' + query.t, { method: 'GET' }, true, true)
        fetch(options.url, options.options).then(response => response.json()).then(data => setInvite(data))

        const auth = authUtils.auth()
        if (auth && auth.id) {
            getAccounts()
        }
    }, [])

    const getAccounts = async () => {
        const data = await get('account')
        setAccounts(data)
        setAccountId(data[0].id)
    }

    const changeAccount = (e) => {
        setAccountId(e.target.value)
    }

    const accept = async () => {
        await get('share/' + accountId + '/accept?a=' + query.a + '&t=' + query.t, true)
        history.push('/?a=' + accountId)
    }

    const decline = async () => {
        await destroy('share/' + query.a + '/' + invite.id, null, true)
        history.push('')
    }

    const submit = async (e) => {
        e.stopPropagation()
        e.preventDefault()
        setWorking(true)
        setError('')
        if (showingRegister) {
            const result = await post("auth/register?token=" + query.t, loginObj)
            if (result && result.id) {
                await login()
            } else if (result.message) {
                setError(result.message)
                setWorking(false)
            }
        } else {
            await login()
        }
    }

    const login = async () => {
        const result = await post('auth/authenticate', { email: loginObj.email, password: loginObj.password }, true)
        if (result && result.id) {
            authUtils.setAuth(result)
            setShowingLogin(false)
            setShowingRegister(false)
            getAccounts()
        } else if (result.message) {
            setError(result.message)
        }
        setWorking(false)
    }

    const handleInputChange = (e) => {
        setLoginObj({ ...loginObj, [e.target.name]: e.target.value })
    }

    if (!invite) {
        return <LoadingIcon />
    }

    const auth = authUtils.auth()


    let registerText = 'Login'
    if (showingRegister) registerText = 'Register'
    if (working) registerText = 'Working'

    return (
        <Grid container justify="center" alignItems="center" className={classes.container}>
            <Paper className={classes.paper} elevation={6}>
                <div style={{ textAlign: 'center' }}>
                    <img src={logo} height="180" className="logo-img" alt="meili-logo" />
                </div>
                <Typography align="center" variant="h5">You have been Invited to use {invite.deviceName}</Typography>
                {Boolean(auth) && Boolean(auth.id) && <Grid container justify="center">
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel id="account">Account</InputLabel>
                        <Select id="account" value={accountId} onChange={changeAccount}>
                            {accounts.map(account => <MenuItem key={account.id} value={account.id}>{account.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <Button className={classes.button} onClick={accept} variant="contained" color="primary">Accept</Button>
                    <Button className={classes.button} onClick={decline} variant="contained">Decline</Button>
                </Grid>}
                {(!auth || !auth.id) && <Grid container justify="center">
                    <Button variant="contained" className={classes.button} color="primary" onClick={() => { setShowingLogin(true); setShowingRegister(false); setError(''); }}>Login</Button>
                    <Button variant="contained" className={classes.button} color="secondary" onClick={() => { setShowingRegister(true); setError(''); }}>Register</Button>
                </Grid>}
                {(showingLogin || showingRegister) && <Container maxWidth="sm">
                    <form onSubmit={submit}>
                        <TextField variant="outlined" margin="normal" required fullWidth label="Email Address" name="email" autoComplete="email" autoFocus onChange={handleInputChange} value={loginObj.email} />
                        <TextField variant="outlined" margin="normal" required fullWidth label="Password" name="password" type="password" autoComplete="current-password" value={loginObj.password} onChange={handleInputChange} />
                        {showingRegister && <TextField variant="outlined" margin="normal" required fullWidth name="confirmPassword" label="Confirm Password" type="password" autoComplete="current-password" value={loginObj.confirmPassword} onChange={handleInputChange} />}
                        <Grid container direction="column" alignItems="center">
                            {Boolean(error) && <div> <Typography style={{ marginBottom: '10px' }}>{error}</Typography></div>}
                            <div>
                                <Button variant="contained" color="primary" type="submit" disabled={working}>{registerText}</Button>
                            </div>
                        </Grid>
                    </form>
                </Container>}
            </Paper>
        </Grid>
    )
}
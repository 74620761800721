import React, { useEffect, useState } from 'react';
import queryString from 'query-string'
import { post } from 'utils/http';
import LoadingIcon from 'Components/LoadingIcon';
import auth from 'utils/auth'
import { Container, makeStyles, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux'
import { setAuth } from 'store/actions';

const myStyle = makeStyles(theme => ({
    container: {
        padding: theme.spacing(4)
    }
}))

export default function Verify() {
    const classes = myStyle()
    const [query, setQuery] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState('')
    const history = useHistory()
    const dispatch = useDispatch()


    useEffect(() => {
        const query = queryString.parse(window.location.search)
        if (query && query.token) {
            setQuery(query)
        } else {
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        (async () => {
            if (!query) return
            const response = await post('auth/verify-email', {
                token: query.token
            })
            const success = response.message === 'Verification successful, you can now login'
            if (success) {
                const result = await auth.checkAuth()
                if (result && result.id) {
                    auth.setAuth(result)
                    dispatch(setAuth(result))
                    history.push('/')
                } else {
                    history.push('/login')
                }
            } else {
                setError(response.message || 'Verification Link Invalid')
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])


    return (

        <>

            <Container maxWidth="sm" className={classes.container}>

                <Typography variant="h5" align="center">Verifying...</Typography>
                {loading == true && <LoadingIcon colour='light' />}
                {error && <Typography variant="h5" color="error" align="center">{error}</Typography>}
            </Container>
        </>
    )
}
import React, { Fragment, useEffect, useState, useRef } from 'react'
import GenericMap from 'Components/Map'
import { makeStyles, useTheme } from '@material-ui/styles'
import { formatDate } from 'utils/format'
import { AppBar, Link, Switch, FormControlLabel, Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import LogoLight from 'Assets/Lockup-Horizontal-White.png'

const myStyle = makeStyles(theme => ({
    content: {
        height: 'calc(100% - 56px)',
        width: '100%',
        top: 56,
        position: 'fixed'
    },
    appBar: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    button: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 10
    },
    control: {
        [theme.breakpoints.down("sm")]: {
            '& .MuiFormControlLabel-label': {
                fontSize: 12
            }
        }
    },
    buttonAction: {
        [theme.breakpoints.down("sm")]: {
            display: 'none'
        }
    }
}))

export default function Map({ location }) {
    const classes = myStyle()
    const theme = useTheme()
    const myLocationTimer = useRef(null)
    const history = useHistory()
    const [myLocation, setMyLocation] = useState(null)
    const [showMyLocation, setShowMyLocation] = useState(false)


    const getMyLocation = () => {
        if (!showMyLocation) return
        navigator.geolocation.getCurrentPosition(position => {
            setMyLocation(position)
            myLocationTimer.current = setTimeout(getMyLocation, 5000)
        })
    }

    useEffect(() => {
        if (showMyLocation) {
            getMyLocation()
        } else if (myLocationTimer.current) {
            clearTimeout(myLocationTimer.current)
            setMyLocation(null)
        }
        // eslint-disable-next-line
    }, [showMyLocation])

    useEffect(() => {
        return function () {
            if (myLocationTimer.current) {
                clearTimeout(myLocationTimer.current)
            }
        }
    }, [])

    const pushpins = [{
        location: [location.latitude, location.longitude],
        color: theme.palette.primary.main,
        pushPinOption: { title: formatDate(new Date(location.created), 'HH:mm'), open: true },
        infoboxOption: {}
    }]

    if (myLocation && myLocation.coords) {
        pushpins.push({
            location: [myLocation.coords.latitude, myLocation.coords.longitude],
            color: theme.palette.secondary.main,
            pushPinOption: { title: 'Your Location', open: true },
            infoboxOption: {}
        })
    }
    return (
        <Fragment>
            <AppBar position="absolute" >
                <div className={classes.appBar}>
                    <Link href="https://meili.earth" target="_blank" alt="homepage">
                        <img src={LogoLight} height="50" width="auto" alt="meili-logo" />
                    </Link>
                    <div className={classes.button}>
                        <FormControlLabel
                            className={classes.control}
                            control={<Switch checked={showMyLocation} onChange={() => setShowMyLocation(s => !s)} name="showMyLocation" color="secondary" />}
                            label="Show My Location"
                        />
                        <Button className={classes.buttonAction} onClick={() => history.push('/login')} variant="contained">Login/Register</Button>
                    </div>
                </div>
            </AppBar>
            <div className={classes.content}>
                <GenericMap hideOptions={true} pushpins={pushpins} defaultLocation={[location.latitude, location.longitude]} />
            </div>
        </Fragment>
    )
}
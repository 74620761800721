import React, { useState } from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, Grid, Fab, Tooltip, Drawer } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import RoleRow from './roleRow'
import AddRole from './add';

export default function Roles({ roles, users, update }) {
    const [addingRole, setAddingRole] = useState(null)

    const deleteRole = async (role) => {

    }

    const close = function () {
        setAddingRole(null)
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12} xl={12}>
                <Grid container direction="row" justify="flex-end" alignItems="flex-end" style={{ height: '100%' }}>
                    <Tooltip title="Add" aria-label="add" >
                        <Fab color="primary" size="small" onClick={() => setAddingRole({})}>
                            <Add />
                        </Fab>
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Table basic='very' selectable>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Users</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {roles.map(role => {
                            let _users = users.filter(u => u.roleId === role.id)
                            return <RoleRow role={role} update={() => setAddingRole(role)} users={_users} deleteRole={() => deleteRole(role)} />
                        })}
                    </TableBody>
                </Table>
            </Grid>
            <Drawer anchor="right" open={Boolean(addingRole)} onClose={() => setAddingRole(null)}>
                <AddRole existing={addingRole} close={close} />
            </Drawer>
        </Grid>
    );

}
import React, { Fragment, useState } from 'react'
import { TableRow, TableCell, Button, MenuItem, Menu } from '@material-ui/core'

export default function RoleRow({ role, users, update, deleteRow }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const buttonClick = (e) => {
        setAnchorEl(e.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <TableRow key={role.id}>
            <TableCell>{role.name}</TableCell>
            <TableCell>{users.length}</TableCell>
            <TableCell align="right">
                {role.name !== 'Administrator' && <Fragment>
                    <Button onClick={buttonClick}>Options</Button>
                    <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                        <MenuItem onClick={update}>Edit</MenuItem>
                        <MenuItem onClick={deleteRow}>Remove</MenuItem>
                    </Menu>
                </Fragment>}
            </TableCell>
        </TableRow>
    )
}
import React, { useEffect, useState } from 'react'
import { Grid, Table, TableRow, TableHead, TableCell, TableBody, Button, makeStyles, Typography, Chip } from '@material-ui/core'
import { formatDate } from 'utils/format'
import { post, destroy, get } from 'utils/resources'
import { useSelector, useDispatch } from 'react-redux'
import { setNotifications } from 'store/actions'
import NotificationCard from './notificationCard'
import Alert from 'Components/Alert'

const myTheme = makeStyles(theme => ({
    holder: {
        position: 'relative'
    },
    buttons: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    table: {
        display: 'table',
        '& row': {
            opacity: 0.9
        },
        [theme.breakpoints.down("sm")]: {
            display: 'none'
        }
    },
    grid: {
        display: 'none',
        [theme.breakpoints.down("sm")]: {
            display: 'block'
        },
    },
    SOS: { backgroundColor: theme.palette.error.main },
    High: { backgroundColor: theme.palette.warning.main },
    Medium: { backgroundColor: theme.palette.info.main },
    Low: { backgroundColor: theme.palette.grey.main }
}))

export default function Notifications({ device, getDevice }) {
    const classes = myTheme()
    const [showArchived, setShowArchived] = useState(false)
    const [clearAllConfirm, setClearAllConfirm] = useState(false)
    const [allNotifications, setAllNotifications] = useState([])
    const [alert, setAlert] = useState('')
    const notifications = useSelector(state => state.notifications)
    const dispatch = useDispatch()

    useEffect(() => {
        (async () => {
            let data = await get('notification/{accountid}/device/' + device.id)
            setAllNotifications(data)
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const toggleArchived = () => {
        setShowArchived(!showArchived)
    }

    const clearNotification = async function (notification) {
        await post('notification/' + notification.id)
        dispatch(setNotifications(await get('notification/new')))
        setAlert('Notification Cleared')
    }
    const clearAllNotifications = async function () {
        setClearAllConfirm(false);
        await get('notification/{accountid}/device/' + device.id + '/acknowledgeall')
        dispatch(setNotifications(await get('notification/new')))
        setAlert('All Notifications Cleared')
        await clearSos()
        getDevice()
    }
    const deleteNotification = async function (notification) {
        await destroy('notification/' + notification.id)
        setAllNotifications(await get('notification/{accountid}/device/' + device.id))
        setAlert('Notification Deleted')
    }

    const clearSos = async () => {
        await destroy(`device/{accountid}/${device.id}/acknowledgeSOS`)
        getDevice()
    }

    const myNotifications = (showArchived ? allNotifications : notifications).filter(n => n.imei === device.device.imei).slice().reverse().sort((a, b) => b.notificationSeverity - a.notificationSeverity)
    const severityMap = ['Low', 'Medium', 'High', 'SOS']

    return (
        <Grid item xs={12} sm={10} md={10} lg={6} xl={6} className={classes.holder}>
            <div align="center" className={classes.buttons} style={{ marginBottom: '10px' }}>
                <Button color={showArchived ? 'primary' : 'default'} variant="contained" onClick={toggleArchived}>{showArchived ? 'Hide Archived' : 'Show Archived'}</Button>
                {!clearAllConfirm && <Button variant="contained" onClick={() => setClearAllConfirm(true)}>Clear All</Button>}
                {clearAllConfirm && <Button color='primary' variant="contained" onClick={() => clearAllNotifications()}>Confirm Clear All</Button>}
                {device.device.sos && <Button variant="contained" color="primary" onClick={clearSos}>Clear SOS</Button>}
            </div>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>Date/Time</TableCell>
                        <TableCell>Severity</TableCell>
                        <TableCell>Message</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {myNotifications.map(row => {
                        const className = classes[severityMap[row.notificationSeverity]]
                        return <TableRow key={row.id}>
                            <TableCell>{formatDate(row.notificationDate)}</TableCell>
                            <TableCell>
                                <Chip label={severityMap[row.notificationSeverity]} className={className} />
                            </TableCell>
                            <TableCell>{row.message}</TableCell>
                            <TableCell>
                                {!row.isAcknowledged && <Button size="small" variant="contained" onClick={() => clearNotification(row)}>Clear</Button>}
                                {row.isAcknowledged && <Button size="small" variant="contained" color="secondary" onClick={() => deleteNotification(row)}>Delete</Button>}
                            </TableCell>
                        </TableRow>
                    })}
                    {!myNotifications.length && <TableRow>
                        <TableCell colSpan="99" align="center">No Notifications</TableCell>
                    </TableRow>}
                </TableBody>
            </Table>
            <Grid container spacing={3} className={classes.grid}>
                {myNotifications.map(row => {
                    const className = classes[severityMap[row.notificationSeverity]]
                    return <NotificationCard className={className} key={row.id} notification={row} clearNotification={() => clearNotification(row)} deleteNotification={() => deleteNotification(row)} />
                })}
                {!myNotifications.length && <Typography style={{ marginTop: 20 }} variant="h5" align="center">No Notifications</Typography>}
            </Grid>

            <Alert alert={alert} setAlert={setAlert} />
        </Grid >

    )
}
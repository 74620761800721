import { Card, Grid, Typography, Badge, Avatar, Button, makeStyles, withStyles, CardContent } from '@material-ui/core'
import { AvatarGroup } from '@material-ui/lab'
import moment from 'moment'
import React from 'react'

const StyledBadge = withStyles((theme) => ({
    badge: {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: '$ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}))(Badge);

const useStyles = makeStyles(theme => ({
    avatar: {
        border: 'none'
    },
    name: {
        justifyContent: 'flex-start'
    },
    card: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2)
    }
}))

export default function GroupCard({ group, goTo, edit, onDelete }) {
    const classes = useStyles()
    return (
        <Grid item xs={12} sm={8} md={6} lg={4} xl={3} className={classes.card}>
            <Card variant="outlined">
                <CardContent>
                    <Typography variant="h5" align="center">{group.name}</Typography>
                    <Grid container alignContent="center" justify="center" style={{ marginTop: 10 }}>
                        <AvatarGroup>
                            {group.registeredDevices.map(d => {
                                if (moment(d.registeredDevice.device.lastOnline).isSame(new Date(), 'day')) {
                                    return <StyledBadge key={d.id} overlap="circle" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot" className={classes.avatar}>
                                        <Avatar style={{ backgroundColor: d.registeredDevice.registeredDeviceOptions.colour }} alt={d.registeredDevice.name} src={d.registeredDevice.registeredDeviceOptions.deviceAvatar}>{d.registeredDevice.name[0]}</Avatar>
                                    </StyledBadge>
                                } else {
                                    return <Avatar style={{ backgroundColor: d.registeredDevice.registeredDeviceOptions.colour }} key={d.id} className={classes.avatar} alt={d.registeredDevice.name} src={d.registeredDevice.registeredDeviceOptions.deviceAvatar}>{d.registeredDevice.name[0]}</Avatar>
                                }
                            })}
                        </AvatarGroup>
                    </Grid>
                    <Grid container spacing={3} style={{ marginTop: 10 }}>
                        <Grid item xs>
                            <Button disabled={!group.registeredDevices.length} variant="contained" fullWidth color="primary" onClick={() => goTo('groups/' + group.id)}>View</Button>
                        </Grid>
                        <Grid item xs>
                            <Button variant="contained" fullWidth onClick={edit}>Edit</Button>
                        </Grid>
                        <Grid item xs>
                            <Button variant="contained" fullWidth color="secondary" onClick={onDelete}>Remove</Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    )
}
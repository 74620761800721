import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next) // bind react-i18next to the instance
    .init({
        load: 'languageOnly', // this stops loading en-GB and only loads en
        fallbackLng: 'en',
        // debug: true,
        ns: ['default'],
        defaultNS: 'default',
        interpolation: {
            escapeValue: false, // not needed for react!!
        },
        react: {
            wait: true
        },
        parseMissingKeyHandler: function (key) {
            var split = key.split('.')
            if (split.length > 1) return split[split.length - 1]
            else {
                return key.split(':')[key.split(':').length - 1]
            }
        }
    });

export default i18n;
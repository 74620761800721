import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { Wallpaper } from '@material-ui/icons';

export default function Step1({ device, setDevice }) {
    return (
        <Container maxWidth="md">
            <Typography variant="h6" align="center">Open the bottom of the tracker</Typography>
            <Wallpaper style={{ fontSize: 72 }} />
        </Container>
    )
}
import React from 'react'
import { useHistory, useLocation } from "react-router";
import { ListItem, ListItemText, ListItemIcon } from '@material-ui/core'
import { SupervisorAccount, Smartphone, LiveHelp, Notifications, DirectionsWalk, Event } from '@material-ui/icons'

export default function AdminNav() {
    const history = useHistory()
    const location = useLocation()

    const handleClick = n => () => history.push('/admin/' + n)
    const activeItem = (location.pathname.split('/admin')[1] || '/').toLowerCase()

    return (
        <div>
            <ListItem button selected={activeItem === '/accounts'} onClick={handleClick('accounts')}>
                <ListItemIcon>
                    <SupervisorAccount />
                </ListItemIcon>
                <ListItemText primary="Accounts" />
            </ListItem>
            <ListItem button selected={activeItem === '/devices'} onClick={handleClick('devices')}>
                <ListItemIcon>
                    <Smartphone />
                </ListItemIcon>
                <ListItemText primary="Devices" />
            </ListItem>
            <ListItem button selected={activeItem === '/events'} onClick={handleClick('events')}>
                <ListItemIcon>
                    <Event />
                </ListItemIcon>
                <ListItemText primary="Events" />
            </ListItem>
            <ListItem button selected={activeItem === '/routes'} onClick={handleClick('routes')}>
                <ListItemIcon>
                    <DirectionsWalk />
                </ListItemIcon>
                <ListItemText primary="Routes" />
            </ListItem>
            <ListItem button selected={activeItem === '/notifications'} onClick={handleClick('notifications')}>
                <ListItemIcon>
                    <Notifications />
                </ListItemIcon>
                <ListItemText primary="Notifications" />
            </ListItem>
            <ListItem button selected={activeItem === '/support'} onClick={handleClick('support')}>
                <ListItemIcon>
                    <LiveHelp />
                </ListItemIcon>
                <ListItemText primary="Support" />
            </ListItem>
        </div>
    )
}
import React from 'react'
import { Card, CardActions, CardContent, CardHeader, Grid, IconButton, Typography } from '@material-ui/core'
import { Archive, Delete, Warning, Info, Help, Error } from '@material-ui/icons'
import { formatDate } from 'utils/format'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'

const myStyle = makeStyles(theme => ({
    button: {
        margin: '0 auto'
    },
    content: {
        padding: theme.spacing(1)
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    card: { borderWidth: 2 },
    '3': { borderColor: theme.palette.error.main },
    '2': { borderColor: theme.palette.warning.main },
    '1': { borderColor: theme.palette.info.main },
    '0': { borderColor: theme.palette.grey.main },
}))

export default function NotificationCard({ notification, clearNotification, deleteNotification }) {
    const severityMapIcons = {
        0: <Help color="disabled" />, 1: <Info color="primary" />, 2: <Warning color="secondary" />, 3: <Error color="error" />
    }
    const severityMap = ['Low', 'Medium', 'High', 'SOS']
    const classes = myStyle()

    const clear = function () {
        if (notification.isAcknowledged) deleteNotification()
        else clearNotification()
    }

    return (<Grid item xs={12} >
        <Card variant="outlined" className={clsx(classes.card, classes[notification.notificationSeverity])}>
            <CardHeader
                title={<div className={classes.title}>
                    <div>{formatDate(notification.notificationDate)}</div>
                    <div>{severityMap[notification.notificationSeverity]}</div>
                </div>}
                avatar={severityMapIcons[notification.notificationSeverity]} />
            <CardContent className={classes.content}>
                <Typography align="center" variant="body1">
                    {notification.message}
                </Typography>
            </CardContent>
            <CardActions>
                <IconButton onClick={clear} className={classes.button}>
                    {notification.isAcknowledged && <Delete />}
                    {!notification.isAcknowledged && <Archive />}
                </IconButton>
            </CardActions>
        </Card>
    </Grid >)
}